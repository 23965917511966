import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Users } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { BootstrapTooltip } from '../common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { getRolesUserData, getUsers } from '../Apicommon'
import { Modal } from 'antd'
$.fn.DataTable = dt;
export default function AddLoginUser() {
	const [rolesData,setRolesData]=useState([])
	const [filterrolesData,setfilterRolesData]=useState([])
    const [loader,setLoader]=useState(false)
    const history=useHistory()
    const [rolesList,setRolesList]=useState([])
const [isModalVisible, setIsModalVisible] = useState(false);
    const [filterText,setFilterText]=useState({roles:'',FirstName:'',LastName:'',mobileNo:'',email:'',active:''})
      const ongetRolesUserData=async()=>{
        setLoader(true);
            $(".loader").fadeOut("slow");
    const data=await getUsers()
    if(data.success===true){
      setRolesData(data.data.data)
	  setfilterRolesData(data.data.data)
    }else{
      console.log('somthing wait wrong')
      setLoader(false)
    }
      }

	  const ongetRoles=async()=>{
		const data=await getRolesUserData()
		if(data.success===true){
			setRolesList(data.data.data)
		}else{
		  console.log('somthing wait wrong')
		}
	  }

	  const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onHandaleChange=(e)=>{
		setFilterText({...filterText,[e.target.name]:e.target.value})
	}

	const onhandleOk=()=>{
		setIsModalVisible(false);
		if(filterText?.roles===''&&filterText?.FirstName===''&&filterText?.LastName===''&&filterText?.mobileNo===null&&filterText?.email===''){
			return setRolesData(filterrolesData)
		}
		const filterArray=filterrolesData.filter((data)=>(
			(filterText?.roles!==''?data?.roles.includes(filterText?.roles):[]) &&
					(filterText?.FirstName!==''?data?.FirstName.includes(filterText?.FirstName):[]) &&
					(filterText?.LastName!==''?data?.LastName.includes(filterText?.LastName):[]) &&
					(filterText?.mobileNo!==''?data?.mobileNo==filterText?.mobileNo:[] )&&
					(filterText?.email!==''?data?.email.includes(filterText?.email):[])&&
					(filterText?.active!==''?data?.active==filterText?.active:[])
		))
		setRolesData(filterArray)
	}
    
      useEffect(() => {
            ongetRolesUserData().then(() =>
                setTimeout(function () {
                    $("#table-1").DataTable({destroy: true,"pageLength": 50});
					setLoader(false);

                }, 700)
            );
			ongetRoles()
        }, []);
    
      const exportPDF = () => {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape
        
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
        
            doc.setFontSize(15);
        
            const title = ` Roles and Users  Data`;
            const headers = [["SRNO","Users UNIQUE Id","LastName","FirstName","email","roles","mobileNo","active"]];
        
            const data = filterrolesData&&filterrolesData.map(elt=> [elt.SRNO,elt.userID,elt.LastName,elt.FirstName,elt.email,elt.roles,elt.mobileNo,elt.active===1?'active':'Inactive']);
        
            let content = {
              startY: 50,
              head: headers,
              body: data
            };
        
            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save(`User.pdf`)
          }
    
        const excelFileExport=async()=>{
          const array=await  filterrolesData.map((item)=>({SRNO:item.SRNO,userID:item.userID,LastName:item.LastName,FirstName:item.FirstName,email:item.email,roles:item.mobileNo,active:item.active===1?'active':'Inactive'}))
          const worksheet = XLSX.utils.json_to_sheet(array);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, "UsersSheet.xlsx");
            
        }
    
        const UpdateRolesUser=(item)=>{
            history.push({ pathname: `/add-users`, state: item })
        }

		
  return (
	<>
	<Modal title="Filter Users" visible={isModalVisible} footer="" onCancel={handleCancel} width={800}>
		<div class="form-row">
            <div class="form-group col-md-3">
              <label for="inputPassword4">Roles:</label>
              <select id="inputState" class="form-select form-control" name="roles" onChange={onHandaleChange} value={filterText.roles}>
                <option value="">Select Roles</option>
                                  {rolesList&&rolesList.map((item)=>(
                <option value={item.ROLES_NAME}>{item.ROLES_NAME}</option>))}
                              </select>
            </div>
            <div class="form-group col-md-3">
              <label for="inputState">First Name:</label>
              <input type="text" class="form-control" id="" onChange={onHandaleChange} value={filterText.FirstName} name="FirstName"  placeholder="Enter First Name"/>
            </div>
            <div class="form-group col-md-3">
              <label for="inputState">Last Name:</label>
              <input type="text" class="form-control" id="" name="LastName" onChange={onHandaleChange} value={filterText.LastName} placeholder="Enter Last Name"/>
            </div>
            <div class="form-group col-md-3">
              <label for="inputState">Moblie No:</label>
              <input type="number" class="form-control" id="" name="mobileNo" onChange={onHandaleChange} value={filterText.mobileNo} placeholder="Enter Mobile No"/>
            </div>
            <div class="form-group col-md-3">
              <label for="inputState">Email:</label>
              <input type="text" class="form-control" id="" name="email" onChange={onHandaleChange} value={filterText.email} placeholder="Enter Email"/>
            </div>
            <div class="form-group col-md-3">
              <label for="inputPassword4">User Status:</label>
              <select id="inputState" class="form-control form-select" name="active" onChange={onHandaleChange} value={filterText.active}>
                <option value=''>Select status</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
              </select>
            </div>
          </div>
		  <div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to="/users">
						Reset
					</Link>
				</div>
					</Modal>
    <div className="main-content">
    {loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to="/group-dashboard">
							<i className="fas fa-tachometer-alt"></i> Home
						</Link>
					</li>
					<li className="breadcrumb-item">
						<Link to="/roles-users">
							<Users style={{ height: "16px" }} /> Roles & Users
						</Link>
					</li>
                    <li className="breadcrumb-item">
						<Link to="/users">
							 Users
						</Link>
					</li>
					
				</ol>
			</nav>
			<section className="section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4>Users</h4>
									<div className="card-header-action">
										<Link to="/add-users" className="btn btn-info text-white mr-2">
											<BootstrapTooltip title="Add">
												<span>
													<i
														className="fas fa-plus text-white"
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
										</Link>
										<button type="button" className="btn btn-info mr-2" onClick={()=>(setIsModalVisible(true))}>
											<BootstrapTooltip title="Filter">
												<span>
													<i className="fa fa-filter text-white"></i>
												</span>
											</BootstrapTooltip>
										</button>
										<div className="dropdown d-inline mr-2">
											<button
												className="btn btn-info dropdown-toggle mr-2"
												type="button"
												id="dropdownMenuButton3"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
												data-placement="bottom"
												title="Download"
											>
												<i className="fa fa-download mr-1"></i>
											</button>
											<div className="dropdown-menu">
												<a className="dropdown-item" onClick={exportPDF}>
													PDF
												</a>
												<a className="dropdown-item" onClick={excelFileExport}>
													Excel
												</a>
												<a className="dropdown-item">
													<CSVLink data={filterrolesData} filename={"UserList.csv"}>
														CSV
													</CSVLink>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-striped" id="table-1">
											<thead>
												<tr>
													<th className="text-center" style={{ maxWidth: "100px" }}>Unique No</th>
                                                    <th>User Id</th>
													<th>Last Name</th>
													<th>First Name</th>
													<th>Email</th>
													<th>Roles</th>
													<th>Mobile No</th>
													<th style={{ maxWidth: "100px" }}>Status</th>
													
												</tr>
											</thead>
											<tbody>
												{rolesData &&
													rolesData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth" key={id} onClick={()=>(item.roles==='ROLE1680234321'?'':UpdateRolesUser(item))}>
															<td className="text-center">{item.SRNO}</td>
															<td>{item.userID}</td>
															<td>{item.LastName}</td>
															<td>{item.FirstName}</td>
															<td>{item.email}</td>
															<td>{item.roles}</td>
															<td>{item.mobileNo}</td>
															<td className="text-center">
																			<div className={`badge ${item.active===1?'badge-success':'badge-danger'} badge-shadow text-white`}>{item.active===1?'Active':'Inactive'}</div>
																		</td>
															
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		</>
  )
}
