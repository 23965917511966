import React, { useEffect, useMemo, useRef, useState } from "react";
import { DatePicker, Space } from 'antd';
import { Link } from "react-router-dom";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { Layers } from "react-feather";
import { BootstrapTooltip } from "../common";
import { bufferDataBYDate, getBufferdata, getDatabaseTableList, getStartAndEndDateByTable } from "../Apicommon";
import Pagination from '../CommonComponnet/Pagination';
import moment from "moment";
import { Modal } from 'antd';
// import './Buffer.css'
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import "jspdf-autotable";
import { CSVLink } from "react-csv";
$.fn.DataTable = dt;


export default function Databasetable() {
	// $(document).ready(function () {
	//   $('#table_id').DataTable()
	// });
	const [Bufferdata, setBufferdata] = useState([]);
	const [DownData, setDownData] = useState([]);

	const [loader, setloader] = useState(false);
  const [PageSize,setPageSize]=useState(50)
  const [dates,setDates]=useState({start:'',end:''})
  const [numberofPage,setnumberofPage]=useState(1) 
  const [currentPage, setCurrentPage] = useState(1);
  const [Btn,setBtn]=useState(false)
  const csvLink = useRef()
  const [isModalVisible, setIsModalVisible] = useState(false);
const [expireDate,setExpireDate]=useState({})
  const showModal =async () => {
	  const data=await getStartAndEndDateByTable({tableName:`gsm_buffer_single`})
	  if(data.success===true){
		  setIsModalVisible(!isModalVisible);
		  setExpireDate(data.data)
	}else{
		console.log("something wait wrong")
	}
  };

	const onHandalBuffer = async () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
		setloader(true);
		// $(".loader").fadeOut("slow");
		const data = await getDatabaseTableList({start:firstPageIndex,end:PageSize});
		if (data.success === true) {
			setBufferdata(data.data.data);
      setnumberofPage(data.data.lengths)
			setloader(false);
		} else {
			setloader(false);
		}
	};

  
	useEffect(() => {
		onHandalBuffer()
	}, [currentPage,PageSize]);
  
	

	return (
		<>
		
			<div className="main-content">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/gsm-buffer">
								<Layers style={{ height: "16px" }} /> Database Table List
							</Link>
						</li>
						
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4> Database Table List</h4>
										<div className="card-header-action">
											
											
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e)=>(setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<table id="table_id" className="table text-center table-striped">
													<thead>
														<tr>
															<th>Table Name</th>
															<th>Version</th>
															<th>Table Row</th>
															<th>Avg Row Length</th>
															<th>Data Length</th>
															<th>Data Free</th>
															<th>Auto Increment</th>
															<th>Size</th>
															<th>Created Time</th>
															<th>Updated Time</th>
														</tr>
													</thead>
													<tbody>
														{Bufferdata.length>0 &&
															Bufferdata.map((item, id) => (
																<tr className="trheigth">
															<td>{item?.TABLE_NAME}</td>
															<td>{item?.VERSION}</td>
															<td>{item?.TABLE_ROWS}</td>
															<td>{item?.AVG_ROW_LENGTH}</td>
															<td>{item?.DATA_LENGTH}</td>
															<td>{item?.DATA_FREE}</td>
															<td>{item?.AUTO_INCREMENT}</td>
															<td>{item?.SIZE} MB</td>
															<td>{item?.CREATE_TIME?moment.utc(item?.CREATE_TIME, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "):''}</td>
															<td>{item?.UPDATE_TIME?moment.utc(item?.UPDATE_TIME, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "):''}</td>
																</tr>
															))}
													</tbody>
												</table>
											</div>
										</div>
                    <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={numberofPage||1}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
