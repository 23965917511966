import { Modal } from 'antd';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Layers } from 'react-feather';
import { Link } from 'react-router-dom';
import Pagination from '../CommonComponnet/Pagination';
import { FindSlaveTableData, FindTableDataInNotifivcation } from '../Apicommon';
import chroma from 'chroma'
// import Notify_Receiver from './Notify_Receiver';

export default function Notification() {

	const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const [BufferData, setBufferData] = useState([])
	const [loader, setLoader] = useState(true)
	const [activeTab, setActiveTab] = useState("Notification");
	const [BufferCountShow, setBufferCountShow] = useState(false)
	const [API_SEND_DATAModal, setAPI_SEND_DATAModal] = useState(false)
	const [ApiSendData, setApiSendData] = useState('{}')
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']


	const ongetGSMBufferData = async () => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await FindTableDataInNotifivcation({ table_name: 'notification_index' }, { start: firstPageIndex, end: PageSize })
		if (data.success === true) {
			setBufferData(data.data.data)
			setnumberofPage(parseInt(data.data.lengths))
			setLoader(false)
		} else {
			console.log('something wait wrong')
			setLoader(false)
		}
	}


	useEffect(() => {
		ongetGSMBufferData()
	}, []);

	useEffect(() => {
		ongetGSMBufferData()

	}, [currentPage, PageSize])
	const onAPISendDataClick = (info) => {
		setAPI_SEND_DATAModal(true)
		setApiSendData(info)

	}

	return (
		<>

			<Modal title={'Mail Response Json'} maskClosable={false} visible={API_SEND_DATAModal} footer='' onCancel={() => {
				setAPI_SEND_DATAModal(false)
				setApiSendData('{}')
			}} width={1500}>
				<pre>{JSON.stringify(JSON.parse(ApiSendData), null, 2)}</pre>
			</Modal>
			<div className="main-content" style={{ minHeight: "562px" }}>

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb ">
						<li className="breadcrumb-item">
							<Link to="/">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>
								Notification
							</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					{/* <div class="row">
						<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
							<div class="card">
								<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
									<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
										<li>
											<button
												style={{
													backgroundColor: activeTab === "Notification" ? chroma(btnColor[0]).alpha(0.4).css() : '',
													color: activeTab === "Notification" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
													borderBottom: `1px solid ${btnColor[0]}`,
													boxShadow: activeTab === "Notification" ? `0 2px 8px -3px ${btnColor[0]}` : '',
												}}
												className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Notification" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
												data-toggle="tab"
												href="#home"
												role="tab"
												aria-controls="home"
												aria-selected="true"
												onClick={() => setActiveTab("Notification")}
											>
												Notification Execution List
											</button>
										</li>
										<li>
											<button
												style={{
													backgroundColor: activeTab === "Event Template" ? chroma(btnColor[1]).alpha(0.4).css() : '',
													color: activeTab === "Event Template" ? chroma(btnColor[1]).darken(1.5).css() : btnColor[1],
													borderBottom: `1px solid ${btnColor[1]}`,
													boxShadow: activeTab === "Event Template" ? `0 2px 8px -3px ${btnColor[1]}` : '',
												}}
												className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Event Template" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
												id="profile-tab"
												data-toggle="tab"
												href="#profile"
												role="tab"
												aria-controls="profile"
												aria-selected="false"
												onClick={() => setActiveTab("Event Template")}
											>
												Notification Template
											</button>
										</li>
										<li>
											<button
												style={{
													backgroundColor: activeTab === "Alert Receiver" ? chroma(btnColor[2]).alpha(0.4).css() : '',
													color: activeTab === "Alert Receiver" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
													borderBottom: `1px solid ${btnColor[2]}`,
													boxShadow: activeTab === "Alert Receiver" ? `0 2px 8px -3px ${btnColor[2]}` : '',
												}}
												className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Alert Receiver" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
												id="profile-tab"
												data-toggle="tab"
												href="#profile"
												role="tab"
												aria-controls="profile"
												aria-selected="false"
												onClick={() => setActiveTab("Alert Receiver")}
											>
												Notification Receiver
											</button>
										</li>
										<li>
											<button
												style={{
													backgroundColor: activeTab === "Notification Script List" ? chroma(btnColor[3]).alpha(0.4).css() : '',
													color: activeTab === "Notification Script List" ? chroma(btnColor[3]).darken(1.5).css() : btnColor[3],
													borderBottom: `1px solid ${btnColor[3]}`,
													boxShadow: activeTab === "Notification Script List" ? `0 2px 8px -3px ${btnColor[3]}` : '',
												}}
												className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Notification Script List" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
												id="profile-tab"
												data-toggle="tab"
												href="#profile"
												role="tab"
												aria-controls="profile"
												aria-selected="false"
												onClick={() => setActiveTab("Notification Script List")}
											>
												Notification Script List
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div> */}
					 <div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  >Notification List</h4>
										<div className="card-header-action">
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<div id="table-1_filter" class="dataTables_filter"><label>Search:<input type="search" class="mr-2" /><div className='btn btn-primary' style={{ padding: '0.2rem 0.8rem', top: '0' }}><i class='fas fa-search'></i></div></label></div>
												<table id="table_id" className="tableCustom table table-striped">
													<thead>
														<tr >
															<th className="text-center" >SRNO</th>
															<th className="text-center" >UNIQUE_ID</th>
															<th className="text-center" >SENT NOTIFICATION MASSAGE</th>
															<th className="text-center" >SENT NOTIFICATION EMAIL</th>
															<th className="text-center" >CREATED AT</th>
															<th className="text-center" >NOTIFICATION STATUS</th>
															<th className="text-center" >MAIL RESPONSE JSON</th>
														</tr>
													</thead>
													<tbody>
														{BufferData && BufferData.map((item) => (
															<tr className="trheigth" >
																<td className="text-center" >{item?.SRNO}</td>
																<td className="text-center" >{item?.UNIQUE_ID}</td>
																<td className="text-center" >{JSON.parse(item?.NOTIFICATION_DETAILS || '{}')?.msg}</td>
																<td className="text-center" >{JSON.parse(item?.NOTIFICATION_DETAILS || '{}')?.email}</td>
																<td className="text-center" >{moment.utc(item?.CREATED_AT).format("DD/MM/YYYY HH:mm:ss")}</td>
																<td className="text-center" >{item?.NOTIFICATION_STATUS == 1 ? 'Success' : item?.NOTIFICATION_STATUS == 0 ? 'Fail' : item?.NOTIFICATION_STATUS == 2 ? 'Not Valid Info' : ''}</td>
																<td className="text-center" >{item?.MAIL_API_RESPONSE ? <button className='btn btn-warning  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onAPISendDataClick(item?.MAIL_API_RESPONSE)}><i className="fa-solid fa-info"></i></button> : '-'}</td>

															</tr>
														))}

													</tbody>
												</table>
											</div>
										</div>
										<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* {activeTab==='Alert Receiver'&&<Notify_Receiver/>} */}
				</section>
			</div>
		</>
	)
}
