import axios from 'axios'

// const url='http://localhost:4001/api'
const url='https://ems.memighty.com:4002/api'
const token=window.localStorage.getItem('token')

const config = () => ({
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});
//login api
export const LoginUesr=async(info)=>{
    try {
const res=await axios.post(`https://ems.memighty.com:4002/loginuser`,info)
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const addUsersLogin=async(info)=>{
    try {
const res=await axios.post(`${url}/addUsersLogin`,info,config())
return { success: true, msg: "Successfully Added" }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const userInactive=async(userId)=>{
    try {
        const res=await axios.put(`${url}/userInactive/${userId}`,{},config())
        return { success: true, msg: "Successfully Inctive User"}
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const userActive=async(userId)=>{
    try {
        const res=await axios.put(`${url}/userActive/${userId}`,{},config())
        return { success: true, msg: "Successfully Inctive User"}
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const updateUser=async(userId,info)=>{
    try {
const res=await axios.put(`${url}/updateUser/${userId}`,info,config())
return { success: true, msg: "Successfully Added"}
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getUsers=async(id)=>{
    try {
        const res=await axios.get(`${url}/getUsers`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
} 

export const getUsersByID=async(id)=>{
    try {
        const res=await axios.get(`${url}/getUsersByID/${id}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const updateUserPassword=async(id,info)=>{
    try {
        const res=await axios.post(`${url}/updateUserPassword/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

//liveData api

export const LiveDeshboadData=async(id)=>{
    try {
        const res=await axios.get(`${url}/LiveDeshboadData/${id}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
} 

export const refreshDeshboardData=async(id)=>{
    try {
        const res=await axios.get(`${url}/refreshDeshboardData`,config())
        return { success: true}
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const getCardDeshboard=async(id,info)=>{
    try {
        const res=await axios.post(`${url}/getCardDeshboard/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
} 

export const getConsumerData=async()=>{
    try {
const res=await axios.get(`${url}/consumerdata`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong"};
    }
}

export const GsmViewUserData=async(id)=>{
    try {
const res=await axios.get(`${url}/gsmviewuserdata/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong"};
    }
}

export const MetterData=async()=>{
    try {
const res=await axios.get(`${url}/allMeterdata`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong"};
    }
}

export const AllMetterData=async()=>{
    try {
const res=await axios.get(`${url}/AllMetterData`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong"};
    }
}

export const consumerSlavebyId=async(id)=>{
    try {
const res=await axios.get(`${url}/consumerSlavebyId/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong"};
    }
}

export const FindSlaveTableData=async(tname,info)=>{
    try {
const res=await axios.get(`${url}/FindSlaveTableData/${tname}?start=${info.start}&end=${info.end}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getBufferdata=async(info)=>{
    try {
const res=await axios.get(`${url}/bufferData?start=${info?.start}&end=${info.end}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllModemData=async()=>{
    try {
const res=await axios.get(`${url}/getAllModemData`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const addCardDeshboard=async(info,tabid)=>{
    try {
        const res=await axios.post(`${url}/addCardDeshboard/${tabid}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const editCardDeshboard=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/editCardDeshboard/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const FindTabName=async()=>{
    try {
        const res=await axios.get(`${url}/FindTabName`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const insertTabName=async(info)=>{
    try {
        const res=await axios.post(`${url}/insertTabName`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const UpdateTabName=async(info,id)=>{
    try {
        const res=await axios.post(`${url}/EditTabName/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const DeleteTabName=async(id)=>{
    try {
        const res=await axios.delete(`${url}/DeleteTabName/${id}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const bufferDataBYDate=async(info)=>{
    try {
        const res=await axios.get(`${url}/bufferDataBYDate?start=${info.start}&end=${info.end}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const getRolesUserData=async()=>{
    try {
        const res=await axios.get(`${url}/getRolesUserData`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const consumerRolesData=async()=>{
    try {
        const res=await axios.get(`${url}/consumerRolesData`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const getRolesDetailesByName=async(info)=>{
    try {
        const res=await axios.post(`${url}/getRolesDetailesByName`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const deleteCardDeshboard=async(id)=>{
    try {
        const res=await axios.delete(`${url}/deleteCardDeshboard/${id}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong"};
            }
}

export const findHardBeatbyIMEI=async(info,IMEI)=>{
    try {
const res=await axios.get(`${url}/findHardBeatbyIMEI/${IMEI}?start=${info?.start}&end=${info.end}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const IsertRolesUserData=async(info)=>{
    try {
const res=await axios.post(`${url}/IsertRolesUserData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const editRolesPermission=async(info,id)=>{
    try {
const res=await axios.put(`${url}/editRolesPermission/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const DownloadTablebyDate=async(info,data)=>{
    try {
const res=await axios.post(`${url}/DownloadTablebyDate?start=${info?.start}&end=${info.end}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getStartAndEndDateByTable=async(data)=>{
    try {
        const res=await axios.post(`${url}/getStartAndEndDateByTable`,data,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const RolesActiveIn=async(userId,info)=>{
    try {
        const res=await axios.put(`${url}/RolesActiveIn/${userId}`,info,config())
        return { success: true, msg: "Successfully Inctive User"}
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getChartDateByTableName=async(info)=>{
    try {
    const res=await axios.post(`${url}/getChartDateByTableName`,info,config())
        return { success: true, msg: "Successfully get data",data:res.data}
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getChartDateByTableNameWithDate=async(info)=>{
    try {
    const res=await axios.post(`${url}/getChartDateByTableNameWithDate`,info,config())
        return { success: true, msg: "Successfully get data",data:res.data}
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getDataSlaveConnectivity=async()=>{
    try {
        const res=await axios.get(`${url}/getDataSlaveConnectivity`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const get_modem_connectivity_status=async()=>{
    try {
        const res=await axios.get(`${url}/get_modem_connectivity_status`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getSlavesDetailbySrno=async(id,status)=>{
    try {
        const res=await axios.get(`${url}/getSlavesDetailbySrno/${id}?active=${status}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getModemDetailbySrno=async(id,status)=>{
    try {
        const res=await axios.get(`${url}/getModemDetailbySrno/${id}?active=${status}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getTotalChartData=async(info)=>{
    try {
    const res=await axios.post(`${url}/getTotalChartData`,info,config())
        return { success: true, msg: "Successfully get data",data:res.data}
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
        }

 export const getSubCardDeshboard=async(id,info)=>{
            try {
                const res=await axios.post(`${url}/getSubCardDeshboard/${id}`,info,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

 export const addSubCardDeshboard=async(info,tabid)=>{
            try {
                const res=await axios.post(`${url}/addSubCardDeshboard`,info,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

export const getCardDeshboardByCardid=async(id)=>{
            try {
                const res=await axios.get(`${url}/getCardDeshboardByCardid/${id}`,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

 export const getSubtoSubCardDeshboard=async(id,info)=>{
            try {
                const res=await axios.post(`${url}/getSubtoSubCardDeshboard/${id}`,info,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

 export const addSubtoSubCardDeshboard=async(info,tabid)=>{
            try {
                const res=await axios.post(`${url}/addSubtoSubCardDeshboard`,info,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

        export const getSubCardDeshboardByCardid=async(id)=>{
            try {
                const res=await axios.get(`${url}/getSubCardDeshboardByCardid/${id}`,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }
 export const GetConsumberInByIMEI=async(info,tabid)=>{
            try {
                const res=await axios.post(`${url}/GetConsumberInByIMEI`,info,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

        export const getCardNameAndId=async()=>{
            try {
                const res=await axios.get(`${url}/getCardNameAndId`,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }
        export const getLetestBufferdata=async(id)=>{
            try {
                const res=await axios.get(`${url}/getLetestBufferdata/${id}`,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }
        export const onRefreshLivedata=async(id)=>{
            try {
                const res=await axios.get(`${url}/onRefreshLivedata/${id}`,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

 export const GetConsumberInBySlavesId=async(info,tabid)=>{
            try {
                const res=await axios.post(`${url}/GetConsumberInBySlavesId`,info,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

export const getCardWiseSlaveChartDataInKWH=async(id)=>{
            try {
                const res=await axios.post(`${url}/getCardWiseSlaveChartDataInKWH/${id}`,{},config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }
export const getCardWiseSlaveChartDataInKWHMonthly=async(id)=>{
            try {
                const res=await axios.get(`${url}/getCardWiseSlaveChartDataInKWHMonthly/${id}`,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

export const getCardWiseSlaveChartDataInKWHYear=async(id)=>{
            try {
                const res=await axios.get(`${url}/getCardWiseSlaveChartDataInKWHYear/${id}`,config())
                return { success: true, data: res.data }
                    }catch (e) {
                        return { success: false, msg: "Something went wrong",err:e};
                    }
        }

    
export const getCardWiseSlaveChartDataInKWHByDate=async(id,info)=>{
    try {
        const res=await axios.post(`${url}/getCardWiseSlaveChartDataInKWHByDate/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getCardWiseSlaveChartDataInKWHMonthlyByDate=async(id,info)=>{
    try {
        const res=await axios.post(`${url}/getCardWiseSlaveChartDataInKWHMonthlyByDate/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getCardWiseSlaveChartDataInKWHByselectedDate=async(id,info)=>{
    try {
        const res=await axios.post(`${url}/getCardWiseSlaveChartDataInKWHByselectedDate/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getCardWiseSlaveChartDataInKWHMonthlyBySelectedMonth=async(id,info)=>{
    try {
        const res=await axios.post(`${url}/getCardWiseSlaveChartDataInKWHMonthlyBySelectedMonth/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getConsumerSlavesdetailsByConsumerId=async(info)=>{
    try {
        const res=await axios.post(`${url}/getConsumerSlavesdetailsByConsumerId`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdateConsumerSlavesdetailsByConsumerId=async(info)=>{
    try {
        const res=await axios.post(`${url}/UpdateConsumerSlavesdetailsByConsumerId`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const upadateCardInfoAlert=async(info)=>{
    try {
        const res=await axios.post(`${url}/upadateCardInfoAlert`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getCardInfoAlertById=async(id,info)=>{
    try {
        const res=await axios.post(`${url}/getCardInfoAlertById/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdateConsumerNameByConsumerId=async(info)=>{
    try {
        const res=await axios.post(`${url}/UpdateConsumerNameByConsumerId`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}


export const FindTableDataInNotifivcation=async(info,ret)=>{
    try {
        const res=await axios.post(`${url}/FindTableDataInNotifivcation?start=${ret.start}&end=${ret.end}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getDatabaseTableList=async(ret)=>{
    try {
        const res=await axios.get(`${url}/getDatabaseTableList?start=${ret.start}&end=${ret.end}`,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdateChartDescription=async(info)=>{
    try {
        const res=await axios.post(`${url}/UpdateChartDescription`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdateCardPositionOnDrag=async(info)=>{
    try {
        const res=await axios.post(`${url}/UpdateCardPositionOnDrag`,info,config())
        return { success: true, data: res.data }
         }catch (e) {
            return { success: false, msg: "Something went wrong",err:e};
            }
}

export const upadateSetCardScheduleInfo=async(info)=>{
    try {
        const res=await axios.post(`${url}/upadateSetCardScheduleInfo`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}