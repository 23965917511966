import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { DatePicker, Modal, Space, Switch } from "antd";
import { HolderOutlined } from "@ant-design/icons";
import $ from "jquery";
import { BootstrapTooltip } from "../common";
import {
	addCardDeshboard,
	consumerSlavebyId,
	deleteCardDeshboard,
	DeleteTabName,
	editCardDeshboard,
	FindTabName,
	getAllModemData,
	getCardDeshboard,
	getCardInfoAlertById,
	getCardWiseSlaveChartDataInKWHByselectedDate,
	getCardWiseSlaveChartDataInKWHMonthlyBySelectedMonth,
	GetConsumberInByIMEI,
	GetConsumberInBySlavesId,
	getRolesDetailesByName,
	insertTabName,
	LiveDeshboadData,
	onRefreshLivedata,
	refreshDeshboardData,
	upadateCardInfoAlert,
	UpdateTabName,
	getCardWiseSlaveChartDataInKWH,
	UpdateCardPositionOnDrag,
	upadateSetCardScheduleInfo
} from "../Apicommon";
import Chart from 'react-apexcharts'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import moment from "moment";
import { Spin } from "antd";
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import swal from "sweetalert2";
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'

// import Select as Selects from 'react-select';
import makeAnimated from 'react-select/animated';
window.Swal = swal;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const Deshbord = (props) => {
	moment.updateLocale("en", {
		relativeTime: {
			future: "in %s",
			past: "%s  ",
			s: " %d seconds ",
			ss: "%d seconds ",
			m: "%d mins ",
			mm: "%d min ",
			h: "an hours ",
			hh: "%d hours ",
			d: "a day ",
			dd: "%d days ",
			w: "a week ",
			ww: "%d weeks ",
			M: "a month ",
			MM: "%d months ",
			y: "a year ",
			yy: "%d years ",
		},
	});
	const [SlaveData, setSlaveData] = useState([]);
	const [personName, setPersonName] = React.useState([]);
	const [model, setmodel] = useState(false);
	const [editBtn, seteditBtn] = useState(false);
	const [Modemroles, setModemroles] = useState({});
	const [CardPermition, setCardPermition] = useState({})
	const [isModalVisibletable, setIsModalVisibletable] = useState(false);
	const [isLastModalVisible, setIsLastModalVisible] = useState(false);
	const [slaves1, setslaves1] = React.useState([]);
	const [slavesVal1, setslavesVal1] = React.useState([]);
	const [slaves2, setslaves2] = React.useState([]);
	const [slavesVal2, setslavesVal2] = React.useState([]);
	var options3 = {
		chart: {
			height: 350,
			type: 'area'
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: 'smooth',
			//   lineCap: 'butt',
			width: [0, 0],
		},
		xaxis: {
			categories: [],
			labels: {
				rotateAlways: true,
				rotate: -60,
			},


		},
		tooltip: {
			x: {
				format: 'dd/MM/yy'
			},
		},
		animations: {
			enabled: false
		},
		noData: {
			text: "Loading...",
			align: 'center',
			verticalAlign: 'middle',
			offsetX: 0,
			offsetY: 0,
			style: {
				color: "#000000",
				fontSize: '14px',
				fontFamily: "Helvetica"
			}
		},
		colors: ['#fc544b', '#ffa426', '#0000ff']
	}
	const handleChange = async (event) => {
		const {
			target: { value, name },
		} = event;

		console.log(event)
		if (editId === null) {
			if (value.length > personName.length) {
				const data = SlaveData
				SlaveList?.map((item) =>
					value?.map((i) => {
						if (i === item.CONSDEVNAME_U) {
							if (SlaveData.filter((e) => e.CONSDEVNAME_U === i).length === 0) {
								data.push(item);
							} else {
							}
						}
					})
				)
				setSlaveData(data);
			} else {
				let str1
				const errror = personName.map((i) => {
					value.map((item) => {
						if (item !== i) { str1 = i }
					})
				})
				console.log(str1);
				setSlaveData(value.length == 0 ? [] : SlaveData.filter((e) => e.CONSDEVNAME_U !== str1));
			}
		} else {
			const data = SlaveData
			console.log(value.length, personName.length);
			if (value.length !== personName.length) {
				if (value.length > personName.length) {
					SlaveList?.map((item) =>
						value?.map((i) => {
							if (i === item.CONSDEVNAME_U) {
								if (SlaveData.filter((e) => e.CONSDEVNAME_U === i).length === 0) {
									data.push(item);
								} else {
								}
							}
						})
					)
					console.log('data', data)
					setSlaveData(data);
				} else {
					let str1
					const errror = personName.map((i) => {
						value.map((item) => {
							if (item !== i) { str1 = i }
						})
					})

					console.log(str1);
					setSlaveData(value.length == 0 ? [] : SlaveData.filter((e) => e.CONSDEVNAME_U !== str1));
				}
			}

		}

		setPersonName(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const handleSlaves1Change = async (event) => {
		const {
			target: { value, name },
		} = event;

		console.log(event)
		if (editId === null) {
			if (value.length > slaves1.length) {
				const data = slavesVal1
				SlaveList?.map((item) =>
					value?.map((i) => {
						if (i === item.CONSDEVNAME_U) {
							if (slavesVal1.filter((e) => e.CONSDEVNAME_U === i).length === 0) {
								data.push(item);
							} else {
							}
						}
					})
				)
				setslavesVal1(data);
			} else {
				let str1
				const errror = slaves1.map((i) => {
					value.map((item) => {
						if (item !== i) { str1 = i }
					})
				})
				console.log(str1);
				setslavesVal1(value.length == 0 ? [] : slavesVal1.filter((e) => e.CONSDEVNAME_U !== str1));
			}
		} else {
			const data = slavesVal1

			if (value.length !== slaves1.length) {
				if (value.length > slaves1.length) {
					SlaveList?.map((item) =>
						value?.map((i) => {
							if (i === item.CONSDEVNAME_U) {
								if (slavesVal1.filter((e) => e.CONSDEVNAME_U === i).length === 0) {
									data.push(item);
								} else {
								}
							}
						})
					)
					setslavesVal1(data);
				} else {
					let str1
					const errror = slaves1.map((i) => {
						value.map((item) => {
							if (item !== i) { str1 = i }
						})
					})
					console.log(str1);
					setslavesVal1(value.length == 0 ? [] : slavesVal1.filter((e) => e.CONSDEVNAME_U !== str1));
				}
			}

		}

		setslaves1(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const handleSlaves2Change = async (event) => {
		const {
			target: { value, name },
		} = event;

		console.log(event)
		if (editId === null) {
			if (value.length > slaves2.length) {
				const data = slavesVal2
				SlaveList?.map((item) =>
					value?.map((i) => {
						if (i === item.CONSDEVNAME_U) {
							if (slavesVal2.filter((e) => e.CONSDEVNAME_U === i).length === 0) {
								data.push(item);
							} else {
							}
						}
					})
				)
				setslavesVal2(data);
			} else {
				let str1
				const errror = slaves2.map((i) => {
					value.map((item) => {
						if (item !== i) { str1 = i }
					})
				})
				console.log(str1);
				setslavesVal2(value.length == 0 ? [] : slavesVal2.filter((e) => e.CONSDEVNAME_U !== str1));
			}
		} else {
			const data = slavesVal2

			if (value.length !== slaves2.length) {
				if (value.length > slaves2.length) {
					SlaveList?.map((item) =>
						value?.map((i) => {
							if (i === item.CONSDEVNAME_U) {
								if (slavesVal2.filter((e) => e.CONSDEVNAME_U === i).length === 0) {
									data.push(item);
								} else {
								}
							}
						})
					)
					setslavesVal2(data);
				} else {
					let str1
					const errror = slaves2.map((i) => {
						value.map((item) => {
							if (item !== i) { str1 = i }
						})
					})
					console.log(str1);
					setslavesVal2(value.length == 0 ? [] : slavesVal2.filter((e) => e.CONSDEVNAME_U !== str1));
				}
			}

		}

		setslaves2(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const [addFlag, setAddFlag] = useState(false);
	const [RefreshIndi, setRefreshIndi] = useState(false)
	const [RefreshId, setRefreshId] = useState(null)
	const [currentDate, setcurrentDate] = useState(moment(new Date()));
	const [platofcard, setPlatofcard] = useState([]);
	const [editId, setEditeId] = useState(null);
	const [ModemID, setModemID] = useState({ MODEM_UNIQUE_ID: "", cardName: "", card_description: "", card_color: '', CARD_VIEW: '0' });
	const [modem, setModem] = useState([]);
	const [SlaveList, setSlaveList] = useState({});
	const [loader, setLoader] = useState(false);
	const [Errors, setValidation] = useState({});
	const [TabList, setTabList] = useState([]);
	const [TabID, setTabID] = useState(null);
	const [TabuserId, setTabuserId] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [tabName, setTabName] = useState("");
	const [roles, setroles] = useState({});
	const permission = JSON.parse(window.localStorage.getItem("user_info"))?.roles || "";
	const user_info = JSON.parse(window.localStorage.getItem("user_info"));
	const [deshboardId, setdeshboardId] = useState(TabList[0]?.TABID);
	const [tableData, setTabledata] = useState([]);
	const [ErrorData, setErrordata] = useState([]);
	const [datesInmap, setdatesInmap] = useState({ start: '', end: '' })

	const [totalTabledata, setTotalTabledata] = useState({});
	const [refresh, setRefresh] = useState(false);
	const buttonArray = ["Available Data List", "Unavailable Data List", "All Slave List"];
	const [btnText, setButtontext] = useState("Available Data List");
	const [AllData, setAllData] = useState([])
	const history = useHistory()
	const [fildDisable, setfildDisable] = useState(false)
	const [fildCardDisable, setfildCardDisable] = useState(false)
	const [ConsumerId, setConsumerId] = useState(null);
	const [DailyCardData, setDailyCardData] = useState({})
	const [DailyChartData, setDailyChartData] = useState({})
	const [DailyPerChartData, setDailyPerChartData] = useState({})
	const [lineChartID, setlineChartID] = useState([])
	const [perkwhChartID, setperkwhChartID] = useState([])
	const [value, setValue] = useState({ cron_value: '0 0 * * *', select_period: 'day',status:'1',email_id:'',report_type:'' })

	// const [intFlag,setIntFlag]=useState(false)
	const [slavesType, setslavesType] = useState("Addition Of Slaves");
	var intFlag = false;
	// console.log('permission',permission)
	const showModal = () => {
		setIsModalVisible(true);
	};
	const onChangeMap = (date, dateString) => {
		setdatesInmap({ ...datesInmap, start: dateString })
	};
	const onChangesMap = (date, dateString) => {
		setdatesInmap({ ...datesInmap, end: dateString })
	};
	const onEditTab = (id) => {
		const tabdata = TabList && TabList.filter((item) => item.TABID === id);
		if (tabdata.length > 0) {
			setTabName(tabdata[0]?.TAB_NAME);
			setTabID(tabdata[0]?.TABID);
			setTabuserId(tabdata[0]?.user_id)
			setIsModalVisible(true);
		}
	};

	const onDelete = (id) => {
		swal.fire({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this Tab!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await DeleteTabName(id);
				if (data.success === true) {
					onliveData();
					onFindTabName();
					setTabName("");
					setIsModalVisible(false);
					setTabID(null);
					setTabuserId(null)
					setfildDisable(false)
					toast.success("Delete Card Success");
				}
			} else if (willDelete.isDismissed) {
				swal.fire("your Tab is safe");
			}
		});
	};
	const handleOk = async () => {
		if (TabID === null) {
			if (tabName !== "") {
				const data = await insertTabName({ TAB_NAME: tabName, user_id: user_info.userID });
				if (data.success === true) {
					toast.success("Add Tab Success");
					onFindTabName();
					setIsModalVisible(false);
					setTabName("");
				} else {
					toast.error("Choice New Name");
				}
			} else {
				toast.error("Require Enter Tab Name");
			}
		} else {
			if (tabName !== "") {
				const data = await UpdateTabName({ tabName: tabName }, TabID);
				if (data.success === true) {
					toast.success("Update Tab Success");
					onFindTabName();
					setIsModalVisible(false);
					setTabName("");
					setTabID(null);
					setTabuserId(null)
				} else {
					toast.error("Choice New Name");
				}
			} else {
				toast.error("Require Enter Tab Name");
			}
		}
	};

	const onRolesDetailesByName = async () => {
		// if (permission !== "ROLE1680234321") {
		const data = await getRolesDetailesByName({ ROLES_NAME: permission });
		if (data.success === true) {
			onFindTabName();
			if (permission !== "ROLE1680234321") {
				setroles(JSON.parse(data?.data?.data?.ROLES_PERMISSION) || {});
				setModemroles(JSON.parse(data?.data?.data?.modem_permission) || {});
				setCardPermition(JSON.parse(data?.data?.data?.card_permission) || {})
			}
		} else {
			console.log("something wait wrong");
		}
		// }
	};

	const onCancelModel = () => {
		setmodel(false);
		setEditeId(null);
		setConsumerId(null)
		setSlaveData([]);
		setPersonName([]);
		setModemID({ MODEM_UNIQUE_ID: "", cardName: "", card_description: "", card_color: '', CARD_VIEW: '0' });
		setAddFlag(false);
		setfildCardDisable(false)
		setslavesType("Addition Of Slave")
		setslaves1([])
		setslavesVal1([])
		setslaves2([])
		setslaves2([])
		setslavesVal2([])
	};

	const handleCancel = () => {
		setTabName("");
		setIsModalVisible(false);
		setTabID(null);
		setTabuserId(null)
		setfildDisable(false)
	};

	const validation = (name, value) => {
		switch (name) {
			case "MODEM_UNIQUE_ID":
				if (!value) {
					return "Please Select Modem!";
				} else {
					return "";
				}
			case "cardName":
				if (!value) {
					return "Please input card Name!";
				} else {
					return "";
				}
			default: {
				return null;
			}
		}
	};

	const OnModemList = async () => {
		const data = await getAllModemData();
		if (data.success === true) {
			setModem(data.data.data);
		} else {
		}
	};

	useEffect(() => {
		if (intFlag === false) {
			if (deshboardId !== null) {
				onliveData();
			}
		}
		const id = setInterval(() => {
			if (intFlag === false) {
				var date = new Date();
				if (date.getMinutes() % 5 == 0 || date.getMinutes() === 0) {
					if (deshboardId !== null) {
						intFlag = true;
						const myTimeout = setTimeout(async () => {
							await onliveData();
							clearTimeout(myTimeout);
						}, 30000);
						setTimeout(async () => {
							intFlag = false;
						}, 60000);
					}
				} else {
					intFlag = false;
				}
			}
		}, 5000);
		return () => {
			clearInterval(id);
		};
	}, [deshboardId]);

	useEffect(() => {
		setdeshboardId(
			permission === "ROLE1680234321"
				? TabList[0]?.TABID
				: TabList?.filter(
					(item, id) =>
						item?.user_id === user_info.userID ||
						roles[item.TAB_NAME]?.view === true ||
						roles[item.TAB_NAME]?.edit === true ||
						roles[item.TAB_NAME]?.delete === true
				)[0]?.TABID
		);
	}, [TabList]);

	useEffect(() => {
		onRolesDetailesByName();

		if (modem.length === 0) {
			OnModemList();
		}
		const timer = setInterval(() => {
			setcurrentDate(moment());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const onHadaleInput = (e) => {
		setModemID({ ...ModemID, [e.target.name]: e.target.value });
	};
	var obj = {}
	var Chart_obj = {}
	const onliveData = async () => {
		var perkwhObj = {}
		if (deshboardId && deshboardId !== null) {
			setLoader(true);
			const data = await getCardDeshboard(deshboardId, { CardPermition: CardPermition, roles: user_info?.roles, user_id: user_info?.userID });
			if (data.success === true) {
				setPlatofcard(data?.data?.data || []);
				setLoader(false);

				data?.data?.data.map(async (item, id) => {
					if (item?.CARD_VIEW == 1) {
						obj[item?.ID] = [{
							name: 'KWH',
							data: []
						}]

						Chart_obj[item?.ID] = {
							chart: {
								height: 350,
								type: 'area'
							},
							grid: {
								padding: {
									top: 0,
									right: 0,
									bottom: 0,
									left: 0
								},
							},
							dataLabels: {
								enabled: false
							},
							stroke: {
								curve: 'smooth',
								//   lineCap: 'butt',
								width: [0, 0],
							},
							xaxis: {
								categories: [],
								labels: {
									rotateAlways: true,
									rotate: -60,
								},


							},
							tooltip: {
								x: {
									format: 'dd/MM/yy'
								},
							},
							animations: {
								enabled: false
							},
							noData: {
								text: "Loading...",
								align: 'center',
								verticalAlign: 'middle',
								offsetX: 0,
								offsetY: 0,
								style: {
									color: "#000000",
									fontSize: '14px',
									fontFamily: "Helvetica"
								}
							},
							colors: ['#fc544b', '#ffa426', '#0000ff']
						}
						const Chart_data = await getCardWiseSlaveChartDataInKWH(item?.ID)
						if (Chart_data.success === true) {
							obj[item?.ID] = [{
								name: 'KWH',
								data: Chart_data.data.data.map((item, id) => (item?.Daily_KWH_count))
							}]
							perkwhObj[item?.ID] = [{
								name: 'Losses % ',
								data: Chart_data.data.data.map((item) => (item?.perkwh?.toFixed(2)))
							}]
							{
								Chart_obj[item?.ID] = {
									legend: {
										show: false,
									},
									dataLabels: {
										enabled: false
									},
									plotOptions: {
										bar: {
											columnWidth: '50%',
											distributed: true,
											borderRadius: 5,
											dataLabels: {
												enabled: false
											},

										}
									},

									xaxis: {
										categories: Chart_data.data.data.map((item, id) => (id == (Chart_data.data.data.length - 1) ? moment(item?.date, 'YYYY-MM-DD').format('DD-MM-YYYY') + '(Live)' : moment(item?.date, 'YYYY-MM-DD').format('DD-MM-YYYY'))),
										labels: {
											rotateAlways: true,
											rotate: -60,
										},
										// tickPlacement: 'on'
									},
									fill: {
										type: 'bar',
										gradient: {
											shade: 'dark',
											type: "vertical",
											shadeIntensity: 0.5,
											gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
											inverseColors: true,
											opacityFrom: 1,
											opacityTo: 1,
											stops: [0, 50, 100],
											colorStops: []
										}
									},
									chart: {
										toolbar: {
											show: false
										}
									},
									noData: {
										text: "Loading...",
										align: 'center',
										verticalAlign: 'middle',
										offsetX: 0,
										offsetY: 0,
										style: {
											color: "#000000",
											fontSize: '14px',
											fontFamily: "Helvetica"
										},
									},
									colors: ['#fc544b']
									// colors: [
									//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
									// ]
								}
							}
							console.log(id + 1, Chart_data, data?.data?.data?.length)


						} else {
							console.log('something wait wrong')
						}
					}

					if ((id + 1) == data?.data?.data?.length) {
						setDailyCardData(obj)
						setDailyChartData(Chart_obj)
						setDailyPerChartData(perkwhObj)
					}
				})

			} else {
				setLoader(false);
			}
		}
	};

	useEffect(() => {

	}, [DailyCardData])

	const AddCard = async (e) => {
		e.preventDefault();

		const userData = {
			MODEM_UNIQUE_ID: ModemID.MODEM_UNIQUE_ID,
			cardName: ModemID.cardName,
			card_description: ModemID.card_description,
		};

		let allErrors = {};
		Object.keys(ModemID).forEach((key) => {
			const error = validation(key, userData[key]);
			if (error && error.length) {
				allErrors[key] = error;
			}
			if (slavesType === 'Differentiation Of Slaves') {
				if (slavesVal1.length == 0) {
					allErrors['slavesVal1'] = 'Please Select Slaves'
				} else if (slavesVal2.length == 0) {
					allErrors['slavesVal2'] = 'Please Select Slaves'
				}
			}
		});
		console.log(allErrors);
		if (Object.keys(allErrors).length) {
			return setValidation(allErrors);
		} else {
			setValidation({});
			setAddFlag(true);
			if (editId === null) {
				console.log(ModemID)
				const data = await addCardDeshboard(
					{ ...ModemID, SLAVES_DETAILS: slavesType === 'Differentiation Of Slaves' ? JSON.stringify(slavesVal1) : JSON.stringify(SlaveData), CARD_TYPE: slavesType, DIFFERENTIATION_SLAVES_DETAILS: JSON.stringify(slavesVal2), CARD_VIEW: ModemID?.CARD_VIEW, consumer_id: user_info?.userID },
					deshboardId
				);
				if (data.success === true) {
					// setTimeout(() => {
					onliveData();
					toast.success("Add Card Success");
					setSlaveData([]);
					setPersonName([]);
					setModemID({ MODEM_UNIQUE_ID: "", cardName: "", card_description: "", card_color: '', CARD_VIEW: '0' });
					setAddFlag(false);
					setmodel(false);
					setfildCardDisable(false)
					setslavesType("Addition Of Slave")
					setslaves1([])
					setslavesVal1([])
					setslaves2([])
					setslaves2([])
					setslavesVal2([])
					// }, 4000);
				} else {
					setAddFlag(false);
					toast.error("Something wait wrong");
				}
			} else {
				const data = await editCardDeshboard({ ...ModemID, SLAVES_DETAILS: slavesType === 'Differentiation Of Slaves' ? JSON.stringify(slavesVal1) : JSON.stringify(SlaveData), CARD_TYPE: slavesType, DIFFERENTIATION_SLAVES_DETAILS: JSON.stringify(slavesVal2), CARD_VIEW: ModemID?.CARD_VIEW }, editId);
				if (data.success === true) {
					// setTimeout(() => {
					toast.success("Update Card Success");
					onliveData();
					setSlaveData([]);
					setPersonName([]);
					setModemID({ MODEM_UNIQUE_ID: "", cardName: "", card_description: "", card_color: '', CARD_VIEW: '0' });
					setAddFlag(false);
					setmodel(false);
					setEditeId(null);
					setConsumerId(null)
					setfildCardDisable(false)
					setslavesType("Addition Of Slave")
					setslaves1([])
					setslavesVal1([])
					setslaves2([])
					setslaves2([])
					setslavesVal2([])
					// }, 3000);
				} else {
					toast.error("Something wait wrong");
					setAddFlag(false);
				}
			}
		}
	};

	const onRefreshDataInGSM = async (id) => {
		setRefreshIndi(true)
		setRefreshId(id)
		if (id) {
			const data = await onRefreshLivedata(id);
			if (data.success === true) {
				onliveData();
				setRefreshIndi(false)
			} else {
				console.log("Something wait wrong");
				setRefreshIndi(false)
			}
		} else {
			setRefreshIndi(false)
		}
	}

	const [ObjectVal1, setObjectVal1] = useState({})
	const [ObjectVal2, setObjectVal2] = useState({})

	const onHandalTableview = (data) => {
		var arrayInTable = JSON.parse(data?.payload?.slaves_details || '[]')
		var arrayInSlaves = JSON.parse(data?.SLAVES_DETAILS || '[]')
		var arrayInDiductionSlaves = JSON.parse(data?.DIFFERENTIATION_SLAVES_DETAILS || '[]')
		const arrayErrorSlaves = JSON.parse(data?.payload?.inactive_slaves_details || '[]')
		const subtocardarray = []
		const Errorcardarray = []

		arrayInSlaves.map((item, id) => {
			modem.map((info) => {
				JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
					if (item?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
						arrayInSlaves[id].CONSDEVNAME_U = con_name?.CONSDEVNAME_U
					}
				})
			})
		})

		arrayInDiductionSlaves.map((item, id) => {
			modem.map((info) => {
				JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
					if (item?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
						arrayInDiductionSlaves[id].CONSDEVNAME_U = con_name?.CONSDEVNAME_U
					}
				})
			})
		})

		var objVal1 = { VRN: 0, VYN: 0, VBN: 0, IR: 0, IY: 0, IB: 0, POW: 0, PF: 0, KVA: 0, KWH: 0, FRQ: 0, count: 0 }
		var objVal2 = { VRN: 0, VYN: 0, VBN: 0, IR: 0, IY: 0, IB: 0, POW: 0, PF: 0, KVA: 0, KWH: 0, FRQ: 0, count: 0 }


		arrayInTable.map((item, id) => {
			arrayInSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					subtocardarray.push({ ...item, CONSDEVNAME_U: info.CONSDEVNAME_U })
					objVal1.VRN = objVal1.VRN + Number(item?.VRN)
					objVal1.VYN = objVal1.VYN + Number(item?.VYN)
					objVal1.VBN = objVal1.VBN + Number(item?.VBN)
					objVal1.IR = objVal1.IR + Number(item?.IR)
					objVal1.IY = objVal1.IY + Number(item?.IY)
					objVal1.IB = objVal1.IB + Number(item?.IB)
					objVal1.POW = objVal1.POW + Number(item?.POW)
					objVal1.PF = objVal1.PF + Number(item?.PF)
					objVal1.KVA = objVal1.KVA + Number(item?.APOW)
					objVal1.KWH = objVal1.KWH + Number(item?.KWH)
					objVal1.FRQ = objVal1.FRQ + Number(item?.FRQ)
					++objVal1.count
				}
			})
			if (id == arrayInTable?.length - 1) {
				objVal1.VRN = objVal1.VRN / objVal1.count
				objVal1.VYN = objVal1.VYN / objVal1.count
				objVal1.VBN = objVal1.VBN / objVal1.count
				objVal1.PF = objVal1.PF / objVal1.count
				objVal1.FRQ = objVal1.FRQ / objVal1.count
			}
		})
		arrayInTable?.map((item, id) => {
			arrayInDiductionSlaves?.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					subtocardarray.push({ ...item, CONSDEVNAME_U: info.CONSDEVNAME_U })
					objVal2.VRN = objVal2.VRN + Number(item?.VRN)
					objVal2.VYN = objVal2.VYN + Number(item?.VYN)
					objVal2.VBN = objVal2.VBN + Number(item?.VBN)
					objVal2.IR = objVal2.IR + Number(item?.IR)
					objVal2.IY = objVal2.IY + Number(item?.IY)
					objVal2.IB = objVal2.IB + Number(item?.IB)
					objVal2.POW = objVal2.POW + Number(item?.POW)
					objVal2.PF = objVal2.PF + Number(item?.PF)
					objVal2.KVA = objVal2.KVA + Number(item?.APOW)
					objVal2.KWH = objVal2.KWH + Number(item?.KWH)
					objVal2.FRQ = objVal2.FRQ + Number(item?.FRQ)
					++objVal2.count
				}
			})
			if (id == arrayInTable?.length - 1) {
				objVal2.VRN = objVal2.VRN / objVal2.count
				objVal2.VYN = objVal2.VYN / objVal2.count
				objVal2.VBN = objVal2.VBN / objVal2.count
				objVal2.PF = objVal2.PF / objVal2.count
				objVal2.FRQ = objVal2.FRQ / objVal2.count
			}
		})
		arrayErrorSlaves.map((item) => {
			arrayInSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					Errorcardarray.push({ ...item, CONSDEVNAME_U: info.CONSDEVNAME_U })
				}
			})
		})
		arrayErrorSlaves.map((item) => {
			arrayInDiductionSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					Errorcardarray.push({ ...item, CONSDEVNAME_U: info.CONSDEVNAME_U })
				}
			})
		})
		if (data?.CARD_TYPE == 'Differentiation Of Slaves') {
			console.log(subtocardarray);
			setTabledata(subtocardarray);
		} else {

			setTabledata(subtocardarray);
		}
		setObjectVal1(objVal1)
		setObjectVal2(objVal2)
		setTotalTabledata(data);
		setErrordata(Errorcardarray)
		setAllData(arrayInSlaves)
		setIsModalVisibletable(true);
	};

	const onHandaleSlaveList = async (e) => {
		if (e.target.value) {
			const data = await consumerSlavebyId(e.target.value);

			var arry = [];
			if (data.success === true) {
				data?.data?.data.map((item) => arry.push(item.payload));
				setSlaveList(arry);
			} else {
			}
		}
	};

	const onHandaleModelList = async (e) => {
		setModemID({ ...ModemID, MODEM_UNIQUE_ID: e.target.value });
		onHandaleSlaveList(e);
		setslavesType(slavesType)
	};
	const editPlatData = async (item) => {
		console.log(item)
		setEditeId(item?.ID);
		setConsumerId(item?.consumer_id)
		seteditBtn(true);
		setModemID({ MODEM_UNIQUE_ID: item?.MODEM_UNIQUE_ID, cardName: item?.CARD_NAME, card_description: item?.card_description, card_color: item?.card_color, CARD_VIEW: item?.CARD_VIEW || '0' });
		const data = await consumerSlavebyId(item?.MODEM_UNIQUE_ID);
		var arry = [];

		data?.data?.data.map((item) => arry.push(item.payload));

		if (data.success === true) {
			setSlaveList(arry);
		}

		setslavesType(item?.CARD_TYPE || 'Addition Of Slaves')
		if (item?.CARD_TYPE === 'Differentiation Of Slaves') {
			var Slaves1_arr = []
			var SlavesVal1_arr = []
			var Slaves2_arr = []
			var SlavesVal2_arr = []
			JSON.parse(item?.SLAVES_DETAILS).map((items) => (modem.map((info) => (JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
				if (items?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
					Slaves1_arr.push(con_name?.CONSDEVNAME_U)
				}
			})
			))
			))
			setslaves1(Slaves1_arr);

			JSON.parse(item?.DIFFERENTIATION_SLAVES_DETAILS).map((items) => (modem.map((info) => (JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
				if (items?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
					Slaves2_arr.push(con_name?.CONSDEVNAME_U)
				}
			})
			))
			))
			setslaves2(Slaves2_arr);

			JSON.parse(item?.SLAVES_DETAILS).map((items) => (modem.map((info) => (JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
				if (items?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
					SlavesVal1_arr.push(con_name)
				}
			})
			))
			))
			setslavesVal1(SlavesVal1_arr)
			JSON.parse(item?.DIFFERENTIATION_SLAVES_DETAILS).map((items) => (modem.map((info) => (JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
				if (items?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
					SlavesVal2_arr.push(con_name)
				}
			})
			))
			))
			setslavesVal2(SlavesVal2_arr)
		} else {
			var SlavesData_arr = []
			JSON.parse(item?.SLAVES_DETAILS).map((items) => (modem.map((info) => (JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
				if (items?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
					SlavesData_arr.push(con_name)
				}
			})
			))
			))
			setSlaveData(JSON.parse(item?.SLAVES_DETAILS));
			const slaveName = [];
			await JSON.parse(item?.SLAVES_DETAILS).map((items) => (modem.map((info) => (JSON.parse(info?.SLAVES_ID_U || '{}')?.DETAILS_U.map((con_name) => {
				if (items?.CONSSLAVEUID_S == con_name?.CONSSLAVEUID_S) {
					slaveName.push(con_name?.CONSDEVNAME_U)
				}
			})
			))
			))
			setPersonName(slaveName);
		}
		setmodel(true);
		seteditBtn(false);
	};

	const onFindTabName = async () => {
		const data = await FindTabName();
		if (data.success === true) {
			setTabList(data.data.data);
		} else {
		}
	};

	const onDeletePlatData = async (id) => {
		swal.fire({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this Card!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await deleteCardDeshboard(id);
				if (data.success === true) {
					onliveData();
					setmodel(false);
					setEditeId(null);
					setConsumerId(null)
					setSlaveData([]);
					setPersonName([]);
					setModemID({ MODEM_UNIQUE_ID: "", cardName: "", card_description: "", CARD_VIEW: '0' });
					setAddFlag(false);
					setfildCardDisable(false)
					setslavesType("Addition Of Slave")
					setslaves1([])
					setslavesVal1([])
					setslaves2([])
					setslaves2([])
					setslavesVal2([])
					toast.success("Delete Card Success");
				}
			} else if (willDelete.isDismissed) {
				swal.fire("your card is safe");
			}
		});
	};

	const onRefreshData = async () => {
		setRefresh(true);
		const data = await refreshDeshboardData();
		if (data.success) {
			onliveData();
			setRefresh(false);
		} else {
			console.log("somting Wait Wrong");
			setRefresh(false);
		}
	};

	const movesubPages = (id) => {
		history.push(`/dashboard-sub-view/${id}/${deshboardId}`)
	}

	const onMoveGSMDeshboard = async (IMEI, slavsID) => {
		if (IMEI || slavsID) {
			const data = await GetConsumberInByIMEI({ IMEI: IMEI, slavsID: slavsID })
			if (data.success === true) {
				history.push({ pathname: `/view-user/${data.data.UNIQUE_ID}`, state: { payload: data.data.filterSlavs, last_seen: data.data.last_seen } });
			} else {
				console.log('something wait wrong');
			}
		}
	}
	const onHandalebtn = (text) => {
		setButtontext(text);
	};

	const onMoverGSMDashboardBySlavesId = async (slavsID) => {
		if (slavsID) {
			const data = await GetConsumberInBySlavesId({ SlavesId: slavsID })
			if (data.success === true) {
				history.push({ pathname: `/view-user/${data.data.UNIQUE_ID}`, state: { payload: data.data.filterSlavs, last_seen: data.data.last_seen } });
			} else {
				console.log('something wait wrong');
			}
		}
	}

	const [lastSeenType, setLastSeenType] = useState("Daily Report");
	const [ReportCardId, setReportCardId] = useState('')
	const [ReportLoader, setReportLoader] = useState(false)
	const [ismodelFilter, setismodelFilter] = useState(false)
	const [ReportList, setReportList] = useState([])
	const [ReportCardDetail, setReportCardDetail] = useState({})
	const csvLink = useRef();

	const onReportGenereteModel = (id, item) => {
		setIsLastModalVisible(true)
		setReportCardId(id)
		setReportCardDetail({ ...item })
		console.log(JSON.parse(item?.SCHEDULE_DETAILS||'{}'))
		setValue({ cron_value: JSON.parse(item?.SCHEDULE_DETAILS||'{}')?.cron_value, select_period: JSON.parse(item?.SCHEDULE_DETAILS||'{}')?.select_period,status:item?.SCHEDULE_STATUS||1,email_id:JSON.parse(item?.SCHEDULE_DETAILS||'{}')?.email_id,report_type:JSON.parse(item?.SCHEDULE_DETAILS||'{}')?.report_type })
	}

	useEffect(() => {
		if (ReportList.length > 0) {
			csvLink.current.link.click();
			setReportList([])
		}
	}, [ReportList])
	const onGenrateReport = async (type) => {
		document.body.style.cursor = 'wait'

		setReportLoader(true)
		const arrayInTable = JSON.parse(ReportCardDetail?.payload?.slaves_details || '[]')
		const arrayInSlaves = JSON.parse(ReportCardDetail?.SLAVES_DETAILS || '[]')
		const arrayInDiductionSlaves = JSON.parse(ReportCardDetail?.DIFFERENTIATION_SLAVES_DETAILS || '[]')
		const arrayErrorSlaves = JSON.parse(ReportCardDetail?.payload?.inactive_slaves_details || '[]')
		const subtocardarray = []
		const Errorcardarray = []
		arrayInTable.map((item, id) => {
			arrayInSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					subtocardarray.push(info.CONSDEVNAME_U)

				}
			})
		})
		arrayInTable?.map((item, id) => {
			arrayInDiductionSlaves?.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					subtocardarray.push(info.CONSDEVNAME_U)
				}
			})

		})
		arrayErrorSlaves.map((item) => {
			arrayInSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					Errorcardarray.push(info.CONSDEVNAME_U)
				}
			})
		})
		arrayErrorSlaves.map((item) => {
			arrayInDiductionSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					Errorcardarray.push(info.CONSDEVNAME_U)
				}
			})
		})
		const data = await getCardWiseSlaveChartDataInKWHByselectedDate(ReportCardId, { start: moment(datesInmap.start, 'DD-MM-YYYY').format('YYYY-MM-DD'), end: moment(datesInmap.end, 'DD-MM-YYYY').format('YYYY-MM-DD') })
		if (data.success === true) {
			if (type === 'csv') {
				setReportList(data.data.data.map((item) => ({ date: item?.date, Daily_KWH_count: parseFloat(ReportCardDetail?.MULTIFICATION_FECTOR ? (item?.Daily_KWH_count * ReportCardDetail?.MULTIFICATION_FECTOR) : (item?.Daily_KWH_count)) })))

			} else {
				const obj = [{ Header: 'Card Name', Details: ReportCardDetail?.CARD_NAME }, { Header: 'Card Description', Details: ReportCardDetail?.card_description }, { Header: 'Total Slaves', Details: ReportCardDetail?.CARD_TYPE == 'Differentiation Of Slaves' ? JSON.parse(ReportCardDetail?.SLAVES_DETAILS || '[]').length + JSON.parse(ReportCardDetail?.DIFFERENTIATION_SLAVES_DETAILS || '[]').length : JSON.parse(ReportCardDetail?.SLAVES_DETAILS || '[]').length }, { Header: 'Available Data Slaves Count', Details: JSON.parse(ReportCardDetail?.payload?.slaves_details || '[]').length }, { Header: 'Unavailable Data Slaves Count', Details: JSON.parse(ReportCardDetail?.payload?.inactive_slaves_details || '[]').length }, { Header: 'Available Data Slaves List', Details: JSON.stringify(subtocardarray) }, { Header: 'Unavailable Data Slaves Count', Details: JSON.stringify(Errorcardarray) }, { Header: 'Report Generat Time', Details: moment().format('DD-MM-YYYY hh:mm:ss A') }]

				const worksheet1 = XLSX.utils.json_to_sheet(obj);
				const worksheet = XLSX.utils.json_to_sheet(data.data.data.map((item) => ({ date: item?.date, Daily_KWH_count: parseFloat(ReportCardDetail?.MULTIFICATION_FECTOR ? (item?.Daily_KWH_count * ReportCardDetail?.MULTIFICATION_FECTOR) : (item?.Daily_KWH_count)) })));

				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet1, "Info");

				XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
				//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
				//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
				XLSX.writeFile(workbook, `${new Date().getTime()}.xlsx`);
			}
			setReportLoader(false)
			document.body.style.cursor = 'default'

		} else {
			toast.error('Server Error')
			setReportLoader(false)
			document.body.style.cursor = 'default'

		}
	}

	const onGenrateReportMonthly = async (type) => {
		document.body.style.cursor = 'wait'

		setReportLoader(true)
		const arrayInTable = JSON.parse(ReportCardDetail?.payload?.slaves_details || '[]')
		const arrayInSlaves = JSON.parse(ReportCardDetail?.SLAVES_DETAILS || '[]')
		const arrayInDiductionSlaves = JSON.parse(ReportCardDetail?.DIFFERENTIATION_SLAVES_DETAILS || '[]')
		const arrayErrorSlaves = JSON.parse(ReportCardDetail?.payload?.inactive_slaves_details || '[]')
		const subtocardarray = []
		const Errorcardarray = []
		arrayInTable.map((item, id) => {
			arrayInSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					subtocardarray.push(info.CONSDEVNAME_U)

				}
			})
		})
		arrayInTable?.map((item, id) => {
			arrayInDiductionSlaves?.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					subtocardarray.push(info.CONSDEVNAME_U)
				}
			})

		})
		arrayErrorSlaves.map((item) => {
			arrayInSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					Errorcardarray.push(info.CONSDEVNAME_U)
				}
			})
		})
		arrayErrorSlaves.map((item) => {
			arrayInDiductionSlaves.map((info) => {
				if (item?.CONSUMER_UNIQUE_ID == info?.CONSSLAVEUID_S) {
					Errorcardarray.push(info.CONSDEVNAME_U)
				}
			})
		})
		console.log(subtocardarray, Errorcardarray);
		const data = await getCardWiseSlaveChartDataInKWHMonthlyBySelectedMonth(ReportCardId, datesInmap)
		if (data.success === true) {
			if (type === 'csv') {
				setReportList(data.data.data.map((item) => ({ date: item?.date, Daily_KWH_count: parseFloat(ReportCardDetail?.MULTIFICATION_FECTOR ? (item?.Daily_KWH_count * ReportCardDetail?.MULTIFICATION_FECTOR) : (item?.Daily_KWH_count)) })))
			} else {
				const obj = [{ Header: 'Card Name', Details: ReportCardDetail?.CARD_NAME }, { Header: 'Card Description', Details: ReportCardDetail?.card_description }, { Header: 'Total Slaves', Details: ReportCardDetail?.CARD_TYPE == 'Differentiation Of Slaves' ? JSON.parse(ReportCardDetail?.SLAVES_DETAILS || '[]').length + JSON.parse(ReportCardDetail?.DIFFERENTIATION_SLAVES_DETAILS || '[]').length : JSON.parse(ReportCardDetail?.SLAVES_DETAILS || '[]').length }, { Header: 'Available Data Slaves Count', Details: JSON.parse(ReportCardDetail?.payload?.slaves_details || '[]').length }, { Header: 'Unavailable Data Slaves Count', Details: JSON.parse(ReportCardDetail?.payload?.inactive_slaves_details || '[]').length }, { Header: 'Available Data Slaves List', Details: JSON.stringify(subtocardarray) }, { Header: 'Unavailable Data Slaves Count', Details: JSON.stringify(Errorcardarray) }, { Header: 'Report Generat Time', Details: moment().format('DD-MM-YYYY hh:mm:ss A') }]
				const worksheet1 = XLSX.utils.json_to_sheet(obj);
				const worksheet = XLSX.utils.json_to_sheet(data.data.data.map((item) => ({ date: item?.date, Daily_KWH_count: parseFloat(ReportCardDetail?.MULTIFICATION_FECTOR ? (item?.Daily_KWH_count * ReportCardDetail?.MULTIFICATION_FECTOR) : (item?.Daily_KWH_count)) })));
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet1, "Info");
				XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
				//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
				//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
				XLSX.writeFile(workbook, `${new Date().getTime()}.xlsx`);
			}
			setReportLoader(false)
			document.body.style.cursor = 'default'

		} else {
			toast.error('Server Error')
			setReportLoader(false)
			document.body.style.cursor = 'default'

		}
	}
	const [FilterObj, setFilterObj] = useState({})
	const [CheckboxVal, setCheckboxVal] = useState({ IR: false, IY: false, IB: false, VRN: false, VYN: false, VBN: false })
	const [CardId, setCardId] = useState('')

	const handleChangeS = (e) => {
		if (e.target.checked) {
			setFilterObj({ ...FilterObj, [e.target.name]: {} })
			setCheckboxVal({ ...CheckboxVal, [e.target.name]: true })
		} else {
			delete FilterObj[e.target.name]
			setFilterObj(FilterObj)
			setCheckboxVal({ ...CheckboxVal, [e.target.name]: false })

		}

	}
	const animatedComponents = makeAnimated();
	const dataWise = [
		{ value: "Between", label: 'Is between' },
		{ value: "lessThan", label: 'Is Less than Equel to' },
		{ value: "greaterThan", label: "Is Greater than Equel to" }
	]

	const customStyles = {
		menu: provided => ({ ...provided, zIndex: 9999 }),
		multiValue: (provided, { data }) => {
			return {
				...provided,
				width: 'min-content',
				backgroundColor: "#6777ef",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "white",
		}),


	}

	const onCalcelAlert = () => {
		setismodelFilter(false)
		setFilterObj({})
		setCheckboxVal({ IR: false, IY: false, IB: false, VRN: false, VYN: false, VBN: false })
	}

	const onupadateCardInfoAlert = async () => {
		if (FilterObj?.email) {
			const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
			const checks = emailRegex.test(FilterObj?.email)
			if (!checks) {
				return toast.error('please Enter Valid Email')
			}
		}
		const data = await upadateCardInfoAlert({ info: JSON.stringify(FilterObj), ID: CardId })
		if (data.success === true) {
			toast.success('Add Alert')
			onCalcelAlert()
		} else {
			console.log('something want wrong')
		}
	}

	const onupadateSetCardScheduleInfo = async () => {
			if(value.select_period&&value.status&&value.email_id&&value.report_type){
		const data = await upadateSetCardScheduleInfo({ SCHEDULE_DETAILS: value,SCHEDULE_STATUS:value?.status, ID: ReportCardDetail?.ID })
		if (data.success === true) {
			toast.success('Add Report Schedule')
			onliveData();
			setValue({ cron_value: '0 0 * * *', select_period: 'day',status:'1',email_id:'',report_type:'' })
			setIsLastModalVisible(false)
		} else {
			console.log('something want wrong')
		}
	}else{
		toast.error('Please Enter Mandatory Fields')
	}
	}

	const onCardAlertModel = async (id) => {
		setismodelFilter(true)
		setCardId(id)
		const data = await getCardInfoAlertById(id, {})
		if (data.success === true) {
			const filObj = JSON.parse(data?.data?.data[0]?.card_alert_info || '{}')
			setFilterObj(filObj)
			if (Object.keys(filObj).length > 0) {
				var obj = { ...CheckboxVal }
				Object.keys(filObj).map((item) => {
					console.log(item);
					if (item !== 'email') {
						obj[item] = true
					}
				})
				setCheckboxVal(obj)
			}
			console.log(JSON.parse(data.data.data[0].card_alert_info))
		} else {
			console.log('something wait wrong')
		}

	}

	const onDrop = async (e, targetIndex) => {
		console.log("end", targetIndex);
		const sourceIndex = parseInt(e.dataTransfer.getData('index'));
		console.log('sourceIndex', sourceIndex)
		const newData = [...platofcard];
		const [removed] = newData.splice(sourceIndex, 1);
		console.log('removed---->', removed)
		newData.splice(targetIndex, 0, removed);
		console.log('newData----->', newData)
		const data = await UpdateCardPositionOnDrag({ card_list: newData })
		if (data.success == true) {
			onliveData()
		} else {
			toast.error('Server Issue')
		}
	};

	const onDragStart = (e, index) => {
		console.log("start", index);
		e.dataTransfer.setData('index', index.toString());

	};

	const allowDrop = e => {
		e.preventDefault();
	};

	return (
		<>
			<Modal title={<div>{!TabID ? "Add New Tab" : 'Update New Tab'}{(permission === "ROLE1680234321" || roles[tabName]?.edit || TabuserId == user_info.userID) && TabID ? <div class="btn btn-info text-white btnedit ml-2" onClick={() => (setfildDisable(true))} style={{ padding: '1px 8px 2px 8px' }}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : ""}{(permission === "ROLE1680234321" || roles[tabName]?.delete || TabuserId == user_info.userID) && TabID ? <div class="btn btn-danger text-white btnedit ml-2" onClick={() => onDelete(TabID)} style={{ padding: '1px 8px 2px 8px' }}><BootstrapTooltip title='Delete'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-trash"></i></span></BootstrapTooltip></div> : ""}</div>} visible={isModalVisible} footer='' onCancel={handleCancel} >
				<form class="">
					<div class="form-group">
						<label>Tab Name</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">
									<i class="fas fa-table"></i>
								</div>
							</div>
							<input
								type="text"
								class="form-control"
								placeholder="Tab Name"
								name="tabName"
								onChange={(e) => setTabName(e.target.value)}
								value={tabName}
								disabled={TabID ? (!fildDisable) : false}
							/>
						</div>
					</div>
				</form>
				<div className="mt-3">
					{fildDisable === true && TabID ? <a className={`btn btn-danger ${fildDisable === false && TabID ? 'disabled' : ''} text-white`} onClick={handleOk}>{!TabID ? 'Submit' : 'Update'}</a> : ''}
					{fildDisable === false && !TabID ? <a className={`btn btn-danger ${fildDisable === false && TabID ? 'disabled' : ''} text-white`} onClick={handleOk}>{!TabID ? 'Submit' : 'Update'}</a> : ''}
					<a className="btn btn-light  text-dark ml-2" onClick={handleCancel}>cancel</a>
				</div>
			</Modal>
			<Modal visible={isModalVisibletable} footer="" width={2000} onCancel={() => setIsModalVisibletable(false)}>
				<h4>{totalTabledata?.CARD_NAME}</h4>
				{buttonArray.map((item, id) => (
					<div
						className={`text-${item === 'Consumer Info' ? 'dark' : 'white'} btn ${item === "Available Data List" && "btn-primary"} ${item === "Unavailable Data List" && "btn-danger"
							} ${item === "All Slave List" && "btn-info"} dashboard  ${buttonArray.findIndex((data) => data === btnText) === id ? "btn-lg btn-text-lg" : ""
							} ml-2`}
						style={{ marginBottom: "8px" }}
						onClick={() => onHandalebtn(item)}
					>
						{item}
					</div>
				))}
				{btnText === 'Available Data List' && <div className="table-responsive">
					{totalTabledata?.CARD_TYPE == 'Differentiation Of Slaves' ? <>
						<table className="table table-striped" id="table-1">
							<thead>
								<tr>
									<th className="text-center">SRNO</th>
									<th>IMEI</th>
									<th>CONSUMER UNIQUE ID</th>
									<th>Slaves Name</th>
									<th>VRN</th>
									<th>VYN</th>
									<th>VBN</th>
									<th>IR</th>
									<th>IY</th>
									<th>IB</th>
									<th>KW</th>
									<th>PF</th>
									<th>KVA</th>
									<th>KWH</th>
									<th>FRQ</th>
									<th>ENTRYTS</th>
								</tr>
							</thead>
							<tbody>
								{tableData &&
									tableData.map((item) => (
										JSON.parse(totalTabledata?.SLAVES_DETAILS || '[]').filter((items, id) => (item?.CONSUMER_UNIQUE_ID == items?.CONSSLAVEUID_S)).length > 0 ?
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoveGSMDeshboard(item?.IMEI, item?.CONSUMER_UNIQUE_ID)}>
												<td className="text-center">{item?.id || "error"}</td>
												<td>{item?.IMEI || "error"}</td>
												<td style={{ width: "200px" }}>{item?.CONSUMER_UNIQUE_ID}</td>
												<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
												<td>{item?.VRN !== null ? item?.VRN : "error"}</td>
												<td>{item?.VYN !== null ? item?.VYN : "error"}</td>
												<td>{item?.VBN !== null ? item?.VBN : "error"}</td>
												<td>{item?.IR !== null ? item?.IR : "error"}</td>
												<td>{item?.IY !== null ? item?.IY : "error"}</td>
												<td>{item?.IB !== null ? item?.IB : "error"}</td>
												<td>{item?.POW !== null ? item?.POW : "error"}</td>
												<td>{item?.PF !== null ? item?.PF : "error"}</td>
												<td>{item?.APOW !== null ? item?.APOW : "error"}</td>
												<td>{item?.KWH !== null ? item?.KWH : "error"}</td>
												<td>{item?.FRQ !== null ? item?.FRQ : "error"}</td>
												<td style={{ width: "100px" }}>{moment.utc(item?.ENTRYTS || "").format("DD/MM/YYYY HH:mm:ss")}</td>
											</tr> : ''
									))}
								<tr className="mt-5">
									<td colSpan={4} className="text-center ">
										<b>Total</b>
									</td>
									<td>{ObjectVal1?.VRN !== null ? ObjectVal1?.VRN?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.VYN !== null ? ObjectVal1?.VYN?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.VBN !== null ? ObjectVal1?.VBN?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.IR !== null ? ObjectVal1?.IR?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.IY !== null ? ObjectVal1?.IY?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.IB !== null ? ObjectVal1?.IB?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.POW !== null ? ObjectVal1?.POW?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.PF !== null ? ObjectVal1?.PF?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.KVA !== null ? ObjectVal1?.KVA?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.KWH !== null ? ObjectVal1?.KWH?.toFixed(2) : "error"}</td>
									<td>{ObjectVal1?.FRQ !== null ? ObjectVal1?.FRQ?.toFixed(2) : "error"}</td>
								</tr>
							</tbody>
						</table>
						<table className="table table-striped" id="table-1">
							<thead>
								<tr>
									<th className="text-center">SRNO</th>
									<th>IMEI</th>
									<th>CONSUMER UNIQUE ID</th>
									<th>Slaves Name</th>
									<th>VRN</th>
									<th>VYN</th>
									<th>VBN</th>
									<th>IR</th>
									<th>IY</th>
									<th>IB</th>
									<th>KW</th>
									<th>PF</th>
									<th>KVA</th>
									<th>KWH</th>
									<th>FRQ</th>
									<th>ENTRYTS</th>
								</tr>
							</thead>
							<tbody>
								{tableData &&
									tableData.map((item) => (
										JSON.parse(totalTabledata?.SLAVES_DETAILS || '[]').filter((items, id) => (item?.CONSUMER_UNIQUE_ID == items?.CONSSLAVEUID_S)).length > 0 ? '' :
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoveGSMDeshboard(item?.IMEI, item?.CONSUMER_UNIQUE_ID)}>
												<td className="text-center">{item?.id || "error"}</td>
												<td>{item?.IMEI || "error"}</td>
												<td style={{ width: "200px" }}>{item?.CONSUMER_UNIQUE_ID}</td>
												<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
												<td>{item?.VRN !== null ? item?.VRN : "error"}</td>
												<td>{item?.VYN !== null ? item?.VYN : "error"}</td>
												<td>{item?.VBN !== null ? item?.VBN : "error"}</td>
												<td>{item?.IR !== null ? item?.IR : "error"}</td>
												<td>{item?.IY !== null ? item?.IY : "error"}</td>
												<td>{item?.IB !== null ? item?.IB : "error"}</td>
												<td>{item?.POW !== null ? item?.POW : "error"}</td>
												<td>{item?.PF !== null ? item?.PF : "error"}</td>
												<td>{item?.APOW !== null ? item?.APOW : "error"}</td>
												<td>{item?.KWH !== null ? item?.KWH : "error"}</td>
												<td>{item?.FRQ !== null ? item?.FRQ : "error"}</td>
												<td style={{ width: "100px" }}>{moment.utc(item?.ENTRYTS || "").format("DD/MM/YYYY HH:mm:ss")}</td>
											</tr>
									))}
								<tr className="mt-5">
									<td colSpan={4} className="text-center ">
										<b>Total</b>
									</td>
									<td>{ObjectVal2?.VRN !== null ? ObjectVal2?.VRN?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.VYN !== null ? ObjectVal2?.VYN?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.VBN !== null ? ObjectVal2?.VBN?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.IR !== null ? ObjectVal2?.IR?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.IY !== null ? ObjectVal2?.IY?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.IB !== null ? ObjectVal2?.IB?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.POW !== null ? ObjectVal2?.POW?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.PF !== null ? ObjectVal2?.PF?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.KVA !== null ? ObjectVal2?.KVA?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.KWH !== null ? ObjectVal2?.KWH?.toFixed(2) : "error"}</td>
									<td>{ObjectVal2?.FRQ !== null ? ObjectVal2?.FRQ?.toFixed(2) : "error"}</td>
								</tr>
							</tbody>
						</table>
						<table className="table table-striped" id="table-1">
							<thead>
								<tr>
									<th className="text-center" style={{ width: '50%' }}></th>

									<th>VRN</th>
									<th>VYN</th>
									<th>VBN</th>
									<th>IR</th>
									<th>IY</th>
									<th>IB</th>
									<th>KW</th>
									<th>PF</th>
									<th>KVA</th>
									<th>KWH</th>
									<th>FRQ</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr className="mt-5">

									<td className="text-center " style={{ width: '50%' }}>
										<b>Total</b>
									</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.VRN !== null ? totalTabledata?.payload?.VRN : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.VYN !== null ? totalTabledata?.payload?.VYN : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.VBN !== null ? totalTabledata?.payload?.VBN : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.IR !== null ? totalTabledata?.payload?.IR : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.IY !== null ? totalTabledata?.payload?.IY : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.IB !== null ? totalTabledata?.payload?.IB : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.POW !== null ? totalTabledata?.payload?.POW : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.PF !== null ? totalTabledata?.payload?.PF : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.KVA !== null ? totalTabledata?.payload?.KVA : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.KWH !== null ? totalTabledata?.payload?.KWH : "error"}</td>
									<td style={{ width: '5%' }}>{totalTabledata?.payload?.FRQ !== null ? totalTabledata?.payload?.FRQ : "error"}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</> : <table className="table table-striped" id="table-1">
						<thead>
							<tr>
								<th className="text-center">SRNO</th>
								<th>IMEI</th>
								<th>CONSUMER UNIQUE ID</th>
								<th>Slaves Name</th>
								<th>VRN</th>
								<th>VYN</th>
								<th>VBN</th>
								<th>IR</th>
								<th>IY</th>
								<th>IB</th>
								<th>KW</th>
								<th>PF</th>
								<th>KVA</th>
								<th>KWH</th>
								<th>FRQ</th>
								<th>ENTRYTS</th>
							</tr>
						</thead>
						<tbody>
							{tableData &&
								tableData.map((item) => (
									<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoveGSMDeshboard(item?.IMEI, item?.CONSUMER_UNIQUE_ID)}>
										<td className="text-center">{item?.id || "error"}</td>
										<td>{item?.IMEI || "error"}</td>
										<td style={{ width: "200px" }}>{item?.CONSUMER_UNIQUE_ID}</td>
										<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
										<td>{item?.VRN !== null ? item?.VRN : "error"}</td>
										<td>{item?.VYN !== null ? item?.VYN : "error"}</td>
										<td>{item?.VBN !== null ? item?.VBN : "error"}</td>
										<td>{item?.IR !== null ? item?.IR : "error"}</td>
										<td>{item?.IY !== null ? item?.IY : "error"}</td>
										<td>{item?.IB !== null ? item?.IB : "error"}</td>
										<td>{item?.POW !== null ? item?.POW : "error"}</td>
										<td>{item?.PF !== null ? item?.PF : "error"}</td>
										<td>{item?.APOW !== null ? item?.APOW : "error"}</td>
										<td>{item?.KWH !== null ? item?.KWH : "error"}</td>
										<td>{item?.FRQ !== null ? item?.FRQ : "error"}</td>
										<td style={{ width: "100px" }}>{moment.utc(item?.ENTRYTS || "").format("DD/MM/YYYY HH:mm:ss")}</td>
									</tr>
								))}
							<tr>
								<td colSpan={4} className="text-center">
									<b>Total</b>
								</td>
								<td>{totalTabledata?.payload?.VRN !== null ? totalTabledata?.payload?.VRN : "error"}</td>
								<td>{totalTabledata?.payload?.VYN !== null ? totalTabledata?.payload?.VYN : "error"}</td>
								<td>{totalTabledata?.payload?.VBN !== null ? totalTabledata?.payload?.VBN : "error"}</td>
								<td>{totalTabledata?.payload?.IR !== null ? totalTabledata?.payload?.IR : "error"}</td>
								<td>{totalTabledata?.payload?.IY !== null ? totalTabledata?.payload?.IY : "error"}</td>
								<td>{totalTabledata?.payload?.IB !== null ? totalTabledata?.payload?.IB : "error"}</td>
								<td>{totalTabledata?.payload?.POW !== null ? totalTabledata?.payload?.POW : "error"}</td>
								<td>{totalTabledata?.payload?.PF !== null ? totalTabledata?.payload?.PF : "error"}</td>
								<td>{totalTabledata?.payload?.KVA !== null ? totalTabledata?.payload?.KVA : "error"}</td>
								<td>{totalTabledata?.payload?.KWH !== null ? totalTabledata?.payload?.KWH : "error"}</td>
								<td>{totalTabledata?.payload?.FRQ !== null ? totalTabledata?.payload?.FRQ : "error"}</td>
							</tr>
						</tbody>
					</table>}
				</div>}
				{btnText === 'Unavailable Data List' && <div className="table-responsive">
					{totalTabledata?.CARD_TYPE == 'Differentiation Of Slaves' ? <>
						<table className="table table-striped" id="table-1">
							<thead>
								<tr>
									<th className="text-center">SRNO</th>
									<th>IMEI</th>
									<th>CONSUMER UNIQUE ID</th>
									<th>Slaves Name</th>
									<th>VRN</th>
									<th>VYN</th>
									<th>VBN</th>
									<th>IR</th>
									<th>IY</th>
									<th>IB</th>
									<th>KW</th>
									<th>PF</th>
									<th>KVA</th>
									<th>KWH</th>
									<th>FRQ</th>
									<th>ENTRYTS</th>
								</tr>
							</thead>
							<tbody>
								{ErrorData &&
									ErrorData.map((item) => (
										JSON.parse(totalTabledata?.SLAVES_DETAILS || '[]').filter((items, id) => (item?.CONSUMER_UNIQUE_ID == items?.CONSSLAVEUID_S)).length > 0 ?
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoveGSMDeshboard(item?.IMEI, item?.CONSUMER_UNIQUE_ID)}>
												<td className="text-center">{item?.id || "error"}</td>
												<td>{item?.IMEI || "error"}</td>
												<td style={{ width: "200px" }}>{item?.CONSUMER_UNIQUE_ID}</td>
												<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
												<td>{item?.VRN !== null ? item?.VRN : "error"}</td>
												<td>{item?.VYN !== null ? item?.VYN : "error"}</td>
												<td>{item?.VBN !== null ? item?.VBN : "error"}</td>
												<td>{item?.IR !== null ? item?.IR : "error"}</td>
												<td>{item?.IY !== null ? item?.IY : "error"}</td>
												<td>{item?.IB !== null ? item?.IB : "error"}</td>
												<td>{item?.POW !== null ? item?.POW : "error"}</td>
												<td>{item?.PF !== null ? item?.PF : "error"}</td>
												<td>{item?.APOW !== null ? item?.APOW : "error"}</td>
												<td>{item?.KWH !== null ? item?.KWH : "error"}</td>
												<td>{item?.FRQ !== null ? item?.FRQ : "error"}</td>
												<td style={{ width: "100px" }}>{moment.utc(item?.ENTRYTS || "").format("DD/MM/YYYY HH:mm:ss")}</td>
											</tr> : ''
									))}
							</tbody>
						</table>
						<table className="table table-striped mt-5" id="table-1">
							<thead>
								<tr>
									<th className="text-center">SRNO</th>
									<th>IMEI</th>
									<th>CONSUMER UNIQUE ID</th>
									<th>Slaves Name</th>
									<th>VRN</th>
									<th>VYN</th>
									<th>VBN</th>
									<th>IR</th>
									<th>IY</th>
									<th>IB</th>
									<th>KW</th>
									<th>PF</th>
									<th>KVA</th>
									<th>KWH</th>
									<th>FRQ</th>
									<th>ENTRYTS</th>
								</tr>
							</thead>
							<tbody>
								{ErrorData &&
									ErrorData.map((item) => (
										JSON.parse(totalTabledata?.SLAVES_DETAILS || '[]').filter((items, id) => (item?.CONSUMER_UNIQUE_ID == items?.CONSSLAVEUID_S)).length > 0 ? '' :
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoveGSMDeshboard(item?.IMEI, item?.CONSUMER_UNIQUE_ID)}>
												<td className="text-center">{item?.id || "error"}</td>
												<td>{item?.IMEI || "error"}</td>
												<td style={{ width: "200px" }}>{item?.CONSUMER_UNIQUE_ID}</td>
												<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
												<td>{item?.VRN !== null ? item?.VRN : "error"}</td>
												<td>{item?.VYN !== null ? item?.VYN : "error"}</td>
												<td>{item?.VBN !== null ? item?.VBN : "error"}</td>
												<td>{item?.IR !== null ? item?.IR : "error"}</td>
												<td>{item?.IY !== null ? item?.IY : "error"}</td>
												<td>{item?.IB !== null ? item?.IB : "error"}</td>
												<td>{item?.POW !== null ? item?.POW : "error"}</td>
												<td>{item?.PF !== null ? item?.PF : "error"}</td>
												<td>{item?.APOW !== null ? item?.APOW : "error"}</td>
												<td>{item?.KWH !== null ? item?.KWH : "error"}</td>
												<td>{item?.FRQ !== null ? item?.FRQ : "error"}</td>
												<td style={{ width: "100px" }}>{moment.utc(item?.ENTRYTS || "").format("DD/MM/YYYY HH:mm:ss")}</td>
											</tr>
									))}
							</tbody>
						</table>
					</> : <table className="table table-striped" id="table-1">
						<thead>
							<tr>
								<th className="text-center">SRNO</th>
								<th>IMEI</th>
								<th>CONSUMER UNIQUE ID</th>
								<th>Slaves Name</th>
								<th>VRN</th>
								<th>VYN</th>
								<th>VBN</th>
								<th>IR</th>
								<th>IY</th>
								<th>IB</th>
								<th>KW</th>
								<th>PF</th>
								<th>KVA</th>
								<th>KWH</th>
								<th>FRQ</th>
								<th>ENTRYTS</th>
							</tr>
						</thead>
						<tbody>
							{ErrorData &&
								ErrorData.map((item) => (
									<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoveGSMDeshboard(item?.IMEI, item?.CONSUMER_UNIQUE_ID)}>
										<td className="text-center">{item?.id || "error"}</td>
										<td>{item?.IMEI || "error"}</td>
										<td style={{ width: "200px" }}>{item?.CONSUMER_UNIQUE_ID}</td>
										<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
										<td>{item?.VRN !== null ? item?.VRN : "error"}</td>
										<td>{item?.VYN !== null ? item?.VYN : "error"}</td>
										<td>{item?.VBN !== null ? item?.VBN : "error"}</td>
										<td>{item?.IR !== null ? item?.IR : "error"}</td>
										<td>{item?.IY !== null ? item?.IY : "error"}</td>
										<td>{item?.IB !== null ? item?.IB : "error"}</td>
										<td>{item?.POW !== null ? item?.POW : "error"}</td>
										<td>{item?.PF !== null ? item?.PF : "error"}</td>
										<td>{item?.APOW !== null ? item?.APOW : "error"}</td>
										<td>{item?.KWH !== null ? item?.KWH : "error"}</td>
										<td>{item?.FRQ !== null ? item?.FRQ : "error"}</td>
										<td style={{ width: "100px" }}>{moment.utc(item?.ENTRYTS || "").format("DD/MM/YYYY HH:mm:ss")}</td>
									</tr>
								))}
						</tbody>
					</table>}
				</div>}
				{btnText === 'All Slave List' && <div className="table-responsive">
					{totalTabledata?.CARD_TYPE == 'Differentiation Of Slaves' ? <><table className="table table-striped mt-3" id="table-1">
						<thead>
							<tr>
								<th className="text-center">SRNO</th>
								<th>CONSUMER UNIQUE ID</th>
								<th>Slaves Name</th>
							</tr>
						</thead>
						<tbody>
							{totalTabledata?.SLAVES_DETAILS &&
								JSON.parse(totalTabledata?.SLAVES_DETAILS || '[]').map((item, id) => (
									<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoverGSMDashboardBySlavesId(item?.CONSSLAVEUID_S)}>
										<td className="text-center" style={{ width: "100px" }}>{id + 1}</td>
										<td style={{ width: "150px" }}>{item?.CONSSLAVEUID_S}</td>
										<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
									</tr>
								))}
						</tbody>
					</table>
						<table className="table table-striped mt-5" id="table-1">
							<thead>
								<tr>
									<th className="text-center">SRNO</th>
									<th>CONSUMER UNIQUE ID</th>
									<th>Slaves Name</th>

								</tr>
							</thead>
							<tbody>
								{totalTabledata?.DIFFERENTIATION_SLAVES_DETAILS &&
									JSON.parse(totalTabledata?.DIFFERENTIATION_SLAVES_DETAILS || '[]').map((item, id) => (
										<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoverGSMDashboardBySlavesId(item?.CONSSLAVEUID_S)}>
											<td className="text-center" style={{ width: "100px" }}>{id + 1}</td>
											<td style={{ width: "150px" }}>{item?.CONSSLAVEUID_S}</td>
											<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
										</tr>
									))}
							</tbody>
						</table></> : <table className="table table-striped" id="table-1">
						<thead>
							<tr>
								<th className="text-center">SRNO</th>
								<th>CONSUMER UNIQUE ID</th>
								<th>Slaves Name</th>

							</tr>
						</thead>
						<tbody>
							{AllData &&
								AllData.map((item, id) => (
									<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth" onClick={() => onMoverGSMDashboardBySlavesId(item?.CONSSLAVEUID_S)}>
										<td className="text-center" style={{ width: "100px" }}>{id + 1}</td>
										<td style={{ width: "150px" }}>{item?.CONSSLAVEUID_S}</td>
										<td style={{ width: "400px" }}>{item?.CONSDEVNAME_U}</td>
									</tr>
								))}
						</tbody>
					</table>}
				</div>}
			</Modal>
			<Modal title={"Download Report"} visible={isLastModalVisible} maskClosable={false} footer='' onCancel={() => {
				setIsLastModalVisible(false)
			setValue({ cron_value: '0 0 * * *', select_period: 'day',status:'1',email_id:'',report_type:'' })
		}} width={1000} height={300}>
				<div class="form-group d-flex tw-gap-2 tw-p-0 tw-m-0" style={{ justifyContent: 'space-around' }}>
					<div className="tw-py-2 tw-w-full tw-flex tw-items-center tw-pl-4 tw-rounded tw-border-solid tw-border tw-border-gray-200 ">
						<input className="tw-w-6 tw-h-6 mr-2"
							type="radio"
							name="login_type"
							id="byHours"
							onChange={() => {
								setLastSeenType("Daily Report")
								setdatesInmap({ start: '', end: '' })
							}}
							checked={lastSeenType === "Daily Report" ? true : false}
						/>
						<label className="tw-mb-0 tw-ml-2 tw-w-full tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-gray-300" for="byHours">
							Daily Report
						</label>
					</div>
					<div className="tw-py-2 tw-w-full tw-flex tw-items-center tw-pl-4 tw-rounded tw-border-solid  tw-border tw-border-gray-200 ">
						<input className="tw-w-6 tw-h-6 mr-2"
							type="radio"
							name="login_type"
							id="byIntervals"
							onChange={() => {
								setLastSeenType("Monthly Report")
								setdatesInmap({ start: '', end: '' })

							}}
							checked={lastSeenType === "Monthly Report" ? true : false}
						/>
						<label className="tw-mb-0 tw-ml-2 tw-w-full tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-gray-300" for="byIntervals">
							Monthly Report
						</label>
					</div>
					<div className="tw-py-2 tw-w-full tw-flex tw-items-center tw-pl-4 tw-rounded tw-border-solid tw-border tw-border-gray-200 ">
						<input className="tw-w-6 tw-h-6 mr-2"
							type="radio"
							name="login_type"
							id="Schedule Report"
							onChange={() => {
								setLastSeenType("Schedule Report")
								setdatesInmap({ start: '', end: '' })
							}}
							checked={lastSeenType === "Schedule Report" ? true : false}
						/>
						<label className="tw-mb-0 tw-ml-2 tw-w-full tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-gray-300" for="Schedule Report">
							Schedule Report
						</label>
					</div>
				</div>
				{lastSeenType === 'Daily Report' ?
					<div className="tw-flex">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start Date
								<DatePicker format='DD-MM-YYYY' name='start' onChange={onChangeMap} disabledDate={(current) => {
									return current.isAfter(moment().subtract(1, 'days'))
								}} />
							</div>
							<div className="d-flex flex-column" >End Date
								<DatePicker name='end' format='DD-MM-YYYY' onChange={onChangesMap} disabledDate={(current) => {
									return current.isAfter(moment().subtract(1, 'days'))
								}} />
							</div>
						</Space>
						<div className="mt-3 d-flex">
							<a className={`btn btn-danger ${ReportLoader == true && 'disabled'} text-white mr-2`} onClick={() => onGenrateReport('excel')}>Excel</a>
							<a className={`btn btn-info ${ReportLoader == true && 'disabled'} text-white mr-2`} onClick={() => onGenrateReport('csv')}>CSV</a>

						</div>
						<CSVLink data={ReportList} filename={`${new Date().getTime()}.csv`} ref={csvLink} />
					</div>
					:
					lastSeenType === 'Monthly Report' ?
						<div className="">
							<div className=" tw-justify-between tw-items-center">
								<div className="d-flex tw-justify-center tw-items-end">
									<Space direction="horizontal">
										<div className="d-flex flex-column">Start Date
											<DatePicker format='MM-YYYY' name='start' picker="month" onChange={onChangeMap} disabledDate={(current) => {
												return current.isAfter(moment().subtract(1, 'month'))
											}} />
										</div>
										<div className="d-flex flex-column" >End Date
											<DatePicker name='end' format='MM-YYYY' onChange={onChangesMap} picker="month" disabledDate={(current) => {
												return current.isAfter(moment().subtract(1, 'month'))
											}} />
										</div>
									</Space>
								</div>
								<div className="mt-3 d-flex">
									<a className={`btn btn-danger ${ReportLoader == true && 'disabled'} text-white mr-2`} onClick={() => (onGenrateReportMonthly('excel'))}>Excel</a>
									<a className={`btn btn-info ${ReportLoader == true && 'disabled'} text-white mr-2`} onClick={() => (onGenrateReportMonthly('csv'))}>CSV</a>
								</div>
							</div>
							<CSVLink data={ReportList} filename={`${new Date().getTime()}.csv`} ref={csvLink} />

						</div> : <div>
							<div>
							<div className="col-md-6 d-flex">
									<label style={{width:'40%'}}>Report Type:-</label>
								<select class="form-select form-control" onChange={(e)=>{setValue({...value,report_type:e.target.value})}} value={value?.report_type}>
								<option value=''>Select Report Type</option>
										<option value='Total-Consumption-Report'>Total Consumption Report</option>
										{ReportCardDetail?.CARD_TYPE == "Differentiation Of Slaves"&&<option value='Losses-report'>% Losses report</option>}
									</select>
								</div>
								<div className="col-md-12 d-flex mt-2">
								<label className="mr-2">Set Schedule:- </label>
									<Cron value={value.cron_value} setValue={((e, values) => {
										console.log(e, value)
										if(e!==value.cron_value){
										setValue({...value, cron_value: e, select_period: values?.selectedPeriod })
										}
									})} allowedPeriods={['month', 'day']} />
								</div>
								<div class="col-md-6 d-flex">
									<label for="inputState" style={{width:'40%'}}>Enter Email:</label>
									<input type="text" class="form-control" id="" name="FirstName" placeholder="abc@gmail.com" onChange={(e) => { setValue({ ...value, email_id: e.target.value }) }} value={value?.email_id} />
								</div>
								<div className="col-md-6 d-flex mt-2">
									<label style={{width:'40%'}}>Schedule Status:-</label>
								<select class="form-select form-control" onChange={(e)=>{setValue({...value,status:e.target.value})}} value={value?.status}>
										<option value='1'>Active</option>
										<option value='0'>Inactive</option>
									</select>
								</div>
								
								<div className="mt-3 d-flex">
									<a className={`btn btn-danger ${ReportLoader == true && 'disabled'} text-white mr-2`} onClick={() => onupadateSetCardScheduleInfo()}>Submit</a>
								</div>
							</div>
						</div>}

			</Modal>
			<Modal title="Set Alert" visible={ismodelFilter} footer='' onCancel={() => onCalcelAlert()} width={1500}>
				<fieldset className="customLegend ">
					<legend >Alert Info</legend>
					<div className="row" style={{ marginLeft: 10 }}>
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextRSSI" name="IR" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.IR ? CheckboxVal?.IR : false} />
									<label className="custom-control-label" for="checktextRSSI">IR</label>
								</div>

								{CheckboxVal.IR && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, IR: { [e.target.value]: true } })} value={Object.keys(FilterObj?.IR)[0]}>
										<option value=''>Select Condition...</option>
										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}

								{FilterObj?.IR?.Between && CheckboxVal.IR && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, IR: { ...FilterObj.IR, From: e.target.value } }) }} value={FilterObj?.IR?.From} />
								</div>)}
								{FilterObj?.IR?.Between && CheckboxVal.IR && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, IR: { ...FilterObj.IR, To: e.target.value } }) }} value={FilterObj?.IR?.To} />
								</div>)}
								{FilterObj?.IR?.lessThan && CheckboxVal.IR && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, IR: { ...FilterObj.IR, value: e.target.value } }) }} value={FilterObj?.IR?.value} />
								</div>)}
								{FilterObj?.IR?.greaterThan && CheckboxVal.IR && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, IR: { ...FilterObj.IR, value: e.target.value } }) }} value={FilterObj?.IR?.value} />
								</div>)}
								{FilterObj?.IR && Object.keys(FilterObj?.IR)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, IR: { ...FilterObj.IR, msg: e.target.value } }) }} value={FilterObj?.IR?.msg || ''} />
								</div>)}

							</div>
						</div>

						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="IY" name="IY" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.IY ? CheckboxVal?.IY : false} />
									<label className="custom-control-label" for="IY">IY</label>
								</div>

								{CheckboxVal.IY && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, IY: { [e.target.value]: true } })} value={Object.keys(FilterObj?.IY)[0]}>
										<option value=''>Select Condition...</option>

										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}
								{FilterObj?.IY?.Between && CheckboxVal.IY && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, IY: { ...FilterObj.IY, From: e.target.value } }) }} value={FilterObj?.IY?.From} />
								</div>)}
								{FilterObj?.IY?.Between && CheckboxVal.IY && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, IY: { ...FilterObj.IY, To: e.target.value } }) }} value={FilterObj?.IY?.To} />
								</div>)}
								{FilterObj?.IY?.lessThan && CheckboxVal.IY && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, IY: { ...FilterObj.IY, value: e.target.value } }) }} value={FilterObj?.IY?.value} />
								</div>)}
								{FilterObj?.IY?.greaterThan && CheckboxVal.IY && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, IY: { ...FilterObj.IY, value: e.target.value } }) }} value={FilterObj?.IY?.value} />
								</div>)}
								{CheckboxVal.IY && Object.keys(FilterObj?.IY)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, IY: { ...FilterObj.IY, msg: e.target.value } }) }} value={FilterObj?.IY?.msg || ''} />
								</div>)}

							</div>
						</div>
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="IB" name="IB" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.IB ? CheckboxVal?.IB : false} />
									<label className="custom-control-label" for="IB">IB</label>
								</div>

								{CheckboxVal.IB && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, IB: { [e.target.value]: true } })} value={Object.keys(FilterObj?.IB)[0]}>
										<option value=''>Select Condition...</option>

										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}
								{FilterObj?.IB?.Between && CheckboxVal.IB && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, IB: { ...FilterObj.IB, From: e.target.value } }) }} value={FilterObj?.IB?.From} />
								</div>)}
								{FilterObj?.IB?.Between && CheckboxVal.IB && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, IB: { ...FilterObj.IB, To: e.target.value } }) }} value={FilterObj?.IB?.To} />
								</div>)}
								{FilterObj?.IB?.lessThan && CheckboxVal.IB && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, IB: { ...FilterObj.IB, value: e.target.value } }) }} value={FilterObj?.IB?.value} />
								</div>)}
								{FilterObj?.IB?.greaterThan && CheckboxVal.IB && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, IB: { ...FilterObj.IB, value: e.target.value } }) }} value={FilterObj?.IB?.value} />
								</div>)}
								{CheckboxVal.IB && Object.keys(FilterObj?.IB)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, IB: { ...FilterObj.IB, msg: e.target.value } }) }} value={FilterObj?.IB?.msg || ''} />
								</div>)}

							</div>
						</div>

						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="VRN" name="VRN" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.VRN ? CheckboxVal?.VRN : false} />
									<label className="custom-control-label" for="VRN">VRN</label>
								</div>

								{CheckboxVal.VRN && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, VRN: { [e.target.value]: true } })} value={Object.keys(FilterObj?.VRN)[0]}>
										<option value=''>Select Condition...</option>

										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}
								{FilterObj?.VRN?.Between && CheckboxVal.VRN && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, VRN: { ...FilterObj.VRN, From: e.target.value } }) }} value={FilterObj?.VRN?.From} />
								</div>)}
								{FilterObj?.VRN?.Between && CheckboxVal.VRN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, VRN: { ...FilterObj.VRN, To: e.target.value } }) }} value={FilterObj?.VRN?.To} />
								</div>)}
								{FilterObj?.VRN?.lessThan && CheckboxVal.VRN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, VRN: { ...FilterObj.VRN, value: e.target.value } }) }} value={FilterObj?.VRN?.value} />
								</div>)}
								{FilterObj?.VRN?.greaterThan && CheckboxVal.VRN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, VRN: { ...FilterObj.VRN, value: e.target.value } }) }} value={FilterObj?.VRN?.value} />
								</div>)}
								{CheckboxVal.VRN && Object.keys(FilterObj?.VRN)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, VRN: { ...FilterObj.VRN, msg: e.target.value } }) }} value={FilterObj?.VRN?.msg || ''} />
								</div>)}

							</div>
						</div>

						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="VYN" name="VYN" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.VYN ? CheckboxVal?.VYN : false} />
									<label className="custom-control-label" for="VYN">VYN</label>
								</div>

								{CheckboxVal.VYN && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, VYN: { [e.target.value]: true } })} value={Object.keys(FilterObj?.VYN)[0]}>
										<option value=''>Select Condition...</option>

										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}
								{FilterObj?.VYN?.Between && CheckboxVal.VYN && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, VYN: { ...FilterObj.VYN, From: e.target.value } }) }} value={FilterObj?.VYN?.From} />
								</div>)}
								{FilterObj?.VYN?.Between && CheckboxVal.VYN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, VYN: { ...FilterObj.VYN, To: e.target.value } }) }} value={FilterObj?.VYN?.To} />
								</div>)}
								{FilterObj?.VYN?.lessThan && CheckboxVal.VYN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, VYN: { ...FilterObj.VYN, value: e.target.value } }) }} value={FilterObj?.VYN?.value} />
								</div>)}
								{FilterObj?.VYN?.greaterThan && CheckboxVal.VYN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, VYN: { ...FilterObj.VYN, value: e.target.value } }) }} value={FilterObj?.VYN?.value} />
								</div>)}
								{CheckboxVal.VYN && Object.keys(FilterObj?.VYN)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, VYN: { ...FilterObj.VYN, msg: e.target.value } }) }} value={FilterObj?.VYN?.msg || ''} />
								</div>)}

							</div>
						</div>

						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="VBN" name="VBN" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.VBN ? CheckboxVal?.VBN : false} />
									<label className="custom-control-label" for="VBN">VBN</label>
								</div>

								{CheckboxVal.VBN && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, VBN: { [e.target.value]: true } })} value={Object.keys(FilterObj?.VBN)[0]}>
										<option value=''>Select Condition...</option>

										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}
								{FilterObj?.VBN?.Between && CheckboxVal.VBN && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, VBN: { ...FilterObj.VBN, From: e.target.value } }) }} value={FilterObj?.VBN?.From} />
								</div>)}
								{FilterObj?.VBN?.Between && CheckboxVal.VBN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, VBN: { ...FilterObj.VBN, To: e.target.value } }) }} value={FilterObj?.VBN?.To} />
								</div>)}
								{FilterObj?.VBN?.lessThan && CheckboxVal.VBN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, VBN: { ...FilterObj.VBN, value: e.target.value } }) }} value={FilterObj?.VBN?.value} />
								</div>)}
								{FilterObj?.VBN?.greaterThan && CheckboxVal.VBN && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, VBN: { ...FilterObj.VBN, value: e.target.value } }) }} value={FilterObj?.VBN?.value} />
								</div>)}
								{CheckboxVal.VBN && Object.keys(FilterObj?.VBN)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, VBN: { ...FilterObj.VBN, msg: e.target.value } }) }} value={FilterObj?.VBN?.msg || ''} />
								</div>)}

							</div>
						</div>

						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="KW" name="KW" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.KW ? CheckboxVal?.KW : false} />
									<label className="custom-control-label" for="KW">KW</label>
								</div>

								{CheckboxVal.KW && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, KW: { [e.target.value]: true } })} value={Object.keys(FilterObj?.KW)[0]}>
										<option value=''>Select Condition...</option>

										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}
								{FilterObj?.KW?.Between && CheckboxVal.KW && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, KW: { ...FilterObj.KW, From: e.target.value } }) }} value={FilterObj?.KW?.From} />
								</div>)}
								{FilterObj?.KW?.Between && CheckboxVal.KW && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, KW: { ...FilterObj.KW, To: e.target.value } }) }} value={FilterObj?.KW?.To} />
								</div>)}
								{FilterObj?.KW?.lessThan && CheckboxVal.KW && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, KW: { ...FilterObj.KW, value: e.target.value } }) }} value={FilterObj?.KW?.value} />
								</div>)}
								{FilterObj?.KW?.greaterThan && CheckboxVal.KW && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, KW: { ...FilterObj.KW, value: e.target.value } }) }} value={FilterObj?.KW?.value} />
								</div>)}
								{CheckboxVal.KW && Object.keys(FilterObj?.KW)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, KW: { ...FilterObj.KW, msg: e.target.value } }) }} value={FilterObj?.KW?.msg || ''} />
								</div>)}

							</div>
						</div>
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="KVA" name="KVA" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.KVA ? CheckboxVal?.KVA : false} />
									<label className="custom-control-label" for="KVA">KVA</label>
								</div>

								{CheckboxVal.KVA && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, KVA: { [e.target.value]: true } })} value={Object.keys(FilterObj?.KVA)[0]}>
										<option value=''>Select Condition...</option>

										{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
									</select>
								</div>)}
								{FilterObj?.KVA?.Between && CheckboxVal.KVA && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, VBN: { ...FilterObj.VBN, From: e.target.value } }) }} value={FilterObj?.KVA?.From} />
								</div>)}
								{FilterObj?.KVA?.Between && CheckboxVal.KVA && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, KVA: { ...FilterObj.KVA, To: e.target.value } }) }} value={FilterObj?.KVA?.To} />
								</div>)}
								{FilterObj?.KVA?.lessThan && CheckboxVal.KVA && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, KVA: { ...FilterObj.KVA, value: e.target.value } }) }} value={FilterObj?.KVA?.value} />
								</div>)}
								{FilterObj?.KVA?.greaterThan && CheckboxVal.KVA && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, KVA: { ...FilterObj.KVA, value: e.target.value } }) }} value={FilterObj?.KVA?.value} />
								</div>)}
								{CheckboxVal.KVA && Object.keys(FilterObj?.KVA)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, KVA: { ...FilterObj.KVA, msg: e.target.value } }) }} value={FilterObj?.KVA?.msg || ''} />
								</div>)}
								<div className="col-12">
									<div className="form-row">
										<div className="custom-control custom-checkbox">
											<input type="checkbox" id="PF" name="PF" className="custom-control-input" value="rssival" onChange={handleChangeS} checked={CheckboxVal?.PF ? CheckboxVal?.PF : false} />
											<label className="custom-control-label" for="PF">PF</label>
										</div>

										{CheckboxVal.PF && (<div className="form-group col-md-2 checktextRSSI length rssival" style={{ marginTop: -10 }}>
											<select class="form-select form-control" onChange={(e) => setFilterObj({ ...FilterObj, PF: { [e.target.value]: true } })} value={Object.keys(FilterObj?.PF)[0]}>
												<option value=''>Select Condition...</option>

												{dataWise && dataWise.map((item) => (<option value={item.value}>{item.label}</option>))}
											</select>
										</div>)}
										{FilterObj?.PF?.Between && CheckboxVal.PF && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
											<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...FilterObj, PF: { ...FilterObj.PF, From: e.target.value } }) }} value={FilterObj?.PF?.From} />
										</div>)}
										{FilterObj?.PF?.Between && CheckboxVal.PF && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
											<input type="text" className="form-control selected" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...FilterObj, PF: { ...FilterObj.PF, To: e.target.value } }) }} value={FilterObj?.PF?.To} />
										</div>)}
										{FilterObj?.PF?.lessThan && CheckboxVal.PF && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
											<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...FilterObj, PF: { ...FilterObj.PF, value: e.target.value } }) }} value={FilterObj?.PF?.value} />
										</div>)}
										{FilterObj?.PF?.greaterThan && CheckboxVal.PF && (<div className="form-group col-md-2 checktext13" style={{ marginTop: -10 }}>
											<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...FilterObj, PF: { ...FilterObj.PF, value: e.target.value } }) }} value={FilterObj?.PF?.value} />
										</div>)}

										{CheckboxVal.PF && Object.keys(FilterObj?.PF)[0] && (<div className="form-group col-md-2 checktext14" style={{ marginTop: -10 }}>
											<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="Enter Alert Massage" onChange={(e) => { setFilterObj({ ...FilterObj, PF: { ...FilterObj.PF, msg: e.target.value } }) }} value={FilterObj?.PF?.msg || ''} />
										</div>)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>

				<fieldset className="customLegend ">
					<legend >User Info</legend>
					<div className="row" style={{ marginLeft: 10 }}>
						<div className="col-12">
							<div className="form-row">
								<div class="form-group col-md-4">
									<label for="inputState">Enter Email:</label>
									<input type="text" class="form-control" id="" name="FirstName" placeholder="abc@gmail.com" onChange={(e) => { setFilterObj({ ...FilterObj, email: e.target.value }) }} value={FilterObj?.email} />
								</div>
							</div>
						</div>
					</div>
				</fieldset>

				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onupadateCardInfoAlert} />
					<div class="btn btn-light text-dark ml-2" onClick={() => onCalcelAlert()}>
						Close
					</div>
				</div>
			</Modal>
			<div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<span> configuration Dashboard</span>
							</Link>
						</li>
					</ol>
				</nav>
				<div className="row">
					<div className="col-12 col-sm-12 col-lg-12">
						<div className="card ">
							<div className="card-header d-flex justify-content-between">
								<div className="d-flex justify-content-between" style={{ width: "100%" }}>
									<ul
										class="nav nav-pills"
										id="myTab3"
										role="tablist"
										style={{ width: "1500px", overflowX: "scroll", flexWrap: "nowrap" }}
									>
										{TabList &&
											TabList.map((item, id) =>
												roles[item.TAB_NAME]?.view ||
													permission === "ROLE1680234321" ||
													item?.user_id === user_info.userID ||
													roles[item.TAB_NAME]?.edit ||
													roles[item.TAB_NAME]?.delete ? (
													<li class="nav-item mr-2 ">
														<a
															class={`nav-link d-flex justify-content-between ${TabList.findIndex((data) => data.TABID === deshboardId) === id
																	? "btn-lg active"
																	: "lead"
																}`}
															id="home-tab3"
															data-toggle="tab"
															href="#home3"
															role="tab"
															aria-controls="home"
															onClick={() => setdeshboardId(item.TABID)}
															style={{ paddingTop: "3px", paddingBottom: "3px", minWidth: "210px" }}
														>
															{item.TAB_NAME}

															<div>
																{/* <div class="dropdown d-inline"> */}
																<button
																	class="btn dropdown"
																	type="button"
																	data-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																>
																	<BootstrapTooltip title="Setting" placement="top">
																		<i className="fa fa-ellipsis-v"></i>
																	</BootstrapTooltip>
																</button>
																<div
																	class="dropdown-menu tab-setting"
																	x-placement="bottom-start"
																	style={{
																		position: "absolute",
																		transform: "translate3d(0px, 28px, 0px)",
																		willChange: "transform",
																	}}
																>

																	<a class="dropdown-item has-icon" onClick={() => onEditTab(item.TABID)}>
																		<i class="fas fa-cog mr-2"></i> Settings
																	</a>

																	{/* {permission === "ROLE1680234321" || roles[item.TAB_NAME]?.delete ? (
																		<a class="dropdown-item has-icon" onClick={() => onDelete(item.TABID)}>
																			<i class="fas fa-trash mr-2"></i>Delete
																		</a>
																	) : (
																		""
																	)} */}
																</div>
															</div>

															{/* {permission === "ROLE1680234321" || permission?.deshboard_tab?.edit ? (
																<div
																	class={`btn btn-info text-white text-end btnedit ${
																		isModalVisible && item.TABID === TabID
																			? `btn-info btn-progress disabled`
																			: `btn-info `
																	} ml-2`}
																	onClick={() => onEditTab(item.TABID)}
																	style={{ height: "30px", padding: "2px 5px" }}
																>
																	<BootstrapTooltip title="Edit Tab" placement="top">
																		<span
																			data-toggle="tooltip"
																			data-placement="bottom"
																			title=""
																			data-original-title="Edit"
																		>
																			<i class="fas fa-edit  text-white"></i>
																		</span>
																	</BootstrapTooltip>
																</div>
															) : (
																""
															)}
															{permission === "ROLE1680234321" || permission?.deshboard_tab?.delete ? (
																<div
																	class="btn btn-danger text-white text-end btnedit ml-2"
																	style={{ height: "30px", padding: "2px 5px" }}
																	onClick={() => onDelete(item.TABID)}
																>
																	<BootstrapTooltip title="Delete Tab" placement="top">
																		<span
																			data-toggle="tooltip"
																			data-placement="bottom"
																			title=""
																			data-original-title="Delete"
																		>
																			<i class="fas fa-trash  text-white"></i>
																		</span>
																	</BootstrapTooltip>
																</div>
															) : (
																""
															)} */}
															{/* </div> */}
														</a>
													</li>
												) : (
													""
												)
											)}
									</ul>

									{permission === "ROLE1680234321" || roles?.create ? (
										<div className="card-header-action d-flex">
											<BootstrapTooltip title="Add Tab" placement="top">
												<span
													className={`btn ${!isModalVisible ? `btn-primary` : `btn-primary btn-progress disabled`} ml-2 `}
													style={{ height: "30px", padding: "2px 5px", marginTop: "4px" }}
													onClick={showModal}
												>
													<i
														className="fas fa-plus "
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
											<BootstrapTooltip title="Refresh" placement="top">
												<span
													className={`btn btn-danger ${refresh ? "btn-progress disabled" : ""} ml-2 `}
													style={{ height: "30px", padding: "2px 5px", marginTop: "4px" }}
													onClick={() => onRefreshData()}
												>
													<i
														className="fas fa-redo"
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
										</div>
									) : (
										""
									)}
								</div>
								{/* <div className="card-header-action">
									<Link
										className="btn btn-info text-white mr-2"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<BootstrapTooltip title="Add">
											<span>
												<i
													className="fas fa-plus text-white"
													style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
												></i>
											</span>
										</BootstrapTooltip>
									</Link>
								</div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-sm-12 col-lg-12">
						<div className="row">
							{loader && (
								<div className="example">
									<Spin size="large" />
								</div>
							)}
							{platofcard.length > 0
								? !loader &&
								platofcard.sort((a, b) => a.CARD_INDEX - b.CARD_INDEX).map((item, ids) =>
									item.consumer_id === user_info.userID || permission === "ROLE1680234321" || CardPermition?.card_permission?.filter((items) => (items == item.ID)).length > 0 ? (
										<div id="keyBoardcol" onDragOver={allowDrop} class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mt-2" key={ids} onDrop={e => onDrop(e, ids)}>
											<div className="card " draggable onDragStart={e => onDragStart(e, ids)}>
												<BootstrapTooltip title={item?.payload?.ENTRYTS} placement="top">
													<div className="card-header flex-column" style={{ alignItems: 'normal', paddingBottom: '0px', backgroundColor: item?.card_color || '#fffff', borderRadius: '13px 13px 0px 0px' }}>
														<div className="d-flex justify-content-between w-100" style={{ cursor: 'move' }} >
															<h4 style={{ cursor: 'pointer', alignItems: 'center' }} className="d-flex" onClick={() => movesubPages(item.ID)}><HolderOutlined style={{ cursor: 'move' }} className="mr-2" />{item.CARD_NAME} {item.card_alert_info && Object.keys(JSON.parse(item.card_alert_info)).length > 0 && JSON.parse(item?.card_alert_info || '{}')?.email && <AccessAlarmIcon />}</h4>
															<div className="card-header-action card-drop" >
																<div class="dropdown d-inline">
																	<button
																		class="btn btn-primary dropdown"
																		type="button"
																		id="dropdownMenuButton2"
																		data-toggle="dropdown"
																		aria-haspopup="true"
																		aria-expanded="false"
																	>
																		<div>
																			<i className="fa fa-ellipsis-v"></i>
																		</div>
																	</button>
																	<div
																		class="dropdown-menu"
																		x-placement="bottom-start"
																		style={{
																			position: "absolute",
																			transform: "translate3d(0px, 28px, 0px)",
																			top: "0px",
																			left: "0px",
																			willChange: "transform",
																		}}
																	>
																		{<a class="dropdown-item has-icon" onClick={() => editPlatData(item)}>
																			<i class="fas fa-cog mr-2"></i> Settings
																		</a>}
																		{/* {permission==='ROLE1680234321'||CardPermition.card_permission_delete.length>0&&CardPermition.card_permission_delete.filter((data)=>(data==item?.ID)).length>0?<a
																				class="dropdown-item has-icon"
																				onClick={() => onDeletePlatData(item.ID)}
																			>
																				<i class="fas fa-trash mr-2"></i>Delete
																			</a>:''} */}
																		<a class="dropdown-item has-icon" onClick={() => onHandalTableview(item)}>
																			<i class="fas fa-external-link-alt mr-2"></i>Table View
																		</a>
																		<Link to={`/dashboard-graph-view/${item.ID}`} class="dropdown-item has-icon" >
																			<i class="fas fa-line-chart mr-2"></i>Graph View
																		</Link>

																		<a class="dropdown-item has-icon" onClick={() => onReportGenereteModel(item.ID, item)}>
																			<i class="fas fa-clipboard-list mr-2" ></i>Generate Report
																		</a>
																		<a class="dropdown-item has-icon" onClick={() => onCardAlertModel(item.ID)}>
																			<i class="fas fa-exclamation-circle mr-2" ></i>Set Alert
																		</a>
																	</div>
																</div>


																{/* {permission==='ROLE1680234321'||permission?.deshboard_card?.edit?<div
																class={`btn ${editBtn&&item?.payload?.ID===editId?`btn-info btn-progress disabled`:`btn-info`} text-white btnedit`}
																data-toggle="modal"
																data-target="#exampleModal"
																onClick={() => editPlatData(item)}
															>
																<BootstrapTooltip title="Edit" placement="top">
																	<span
																		data-toggle="tooltip"
																		data-placement="bottom"
																		title=""
																		data-original-title="Edit"
																	>
																		<i class="fas fa-edit  text-white"></i>
																	</span>
																</BootstrapTooltip>
															</div>:''}
															{permission==='ROLE1680234321'||permission?.deshboard_card?.delete?<div
																class="btn btn-danger text-white btnedit ml-2"
																onClick={() => onDeletePlatData(item.payload.ID)}
															>
																<BootstrapTooltip title="Delete" placement="top">
																	<span
																		data-toggle="tooltip"
																		data-placement="bottom"
																		title=""
																		data-original-title="Delete"
																	>
																		<i class="fas fa-trash  text-white"></i>
																	</span>
																</BootstrapTooltip>
															</div>:''} */}
															</div>
															<div className="dropdown ">
																<BootstrapTooltip title="Refresh" placement="top">
																	<button
																		className={`btn btn-danger ${RefreshId == item.ID && RefreshIndi === true ? "btn-progress disabled" : ""} ml-2 `}
																		type="button"
																		style={{ paddingLeft: '8px', paddingRight: '10px' }}
																		onClick={() => onRefreshDataInGSM(item.ID)}
																	>
																		<i className="fas fa-redo"></i>
																	</button>
																</BootstrapTooltip>
															</div>
														</div>
														{/* {console.log(item.card_description)} */}
														{item?.card_description !== '' ? (item?.card_description !== null ? <p style={{ margin: '0' }}>{item?.card_description}</p> : <br />) : <br />}
													</div>
												</BootstrapTooltip>
												<div class="card-body" style={{ opacity: item?.payload?.data_status === 0 ? '0.5' : '1' }}>
													<div className="row">
														{item?.CARD_VIEW == 1 ?
															<><div className="d-flex" style={{ justifyContent: 'end', alignItems: 'center' }}>
																<Switch size="small" onChange={(e) => {
																	if (e) {
																		setperkwhChartID([...perkwhChartID, item?.ID])
																	} else {
																		setperkwhChartID(perkwhChartID.filter((line) => (line != item?.ID)))
																	}
																}} checked={perkwhChartID.filter((line) => (line === item?.ID)).length > 0} />
																<span>Losses % Chart</span>
																<Switch size="small" onChange={(e) => {
																	if (e) {
																		setlineChartID([...lineChartID, item?.ID])
																	} else {
																		setlineChartID(lineChartID.filter((line) => (line != item?.ID)))
																	}
																}} checked={lineChartID.filter((line) => (line === item?.ID)).length > 0} />
																<span>Line Chart</span>
															</div>
																{lineChartID.filter((line) => (line === item?.ID)).length > 0 && <Chart options={DailyChartData[item?.ID] || options3} series={perkwhChartID.filter((line) => (line === item?.ID)).length > 0 ? DailyPerChartData[item?.ID] || [{ name: 'KWH', data: [] }] : DailyCardData[item?.ID] || [{ name: 'KWH', data: [] }]} type="line" height={250} />}

																{lineChartID.filter((line) => (line == item?.ID)).length == 0 && <Chart options={DailyChartData[item?.ID] || options3} series={perkwhChartID.filter((line) => (line === item?.ID)).length > 0 ? DailyPerChartData[item?.ID] || [{ name: 'KWH', data: [] }] : DailyCardData[item?.ID] || [{ name: 'KWH', data: [] }]} type="bar" height={250} />}</> : <>
																<div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
																	<b className="text-danger">VRN :{item?.payload?.data_status === 1 ? (item?.payload?.VRN !== null ? item?.payload?.VRN?.toFixed(2) : 'error') : 'Pending'} V</b>
																	<br />
																	<b className="text-warning">VYN : {item?.payload?.data_status === 1 ? (item?.payload?.VYN !== null ? item?.payload?.VYN?.toFixed(2) : 'error') : 'Pending'} V</b> <br />
																	<b style={{ color: "blue" }}>VBN : {item?.payload?.data_status === 1 ? (item?.payload?.VBN !== null ? item?.payload?.VBN?.toFixed(2) : 'error') : 'Pending'} V</b>
																	<div className="badge badge-primary badge-shadow text-white mt-2 ">
																		<span style={{ fontSize: "14px" }}>{item?.payload?.data_status === 1 ? (item?.payload?.POW !== null ? item?.payload?.POW?.toFixed(2) : 'error') : 'Pending'} KW </span>
																	</div>
																	<div className="badge badge-primary badge-shadow text-white mt-2 ">
																		<span style={{ fontSize: "14px" }}>PF : {item?.payload?.data_status === 1 ? (item?.payload?.PF !== null ? item?.payload?.PF?.toFixed(2) : 'error') : 'Pending'} </span>
																	</div>
																	<div className="badge badge-primary badge-shadow text-white mt-2 ">
																		<span style={{ fontSize: "14px" }}>{item?.payload?.data_status === 1 ? (item?.payload?.FRQ !== null ? item?.payload?.FRQ?.toFixed(2) : 'error') : 'Pending'} Hz</span>
																	</div>

																</div>
																<div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
																	<b className="text-danger">IR :{item?.payload?.data_status === 1 ? (item?.payload?.IR !== null ? item?.payload?.IR?.toFixed(2) : 'error') : 'Pending'} A</b>
																	<br />
																	<b className="text-warning">IY : {item?.payload?.data_status === 1 ? (item?.payload?.IY !== null ? item?.payload?.IY?.toFixed(2) : 'error') : 'Pending'} A</b>
																	<br />
																	<b style={{ color: "blue" }}>IB : {item?.payload?.data_status === 1 ? (item?.payload?.IB !== null ? item?.payload?.IB?.toFixed(2) : 'error') : 'Pending'} A</b>
																	<br />
																	<div className="badge badge-primary badge-shadow text-white mt-2">
																		<span style={{ fontSize: "14px" }}>{item?.payload?.data_status === 1 ? (item?.payload?.KVA !== null ? item?.payload?.KVA?.toFixed(2) : 'error') : 'Pending'} KVA </span>
																	</div>
																	<div className="badge badge-primary badge-shadow text-white mt-2 ">
																		<span style={{ fontSize: "14px" }}>{item?.payload?.data_status === 1 ? (item?.payload?.KWH !== null ? item?.payload?.KWH?.toFixed(2) : 'error') : 'Pending'} KWH</span>
																	</div>
																	<br />
																	{item?.MULTIFICATION_FECTOR && <div className="badge badge-primary badge-shadow text-white mt-2 ">
																		<span style={{ fontSize: "14px" }}>MFKWH:{item?.payload?.data_status === 1 ? (item?.payload?.KWH !== null ? item?.MULTIFICATION_FECTOR ? (item?.payload?.KWH * item?.MULTIFICATION_FECTOR)?.toFixed(2) : item?.payload?.KWH?.toFixed(2) : 'error') : 'Pending'}</span>
																	</div>}
																</div>
															</>
														}
														<div
															class="card-footer mt-2"
															style={{ display: "flex", justifyContent: "space-between", padding: "0px" }}
														>
															<span className="text-start">
																{currentDate.diff(
																	moment.utc(item?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"),
																	"minutes"
																) === 0
																	? moment
																		.utc(item?.payload?.ENTRYTS || null)
																		.add(-moment().utcOffset(), "m")
																		.fromNow(true)
																	: currentDate.diff(
																		moment
																			.utc(item?.payload?.ENTRYTS || null)
																			.add(-moment().utcOffset(), "m"),
																		"minutes"
																	) + ` minutes `}
																ago
															</span>
															<span className="">
																<span>Sub Card: {item?.Roles_subcard_count}/{item?.subcard_count}</span>
															</span>
															<span className="text-end">
																{item?.CARD_TYPE === 'Differentiation Of Slaves' ? `Slaves: ${JSON.parse(item?.payload?.slaves_details || '[]')?.length}/
																	${JSON.parse(item?.SLAVES_DETAILS || '[]')?.length + JSON.parse(item?.DIFFERENTIATION_SLAVES_DETAILS || '[]')?.length}` : `Slaves: ${JSON.parse(item?.payload?.slaves_details || '[]')?.length}/
																	${JSON.parse(item?.SLAVES_DETAILS || '[]')?.length}`}
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									) : (
										""
									)
								)
								: ""}
							{/* {permission==='ROLE1680234321'||permission?.deshboard_card?.add?(!loader && deshboardId && (
								<div id="keyBoard" class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-2">
									<div className="card ">
										<div className="card-header"></div>
										<div className="card-body" style={{height:'209px',textAlign:'center'}}>
										<div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'54px'}}>
											<div className={`btn text-white ${!model&&editId===null?`btn-info`:`btn-info btn-progress disabled`} mr-2`} onClick={() => setmodel(true)}>
												<BootstrapTooltip title="Add Card">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</div>
											</div>
										</div>
									</div>
								</div>
							)):''} */}
						</div>
					</div>
				</div>
			</div>

			{/* modale open */}

			<Modal title={<div>{editId === null ? `Add Card` : "Update Card"}{(permission === "ROLE1680234321" || permission === 'ROLE1680234321' || ConsumerId == user_info?.userID || CardPermition?.card_permission_edit?.length > 0 && CardPermition?.card_permission_edit?.filter((data) => (data == editId))?.length > 0) && editId ? <div class="btn btn-info text-white btnedit ml-2" onClick={() => (setfildCardDisable(true))} style={{ padding: '1px 8px 2px 8px' }}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : ""}{(permission === "ROLE1680234321" || ConsumerId == user_info?.userID || CardPermition?.card_permission_delete?.length > 0 && CardPermition?.card_permission_delete?.filter((data) => (data == editId))?.length > 0) && editId ? <div class="btn btn-danger text-white btnedit ml-2" style={{ padding: '1px 8px 2px 8px' }} onClick={() => onDeletePlatData(editId)}><BootstrapTooltip title='Delete'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-trash"></i></span></BootstrapTooltip></div> : ""}</div>} visible={model} footer="" onCancel={() => onCancelModel()}>
				<form class="">
					<div class="form-group">
						<label>Card Name</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">
									<i class="fas fa-envelope"></i>
								</div>
							</div>
							<input
								type="text"
								class="form-control"
								placeholder="Card Name"
								name="cardName"
								onChange={onHadaleInput}
								value={ModemID?.cardName}
								disabled={editId ? !fildCardDisable : false}
							/>
						</div>
						<p className="text-danger">{Errors?.cardName || ""}</p>
					</div>
					<div class="form-group">
						<label>Card Description</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">
									<i class="fas fa-comment"></i>
								</div>
							</div>
							<input
								type="text"
								class="form-control"
								placeholder="Card Description"
								name="card_description"
								onChange={onHadaleInput}
								value={ModemID?.card_description || ''}
								disabled={editId ? !fildCardDisable : false}

							/>
						</div>
					</div>
					<div class="form-group">
						<label>Card View</label>
						<div class="input-group">
							<select
								className="form-select form-control"
								aria-label="Default select example"
								name="CARD_VIEW"
								onChange={onHadaleInput}
								value={ModemID?.CARD_VIEW}
								disabled={editId ? !fildCardDisable : false}
							>
								<option selected value="0">
									List View
								</option>
								<option selected value="1">
									Graph View
								</option>
							</select>

						</div>
					</div>
					<div class="form-group">
						<label>Card Color</label>
						<div class="input-group colorpickerinput colorpicker-element" data-colorpicker-id="2">
							<input type="text" class="form-control" value={ModemID.card_color} disabled={editId ? !fildCardDisable : false} onChange={(e) => setModemID({ ...ModemID, card_color: e.target.value })} />
							<div class="input-group-append">
								<div class="input-group-text">
									<input type="color" id='color-picker' onChange={(e) => setModemID({ ...ModemID, card_color: e.target.value })} disabled={editId ? !fildCardDisable : false} value={ModemID?.card_color || "#ffffff"}></input>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label>Modem List</label>
						<select
							className="form-select form-control"
							aria-label="Default select example"
							onChange={onHandaleModelList}
							value={ModemID?.MODEM_UNIQUE_ID}
							disabled={editId ? !fildCardDisable : false}
						>
							<option selected value="">
								Open Modem select menu
							</option>
							{modem.length > 0 &&
								modem.map((item) =>
									Modemroles[item.IMEI_U]?.view === true || permission === "ROLE1680234321" ? (
										<option value={item.UNIQUE_ID_S}>{item.CONSUMER_MODEM_NAME_U}</option>
									) : (
										""
									)
								)}
						</select>
						<p className="text-danger">{Errors?.MODEM_UNIQUE_ID || ""}</p>
					</div>
					{ModemID.MODEM_UNIQUE_ID !== "" && (<>
						<div class="form-group text-left">
							<input type="radio"
								name="login_type"
								id="byHours"
								onChange={() => setslavesType("Addition Of Slaves")}
								style={{ height: "16px", width: "20px", verticalAlign: "middle" }}
								checked={slavesType === "Addition Of Slaves" ? true : false} /><label for="byHours" className="mr-2" style={{ marginLeft: "5px" }}>Addition Of Slaves</label>
							<input type="radio"
								name="login_type"
								id="byIntervals"
								onChange={() => setslavesType("Differentiation Of Slaves")}
								style={{ height: "16px", width: "20px", verticalAlign: "middle" }}
								checked={slavesType === "Differentiation Of Slaves" ? true : false} /><label for="byIntervals" style={{ marginLeft: "5px" }}>Differentiation Of Slaves</label>
						</div>
						{slavesType === "Addition Of Slaves" ? <div class="form-group">
							<FormControl sx={{ width: 450 }} disabled={editId ? !fildCardDisable : false}>
								<InputLabel id="demo-multiple-checkbox-label">Select Slaves</InputLabel>
								<Select
									labelId="demo-multiple-checkbox-label"
									id="demo-multiple-checkbox"
									multiple
									value={personName}
									onChange={handleChange}
									input={<OutlinedInput label="Select Slaves" />}
									renderValue={(selected) => (
										<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
											{selected.map((value) => (
												<Chip key={value} label={value} />
											))}
										</Box>
									)}
									MenuProps={MenuProps}
								>
									{SlaveList.length > 0 &&
										SlaveList?.map((name) => (
											<MenuItem key={name.CONSDEVNAME_U} value={name.CONSDEVNAME_U}>
												<Checkbox checked={personName.indexOf(name.CONSDEVNAME_U) > -1} />
												<ListItemText primary={name.CONSDEVNAME_U} />
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</div> : <>
							<div class="form-group">
								<FormControl sx={{ width: 450 }} disabled={editId ? !fildCardDisable : false}>
									<InputLabel id="demo-multiple-checkbox-label">Select Slaves</InputLabel>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										value={slaves1}
										onChange={handleSlaves1Change}
										input={<OutlinedInput label="Select Slaves" />}
										renderValue={(selected) => (
											<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
												{selected.map((value) => (
													<Chip key={value} label={value} />
												))}
											</Box>
										)}
										MenuProps={MenuProps}
									>
										{SlaveList.length > 0 &&
											SlaveList?.map((name) => (
												<MenuItem key={name.CONSDEVNAME_U} value={name.CONSDEVNAME_U}>
													<Checkbox checked={slaves1.indexOf(name.CONSDEVNAME_U) > -1} />
													<ListItemText primary={name.CONSDEVNAME_U} />
												</MenuItem>
											))}
									</Select>
								</FormControl>
								<p className="text-danger">{Errors?.slavesVal1 || ""}</p>

							</div>
							<div class="form-group">
								<FormControl sx={{ width: 450 }} disabled={editId ? !fildCardDisable : false}>
									<InputLabel id="demo-multiple-checkbox-label">Select Slaves</InputLabel>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										value={slaves2}
										onChange={handleSlaves2Change}
										input={<OutlinedInput label="Select Slaves" />}
										renderValue={(selected) => (
											<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
												{selected.map((value) => (
													<Chip key={value} label={value} />
												))}
											</Box>
										)}
										MenuProps={MenuProps}
									>
										{SlaveList.length > 0 &&
											SlaveList?.map((name) => (
												<MenuItem key={name.CONSDEVNAME_U} value={name.CONSDEVNAME_U}>
													<Checkbox checked={slaves2.indexOf(name.CONSDEVNAME_U) > -1} />
													<ListItemText primary={name.CONSDEVNAME_U} />
												</MenuItem>
											))}
									</Select>
								</FormControl>
								<p className="text-danger">{Errors?.slavesVal2 || ""}</p>

							</div>
						</>}
					</>)}

					{editId && fildCardDisable === true ? <button
						type="button"
						class={`btn btn-primary ${addFlag ? "btn-info btn-progress disabled" : ""} m-t-15 waves-effect`}
						onClick={AddCard}
					>
						{editId === null ? `Add New Card` : `Update Card`}
					</button> : ''}
					{!editId && fildCardDisable === false ? <button
						type="button"
						class={`btn btn-primary ${addFlag ? "btn-info btn-progress disabled" : ""} m-t-15 waves-effect`}
						onClick={AddCard}
					>
						{editId === null ? `Add New Card` : `Update Card`}
					</button> : ''}
				</form>
			</Modal>
			{/* <div
				class="modal fade"
				id="exampleModal"
				tabindex="-1"
				style={{ display: `${model ? "block" : "none"}`, paddingRight: "6px" }}
				role="dialog"
				aria-labelledby="formModal"
				aria-hidden="true"
			>
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="formModal">
								Add Card
							</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setmodel(false)}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form class="">
								<div class="form-group">
									<label>Card Name</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text">
												<i class="fas fa-envelope"></i>
											</div>
										</div>
										<input
											type="text"
											class="form-control"
											placeholder="Card Name"
											name="cardName"
											onChange={onHadaleInput}
											value={ModemID?.cardName}
										/>
									</div>
									<p className="text-danger">{Errors?.cardName || ""}</p>
								</div>
								<div class="form-group">
									<label>Modem List</label>
									<select
										className="form-select form-control"
										aria-label="Default select example"
										onChange={onHandaleModelList}
										value={ModemID?.MODEM_UNIQUE_ID}
									>
										<option selected value="">
											Open Modem select menu
										</option>
										{modem.length > 0 &&
											modem.map((item) => <option value={item.UNIQUE_ID_S}>{item.CONSUMER_MODEM_NAME_U}</option>)}
									</select>
									<p className="text-danger">{Errors?.MODEM_UNIQUE_ID || ""}</p>
								</div>
								{ModemID.MODEM_UNIQUE_ID !== "" && (
									<div class="form-group">
										<FormControl sx={{ width: 450 }} disableEnforceFocus>
											<InputLabel id="demo-multiple-checkbox-label">Select Slaves</InputLabel>
											<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												multiple
												value={personName}
												onChange={handleChange}
												input={<OutlinedInput label="Select Slaves" />}
												renderValue={(selected) => (
													<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
														{selected.map((value) => (
															<Chip key={value} label={value} />
														))}
													</Box>
												)}
												MenuProps={MenuProps}
											>
												{SlaveList?.DETAILS_U?.map((name) => (
													<MenuItem key={name.CONSDEVNAME_U} value={name.CONSDEVNAME_U}>
														<Checkbox checked={personName.indexOf(name.CONSDEVNAME_U) > -1} />
														<ListItemText primary={name.CONSDEVNAME_U} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
								)}
								{/* {ModemID.MODEM_UNIQUE_ID!==''&&<div class="form-group">
                  {console.log('SlaveList',SlaveList)}
                    <label>Slaves List</label>
                    <select className="form-select form-control " multiple='multiple' aria-label="Default select example" onChange={onHadaleSelection}>
                                <option selected value=''>Open Slaves select menu</option>
                                {SlaveList?.DETAILS_U?.length>0&&SlaveList?.DETAILS_U?.map((item)=>(<option value={item}>{item.CONSDEVNAME_U}</option>))}
                              </select>
                    
                  </div>} 
								<button type="button" class="btn btn-primary m-t-15 waves-effect" onClick={AddCard}>
									{editId === null ? `Add New Card` : `Update Card`}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div> */}
			{!loader && deshboardId && (CardPermition?.card_create === true || permission === "ROLE1680234321") && (
				<button
					id="myBtn"
					title="Add New Card"
					onClick={() => setmodel(true)}
					className={`${!model && editId === null ? `btn-info` : `btn-info btn-progress disabled`}`}
				>
					<BootstrapTooltip title="Add New Card" placement="top">
						<i className="fas fa-plus text-white" style={{ fontSize: "20px", paddingLeft: "5px", paddingRight: "5px" }}></i>
					</BootstrapTooltip>
				</button>
			)}
		</>
	);
};

export default Deshbord;
