import React, { useEffect, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { Flag, Users } from "react-feather";
import { BootstrapTooltip } from "../common";
import { getRolesUserData } from "../Apicommon";
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable"
import dt from "datatables.net-dt";
import { CSVLink } from "react-csv";
import { Modal } from "antd";
$.fn.DataTable = dt;


export default function Rolesuser() {
const [rolesData,setRolesData]=useState([])
const [filterrolesData,setfilterRolesData]=useState([])
const [loader,setLoader]=useState(false)
const history=useHistory()
const [isModalVisible, setIsModalVisible] = useState(false);
const [roleFilter,setRoleFilter]=useState('')


  const ongetRolesUserData=async()=>{
    setLoader(true);
		$(".loader").fadeOut("slow");
const data=await getRolesUserData()
if(data.success===true){
  setRolesData(data.data.data)
  setfilterRolesData(data.data.data)
}else{
  console.log('somthing wait wrong')
  setLoader(false)
}
  }

  const handleCancel = () => {
	setIsModalVisible(false);
};
 
const onhandleOk = () => {
	setIsModalVisible(false)
if(roleFilter===''){
return setRolesData(filterrolesData)
}

const filteData=filterrolesData&&filterrolesData.filter((item)=>(item?.ROLES_NAME?.includes(roleFilter)))
setRolesData(filteData)
}

const onChanegeHandale=(e)=>{
	setRoleFilter(e.target.value)
}

  useEffect(() => {
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({destroy: true,"pageLength": 50});
  setLoader(false);

			}, 700)
		);
	}, []);

  const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape
	
		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);
	
		doc.setFontSize(15);
	
		const title = ` Roles and Users  Data`;
		const headers = [["Unique No","Roles Name"]];
	
		const data = filterrolesData&&filterrolesData.map(elt=> [elt.ID,elt.ROLES_NAME]);
	
		let content = {
		  startY: 50,
		  head: headers,
		  body: data
		};
	
		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save(`RolesUser.pdf`)
	  }

    const excelFileExport=async()=>{
		
      const worksheet = XLSX.utils.json_to_sheet(filterrolesData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "RolesSheet.xlsx");
        
    }

	const UpdateRolesUser=(item)=>{
		history.push({ pathname: `/add-roles`, state: item })
	}

	return (
		<>
		<Modal title="Filter Roles" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputPassword4">Role Name:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={onChanegeHandale}
							value={roleFilter}
						>
							<option value="">Select Role</option>
							{filterrolesData&&filterrolesData.map((item)=>(<option value={item.ROLES_NAME}>{item.ROLES_NAME}</option>))}
						</select>
					</div>
					</div>
					<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/roles-users`}>
						Reset
					</Link>
				</div>
					</Modal>
		<div className="main-content">
    {loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to="/group-dashboard">
							<i className="fas fa-tachometer-alt"></i> Home
						</Link>
					</li>
					<li className="breadcrumb-item">
						<Link to="/roles-users">
							<Users style={{ height: "16px" }} /> Roles & Users
						</Link>
					</li>
					<li className="breadcrumb-item">
						<Link to="/roles-users">
							 Roles
						</Link>
					</li>
				</ol>
			</nav>
			<section className="section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4>Roles</h4>
									<div className="card-header-action">
										<Link to="/add-roles" className="btn btn-info text-white mr-2">
											<BootstrapTooltip title="Add">
												<span>
													<i
														className="fas fa-plus text-white"
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
										</Link>
										<button type="button" className="btn btn-info mr-2" onClick={()=>(setIsModalVisible(true))}>
											<BootstrapTooltip title="Filter">
												<span>
													<i className="fa fa-filter text-white"></i>
												</span>
											</BootstrapTooltip>
										</button>
										<div className="dropdown d-inline mr-2">
											<button
												className="btn btn-info dropdown-toggle mr-2"
												type="button"
												id="dropdownMenuButton3"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
												data-placement="bottom"
												title="Download"
											>
												<i className="fa fa-download mr-1"></i>
											</button>
											<div className="dropdown-menu">
												<a className="dropdown-item" onClick={exportPDF}>
													PDF
												</a>
												<a className="dropdown-item" onClick={excelFileExport}>
													Excel
												</a>
												<a className="dropdown-item">
													<CSVLink data={filterrolesData} filename={"GSMmodem.csv"}>
														CSV
													</CSVLink>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-striped" id="table-1">
											<thead>
												<tr>
													<th className="text-center" style={{ maxWidth: "100px" }}>Unique No</th>
													<th >Roles Name</th>
													<th style={{ maxWidth: "100px" }}>Status</th>
													
												</tr>
											</thead>
											<tbody>
												{rolesData &&
													rolesData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth" key={id} onClick={()=>(item.ROLES_NAME==='ROLE1680234321'?'':UpdateRolesUser(item))}>
															<td className="text-center">{item.ID}</td>
															<td>{item.ROLES_NAME}</td>
															{item.active===1?<td className="text-center">
																			<div className="badge badge-success badge-shadow text-white">Active</div>
																		</td>:<td className="text-center">
																			<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																		</td>}
															
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		</>
	);
}
