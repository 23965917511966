import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { UpdateChartDescription, getCardDeshboardByCardid, getCardWiseSlaveChartDataInKWH, getCardWiseSlaveChartDataInKWHByDate, getCardWiseSlaveChartDataInKWHMonthly, getCardWiseSlaveChartDataInKWHMonthlyByDate, getCardWiseSlaveChartDataInKWHYear } from '../Apicommon'
import Chart from "react-apexcharts";
import { DatePicker, Modal, Space } from 'antd';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { toast } from 'react-toastify';

export default function DashboardGraph() {
    const [mainCard,setMainCard]=useState({})
    const [DailyData,setDailyData]=useState({total:[],date:[]})
    const [MonthlyData,setMonthlyData]=useState({total:[],date:[]})
    const [YearData,setYearData]=useState({total:[],date:[]})
    const [DailyDataByDate,setDailyDataByDate]=useState({total:[],date:[]})
    const [MonthlyDataByDate,setMonthlyDataByDate]=useState({total:[],date:[]})

const [isModalVisible, setIsModalVisible] = useState(false);
const [filterChartflag,setfilterChartflag]=useState(false)
const [isModalVisibleMonth,setisModalVisibleMonth]=useState(false)
const [isModaldesc,setisModaldesc]=useState(false)
const [DescriptionJson,setDescriptionJson]=useState({'Daily_KWH_Count':'','Monthly_KWH_Count':'','Yearly_KWH_Count':''})

const [expireDate,setExpireDate]=useState({})
const [dates, setDates] = useState({ start: "", end: "" });
const [months, setmonths] = useState({ start: "", end: "" });
const [DescType,setDescType]=useState('')
const [ChartType,setChartType]=useState('bar')

	const [currentDate, setcurrentDate] = useState(moment(new Date()));
	const colors = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
    const history=useHistory()
    const params=useParams()

    const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString });
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString });
	};

    const showModal =async () => {
        setIsModalVisible(!isModalVisible);
		
	};
    const ongetCardDeshboardByCardid=async()=>{
        const data=await getCardDeshboardByCardid(params?.SRNO)
        if(data.success===true){
            setMainCard(data.data.data[0])
			setDescriptionJson(JSON.parse(data?.data?.data[0]?.CHART_DISCRIPTION_JSON||'{"Daily_KWH_Count":"","Monthly_KWH_Count":"","Yearly_KWH_Count":""}'))
        }else{
            console.log('something wait wrong')
        }
    }

    const ongetCardWiseSlaveChartDataInKWH=async()=>{
        const data=await getCardWiseSlaveChartDataInKWH(params?.SRNO)
        if(data.success===true){
            setDailyData({total:data.data.data.map((item)=>(mainCard?.MULTIFICATION_FECTOR?(mainCard?.MULTIFICATION_FECTOR*item?.Daily_KWH_count).toFixed(2):item?.Daily_KWH_count)),date:data.data.data.map((item,id)=>(id==(data.data.data.length-1)?moment(item?.date,'YYYY-MM-DD').format('DD-MM-YYYY')+'(Live)':moment(item?.date,'YYYY-MM-DD').format('DD-MM-YYYY')))})
        }else{
            console.log('something wait wrong')
        }
    }

    const ongetCardWiseSlaveChartDataInKWHMonthly=async()=>{
        const data=await getCardWiseSlaveChartDataInKWHMonthly(params?.SRNO)
        if(data.success===true){
            setMonthlyData({total:data.data.data.map((item)=>(mainCard?.MULTIFICATION_FECTOR?(mainCard?.MULTIFICATION_FECTOR*item?.Daily_KWH_count).toFixed(2):item?.Daily_KWH_count)),date:data.data.data.map((item)=>(item?.date))})
        }else{
            console.log('something wait wrong')
        }
    }

    const ongetCardWiseSlaveChartDataInKWHYear=async()=>{
        const data=await getCardWiseSlaveChartDataInKWHYear(params?.SRNO)
        if(data.success===true){
            setYearData({total:data.data.data.map((item)=>(mainCard?.MULTIFICATION_FECTOR?(mainCard?.MULTIFICATION_FECTOR*item?.Daily_KWH_count).toFixed(2):item?.Daily_KWH_count)),date:data.data.data.map((item)=>(item?.date))})
        }else{
            console.log('something wait wrong')
        }
    }
    const onSelectDateByDailyData=async()=>{
        
        if(dates.start!==''&&dates.end!==''){
            setfilterChartflag(true)
        const data=await getCardWiseSlaveChartDataInKWHByDate(params?.SRNO,{start:moment(dates.start,'DD-MM-YYYY').format('YYYY-MM-DD'),end:moment(dates.end,'DD-MM-YYYY').format('YYYY-MM-DD')})
        if(data.success===true){
            setDailyDataByDate({total:data.data.data.map((item)=>(mainCard?.MULTIFICATION_FECTOR?(mainCard?.MULTIFICATION_FECTOR*item?.Daily_KWH_count).toFixed(2):item?.Daily_KWH_count)),date:data.data.data.map((item)=>(moment(item?.date,'YYYY-MM-DD').format('DD-MM-YYYY')))})
            setfilterChartflag(false)

        }else{
            console.log('something wait wrong')
            setfilterChartflag(false)

        }
    }
    }

    const onSelectDateByMonthlyData=async()=>{
        
        if(months.start!==''&&months.end!==''){
            setfilterChartflag(true)
        const data=await getCardWiseSlaveChartDataInKWHMonthlyByDate(params?.SRNO,{start:months.start,end:months.end})
        if(data.success===true){
            setMonthlyDataByDate({total:data.data.data.map((item)=>(mainCard?.MULTIFICATION_FECTOR?(mainCard?.MULTIFICATION_FECTOR*item?.Daily_KWH_count).toFixed(2):item?.Daily_KWH_count)),date:data.data.data.map((item)=>(item?.date))})
            setfilterChartflag(false)

        }else{
            console.log('something wait wrong')
            setfilterChartflag(false)

        }
    }
    }

    const state1 = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
                    columnWidth:'50%',
					distributed: true,
					borderRadius: 5,
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: DailyData.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "KWH",
				data: DailyData.total
			}
		]
	};
	const state10 = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
                    columnWidth:'50%',
					distributed: true,
					borderRadius: 5,
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: DailyData.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "KWH",
				data: DailyData.total
			}
		]
	};
    const state2 = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
                    columnWidth:'50%',
					distributed: true,
					borderRadius: 5,
					dataLabels: {
						position: 'top',
						offsetY: 20,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: MonthlyData.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: -4,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "KWH",
				data: MonthlyData.total
			}
		]
	};
    const state3 = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
                    columnWidth:'70%',
					distributed: true,
					borderRadius: 5,
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '5px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: YearData.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "KWH",
				data: YearData.total
			}
		]
	};
    useEffect(() => {
		const fetchData = async () => {
      await  ongetCardDeshboardByCardid()
        
		}
		fetchData();
    },[]);

	useEffect(()=>{
		if(Object.keys(mainCard).length>0){
		ongetCardWiseSlaveChartDataInKWH()
        ongetCardWiseSlaveChartDataInKWHMonthly()
        ongetCardWiseSlaveChartDataInKWHYear()
		}
	},[mainCard])

    const onBackUrl=()=>{
        window.history.back()
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        zoomPlugin, ChartDataLabels
	);

    // const chartRef = useRef()

    const optionsDKwh = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				ticks: {
					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		plugins: {
			
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						// modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
			
				align: 'start',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const dataDkwh = {
		labels: DailyDataByDate.date,
		datasets: [
			{
				label: "KWH",
				data: DailyDataByDate.total,
				backgroundColor: colors,
			}
		]
	};
    const optionsMKwh = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				ticks: {
					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		plugins: {
			
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						// modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
			
				align: 'start',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const dataMkwh = {
		labels: MonthlyDataByDate.date,
		datasets: [
			{
				label: "KWH",
				data: MonthlyDataByDate.total,
				backgroundColor: colors,
			}
		]
	};


	const onUpdateChartDescription=async()=>{
		const data=await UpdateChartDescription({DESC_JSON:JSON.stringify(DescriptionJson),ID:params?.SRNO})
		if(data.success==true) {
			toast.success('successfully Submit description')
			ongetCardDeshboardByCardid()
			setisModaldesc(false)
		}else{
			toast.error('Something Went Wrong')

		}
	}

  return (
    <>
    <Modal title="Select Date" visible={isModalVisible} footer="" width={1500} onCancel={() => {
					setIsModalVisible(false)
					}}>
					<div className="d-flex">
				<Space direction="horizontal">
					<div className="d-flex flex-column">
						Start In
						<DatePicker onChange={onChange} name="start" format='DD-MM-YYYY'  disabledDate={(current) => {
              return current.isAfter(moment())
            }} />
					</div>
					<div className="d-flex flex-column">
						End In
						<DatePicker onChange={onChanges} name="end" format='DD-MM-YYYY'  disabledDate={(current) => {
              return current.isAfter(moment())
            }}  />
					</div>
				</Space>
					<a className={`btn btn-info text-white ml-3 mt-3 ${filterChartflag?'btn-progress disabled':''}`} onClick={onSelectDateByDailyData}>Submit</a>
					</div>

                    {DailyDataByDate.total.length > 0 && DailyDataByDate.date.length > 0 ? <div style={{height: '560px', maxHeight: '100vh', paddingLeft:'20px', paddingRight:'20px'}}><Bar options={optionsDKwh} data={dataDkwh} /> </div>: ''}
                    </Modal>

                    <Modal title="Select Date" visible={isModalVisibleMonth} footer="" width={1500} onCancel={() => {
					setisModalVisibleMonth(false)
					}}>
					<div className="d-flex">
				<Space direction="horizontal">
					<div className="d-flex flex-column">
						Start In
						<DatePicker onChange={(d,dtost)=>(setmonths({...months,start:dtost}))} name="start" format='MM-YYYY' picker="month" disabledDate={(current) => {
              return current.isAfter(moment())
            }}/>
					</div>
					<div className="d-flex flex-column">
						End In
						<DatePicker onChange={(d,dtost)=>(setmonths({...months,end:dtost}))} name="end" format='MM-YYYY'  picker="month"  disabledDate={(current) => {
              return current.isAfter(moment())
            }}/>
					</div>
				</Space>
					<a className={`btn btn-info text-white ml-3 mt-3 ${filterChartflag?'btn-progress disabled':''}`} onClick={onSelectDateByMonthlyData}>Submit</a>
					</div>

                    {MonthlyDataByDate.total.length > 0 && MonthlyDataByDate.date.length > 0 ? <div style={{height: '560px', maxHeight: '100vh', paddingLeft:'20px', paddingRight:'20px'}}><Bar options={optionsMKwh} data={dataMkwh} /> </div>: ''}
                    </Modal>

					<Modal title="Discription" visible={isModaldesc} footer="" width={500} onCancel={() => {
					setisModaldesc(false)
					}}>
<div class="form-row">
					<div class="form-group col-md-12">
						<label for="inputState">Description:</label>
						<textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter Description" onChange={(e)=>(setDescriptionJson({...DescriptionJson,[DescType]:e.target.value}))} value={DescriptionJson[DescType]}></textarea>
					</div>
					
					</div>
					<div className='btn btn-danger mr-2' onClick={onUpdateChartDescription}>Submit</div>
					<div className='btn btn-secodary' onClick={()=>setisModaldesc(false)}>Cancel</div>
					</Modal>
        <div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<span> configuration Dashboard</span>
							</Link>
						</li>
						<li className="breadcrumb-item">
							<a>
								<span>{mainCard?.CARD_NAME}</span>
							</a>
						</li>
						
						<li className="breadcrumb-item active">
							<span>Graph View</span>
						</li>
					</ol>
				</nav>

                <div class="row"><div class="col-12"><div class="card"><div class="card-header">
																
                <h4>Card Details</h4><div className="card-header-action card-drop">
                    <a className='btn btn-info' onClick={onBackUrl}>Back</a>
                </div></div>
                <div class="card-body" style={{paddingTop: "0px"}}>
                <div class="row"><div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <b class='mr-2'>Card Name: </b>{mainCard?.CARD_NAME}<br/><b class='mr-2'>Card Description:</b> {mainCard?.card_description}<br/><b class='mr-2'>Last Seen:</b>{currentDate.diff(
																		moment.utc(mainCard?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"),
																		"minutes"
																	) === 0
																		? moment
																				.utc(mainCard?.payload?.ENTRYTS || null)
																				.add(-moment().utcOffset(), "m")
																				.fromNow(true)
																		: currentDate.diff(
																				moment
																					.utc(mainCard?.payload?.ENTRYTS || null)
																					.add(-moment().utcOffset(), "m"),
																				"minutes"
																		  ) + ` minutes `}
																	ago
																	<div className='d-flex'><b class='mr-2'>Chart Type:</b><select className="form-select form-control" onChange={(e)=>setChartType(e.target.value)}>
				<option value="bar">Bar Chart</option>
				<option  value="line">Line Chart</option></select></div>
																	</div>
				
                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"><b class='mr-2'>VRN:</b>{mainCard?.payload?.VRN}<br/><b class='mr-2'>VYN:</b>{mainCard?.payload?.VYN}<br/><b class='mr-2'>VBN:</b>{mainCard?.payload?.VBN}<br/></div>
               
               <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"><b class='mr-2'>IR : </b>{mainCard?.payload?.IR}<br/><b class='mr-2'>IY : </b>{mainCard?.payload?.IY}<br/><b class='mr-2'>IB : </b>{mainCard?.payload?.IB}<br/></div>
               <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"><b class='mr-2'>KVA : </b>{mainCard?.payload?.KVA}<br/><b class='mr-2'>KW : </b>{mainCard?.payload?.POW}<br/><b class='mr-3'>PF  : </b>{mainCard?.payload?.PF}<br/></div>
               </div>
               </div></div></div></div>
               <div class="row">
							<div class="col-xs-12 col-md-6  ">
								<div class="card">
									<div class="card-header d-flex flex-row justify-content-between">
										<div><h4>Daily KWH Count</h4><div>{DescriptionJson.Daily_KWH_Count}</div></div>
                                        <div className="card-header-action card-drop">
										<a className='btn btn-outline-primary mr-2' onClick={()=>{setisModaldesc(true)
										setDescType('Daily_KWH_Count')}}>Add Description</a>

                    <a className='btn btn-info' onClick={showModal}>Expand</a>
                </div>
									</div>
									{ChartType=='line'&&<div
										class="card-body"
										style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
										<Chart options={state1.options} type={"line"} series={state1.series} width="100%" height={"350"} />
									</div>}
									{ChartType=='bar'&&<div
										class="card-body"
										style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
										<Chart options={state10.options} type={"bar"} series={state10.series} width="100%" height={"350"} />
									</div>}
									<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
                            <div class="col-xs-12 col-md-6  ">
								<div class="card">
									<div class="card-header d-flex flex-row justify-content-between">
										<div><h4>Monthly KWH Count</h4><div>{DescriptionJson.Monthly_KWH_Count}</div></div>
                                        <div className="card-header-action card-drop">
										<a className='btn btn-outline-primary mr-2' onClick={()=>{setisModaldesc(true)
										setDescType('Monthly_KWH_Count')}}>Add Description</a>
                    <a className='btn btn-info' onClick={()=>{setisModalVisibleMonth(true)}}>Expand</a>
                </div>
									</div>
									{ChartType=='line'&&<div
										class="card-body"
										style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
										<Chart options={state2.options} type="line" series={state2.series} width="100%" height={"350"} />
									</div>}
									{ChartType=='bar'&&<div
										class="card-body"
										style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
										<Chart options={state2.options} type="bar" series={state2.series} width="100%" height={"350"} />
									</div>}
									<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
                            <div class="col-xs-12 col-md-6  ">
								<div class="card">
									<div class="card-header d-flex flex-row justify-content-between">
										<div><h4>Yearly KWH Count</h4><div>{DescriptionJson.Yearly_KWH_Count}</div></div>
										
                                        <div className="card-header-action card-drop">
										<a className='btn btn-outline-primary mr-2' onClick={()=>{setisModaldesc(true)
										setDescType('Yearly_KWH_Count')}}>Add Description</a>
                </div>
									</div>
									{ChartType=='line'&&<div
										class="card-body"
										style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
										<Chart options={state3.options} type="line" series={state3.series} width="100%" height={"350"} />
									</div>}
									{ChartType=='bar'&&<div
										class="card-body"
										style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
										<Chart options={state3.options} type="bar" series={state3.series} width="100%" height={"350"} />
									</div>}
									<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
                            </div>
                </div>
    </>
  )
}
