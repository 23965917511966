import React, { useEffect, useState, createContext, useContext, useRef } from "react";
import "./Gsmmodem.css";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { Link, useHistory } from "react-router-dom";
import { Briefcase } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import { BootstrapTooltip } from "../common";
import { getConsumerData, getRolesDetailesByName, MetterData, UpdateConsumerNameByConsumerId } from "../Apicommon";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";

import jsPDF from "jspdf";
import "jspdf-autotable";
import CsvDownload from "react-json-to-csv";
import { Modal } from "antd";
import signal1 from '../assets/img/onesignal.png'
import signal2 from '../assets/img/secondsignal.png'
import signal3 from '../assets/img/signal3.png'
import signal4 from '../assets/img/signal4.png'
import signal5 from '../assets/img/signal5.png'
import swal from 'sweetalert2';
import { toast } from "react-toastify";
window.Swal = swal;
$.fn.DataTable = dt;

export const GsmModemData = createContext("");

export default function Gsmmodem() {
	const [modemRoles,setModemRoles]=useState({})

	
	const [arraydatass, setarraydatass] = useState([]);

	const [GsmmodemData, setGsmmodemData] = useState([]);
	const [filterGsmmodemData, setFilterGsmmodemData] = useState([]);

	const [loader, setloader] = useState(false);
	const history = useHistory();
	const el = useRef(null);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [filterData, setFilterdata] = useState({
		modem_name: "",
		consumer_unique_id: "",
		IMEI: "",
		modem_serial_no: "",
		connectivity_indication: "",
		consumer_status: "",
		modem_slave_name:''
	});

	const [meterdata, setmeterdata] = useState([]);
	const permission = JSON.parse(window.localStorage.getItem("user_info"))?.roles||'';

	const onRolesDetailesByName=async()=>{
		if(permission!=='ROLE1680234321'){
		const data=await getRolesDetailesByName({ROLES_NAME:permission})
		if(data.success===true){
	setModemRoles(JSON.parse(data?.data?.data?.modem_permission)||{})
}else{
	console.log('something wait wrong')
}
		}
	}
    const onHandalemeterData=async()=>{
        const data=await MetterData()
		if (data.success === true) {
const arrayoFdata=[]
            data.data.data.length>0 &&data.data.data.map((item, id) => {
                                                                var slave=JSON.parse(item.SLAVES_ID_U)
                                                                slave.DETAILS_U.map((data)=>{
                                                                        arrayoFdata.push({...data,CONSUMER_MODEM_NAME:item.CONSUMER_MODEM_NAME_U})
                                                                    })
                                                            })
			setmeterdata(arrayoFdata);
		} else {
		}
    }
	const showModal = () => {
		setIsModalVisible(true);
	};

	const onHandalModel = (e) => {
		setFilterdata({ ...filterData, [e.target.name]: e.target.value });
	};

	const onhandleOk = () => {
		setIsModalVisible(false);
		if (
			filterData?.modem_name === "" &&
			filterData?.consumer_unique_id === "" &&
			filterData?.IMEI === "" &&
			filterData?.modem_serial_no === "" &&
			filterData?.connectivity_indication === ""&&
			filterData?.modem_slave_name === ""
		) {
			return setGsmmodemData(filterGsmmodemData);
		}
		const filterGsmdata =
			filterGsmmodemData &&
			filterGsmmodemData.filter(
				(data) =>
					(filterData?.modem_name !== "" ? data?.payload?.CONSUMER_MODEM_NAME_U.includes(filterData?.modem_name) : []) &&
					(filterData?.consumer_unique_id !== "" ? data?.payload?.UNIQUE_ID_S?.includes(filterData?.consumer_unique_id) : []) &&
					(filterData?.IMEI !== "" ? data?.payload?.IMEI_U.includes(filterData?.IMEI) : []) &&
					(filterData?.modem_serial_no !== "" ? data?.payload?.CONSUMER_MODEM_SRNO_U.includes(filterData?.modem_serial_no) : []) &&
					(filterData?.connectivity_indication !== ""
						? filterData?.connectivity_indication == "null"
							? data?.MASSAGE === null
							: data?.MASSAGE == filterData?.connectivity_indication
						: [])&&
						(filterData?.modem_slave_name !== "" ? data?.payload?.CONSUMER_MODEM_NAME_U.includes(filterData?.modem_slave_name) : [])
			);
		setGsmmodemData(filterGsmdata);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const getConsumerDataFun = async () => {
		setloader(true);
		$(".loader").fadeOut("slow");
		const data = await getConsumerData();
		if (data.success === true) {
			setGsmmodemData(
				data.data.data.sort(function (a, b) {
					return a.SRNO_S - b.SRNO_S;
				})
			);
			setFilterGsmmodemData(
				data.data.data.sort(function (a, b) {
					return a.SRNO_S - b.SRNO_S;
				})
			);
			let arrayoFdata = [];

			data.data.data.map((elt) => {
				if(modemRoles[elt.payload.IMEI_U]?.view===true||permission === "ROLE1680234321"){
				arrayoFdata.push({
					SRNO_S: elt.payload.SRNO_S,
					CONSUMER_MODEM_NAME_U: elt.payload.CONSUMER_MODEM_NAME_U,
					UNIQUE_ID_S: elt.payload.UNIQUE_ID_S,
					IMEI_U: elt.payload.IMEI_U,
					CONSUMER_MODEM_SRNO_U: elt.payload.CONSUMER_MODEM_SRNO_U,
					RSSI: elt.RSSI,
					last_seen: elt.last_seen,
					MASSAGE: elt.MASSAGE===null?'Never Seen':elt.MASSAGE,
				});
			}
			});
			setarraydatass(arrayoFdata);
		} else {
			setloader(false);
		}
	};
	const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(15);

		const title = ` GSMmodem  Data`;
		const headers = [
			[
				"Unique No",
				"Modem Name",
				"Consumer Unique Id",
				"IMEI",
				"Modem Serial No",
				"RSSI",
				"Last Seen",
				"Last Duration",
				"Total Slave",
				"Connectivity Indication",
			],
		];

		const data =
			filterGsmmodemData &&
			filterGsmmodemData.map((elt) => modemRoles[elt.payload.IMEI_U]?.view===true||permission === "ROLE1680234321"?[
				elt.payload.SRNO_S,
				elt.payload.CONSUMER_MODEM_NAME_U,
				elt.payload.UNIQUE_ID_S,
				elt.payload.IMEI_U,
				elt.payload.CONSUMER_MODEM_SRNO_U,
				elt.RSSI,
				moment.utc(elt.last_seen).format("DD/MM/YYYY hh:mm:ss a"),
				moment.utc(elt.last_seen).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm'),
				elt.payload.TOTAL_SLAVES_S,
				elt.MASSAGE===null?'Never Seen':elt.MASSAGE,
			]:[]);

		let content = {
			startY: 50,
			head: headers,
			body: data,
		};

		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save(`GSMmodem.pdf`);
	};

	const excelFileExport = async () => {
		const data = [];
		filterGsmmodemData &&
			filterGsmmodemData.map((elt) =>{
			if(modemRoles[elt.payload.IMEI_U]?.view===true||permission === "ROLE1680234321"){
				data.push({
					SRNO_S: elt.payload.SRNO_S,
					CONSUMER_MODEM_NAME_U: elt.payload.CONSUMER_MODEM_NAME_U,
					UNIQUE_ID_S: elt.payload.UNIQUE_ID_S,
					IMEI_U: elt.payload.IMEI_U,
					CONSUMER_MODEM_SRNO_U: elt.payload.CONSUMER_MODEM_SRNO_U,
					RSSI: elt.RSSI,
					last_seen: moment.utc(elt.last_seen).format("DD/MM/YYYY hh:mm:ss a"),
					Last_Duration:moment.utc(elt.last_seen).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm'),
					MASSAGE: elt.MASSAGE===null?'Never Seen':elt.MASSAGE,
				})
			}
		}
			);

		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		XLSX.writeFile(workbook, "DataSheet.xlsx");
	};

	useEffect(() => {
		onRolesDetailesByName()
		getConsumerDataFun().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({destroy: true, pageLength: 50 });
				setloader(false);
			}, 1000)
		);

		onHandalemeterData()
	}, []);

	const onRedirectData = (id) => {
		history.push(`/consumer-meter-details/${id}`);
	};
	const [consumerName,setconsumerName]=useState({ModemName:'',ID:''})
	const [modemModal,setmodemModal]=useState(false)

	const handleModemCancel=()=>{
		setmodemModal(false)
		setconsumerName({ModemName:'',ID:''})
	}

	const onSetValuConsumer=(ID,NAME)=>{
		setconsumerName({ModemName:NAME,ID:ID})
		setmodemModal(true)
	}

	const onSubmitConsumerName=async()=>{
		const data=await UpdateConsumerNameByConsumerId(consumerName)
		if(data.success===true){
			setmodemModal(false)
		setconsumerName({ModemName:'',ID:''})
		history.push(`/gsm-modem`);
		toast.success('Change Modem Name Success');
		}else{
			swal.fire('', 'Server Issue', 'error');
		}
	}
	return (
		<>
		<Modal title="Edit GSM" visible={modemModal} footer="" onCancel={handleModemCancel} width={800}>
		<div class="form-row">
					<div class="form-group col-md-6">
					<label for="inputPassword4">Consumer Unique Id:</label>
						<input
							type="text"
							class="form-control"
							id=""
							name="consumer_unique_id"
							placeholder="Enter Consumer Name"
							onChange={(e)=>(setconsumerName({...consumerName,ModemName:e.target.value}))}
							value={consumerName?.ModemName}
						/>
					</div>
					</div>
					<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitConsumerName} />
					</div>
		</Modal>
			<Modal title="Filter GSM Data" visible={isModalVisible} footer="" onCancel={handleCancel} width={800}>
				<div class="form-row">
					<div class="form-group col-md-3">
						<label for="inputPassword4">Modem Name:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={onHandalModel}
							value={filterData.modem_name}
						>
							<option value="">Select Account</option>
							{filterGsmmodemData &&
								filterGsmmodemData.map((item) => (
									<option value={item.payload.CONSUMER_MODEM_NAME_U}>{item.payload.CONSUMER_MODEM_NAME_U}</option>
								))}
						</select>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">Modem Slave Name:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_slave_name"
							onChange={onHandalModel}
							value={filterData.modem_slave_name}
						>
							<option value="">Select Account</option>
							{meterdata &&
								meterdata.map((item) => (
									<option value={item.CONSUMER_MODEM_NAME}>{item.CONSDEVNAME_U}</option>
								))}
						</select>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">Consumer Unique Id:</label>
						<input
							type="text"
							class="form-control"
							id=""
							name="consumer_unique_id"
							placeholder="Enter Consumer Unique Id"
							onChange={onHandalModel}
							value={filterData.consumer_unique_id}
						/>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">IMEI:</label>
						<input
							type="text"
							class="form-control"
							id=""
							name="IMEI"
							placeholder="Enter IMEI No"
							onChange={onHandalModel}
							value={filterData.IMEI}
						/>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">Modem Serial No:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_serial_no"
							onChange={onHandalModel}
							value={filterData.modem_serial_no}
						>
							<option value="">Select Account</option>
							{filterGsmmodemData &&
								filterGsmmodemData.map((item) => (
									<option value={item.payload.CONSUMER_MODEM_SRNO_U}>{item.payload.CONSUMER_MODEM_SRNO_U}</option>
								))}
						</select>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">Connectivity Indication:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="connectivity_indication"
							onChange={onHandalModel}
							value={filterData.connectivity_indication}
						>
							<option value="">Select Account</option>
							<option value="online">online</option>
							<option value="offline">offline</option>
							<option value="null">Never Seen</option>
						</select>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">Consumer Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="consumer_status"
							onChange={onHandalModel}
							value={filterData.consumer_status}
						>
							<option value="">Select Account</option>
							<option value="Active">Active</option>
							<option value="Inactive">Inactive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to="/gsm-modem">
						Reset
					</Link>
				</div>
			</Modal>
			<GsmModemData.Provider value={"hello context to kese he aplog"}>
				{/* <Viewuser/> */}
				<div className="main-content">
					{loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/group-dashboard">
									<i className="fas fa-tachometer-alt"></i> Home
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link to="/gsm-modem">
									<Briefcase style={{ height: "16px" }} /> Consumer
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link to="/gsm-modem">
									<i className="fas fa-list"></i> GSM Modem
								</Link>
							</li>
						</ol>
					</nav>
					<section className="section">
						<div className="section-body">
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<h4>GSM Modem</h4>
											<div className="card-header-action">
												<Link to="/add-consumer" className="btn btn-info text-white mr-2">
													<BootstrapTooltip title="Add">
														<span>
															<i
																className="fas fa-plus text-white"
																style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
															></i>
														</span>
													</BootstrapTooltip>
												</Link>
												<button type="button" className="btn btn-info mr-2" onClick={showModal}>
													<BootstrapTooltip title="Filter">
														<span>
															<i className="fa fa-filter text-white"></i>
														</span>
													</BootstrapTooltip>
												</button>
												<div className="dropdown d-inline mr-2">
													<button
														className="btn btn-info dropdown-toggle mr-2"
														type="button"
														id="dropdownMenuButton3"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
														data-placement="bottom"
														title="Download"
													>
														<i className="fa fa-download mr-1"></i>
													</button>
													<div className="dropdown-menu">
														<a className="dropdown-item" onClick={exportPDF}>
															PDF
														</a>
														<a className="dropdown-item" onClick={excelFileExport}>
															Excel
														</a>
														<a className="dropdown-item">
															<CsvDownload
																data={arraydatass}
																filename={`GSMmodem.csv`}
																style={{ border: "0px", background: "white" }}
															>
																CSV
															</CsvDownload>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<table className="table table-striped" id="table-1" ref={el}>
													<thead>
														<tr>
															<th className="text-center">Unique No</th>
															<th style={{ maxWidth: "200px" }}>Modem Name</th>
															<th>IMEI</th>
															<th style={{ minWidth: "90px" }}>Modem Serial No</th>
															<th>RSSI</th>
															<th style={{ minWidth: "100px" }}>Last Seen</th>
															<th>Last Seen Duration</th>
															<th>Total Slave</th>
															<th>Connectivity Indication</th>
															<th>Consumer Status</th>
															<th style={{ minWidth: "90px" }}>View Details</th>
															<th>Edit</th>
														</tr>
													</thead>
													<tbody>
														{GsmmodemData &&
															GsmmodemData.map((item, id) => (
																modemRoles[item.payload.IMEI_U]?.view===true||permission === "ROLE1680234321"?<tr style={{ height: "4rem" }} className="trheigth" key={id}>
																	<td>{item.payload.SRNO_S}</td>
																	<td>{item.payload.CONSUMER_MODEM_NAME_U}</td>
																	<td>{item.payload.IMEI_U}</td>
																	{/* <BootstrapTooltip title={item.payload.CONSUMER_MODEM_SRNO_U} placement="top"> */}
																		<td>
																			{item.payload.CONSUMER_MODEM_SRNO_U}
																		</td>
																	{/* </BootstrapTooltip> */}
																	<BootstrapTooltip title={`RSSI:${item?.RSSI >= 2 && item?.RSSI <= 9?'Marginal':item?.RSSI >= 10 && item?.RSSI <= 14?'Ok':item?.RSSI >= 15 && item?.RSSI <= 19?'Good':item?.RSSI >= 20 && item?.RSSI <= 31?'Excellent':''	}`} placement="top">
																	<td className="text-center">
																		{item?.RSSI >= 2 && item?.RSSI <= 9 ? (
																			<>
																				<img src={signal2} style={{height:'20px',width:"40px",border:'0',boxShadow:'none',borderRadius:'none'}}/>
																				<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : item?.RSSI >= 10 && item?.RSSI <= 14 ? (
																			<>
																			<img src={signal3} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																					<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : item?.RSSI >= 15 && item?.RSSI <= 19 ? (
																			<>
																			<img src={signal4} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : item?.RSSI >= 20 && item?.RSSI <= 31 ? (
																			<>
																			<img src={signal5} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : (
																			"-"
																		)}
																	</td>
																		</BootstrapTooltip>
																	<td>{moment.utc(item.last_seen).format("DD/MM/YYYY HH:mm:ss")}</td>
																	<td>{moment.utc(item.last_seen).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm')}</td>
																	<td>
																		<a
																			className="btn btn-primary text-white"
																			onClick={() => onRedirectData(item.payload.UNIQUE_ID_S)}
																		>
																			{item?.payload?.TOTAL_SLAVES_S}
																		</a>
																	</td>
																	<td className="text-center">
																		<div
																			className={`badge badge-${item.MASSAGE === "online" ? `success` : ""}${
																				item.MASSAGE === "offline" ? `danger` : ""
																			}${item.MASSAGE == null ? `warning` : ""} badge-shadow text-white`}
																		>
																			{item.MASSAGE === "online" && `online`}
																			{item.MASSAGE === "offline" && `offline`}
																			{item.MASSAGE == null && `Never Seen`}
																		</div>
																	</td>
																	<td className="text-center">
																		<div className="badge badge-success badge-shadow text-white">Active</div>
																	</td>
																	<td>
																		<div
																			// to={pathname:`/gsm-heartbeat/${item.payload.IMEI_U}`}
																			className="btn btn-primary text-white"
																			onClick={() =>
																				history.push({
																					pathname: `/gsm-heartbeat/${item.payload.IMEI_U}`,
																					state: item,
																				})
																			}
																		>
																			View Details
																		</div>
																	</td>
																	<td>
																	{modemRoles[item.payload.IMEI_U]?.edit===true||permission === "ROLE1680234321"?<a class="btn-sm btn-danger text-white ml-2" onClick={()=>(onSetValuConsumer(item.payload.UNIQUE_ID_S,item.payload.CONSUMER_MODEM_NAME_U))}><BootstrapTooltip title="Edit" placement="top"><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Refresh"><i class="fa fa-edit text-white"></i></span></BootstrapTooltip></a>:''}
																	</td>
																</tr>:''
															))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</GsmModemData.Provider>
		</>
	);
}
