import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Briefcase } from "react-feather";
import Chart from 'react-apexcharts'
import * as $ from "jquery";
import dt from "datatables.net-dt";
import moment from 'moment';
import { AllMetterData, getChartDateByTableNameWithDate, GetConsumberInByIMEI, getConsumerData, getDataSlaveConnectivity, getModemDetailbySrno, getSlavesDetailbySrno, getStartAndEndDateByTable, get_modem_connectivity_status } from '../Apicommon';
import { DatePicker, Modal, Space } from "antd";
import signal2 from '../assets/img/secondsignal.png'
import signal3 from '../assets/img/signal3.png'
import signal4 from '../assets/img/signal4.png'
import signal5 from '../assets/img/signal5.png'
import { BootstrapTooltip } from '../common';
$.fn.DataTable = dt;

export default function Connect_deshboard() {

const [ActiveSlave,setActiveSlave]=useState([])
const [ActiveSlave2,setActiveSlave2]=useState([])
const [ActiveSlaveData, setActiveSlaveData] = useState([]);

const [InActiveSlave,setInActiveSlave]=useState([])
const [InActiveSlave2,setInActiveSlave2]=useState([])
const [SlaveTs,setSlaveTs]=useState([])
const [SlaveTs2,setSlaveTs2]=useState([])

const [loader,setLoader]=useState(false)
const [isModalVisible, setIsModalVisible] = useState(false);
const [isModalTableVisible, setisModalTableVisible] = useState(false);
    const [flag,setFlag]=useState(false)
const [params,setparams]=useState(null)
const [location,setlocation]=useState(false)
const [locationModem,setlocationModem]=useState(false)
	const [dates, setDates] = useState({ start: "", end: "" });
	const [expireDate,setExpireDate]=useState({})
	const [filterChartflag,setfilterChartflag]=useState(false)
	const [idCombo,setIdcombo]=useState([])
	const [idCombo2,setIdcombo2]=useState([])
	const [AllMeterDataInfo,setAllMeterDataInfo]=useState([])
	const [modemIdcombo,setmodemIdcombo]=useState([])
	const [ActiveModem,setActiveModem]=useState([])
	const [InActiveModem,setInActiveModem]=useState([])
	const [ModemTs,setModemTs]=useState([])
	const [modemIdcombo2,setmodemIdcombo2]=useState([])
	const [ActiveModem2,setActiveModem2]=useState([])
	const [InActiveModem2,setInActiveModem2]=useState([])
	const [ModemTs2,setModemTs2]=useState([])
	const [GsmmodemData, setAllModemDataInfo] = useState([]);
	const [ActiveModemData,setActiveModemData]=useState([])
	const [isModalModemTableVisible,setisModalModemTableVisible]=useState(false)
	const [isModalVisibleModem,setisModalVisibleModem]=useState(false)
	const history=useHistory()

	const getChartDataInSlaves=async()=>{
		const data=await getDataSlaveConnectivity()
        if(data.success===true){
			setIdcombo(data.data.data.map((item)=>(item.SRNO)))
            setActiveSlave(data.data.data.map((item)=>(item.TOTAL_ACTIVE_SLAVES)))
            setInActiveSlave(data.data.data.map((item)=>(item.TOTAL_INACTIVE_SLAVES)))
            setSlaveTs(data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY hh:mm:ss A'))))

		}else{
			console.log('something wait wrong')

		}
	}

	const getChartDataInModem=async()=>{
		const data=await get_modem_connectivity_status()
        if(data.success===true){
			setmodemIdcombo(data.data.data.map((item)=>(item.SRNO)))
            setActiveModem(data.data.data.map((item)=>(item.TOTAL_ACTIVE_MODEM)))
            setInActiveModem(data.data.data.map((item)=>(item.TOTAL_INACTIVE_MODEM)))
            setModemTs(data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY hh:mm:ss A'))))
		}else{
			console.log('something wait wrong')
		}
	}
    const onGetSlaveConnectivity=async()=>{
		const data2=await AllMetterData()
		if (data2.success === true) {
			setAllMeterDataInfo(data2.data.data)
		
        }else{
			console.log('something wait wrong')

		}
    }

	const onGetModemConnectivity=async()=>{
		setLoader(true)
		const data2=await getConsumerData()
		if (data2.success === true) {
			setAllModemDataInfo(data2.data.data)
			setLoader(false)

        }else{
			console.log('something wait wrong')
			setLoader(false)
		}
    }

	const onHandalemeterData=async(ids,status)=>{
        setLoader(true);
		$(".loader").fadeOut("slow");
        // const data=await AllMetterData()
		// console.log(data)
		// if (data.success === true) {
// const arrayoFdata=[]
// await data.data.data.length>0 &&data.data.data.map((item) => {
//                                                                 var slave=JSON.parse(item.SLAVES_ID_U)
//                                                                 slave.DETAILS_U.map((data)=>{
//                                                                         arrayoFdata.push(data)
//                                                                     })
//                                                             })
        const data2=await getSlavesDetailbySrno(ids,status)
		if (data2.success === true) {
			if(status===true){
               const arraySlaves=  AllMeterDataInfo.filter((item)=>(JSON.parse(data2?.data?.data[0]?.ACTIVE_SLAVES_DETAILS).some((data)=>(item?.payload?.CONSSLAVEUID_S==data?.consumer_unique_id))))
			   setActiveSlaveData(arraySlaves)
			   setisModalTableVisible(true)
			setLoader(false);
            }else{
				const arraySlaves=await AllMeterDataInfo.filter((item)=>(JSON.parse(data2?.data?.data[0]?.INACTIVE_SLAVES_DETAILS).some((data)=>(item?.payload?.CONSSLAVEUID_S==data?.consumer_unique_id))))
				setActiveSlaveData(arraySlaves)
				setisModalTableVisible(true)
			setLoader(false);
            }
		} else {
			setLoader(false);
		}
			
		// } 
		// else {
		// 	setLoader(false);
		// }
    }

	
	const onChange = (date, dateString) => {
		setDates({ ...dates, start: moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD') });
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD') });
	};

	useEffect(() => {
		onGetModemConnectivity()
		onGetSlaveConnectivity()
		getChartDataInSlaves()
		getChartDataInModem()
	}, []);
	
	
	const onHadaleSalves=(id,status)=>{
		// history.push({ pathname: `/slaves-connecvity-details/${idCombo[id]}`, state: {active:status} })
		setlocation(status)
		setparams(idCombo[id])

		onHandalemeterData(idCombo[id],status)
		// .then(() =>
        // setTimeout(function () {
		// 	// if(ActiveSlaveData.length===0){
        //     $("#table-1").DataTable({"pageLength": 50, ordering: true});
		// 	// }
		// 	setLoader(false);
        // }, 1000)
		// )
	}
	const onHadaleSalves2=(id,status)=>{
		// history.push({ pathname: `/slaves-connecvity-details/${idCombo[id]}`, state: {active:status} })
		setIsModalVisible(false)
		setlocation(status)
		setparams(idCombo2[id])

		onHandalemeterData(idCombo2[id],status)
		// .then(() =>
        // setTimeout(function () {
		// 	// if(ActiveSlaveData.length===0){
        //     $("#table-1").DataTable({"pageLength": 50, ordering: true});
		// 	// }
		// 	setLoader(false);
        // }, 1000)
		// )
	}

	const onHandalemodemData=async(ids,status)=>{
        setLoader(true);
		$(".loader").fadeOut("slow");

        const data2=await getModemDetailbySrno(ids,status)
		if (data2.success === true) {
			if(status===true){
               const arraySlaves=  GsmmodemData.filter((item)=>(JSON.parse(data2?.data?.data[0]?.ACTIVE_MODEM_DETAILS).some((data)=>(item?.payload?.IMEI_U==data?.IMEI))))
			   setActiveModemData(arraySlaves)
			   setisModalModemTableVisible(true)
			setLoader(false);
            }else{
				const arraySlaves=await GsmmodemData.filter((item)=>(JSON.parse(data2?.data?.data[0]?.INACTIVE_MODEM_DETAILS).some((data)=>(item?.payload?.IMEI_U==data?.IMEI))))
				setActiveModemData(arraySlaves)
				setisModalModemTableVisible(true)
			setLoader(false);
            }
		} else {
			setLoader(false);
		}
	}

	const onRedirectData = (id) => {
		history.push(`/consumer-meter-details/${id}`);
	};

	const onHadaleModem=(id,status)=>{
		// history.push({ pathname: `/slaves-connecvity-details/${idCombo[id]}`, state: {active:status} })
		setlocationModem(status)
		onHandalemodemData(modemIdcombo[id],status)
	}

	const onHadaleModem2=(id,status)=>{
		// history.push({ pathname: `/slaves-connecvity-details/${idCombo[id]}`, state: {active:status} })
		setlocationModem(status)
		onHandalemodemData(modemIdcombo2[id],status)
	}

    var series= [{
		name: 'Active Slaves',
		data: ActiveSlave,
		details:idCombo
	  },
	  {
		name: 'Inactive Slaves',
		data: InActiveSlave,
		details:idCombo
	  }]
	  var series2= [{
		name: 'Active Slaves',
		data: ActiveSlave2,
		details:idCombo
	  },
	  {
		name: 'Inactive Slaves',
		data: InActiveSlave2,
		details:idCombo
	  }]
	  var options2={
		chart: {
	   type: 'bar',
	   height: 350,
	   stacked: true,
	   toolbar: {
		   tools: {
			   download: true,
			   selection: true,
			   zoom: true,
			   zoomin: true,
			   zoomout: true,
			   pan: true,
			   reset: true | '<img src="/static/icons/reset.png" width="20">',
			   customIcons: []
		   }
	   },
	   zoom: {
		 enabled: true,
	   },
	   plotOptions: {
		   bar: {
			 columnWidth: '100%',
		   },
		 },
	   events: {
		   dataPointSelection: function(event, chartContext, config) {
			   if(config.selectedDataPoints.length===2){
				onHadaleSalves2(config.dataPointIndex,false)
			   }else{
				onHadaleSalves2(config.dataPointIndex,true)
			   }
		   }
		 },
		 
	 },
	 colors: [ '#00e396','#feb019'],
	 responsive: [{
	   breakpoint: 480,
	   options: {
		 legend: {
		   position: 'bottom',
		   offsetX: -10,
		   offsetY: 0
		 }
	   }
	 }],
	 noData: {  
		text: "Loading...",  
		align: 'center',  
		verticalAlign: 'middle',  
		offsetX: 0,  
		offsetY: 0,  
		style: {  
		  color: "#000000",  
		  fontSize: '14px',  
		  fontFamily: "Helvetica"  
		},
	},
	 plotOptions: {
	   bar: {
		 horizontal: false,
		 borderRadius: 10
	   },
	 },
	 xaxis: {
	   categories: SlaveTs2,
	   tickPlacement: 'on'
	 },
	 legend: {
	   position: 'right',
	   offsetY: 40
	 },
	 fill: {
	   opacity: 1
	 }
	
	 }
      var options={
		 chart: {
		type: 'bar',
		height: 350,
		stacked: true,
		toolbar: {
			tools: {
				download: true,
				selection: true,
				zoom: true,
				zoomin: true,
				zoomout: true,
				pan: true,
				reset: true | '<img src="/static/icons/reset.png" width="20">',
				customIcons: []
			}
		},
		zoom: {
		  enabled: true,
		},
		plotOptions: {
			bar: {
			  columnWidth: '100%',
			},
		  },
		events: {
			dataPointSelection: function(event, chartContext, config) {
				
				if(config.selectedDataPoints.length===2){
				onHadaleSalves(config.dataPointIndex,false)
				}else{
				onHadaleSalves(config.dataPointIndex,true)
				}
			}
		  },
		  
	  },
	  colors: [ '#00e396','#feb019'],
	  responsive: [{
		breakpoint: 480,
		options: {
		  legend: {
			position: 'bottom',
			offsetX: -10,
			offsetY: 0
		  }
		}
	  }],
	  plotOptions: {
		bar: {
		  horizontal: false,
		  borderRadius: 10
		},
	  },
	  xaxis: {
		categories: SlaveTs.map((item)=>([item.split(' ')[0],item.split(' ')[1] + ' '+item.split(' ')[2]])),
		tickPlacement: 'on'
	  },
	  legend: {
		position: 'right',
		offsetY: 40
	  },
	  fill: {
		opacity: 1
	  }
	 
	  }
	  var Modemseries= [{
		name: 'Online Modem',
		data: ActiveModem,
	  },
	  {
		name: 'Offline Modem',
		data: InActiveModem,
	  }]
	  var Modemoptions={
		chart: {
	   type: 'bar',
	   height: 350,
	   stacked: true,
	   toolbar: {
		   tools: {
			   download: true,
			   selection: true,
			   zoom: true,
			   zoomin: true,
			   zoomout: true,
			   pan: true,
			   reset: true | '<img src="/static/icons/reset.png" width="20">',
			   customIcons: []
		   }
	   },
	   zoom: {
		 enabled: true,
	   },
	   plotOptions: {
		   bar: {
			 columnWidth: '100%',
		   },
		 },
	   events: {
		   dataPointSelection: function(event, chartContext, config) {
			   
			   if(config.selectedDataPoints.length===2){
				onHadaleModem(config.dataPointIndex,false)
			   }else{
				onHadaleModem(config.dataPointIndex,true)
			   }
		   }
		 },
		 
	 },
	 colors: [ '#00e396','#feb019'],
	 responsive: [{
	   breakpoint: 480,
	   options: {
		 legend: {
		   position: 'bottom',
		   offsetX: -10,
		   offsetY: 0
		 }
	   }
	 }],
	 plotOptions: {
	   bar: {
		 horizontal: false,
		 borderRadius: 10
	   },
	 },
	 xaxis: {
	   categories: ModemTs.map((item)=>([item.split(' ')[0],item.split(' ')[1]+' '+item.split(' ')[2]])),
	   tickPlacement: 'on'
	 },
	 legend: {
	   position: 'right',
	   offsetY: 40
	 },
	 fill: {
	   opacity: 1
	 }
	
	 }
	 var Modemseries2= [{
		name: 'Online Modem',
		data: ActiveModem2,
	  },
	  {
		name: 'Offline Modem',
		data: InActiveModem2,
	  }]
	  var Modemoptions2={
		chart: {
	   type: 'bar',
	   height: 350,
	   stacked: true,
	   toolbar: {
		   tools: {
			   download: true,
			   selection: true,
			   zoom: true,
			   zoomin: true,
			   zoomout: true,
			   pan: true,
			   reset: true | '<img src="/static/icons/reset.png" width="20">',
			   customIcons: []
		   }
	   },
	   zoom: {
		 enabled: true,
	   },
	   plotOptions: {
		   bar: {
			 columnWidth: '100%',
		   },
		 },
	   events: {
		   dataPointSelection: function(event, chartContext, config) {
			   
			   if(config.selectedDataPoints.length===2){
				onHadaleModem2(config.dataPointIndex,false)
			   }else{
				onHadaleModem2(config.dataPointIndex,true)
			   }
		   }
		 },
		 
	 },
	 colors: [ '#00e396','#feb019'],
	 responsive: [{
	   breakpoint: 480,
	   options: {
		 legend: {
		   position: 'bottom',
		   offsetX: -10,
		   offsetY: 0
		 }
	   }
	 }],
	 plotOptions: {
	   bar: {
		 horizontal: false,
		 borderRadius: 10
	   },
	 },
	 xaxis: {
	   categories: ModemTs2,
	   tickPlacement: 'on'
	 },
	 legend: {
	   position: 'right',
	   offsetY: 40
	 },
	 fill: {
	   opacity: 1
	 }
	
	 }
	  const showModal =async () => {
		const data=await getStartAndEndDateByTable({ tableName:'slaves_connectivity_status' })
		if(data.success===true){
			setIsModalVisible(!isModalVisible);
			setExpireDate(data.data)

	  }else{
		  console.log("something wait wrong")
	  }
	};

	const showModalModem =async () => {
		const data=await getStartAndEndDateByTable({ tableName:'modem_connectivity_status' })
		if(data.success===true){
			setisModalVisibleModem(!isModalVisibleModem);
			setExpireDate(data.data)
	  }else{
		  console.log("something wait wrong")
	  }
	};

	const ongetChartDateByTableNameWithDate=async()=>{
		if(dates.start!==''&&dates.end!==''){
			setfilterChartflag(true)
		const data=await getChartDateByTableNameWithDate({...dates,tableName:'slaves_connectivity_status'})
		if(data.success===true){
			setIdcombo2(data.data.data.map((item)=>(item.SRNO)))
			setActiveSlave2(data.data.data.map((item)=>(item.TOTAL_ACTIVE_SLAVES)))
            setInActiveSlave2(data.data.data.map((item)=>(item.TOTAL_INACTIVE_SLAVES)))
            setSlaveTs2(data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))

			setfilterChartflag(false)
		}
		else{
			console.log('something wait wrong')
			setfilterChartflag(false)
		}
	}
	}

	const ongetModemChartDateByTableNameWithDate=async()=>{
		if(dates.start!==''&&dates.end!==''){
			setfilterChartflag(true)
		const data=await getChartDateByTableNameWithDate({...dates,tableName:'modem_connectivity_status'})
		if(data.success===true){
			setmodemIdcombo2(data.data.data.map((item)=>(item.SRNO)))
			setActiveModem2(data.data.data.map((item)=>(item.TOTAL_ACTIVE_MODEM)))
            setInActiveModem2(data.data.data.map((item)=>(item.TOTAL_INACTIVE_MODEM)))
            setModemTs2(data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))

			setfilterChartflag(false)
		}
		else{
			console.log('something wait wrong')
			setfilterChartflag(false)
		}
	}
	}
	const onMoveGSMDeshboard=async(IMEI,slavsID)=>{
		if(IMEI||slavsID){
		const data=await GetConsumberInByIMEI({IMEI:IMEI,slavsID:slavsID})
		if(data.success===true){
			history.push({ pathname: `/view-user/${data.data.UNIQUE_ID}`, state: {payload:data.data.filterSlavs,last_seen:data.data.last_seen} });
		}else{
			console.log('something wait wrong');
		}
	}
	}
	
  return (
	<>
	
    <div className="main-content">
	{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<Modal  visible={isModalTableVisible} footer='' width={2000} onCancel={()=>{setisModalTableVisible(false)
				
// 				var elmtTable = document.getElementById('tbody');
// var tableRows = document.getElementsByTagName('tr');
// var rowCount = tableRows.length;
// for (var x=0; x===ActiveSlaveData.length; x++) {
// document.getElementById("myTable").deleteRow(x);
//    elmtTable.removeChild(tableRows[x]);
// }
				}}>
	<div>
	<h4>{location===true?"Active Slaves Details":"Inactive Slaves Details"}</h4>
			<div className="table-responsive mt-5">
			<table className="table table-striped " id="table-1">
													<thead>
														<tr>
															<th className="text-center">Unique No</th>
															<th >Consumer Slave Id</th>
															<th>Consumer Device Name</th>
															<th>Script Decode Id</th>
															<th >Meter Profile</th>
															{/* <th style={{maxWidth:'100px',width:'100px'}}>Last Seen</th>
                                                            <th>Last Seen Duration</th> */}
                                                            <th style={{minWidth:'440px'}}>View Details</th>

														</tr>
													
													</thead>
													<tbody id="tbody">
													<>
														{ActiveSlaveData.length>0 ?
															ActiveSlaveData.map((data, id) => (
													
                                                                        <tr style={{ height: "4rem" }} className="trheigth" key={id}>
																		<td>{data?.payload?.SLAVEID_U}</td>
																		<td>{data?.payload?.CONSSLAVEUID_S}</td>
																		<td>{data?.payload?.CONSDEVNAME_U}</td>
																		<td>{data?.payload?.SCRIPTDECODEID_S}</td>
																		<td>{data?.payload?.meter_profile_U===1?"Load Survey":"Instantaneous"}</td>
																		{/* <td>{moment.utc(data?.last_seen).format("DD/MM/YYYY hh:mm:ss a")}</td>
                                    <td>{data?.last_seen?moment.utc(data?.last_seen||null).add(-(moment().utcOffset()), 'm').fromNow(true):'Not Last Seen'}</td> */}
									<td className="">
																		<a
																			className="btn btn-primary text-white btn-lg"
																			style={{ lineHeight: "1.5" }}
																			onClick={() => onMoveGSMDeshboard(data?.IMEI_U,data?.payload?.CONSSLAVEUID_S)}
																		>
																			View Details
																		</a>
																	</td>
																	</tr>
                                                        )):<tr></tr>}
														</>
													</tbody>
												</table>
									</div>
									</div>
			</Modal>
			<Modal  visible={isModalModemTableVisible} footer='' width={2000} onCancel={()=>{setisModalModemTableVisible(false)	}}>
					<h4>{locationModem===true?"Active Slaves Details":"Inactive Slaves Details"}</h4>
					<div className="table-responsive">
												<table className="table table-striped" id="table-1" >
													<thead>
														<tr>
															<th className="text-center">Unique No</th>
															<th style={{ maxWidth: "200px" }}>Modem Name</th>
															<th>IMEI</th>
															<th style={{ minWidth: "90px" }}>Modem Serial No</th>
															<th style={{ width: "65px" }}>RSSI</th>
															{/* <th style={{ minWidth: "100px" }}>Last Seen</th> */}
															{/* <th>Last Seen Duration</th> */}
															<th>Total Slave</th>
															<th>Connectivity Indication</th>
															<th>Consumer Status</th>
															<th style={{ minWidth: "90px" }}>View Details</th>
														</tr>
													</thead>
													<tbody>
														{ActiveModemData &&
															ActiveModemData.map((item, id) => (
																<tr style={{ height: "4rem" }} className="trheigth" key={id}>
																	<td>{item.payload.SRNO_S}</td>
																	<td>{item.payload.CONSUMER_MODEM_NAME_U}</td>
																	<td>{item.payload.IMEI_U}</td>
																		<td>
																			{item.payload.CONSUMER_MODEM_SRNO_U}
																		</td>
																	{/* </BootstrapTooltip> */}
																	<BootstrapTooltip title={`RSSI:${item?.RSSI >= 2 && item?.RSSI <= 9?'Marginal':item?.RSSI >= 10 && item?.RSSI <= 14?'Ok':item?.RSSI >= 15 && item?.RSSI <= 19?'Good':item?.RSSI >= 20 && item?.RSSI <= 31?'Excellent':''	}`} placement="top">
																	<td className="text-center">
																		{item?.RSSI >= 2 && item?.RSSI <= 9 ? (
																			<>
																				<img src={signal2} style={{height:'20px',width:"40px",border:'0',boxShadow:'none',borderRadius:'none'}}/>
																				<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : item?.RSSI >= 10 && item?.RSSI <= 14 ? (
																			<>
																			<img src={signal3} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																					<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : item?.RSSI >= 15 && item?.RSSI <= 19 ? (
																			<>
																			<img src={signal4} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : item?.RSSI >= 20 && item?.RSSI <= 31 ? (
																			<>
																			<img src={signal5} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{item?.RSSI} </span> 
																			</>
																		) : (
																			"-"
																		)}
																	</td>
																		</BootstrapTooltip>
																	{/* <td>{moment.utc(item.last_seen).format("DD/MM/YYYY HH:mm:ss")}</td>
																	<td>{moment.utc(item.last_seen).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm')}</td> */}
																	<td className="text-center">
																		<a
																			className="btn btn-primary text-white"
																			onClick={() => onRedirectData(item.payload.UNIQUE_ID_S)}
																		>
																			{item?.payload?.TOTAL_SLAVES_S}
																		</a>
																	</td>
																	<td className="text-center">
																		<div
																			className={`badge badge-${item.MASSAGE === "online" ? `success` : ""}${
																				item.MASSAGE === "offline" ? `danger` : ""
																			}${item.MASSAGE == null ? `warning` : ""} badge-shadow text-white`}
																		>
																			{item.MASSAGE === "online" && `online`}
																			{item.MASSAGE === "offline" && `offline`}
																			{item.MASSAGE == null && `Never Seen`}
																		</div>
																	</td>
																	<td className="text-center">
																		<div className="badge badge-success badge-shadow text-white">Active</div>
																	</td>
																	<td>
																		<div
																			// to={pathname:`/gsm-heartbeat/${item.payload.IMEI_U}`}
																			className="btn btn-primary text-white"
																			onClick={() =>
																				history.push({
																					pathname: `/gsm-heartbeat/${item.payload.IMEI_U}`,
																					state: item,
																				})
																			}
																		>
																			view Details
																		</div>
																	</td>
																</tr>
															))}
													</tbody>
												</table>
											</div>
							</Modal>
    <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link >
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>

						<li className="breadcrumb-item">
							<Link to="/connectivity-deshboard">
								 Connectivity Dashboard
							</Link>
						</li>
					</ol>
				</nav>
		
				<Modal title="Select Date" visible={isModalVisible} footer="" width={1500} onCancel={() => {
					setIsModalVisible(false)
					}}>
					<div className="d-flex">
				<Space direction="horizontal">
					<div className="d-flex flex-column">
						Start In
						<DatePicker onChange={onChange} name="start" format='DD-MM-YYYY'  disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }} />
					</div>
					<div className="d-flex flex-column">
						End In
						<DatePicker onChange={onChanges} name="end" format='DD-MM-YYYY'  disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }}  />
					</div>
				</Space>
					<a className={`btn btn-info text-white ml-3 mt-3 ${filterChartflag?'btn-progress disabled':''}`} onClick={ongetChartDateByTableNameWithDate}>Submit</a>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${filterChartflag?'disabled':''}`} onClick={onGetSlaveConnectivity}>Reset</a>
					</div>
				<div class="mt-5" style={{paddingTop:"5px",paddingBottom:"5px"}}>

										<Chart options={options2} series={series2} type="bar" height={350}/>
										</div>
			</Modal>

			<Modal title="Select Date" visible={isModalVisibleModem} footer="" width={1500} onCancel={() => {
					setisModalVisibleModem(false)
					}}>
					<div className="d-flex">
				<Space direction="horizontal">
					<div className="d-flex flex-column">
						Start In
						<DatePicker onChange={onChange} name="start"  format='DD-MM-YYYY' disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }} />
					</div>
					<div className="d-flex flex-column">
						End In
						<DatePicker onChange={onChanges} name="end" format='DD-MM-YYYY'  disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }}  />
					</div>
				</Space>
					<a className={`btn btn-info text-white ml-3 mt-3 ${filterChartflag?'btn-progress disabled':''}`} onClick={ongetModemChartDateByTableNameWithDate}>Submit</a>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${filterChartflag?'disabled':''}`} onClick={onGetSlaveConnectivity}>Reset</a>
					</div>
				<div class="mt-5" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										<Chart options={Modemoptions2} series={Modemseries2} type="bar" height={350}/>
										</div>
			</Modal>
    <div class="row">
							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>Slaves Connectivity</h4>
											<div className="card-header-action card-drop">
												<div className='btn btn-primary' onClick={showModal}>Select Date</div>
											</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>

										<Chart options={options} series={series} type="bar" height={350}/>
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
											Bar chart
										</p>
									</div>
								</div>
                                </div>
								<div class="row">
							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>Modem Connectivity</h4>
											<div className="card-header-action card-drop">
												<div className='btn btn-primary' onClick={showModalModem}>Select Date</div>
											</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>

										<Chart options={Modemoptions} series={Modemseries} type="bar" height={350}/>
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
											Bar chart
										</p>
									</div>
								</div>
                                </div>
    </div>
	</>
  )
}
