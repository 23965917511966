import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { BootstrapTooltip } from '../common'
import './Userprofile.css'
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getUsersByID, updateUser, updateUserPassword } from '../Apicommon';
window.Swal = swal;

function Userprofile() {

  const [enbale, setEnbale] = useState(false)
  const [enbalepass, setEnbalepass] = useState(false)
  const [changesPass, setChangesPass] = useState({ password: '', comform_password: '' })
  const [imgs, setimgs] = useState('')
  const [profile,setprofile]=useState({FirstName:'',LastName:'',email:'',mobileNo:null})
  const [loginErrors, setValidation] = useState({});
  const [flag,setFlag]=useState(false)


  const profiledata=JSON.parse(window.localStorage.getItem('user_info'))||{}
  useEffect(()=>{
if(profiledata){
    setprofile({FirstName:profiledata?.FirstName,LastName:profiledata?.LastName,email:profiledata?.email,mobileNo:profiledata?.mobileNo})
    setimgs(profiledata?.profile_user===''?'':profiledata?.profile_user)
}
  },[])

  const onHandalInputs=(e)=>{
    setprofile({...profile,[e.target.name]:e.target.value})
  }
  const onEnableFun = () => {
    setEnbale(true)
  }
  const onDisable = () => {
    setEnbale(false)
  }

  function isImage(icon) {
    const ext = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.svg'];
    return ext.some(el => icon.endsWith(el));
  }



  const onHandaleFile = (e) => {
    var totalSizeMB = e.target.files[0].size / Math.pow(1024, 2)
    if (totalSizeMB >= 2 || !isImage(e.target.files[0].name)) {
      swal.fire('', 'File size should be under 2 MB and File Extension Should only be PNG , JPG , JPEG , JIF . Please Try Again', 'error');
    } else {
      var reader = new FileReader();
      reader.onloadend = function () {
        var base64data = reader.result;
        setimgs(base64data)
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  

  const onHandalChange = (e) => {
    setChangesPass({ ...changesPass, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (changesPass.password === changesPass.comform_password && changesPass.password !== '' && changesPass.comform_password !== '') {
      setEnbalepass(true)
    } else {
      setEnbalepass(false)
    }
  }, [changesPass])
  const validation = (name, value) => {
    switch (name) {
        case 'email':
            if (!value) {
                return 'Please input email!'
            } else {
                return '';
              }
              case 'LastName':
            if (!value) {
                return 'Please input Last Name!'
            } else {
                return '';
              }
              case 'FirstName':
            if (!value) {
                return 'Please input First Name!'
            } else {
                return '';
              }
              case 'mobileNo':
            if (!value) {
                return 'Please input Mobile No!'
            } else {
                return '';
              }
              default: {
                return null;
            }
        }
        }

  const onHandalModale =async (e) => {
    e.preventDefault()
    setFlag(true)

    let allErrors = {};
      Object.keys(profile).forEach(key => {
        const error = validation(key, profile[key]);
        if (error && error.length) {
            allErrors[key] = error
        }
    });

    if (Object.keys(allErrors).length) {
      setFlag(false)
      return setValidation(allErrors)
    }else{
      setValidation({})

      const obj={
        ...profile,
   profile_user: imgs===''?null:imgs,
   roles: profiledata?.roles
       }
      const data=await updateUser(profiledata?.userID,obj);
if(data.success===true){
  const res=await getUsersByID(profiledata?.userID)
  if(res.success===true){
    window.localStorage.setItem('user_info',JSON.stringify(res?.data?.data[0]))
    setEnbale(false)
    setFlag(false)

    swal.fire('', 'Submit Successfuly', 'success');
  }else{
    setFlag(false)

  }
}else{
  setFlag(false)

}
    }
  }

  const onUpdateUserPassword=async(e)=>{
    e.preventDefault()
    const data=await updateUserPassword(profiledata?.userID,changesPass)
    if(data.success===true){
      swal.fire('', 'Update Password Successfuly', 'success');
      setChangesPass({password:'',comform_password:''})
    setEnbale(false)

    }else{
      swal.fire('', 'Password Not Match', 'error');
    }
  }

  return (
    <>
      <div className="main-content" style={{ minHeight: "562px" }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/group-dashboard"><i className="fas fa-tachometer-alt"></i> Home</Link></li>
            <li className="breadcrumb-item"><Link to="/userprofile">User Profile</Link></li>
          </ol>
        </nav>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>User Profile</h4>
                    <div className="card-header-action">
                      <div className="btn btn-info text-white" onClick={onEnableFun}>
                        <BootstrapTooltip title='Edit Profile'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit"><i className="fas fa-edit text-white"></i></span></BootstrapTooltip></div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <fieldset className="customLegend">
                          <legend>User Profile Details</legend>
                          <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" style={{ marginLeft: "25px" }}>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="col-lg-12 ">
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label for="inputPassword4">First Name:</label>
                                      <input className="form-control" id="fname" name="FirstName" type="text" placeholder="Enter First Name" value={profile?.FirstName} disabled={!enbale} onChange={onHandalInputs}/>
                                      <p className="text-danger">
                      {loginErrors?.FirstName||''}
                    </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label for="inputPassword4">Last Name:</label>
                                      <input className="form-control" id="lname" name="LastName" type="text" placeholder="Enter Last Name" value={profile?.LastName} disabled={!enbale} onChange={onHandalInputs}/>
                                      <p className="text-danger">
                      {loginErrors?.LastName||''}
                    </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 ">
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label for="inputPassword4">Email:</label>
                                      <input className="form-control" id="emailuser" name="email" type="text" placeholder="Enter Email" value={profile?.email} disabled={!enbale} onChange={onHandalInputs} />
                                      <p className="text-danger">
                      {loginErrors?.email||''}
                    </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label for="inputPassword4">Personal Mobile Number:</label>
                                      <input className="form-control" id="userphone" name="mobileNo" type="text" placeholder="Enter Personal Mobile Number" value={profile?.mobileNo} disabled={!enbale} onChange={onHandalInputs} />
                                      <p className="text-danger">
                      {loginErrors?.mobileNo||''}
                    </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 " style={{ marginTop: '30px' }}>
                                <div className="col-lg-12 ">
                                  <div className="form-row">
                                    <div className="form-group col-md-3">
                                      <div className="change_profile_image text-right float-left">
                                        <img src={imgs || `https://eums.memighty.com/dist/userprofile/165302266740PINBreakawayHeaderMALE.png`} name="image" height="100px" width="100px" className="profileimagedata" id="image_d" />
                                        <div className="file_upload">
                                          <input type="file" name="image" id="profileimage" onChange={onHandaleFile} disabled={!enbale} value='' style={{ display: `${!enbale ? 'none' : ''}` }} />
                                          <label htmlFor="profileimage" ><i className="fas fa-camera" ></i></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {enbale ? <div className="text-left subgrp">
                              <input type="submit" name="submit" className={`btn btn-danger ${flag?'btn-progress disabled':''}`} value="Submit" id="submitvn" onClick={onHandalModale} style={{ marginRight: '5px' }} />
                              <div className="btn btn-light text-dark" onClick={onDisable}>Cancel</div>
                            </div> : ''}
                          </form>
                        </fieldset>
                      </div>
                      <div className="col-12">
                        <fieldset className="customLegend">
                          <legend>Password Change</legend>
                          <form style={{ marginLeft: "25px" }}>
                            <div className="row">
                              <div className="col-lg-10">
                                <div className="col-lg-12 ">
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label for="inputPassword4">New Password</label>
                                      <input className="form-control" id="Password" name="password" type="password" placeholder="Enter New  Password" disabled={!enbale} onChange={onHandalChange} value={changesPass.password} />
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label for="inputPassword4">Comform Password:</label>
                                      <input className="form-control" id="Comform Password" name="comform_password" type="password" placeholder="Enter Comform Password" onChange={onHandalChange} value={changesPass.comform_password} disabled={!enbale} />
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                            {enbalepass ? <div className="text-left subgrp">
                              <input type="submit" name="submit" className="btn btn-danger" value="Submit" id="submitvn" style={{ marginRight: '5px' }} onClick={onUpdateUserPassword}/></div> : ''}
                          </form>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default withRouter(Userprofile)
