import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTablebyDate, FindSlaveTableData, getStartAndEndDateByTable } from "../Apicommon";
import $ from "jquery";
import dt from "datatables.net-dt";
import { Space, Spin, Modal, DatePicker } from "antd";
import { BootstrapTooltip } from "../common";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import Pagination from "../CommonComponnet/Pagination";
import { exportCSVFile, exportExcelDownload } from "../CommonComponnet/Common";
$.fn.DataTable = dt;


export default function Slavedata(props) {
	const buttonArray = [`${props.view.payload.meter_profile_U === 1 ? "LoadSurvey" : "Instantaneous"}`, "Midnight"];
	const [btnText, setButtontext] = useState("");
	const [LoadeSurveydata, setLoadeSurveydata] = useState([]);
	const [Midnightdata, setMidnightdata] = useState([]);
	const [Instantaneous, setInstantaneousdata] = useState([]);
	const [loader, setLoader] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [numberofPage,setnumberofPage]=useState(1) 
	const [currentPage, setCurrentPage] = useState(1);
	const [dates, setDates] = useState({ start: "", end: "" });
	const [DownData, setDownData] = useState([]);
	const [PageSize,setPageSize]=useState(50)
	const [expireDate,setExpireDate]=useState({})

	const csvLink = useRef();

	const showModal =async () => {
		const data=await getStartAndEndDateByTable({ tableName:`${btnText === "LoadSurvey"? props.view.payload.LOADSURVEY_S:''}${btnText === "Midnight"? props.view.payload.MIDNIGHT_S:''}${btnText === "Instantaneous"? props.view.payload.instantaneous_S:''}` })
		if(data.success===true){
			setIsModalVisible(!isModalVisible);
			setExpireDate(data.data)
	  }else{
		  console.log("something wait wrong")
	  }
	};

	const onChange = (date, dateString) => {
		setDates({ ...dates, start: moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD') });
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end:  moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD') });
	};

	useEffect(() => {
		if (btnText !== "") {
			if (btnText === "LoadSurvey") {
				onFindSlaveTableData(props.view.payload.LOADSURVEY_S,true)
			} else if (btnText === "Midnight") {
				onFindSlaveTableData(props.view.payload.MIDNIGHT_S,true)
			} else if (btnText === "Instantaneous") {
				onFindSlaveTableData(props.view.payload.instantaneous_S,true)
			}
		}
	}, [btnText]);

	const onFindSlaveTableData = async (tname,flag) => {
		const firstPageIndex = flag?0:(currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		setLoader(true);
		const data = await FindSlaveTableData(tname,{start:firstPageIndex,end:PageSize});
		if (data.success === true) {
			if (btnText === "LoadSurvey") {
				setLoader(false);
			setnumberofPage(data?.data?.lengths)
				return	setLoadeSurveydata(data?.data?.data || []);
			} else if (btnText === "Midnight") {
				setLoader(false);
			setnumberofPage(data?.data?.lengths)
				return setMidnightdata(data?.data?.data || []);
			} else if (btnText === "Instantaneous") {
				setLoader(false);
			setnumberofPage(data?.data?.lengths)
				return setInstantaneousdata(data?.data?.data || []);
			}
		} else {
			setLoader(false);
		}
	};
	const onHandalebtn = (text) => {
		setButtontext(text);
	};

	useEffect(() => {
		if (btnText !== "") {
		if (btnText === "LoadSurvey") {
			onFindSlaveTableData(props.view.payload.LOADSURVEY_S)
		} else if (btnText === "Midnight") {
			onFindSlaveTableData(props.view.payload.MIDNIGHT_S)
		} else if (btnText === "Instantaneous") {
			onFindSlaveTableData(props.view.payload.instantaneous_S)
		}
	}
	}, [currentPage,PageSize]);

	const exportPDF = async () => {
		if (dates.start !== "" && dates.end !== "") {
			setIsModalVisible(false);
			const datas = await DownloadTablebyDate(dates, { tableName:`${btnText === "LoadSurvey"? props.view.payload.LOADSURVEY_S:''}${btnText === "Midnight"? props.view.payload.MIDNIGHT_S:''}${btnText === "Instantaneous"? props.view.payload.instantaneous_S:''}` });
			if (datas.success === true) {
				const unit = "pt";
				const size = "A4"; // Use A1, A2, A3 or A4
				const orientation = "portrait"; // portrait or landscape

				const marginLeft = 40;
				const doc = new jsPDF(orientation, unit, size);

				doc.setFontSize(15);

				const title = `${btnText}  Data`;
				var headers
				if (btnText === "LoadSurvey") {
				 headers = [
					["Unique No", "START TIME","END TIME","IRMIN","IRMAX","IRAVG","IYMIN","IYMAX","IYAVG","IBMIN","IBMAX","IBAVG","POWMIN","POWMAX","POWAVG","APOWMIN","APOWMAX","APOWAVG","KWH","PFMIN","PFMAX","PFAVG","FRQMIN","FRQMAX","FRQAVG","CREATED AT"],
				];
			}else if (btnText === "Midnight") {
				headers = [
					["Unique No",'START TIME','END TIME', 'KWHSTART','KWHEND','KWHDIFF','METERSRNO','ENTRYTS'],
				];
			}else if (btnText === "Instantaneous") {
				headers = [
					["Unique No","IR","IY","IB",'VBN','VRN','VYN','PWRB','PWRR','PWRY',"POW","APOW",'RPOW',"KWH",'PF','FRQ',"CREATED AT"],
				];
			}
			var data =[]
			if (btnText === "LoadSurvey") {
				 data = datas?.data?.data?.map((item) => [item?.ID, moment.utc(item?.STARTTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),moment.utc(item?.ENDTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),item?.IRMIN!==null?item?.IRMIN:'error',item?.IRMAX!==null?item?.IRMAX:'error',item?.IRAVG!==null?item?.IRAVG:'error',item?.IYMIN!==null?item?.IYMIN:'error',item?.IYMAX!==null?item?.IYMAX:'error',item?.IYAVG!==null?item?.IYAVG:'error',item?.IBMIN!==null?item?.IBMIN:'error',item?.IBMAX!==null?item?.IBMAX:'error',item?.IBAVG!==null?item?.IBAVG:'error',item?.POWMIN!==null?item?.POWMIN:'error',item?.POWMAX!==null?item?.POWMAX:'error',item?.POWAVG!==null?item?.POWAVG:'error',item?.APOWMIN!==null?item?.APOWMIN:'error',item?.APOWMAX!==null?item?.APOWMAX:'error',item?.APOWAVG!==null?item?.APOWAVG:'error',item?.KWH!==null?item?.KWH:'error',item?.PFMIN!==null?item?.PFMIN : 'error',item?.PFMAX!==null?item?.PFMAX : 'error',item?.PFAVG!==null?item?.PFAVG : 'error',item?.FRQMIN!==null?item?.FRQMIN : 'error',item?.FRQMAX!==null?item?.FRQMAX : 'error',item?.FRQAVG!==null?item?.FRQAVG: 'error',moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")]);
			}else if(btnText === "Midnight"){
				data = datas?.data?.data?.map((item) => [item?.ID,moment.utc(item?.STARTTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),moment.utc(item?.ENDTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),item?.KWHSTART!==null?item?.KWHSTART:'error',item?.KWHEND!==null?item?.KWHEND:'error',item?.KWHDIFF!==null?item?.KWHDIFF:'error',item?.METERSRNO!==null?item?.METERSRNO:'error', moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")]);
			}else if(btnText === "Instantaneous"){
				data = datas?.data?.data?.map((item) => [
					item?.ID||'error',item?.IR!==null?item?.IR:'error',item?.IY!==null?item?.IY:'error',item?.IB!==null?item?.IB:'error',item?.VBN!==null?item?.VBN:'error',item?.VRN!==null?item?.VRN:'error',item?.VYN!==null?item?.VYN:'error',item?.PWRB!==null?item?.PWRB:'error',item?.PWRR!==null?item?.PWRR:'error',item?.PWRY!==null?item?.PWRY:'error',item?.POW!==null?item?.POW:'error',item?.APOW!==null?item?.APOW:'error',item?.RPOW!==null?item?.RPOW:'error',item?.KWH!==null?item?.KWH:'error',item?.PF!==null?item?.PF:'error',item?.FRQ!==null?item?.FRQ:'error',moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")
				]);
				
			}
				let content = {
					startY: 50,
					head: headers,
					body: data,
				};

				doc.text(title, marginLeft, 40);
				doc.autoTable(content);
				doc.save(`${btnText}.pdf`);
			} else {
			}
		}
	};

	const excelFileExport=async()=>{
		if(dates.start!==''&&dates.end!==''){
			setIsModalVisible(false);

		if (btnText === "LoadSurvey") {

			const datas=await DownloadTablebyDate(dates, { tableName: props.view.payload.LOADSURVEY_S })
			if (datas.success === true) {
				const arrayLoade=await datas?.data?.data?.map((item) => ({ID:item?.ID,STARTTS:moment.utc(item?.STARTTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),ENDTS:moment.utc(item?.ENDTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),IRMIN:item?.IRMIN===null?'error':item.IRMIN,IRMAX:item?.IRMAX===null?'error':item.IRMAX,IRAVG:item?.IRAVG===null?'error':item.IRAVG,IYMIN:item?.IYMIN===null?'error':item.IYMIN,IYMAX:item?.IYMAX===null?'error':item.IYMAX,IYAVG:item?.IYAVG===null?'error':item.IYAVG,IBMIN:item?.IBMIN===null?'error':item.IBMIN,IBMAX:item?.IBMAX===null?'error':item.IBMAX,IBAVG:item?.IBAVG===null?'error':item.IBAVG,POWMIN:item?.POWMIN===null?'error':item.POWMIN,POWMAX:item?.POWMAX===null?'error':item.POWMAX,POWAVG:item?.POWAVG===null?'error':item.POWAVG,APOWMIN:item?.APOWMIN===null?'error':item.APOWMIN,APOWMAX:item?.APOWMAX===null?'error':item.APOWMAX===null?'error':item.APOWMAX,APOWAVG:item?.APOWAVG===null?'error':item.APOWAVG,KWH:item?.KWH===null?'error':item.KWH,PFMIN:item?.PFMIN===null?'error':item.PFMIN,PFMAX:item?.PFMAX===null?'error':item.PFMAX,PFAVG:item?.PFAVG===null?'error':item.PFAVG,FRQMIN:item?.FRQMIN===null?'error':item.FRQMIN,FRQMAX:item?.FRQMAX===null?'error':item.FRQMAX,FRQAVG:item?.FRQAVG===null?'error':item.FRQAVG,ENTRYTS:moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")}))
		// const worksheet = XLSX.utils.json_to_sheet(arrayLoade);
		// const workbook = XLSX.utils.book_new();
		// XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		exportExcelDownload(arrayLoade, "LoadSurvey");
		return
			}
		}
		else if (btnText === "Midnight") {

			const datas=await DownloadTablebyDate(dates, { tableName: props.view.payload.MIDNIGHT_S })
			if (datas.success === true) {
				const arrayLoade=await datas?.data?.data?.map((item) => ({ID:item?.ID,START_TIME:moment.utc(item.STARTTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),END_TIME:moment.utc(item.ENDTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss "),KWHSTART:item?.KWHSTART===null?'error':item.KWHSTART,KWHEND:item?.KWHEND===null?'error':item.KWHEND,KWHDIFF:item?.KWHDIFF===null?'error':item.KWHDIFF,METERSRNO:item?.METERSRNO===null?'error':item.METERSRNO,ENTRYTS: moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")}))
		// const worksheet = XLSX.utils.json_to_sheet(arrayLoade);
		// const workbook = XLSX.utils.book_new();
		// XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		exportExcelDownload(arrayLoade, "Midnight");
		// XLSX.writeFile(workbook, "Midnight.xlsx");
		return
			}
		}else if (btnText === "Instantaneous") {

			const datas=await DownloadTablebyDate(dates, { tableName: props.view.payload.instantaneous_S })
			if (datas.success === true) {
				const arrayLoade=await datas?.data?.data?.map((item) => ({ID:item?.ID===null?'error':item.ID,IR:item?.IR===null?'error':item.IR,IY:item?.IY===null?'error':item.IY,IB:item?.IB===null?'error':item.IB,VBN:item?.VBN===null?'error':item.VBN,VRN:item?.VRN===null?'error':item.VRN,VYN:item?.VYN===null?'error':item.VYN,PWRB:item?.PWRB===null?'error':item.PWRB,PWRB:item?.PWRR===null?'error':item.PWRB,PWRY:item?.PWRY===null?'error':item?.PWRY,POW:item?.POW===null?'error':item.POW,APOW:item?.APOW===null?'error':item.APOW,RPOW:item?.RPOW===null?'error':item.RPOW,KWH:item?.KWH===null?'error':item.KWH,PF:item?.PF===null?'error':item.PF,FRQ:item?.FRQ===null?'error':item.FRQ,ENTRYTS:moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")}))
		// const worksheet = XLSX.utils.json_to_sheet(arrayLoade);
		// const workbook = XLSX.utils.book_new();
		// XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		exportExcelDownload(arrayLoade, "Instantaneous");
		// XLSX.writeFile(workbook, "Instantaneous.xlsx");
		return
			}
		}
	}
	  }

	const onDownloadcsv = async () => {
		if (btnText === "LoadSurvey") {
			if (dates.start !== "" && dates.end !== "") {
		setIsModalVisible(false);

				const datas = await DownloadTablebyDate(dates, { tableName: props.view.payload.LOADSURVEY_S });
				if (datas.success === true) {
					setDownData(datas.data.data);
					var headers={}
					Object.entries(datas.data.data[0]).map(([key,val])=>{headers[key]=key})
					console.log(headers)
					exportCSVFile(headers,datas.data.data,`LoadSurvey`)
					setDates({ start: "", end: "" });
				} else {
				}
			}
		} else if (btnText === "Midnight") {
			if (dates.start !== "" && dates.end !== "") {
		setIsModalVisible(false);

				const datas = await DownloadTablebyDate(dates, { tableName: props.view.payload.MIDNIGHT_S });

				if (datas.success === true) {
					var headers={}
					setDownData(datas.data.data);
					var headers={}
					Object.entries(datas.data.data[0]).map(([key,val])=>{headers[key]=key})
					console.log(headers)
					
					exportCSVFile(headers,datas.data.data,`Midnight`)
					setDates({ start: "", end: "" });
				} else {
				}
			}
		} else if (btnText === "Instantaneous") {
			if (dates.start !== "" && dates.end !== "") {
		setIsModalVisible(false);
		var headers={}
				const datas = await DownloadTablebyDate(dates, { tableName: props.view.payload.instantaneous_S });

				if (datas.success === true) {
					setDownData(datas.data.data);
					var headers={}
					Object.entries(datas.data.data[0]).map(([key,val])=>{headers[key]=key})
					console.log(headers)
					exportCSVFile(headers,datas.data.data,`Instantaneous`)
				} else {
				}
			}
			// csvLink.current.link.click();

			setDates({ start: "", end: "" });
		}
		setDates({ start: "", end: "" });
	};
	return (
		<>
			<Modal title="download File" visible={isModalVisible} footer="" onCancel={() => setIsModalVisible(false)}>
				<Space direction="horizontal">
					<div className="d-flex flex-column">
						Start In
						<DatePicker onChange={onChange} name="start" format='DD-MM-YYYY'  disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }} />
					</div>
					<div className="d-flex flex-column">
						End In
						<DatePicker onChange={onChanges} name="end" format='DD-MM-YYYY'  disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(btnText==='Midnight'?1:0,'days')<=current)  ;
            }}  />
					</div>
				</Space>
				<div className="mt-3">
					<a className="btn btn-info text-white" onClick={exportPDF}>PDF</a>
					<a className="btn btn-primary text-white ml-2" onClick={excelFileExport}>Excel</a>
					<a className="btn btn-danger text-white ml-2" onClick={() => onDownloadcsv()}>
						CSV
					</a>
						<CSVLink data={DownData} filename={`${btnText}.csv`} ref={csvLink} />
				</div>
			</Modal>
			<div className="card-body" style={{ paddingTop: "0px" }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<div
								className={`text-white btn  btn-info dashboard  ${
									buttonArray.findIndex((data) => data === btnText) === id ? "btn-lg btn-text-lg" : ""
								} `}
								onClick={() => onHandalebtn(item)}
							>
								{item}
							</div>
						))}
					</div>
					{LoadeSurveydata.length > 0 || Midnightdata.length > 0 || Instantaneous.length > 0 ? (
						<div className="dropdown d-inline mr-2">
							<button className="btn btn-info dropdown-toggle mr-2" type="button" title="Download" onClick={showModal}>
								<i className="fa fa-download mr-1"></i>
							</button>
						</div>
					) : (
						""
					)}
				</div>
				{loader && (
					<div className="example">
						<Spin size="large" />
					</div>
				)}
				{btnText === "LoadSurvey" && !loader && (
					<div className="card-body" style={{ paddingTop: "0px" }}>
					<div className="table-responsive dataTables_wrapper no-footer" id="table-1_wrapper">
						<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e)=>(setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
							<table className="table table-striped" id="table-1">
								<thead>
									<tr>
										<th className="text-center">Unique No</th>
										<th style={{ minWidth: "100px" }}>START TIME</th>
										<th style={{ minWidth: "100px" }}>END TIME</th>
										<th style={{ minWidth: "70px" }}>IR min</th>
										<th  style={{ minWidth: "70px" }}>IR max</th>
										<th style={{ minWidth: "70px" }}>IR avg</th>
										<th style={{ minWidth: "70px" }}>IY min</th>
										<th style={{ minWidth: "70px" }}>IY max</th>
										<th style={{ minWidth: "70px" }}>IY avg</th>
										<th style={{ minWidth: "70px" }}>IB min</th>
										<th style={{ minWidth: "70px" }}>IB max</th>
										<th style={{ minWidth: "70px" }}>IB avg</th>
										<th style={{ minWidth: "90px" }}>KW min</th>
										<th style={{ minWidth: "90px" }}>KW max</th>
										<th style={{ minWidth: "90px" }}>KW avg</th>
										<th style={{ minWidth: "90px" }}>KVA min</th>
										<th style={{ minWidth: "90px" }}>KVA max</th>
										<th style={{ minWidth: "90px" }}>KVA avg</th>
										<th style={{ minWidth: "70px" }}>PF min</th>
										<th style={{ minWidth: "70px" }}>PF max</th>
										<th style={{ minWidth: "70px" }}>PF avg</th>
										<th style={{ minWidth: "90px" }}>FRQ min</th>
										<th style={{ minWidth: "90px" }}>FRQ max</th>
										<th style={{ minWidth: "90px" }}>FRQ avg</th>
										<th>KWH</th>
										<th>Information</th>
										<th style={{ minWidth: "100px" }}>CREATED AT</th>
									</tr>
								</thead>
								<tbody>
									{btnText === "LoadSurvey" &&
										LoadeSurveydata.length > 0 ?
										LoadeSurveydata.map((item, id) => (
											<tr style={{ height: "4rem" }} className="trheigth" key={id}>
												<td>{item.ID}</td>
												<td>{moment.utc(item.STARTTS).format("DD/MM/YYYY HH:mm:ss ")}</td>
												<td>{moment.utc(item.ENDTS).format("DD/MM/YYYY HH:mm:ss ")}</td>
												<td>{item.IRMIN===null?'error':item.IRMIN}</td>
												<td>{item.IRMAX===null?'error':item.IRMAX}</td>
												<td>{item.IRAVG===null?'error':item.IRAVG}</td>
												<td>{item.IYMIN===null?'error':item.IYMIN}</td>
												<td>{item.IYMAX===null?'error':item.IYMAX}</td>
												<td>{item.IYAVG===null?'error':item.IYAVG}</td>
												<td>{item.IBMIN===null?'error':item.IBMIN}</td>
												<td>{item.IBMAX===null?'error':item.IBMAX}</td>
												<td>{item.IBAVG===null?'error':item.IBAVG}</td>
												<td>{item.POWMIN===null?'error':item.POWMIN}</td>
												<td>{item.POWMAX===null?'error':item.POWMAX}</td>
												<td>{item.POWAVG===null?'error':item.POWAVG}</td>
												<td>{item.APOWMIN===null?'error':item.APOWMIN}</td>
												<td>{item.APOWMAX===null?'error':item.APOWMAX}</td>
												<td>{item.APOWAVG===null?'error':item.APOWAVG}</td>
												<td>{item.PFMIN===null?'error':item.PFMIN}</td>
												<td>{item.PFMAX===null?'error':item.PFMAX}</td>
												<td>{item.PFAVG===null?'error':item.PFAVG}</td>
												<td>{item.FRQMIN===null?'error':item.FRQMIN}</td>
												<td>{item.FRQMAX===null?'error':item.FRQMAX}</td>
												<td>{item.FRQAVG===null?'error':item.FRQAVG}</td>
												<td>{item.KWH===null?'error':item.KWH}</td>
												<BootstrapTooltip
													title={
														<div>
															IMEI:{item.IMEI}
															<br />
															MBSLAVEID:{item.MBSLAVEID}
															<br />
															SCRIPTCODE:{item.SCRIPTCODE}
															<br />
															CONSUMER UNIQUE ID:{item.CONSUMER_UNIQUE_ID}
														</div>
													}
													placement="top"
												>
													<td>Details:</td>
												</BootstrapTooltip>
												<td>{moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")}</td>
											</tr>
										)):''}
								</tbody>
							</table>
							{LoadeSurveydata.length===0?<h4 className="text-center">No Data Found</h4>:''}
						</div>
					</div>
				)}
				{btnText === "Midnight" && !loader && (
					<div className="card-body" style={{ paddingTop: "0px" }}>
					<div className="table-responsive dataTables_wrapper no-footer" id="table-1_wrapper">
						<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e)=>(setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
							<table className="table table-striped" id="table-2">
								<thead>
									<tr>
										<th className="text-center">Unique No</th>

										<th style={{ width: "100px" }}>START TIME</th>
										<th style={{ width: "100px" }}>END TIME</th>
										<th>KWH start</th>
										<th>KWH end</th>
										<th>KWH diff</th>
										<th>METER SRNO</th>
										<th>Information</th>
										<th style={{ width: "100px" }}>CREATED AT</th>
									</tr>
								</thead>
								<tbody>
									{Midnightdata.length > 0 ?
										Midnightdata.map((item, id) => (
											<tr style={{ height: "4rem" }} className="trheigth" key={id}>
												<td>{item.ID}</td>
												<td>{moment.utc(item.STARTTS).format("DD/MM/YYYY HH:mm:ss ")}</td>
												<td>{moment.utc(item.ENDTS).format("DD/MM/YYYY HH:mm:ss ")}</td>
												<td>{item?.KWHSTART===null?'error':item?.KWHSTART}</td>
												<td>{item?.KWHEND===null?'error':item?.KWHEND}</td>
												<td>{item?.KWHDIFF===null ?'error':item?.KWHDIFF}</td>
												<td>{item?.METERSRNO===null?'error':item?.METERSRNO}</td>
												<BootstrapTooltip
													title={
														<div>
															IMEI:{item.IMEI}
															<br />
															MBSLAVEID:{item.MBSLAVEID}
															<br />
															SCRIPTCODE:{item.SCRIPTCODE}
															<br />
															CONSUMER UNIQUE ID:{item.CONSUMER_UNIQUE_ID}
														</div>
													}
													placement="top"
												>
													<td>Details:</td>
												</BootstrapTooltip>
												<td>{moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss ")}</td>
											</tr>
										)):''}
								</tbody>
							</table>
							{Midnightdata.length===0?<h4 className="text-center">No Data Found</h4>:''}
							
						</div>
					</div>
				)}
				{btnText === "Instantaneous" && !loader && (
					<div className="card-body" style={{ paddingTop: "0px" }}>
						<div className="table-responsive dataTables_wrapper no-footer" id="table-1_wrapper">
						<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e)=>(setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
							<table className="table table-striped" id="table-3">
								<thead>
									<tr>
										<th className="text-center">Unique No</th>
										<th>IR</th>
										<th>IY</th>
										<th>IB</th>
										<th>VRN</th>
										<th>VYN</th>
										<th>VBN</th>
										<th>KW</th>
										<th>KVA</th>
										<th>PF</th>
										<th>FRQ</th>
										<th>KWH</th>
										<th>METERSRNO</th>
										<th>Information</th>
										<th style={{ minWidth: "100px" }}>CREATED AT</th>
									</tr>
								</thead>
								<tbody>
									{btnText === "Instantaneous" &&
										Instantaneous.length > 0 ?
										Instantaneous.map((item, id) => (
											<tr style={{ height: "4rem" }} className="trheigth" key={id}>
												<td>{item.ID}</td>
												<td>{item?.IR===null?'error':item?.IR}</td>
												<td>{item?.IY===null?'error':item?.IY}</td>
												<td>{item?.IB===null?'error':item?.IB}</td>
												<td>{item?.VRN===null?'error':item?.VRN}</td>
												<td>{item?.VYN===null?'error':item?.VYN}</td>
												<td>{item?.VBN===null?'error':item?.VBN}</td>
												<td>{item?.POW===null?'error':item?.POW}</td>
												<td>{item?.APOW===null?'error':item?.APOW}</td>
												<td>{item?.PF===null?'error':item?.PF}</td>
												<td>{item?.FRQ===null?'error':item?.FRQ}</td>
												<td>{item?.KWH===null?'error':item?.KWH}</td>
												<td>{item?.METERSRNO==='null'?'-':item?.METERSRNO}</td>
												<BootstrapTooltip
													title={
														<div>
															IMEI:{item.IMEI}
															<br />
															MBSLAVEID:{item.MBSLAVEID}
															<br />
															SCRIPTCODE:{item.SCRIPTCODE}
															<br />
															CONSUMER UNIQUE ID:{item.CONSUMER_UNIQUE_ID}
														</div>
													}
													placement="top"
												>
													<td>Details:</td>
												</BootstrapTooltip>
												<td>{moment.utc(item.ENTRYTS, "", "Asia/Kolkata").format("DD/MM/YYYY HH:mm:ss")}</td>
											</tr>
										)):''}
								</tbody>
							</table>
							{Instantaneous.length===0?<h4 className="text-center">No Data Found</h4>:''}

						</div>
					</div>
				)}
				
				{(btnText==="LoadSurvey"&&LoadeSurveydata.length > 0 )|| (btnText=='Midnight'&&Midnightdata.length > 0) || (btnText==='Instantaneous'&&Instantaneous.length > 0) ? (<><div className='d-flex justify-content-between'>
    <div>Total Number Of Row {numberofPage}</div><Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={numberofPage||1}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      /></div></>):''}
			</div>
		</>
	);
}
