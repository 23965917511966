import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { getChartDateByTableName, getChartDateByTableNameWithDate, getConsumerSlavesdetailsByConsumerId, getLetestBufferdata, getStartAndEndDateByTable, getTotalChartData, GsmViewUserData } from "../Apicommon";
import $ from "jquery";
import moment from "moment";
import Consumerinfo from "./Consumerinfo";
import Slavedata from "./Slavedata";
import { BootstrapTooltip } from "../common";
import { Briefcase } from "react-feather";
import signal2 from "../assets/img/secondsignal.png";
import signal3 from "../assets/img/signal3.png";
import signal4 from "../assets/img/signal4.png";
import signal5 from "../assets/img/signal5.png";
import ApexCharts from 'apexcharts'
import Chart from 'react-apexcharts'
import { DatePicker, Modal, Space } from "antd";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';



export default function Viewuser() {
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		BarElement,
		Title,
		Tooltip,
		Filler,
		Legend,
		zoomPlugin, ChartDataLabels,
		Decimation, TimeScale
	);
	const buttonArray = ["Dashboard", "Consumer Info", "Configuration", "Slave Data"];

	const [btnText, setButtontext] = useState("Dashboard");
	const [viewData, setViewData] = useState({});
	const [loader, setloader] = useState(false);
	const [consumerSlave, setconsumerSlave] = useState({});
	const [loadFlag,setLoadFlag]=useState(false)
	const { consumer_id } = useParams();
	const history = useHistory();
	const location = useLocation();
	var [IBData,setIBData]=useState([])
	var [IRData,setIRData]=useState([])
	var [IYData,setIYData]=useState([])
	var [PFAVG,setPFAVG]=useState([])
	var [PFMIN,setPFMIN]=useState([])
	var [PFMAX,setPFMAX]=useState([])
	var [POWAVG,setPOWAVG]=useState([])
	var [POWMIN,setPOWMIN]=useState([])
	var [POWMAX,setPOWMAX]=useState([])
	var [APOWAVG,setAPOWAVG]=useState([])
	var [APOWMIN,setAPOWMIN]=useState([])
	var [APOWMAX,setAPOWMAX]=useState([])
	var [dateData,setdateData]=useState([])
	var [datePFData,setdatePFData]=useState([])
	var [datePOWData,setdatePOWData]=useState([])
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [ModelType,setModelType]=useState('')
	const [ExpandData,setExpandData]=useState({data:{label:[],datasets:[]},options:{}})
	const [dates, setDates] = useState({ start: "", end: "" });
	//pofile0
	const [expireDate,setExpireDate]=useState({})
	const [filterChartflag,setfilterChartflag]=useState(false)
	const [PF,setPF]=useState([])
	const [POW,setPOW]=useState([])
	var [VYN,setVYN]=useState([])
	var [VRN,setVRN]=useState([])
	var [VBN,setVBN]=useState([])
	const [APOW,setAPOW]=useState([])
	const [loadTotalData,setloadTotalData]=useState([])
	const [loadTotalDate,setloadTotalDate]=useState([])
	const [midTotalData,setMidTotalData]=useState([])
	const [midTotalDate,setMidTotalDate]=useState([])
	const [instantTotalData,setinstantTotalData]=useState([])
	const [instantTotalDate,setinstantTotalDate]=useState([])
	const [BufferData,setBufferData]=useState({})
	const viewgetuserdata = async () => {
		setloader(true);
		$(".loader").fadeOut("slow");
		const resultData = await GsmViewUserData(consumer_id);
		if (resultData.success === true) {
			setViewData(resultData.data.data[0]);
			setloader(false);
		} else {
			setloader(false);
		}
	};
	
	var series= [{
		name: 'IRData',
		data: IRData
	  }, {
		name: 'IYData',
		data: IYData
	  },{
		name: 'IBData',
		data: IBData
	  }]
	  var seriesPF= [{
		name: 'PFAVG',
		data: PFAVG
	  }, {
		name: 'PFMAX',
		data: PFMAX
	  }, {
		name: 'PFMIN',
		data: PFMIN
	  }]
	  var seriesPOW= [{
		name: 'KWAVG',
		data: POWAVG
	  }, {
		name: 'KWMAX',
		data: POWMAX
	  }, {
		name: 'KWMIN',
		data: POWMIN
	  }]
	  var seriesAPOW= [{
		name: 'KVAAVG',
		data: APOWAVG
	  }, {
		name: 'KVAMAX',
		data: APOWMAX
	  }, {
		name: 'KVAMIN',
		data: APOWMIN
	  }]
	  var seriesV= [ {
		name: 'VRN',
		data: VRN
	  }, {
		name: 'VYN',
		data: VYN
	  },{
		name: 'VBN',
		data: VBN
	  }]
	  var seriesOnlyPF=[{
		name: 'Pf',
		data: PF
	  }]
	  var seriesOnlyPOW=[{
		name: 'KW',
		data: POW
	  }]
	  var seriesOnlyAPOW=[{
		name: 'KVA',
		data: APOW
	  }]
	  var seriesOnlyload=[{
		name: 'Load Survey',
		data: loadTotalData
	  }]
	  var seriesOnlymid=[{
		name: 'MidNight',
		data: midTotalData
	  }]
	  var seriesOnlyinstant=[{
		name: 'Instantaneous',
		data: instantTotalData
	  }]
	 var options3= {
		chart: {
		  height: 350,
		  type: 'area'
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth',
		//   lineCap: 'butt',
		  width: [0,0],
		},
		xaxis: {
		  categories: dateData ||datePFData||datePOWData,
		  labels: {
			rotateAlways: true,
			rotate: -60,
		  },
		  

		},
		tooltip: {
		  x: {
			format: 'dd/MM/yy'
		  },
		},
		animations: {
			enabled: false
			},
			noData: {  
				text: "Loading...",  
				align: 'center',  
				verticalAlign: 'middle',  
				offsetX: 0,  
				offsetY: 0,  
				style: {  
				  color: "#000000",  
				  fontSize: '14px',  
				  fontFamily: "Helvetica"  
				}  
			  },
			  colors:['#fc544b','#ffa426','#0000ff']
	}
	var optionsPF= {
		chart: {
		  height: 350,
		  type: 'area'
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth'
		},
		xaxis: {
		  categories: datePFData,
		  labels: {
			rotateAlways: true,
			rotate: -60,
		  },
		  

		},
		tooltip: {
		  x: {
			format: 'dd/MM/yy'
		  },
		},
		animations: {
			enabled: false
			},
			noData: {  
				text: "Loading...",  
				align: 'center',  
				verticalAlign: 'middle',  
				offsetX: 0,  
				offsetY: 0,  
				style: {  
				  color: "#000000",  
				  fontSize: '14px',  
				  fontFamily: "Helvetica"  
				}  
			  }
	}
	var optionsPOW= {
		chart: {
		  height: 350,
		  type: 'area'
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth'
		},
		xaxis: {
		  categories: datePOWData,
		  labels: {
			rotateAlways: true,
			rotate: -60,
		  },
		 
		},
		animations: {
			enabled: false
			},
		tooltip: {
		  x: {
			format: 'dd/MM/yy'
		  },
		},
		noData: {  
			text: "Loading...",  
			align: 'center',  
			verticalAlign: 'middle',  
			offsetX: 0,  
			offsetY: 0,  
			style: {  
			  color: "#000000",  
			  fontSize: '14px',  
			  fontFamily: "Helvetica"  
			}  
		  },
		  

	}
	var optionsV= {
		chart: {
		  height: 350,
		  type: 'area'
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth'
		},
		xaxis: {
		  categories: datePOWData,
		  labels: {
			rotateAlways: true,
			rotate: -60,
		  },
		 
		},
		animations: {
			enabled: false
			},
		tooltip: {
		  x: {
			format: 'dd/MM/yy'
		  },
		},
		noData: {  
			text: "Loading...",  
			align: 'center',  
			verticalAlign: 'middle',  
			offsetX: 0,  
			offsetY: 0,  
			style: {  
			  color: "#000000",  
			  fontSize: '14px',  
			  fontFamily: "Helvetica"  
			}  
		  },
		  colors:['#fc544b','#ffa426','#0000ff']

	}
	var optionsAPWO= {
		chart: {
			type: 'area',
			height: 350
		  },
		  plotOptions: {
			bar: {
			  horizontal: false,
			  columnWidth: '55%',
			  endingShape: 'rounded'
			},
		  },
		  dataLabels: {
			enabled: false
		  },
		  stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		  },
		  xaxis: {
			categories: dateData,
			labels: {
				rotateAlways: true,
				rotate: -60,
			  },
		  },
		  fill: {
			opacity: 1
		  },
		  tooltip: {
			x: {
				format: 'dd/MM/yy'
			  },
		  },
		  animations: {
			enabled: false
			},
			noData: {  
				text: "Loading...",  
				align: 'center',  
				verticalAlign: 'middle',  
				offsetX: 0,  
				offsetY: 0,  
				style: {  
				  color: "#000000",  
				  fontSize: '14px',  
				  fontFamily: "Helvetica"  
				}  
			  }
	}
	var optionsLoad= {
		chart: {
			type: 'bar',
			height: 350
		  },
		  plotOptions: {
			bar: {
			  horizontal: false,
			  columnWidth: '55%',
			  endingShape: 'rounded'
			},
		  },
		  dataLabels: {
			enabled: false
		  },
		  stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		  },
		  xaxis: {
			categories: loadTotalDate,
			labels: {
				rotateAlways: true,
				rotate: -60,
			  },
		  },
		  fill: {
			opacity: 1
		  },
		  tooltip: {
			x: {
				format: 'dd/MM/yy'
			  },
		  },
		  dataLabels: {
			enabled: true,
			position: 'top',
			formatter: function (val) {
			return val ;
		  },
			horizontal: true,
			offsetX: 0,
			style: {
				fontSize: '10px',
				colors: ['#ffffff']
			}
		},
		  animations: {
			enabled: false
			},
			noData: {  
				text: "Loading...",  
				align: 'center',  
				verticalAlign: 'middle',  
				offsetX: 0,  
				offsetY: 0,  
				style: {  
				  color: "#000000",  
				  fontSize: '14px',  
				  fontFamily: "Helvetica"  
				}  
			  }
	}
	var optionsmid= {
		chart: {
			type: 'bar',
			height: 350
		  },
		  plotOptions: {
			bar: {
			  horizontal: false,
			  columnWidth: '55%',
			  endingShape: 'rounded'
			},
		  },
		  dataLabels: {
			enabled: false
		  },
		  stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		  },
		  xaxis: {
			categories: midTotalDate,
			labels: {
				rotateAlways: true,
				rotate: -60,
			  },
		  },
		  fill: {
			opacity: 1
		  },
		  tooltip: {
			x: {
				format: 'dd/MM/yy'
			  },
		  },
		  dataLabels: {
			enabled: true,
			position: 'top',
			formatter: function (val) {
			return val ;
		  },
			horizontal: true,
			offsetX: 0,
			style: {
				fontSize: '10px',
				colors: ['#ffffff']
			}
		},
		  animations: {
			enabled: false
			},
			noData: {  
				text: "Loading...",  
				align: 'center',  
				verticalAlign: 'middle',  
				offsetX: 0,  
				offsetY: 0,  
				style: {  
				  color: "#000000",  
				  fontSize: '14px',  
				  fontFamily: "Helvetica"  
				}  
			  }
	}
	var optionsItant= {
		chart: {
			type: 'bar',
			height: 350
		  },
		  plotOptions: {
			bar: {
			  horizontal: false,
			  columnWidth: '55%',
			  endingShape: 'rounded'
			},
		  },
		  dataLabels: {
			enabled: false
		  },
		  stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		  },
		  dataLabels: {
			enabled: true,
			position: 'top',
			formatter: function (val) {
			return val ;
		  },
			horizontal: true,
			offsetX: 0,
			style: {
				fontSize: '10px',
				colors: ['#ffffff']
			}
		},
		  xaxis: {
			categories: instantTotalDate,
			labels: {
				rotateAlways: true,
				rotate: -60,
			  },
		  },
		  fill: {
			opacity: 1
		  },
		  tooltip: {
			x: {
				format: 'dd/MM/yy'
			  },
		  },
		  animations: {
			enabled: false
			},
			noData: {  
				text: "Loading...",  
				align: 'center',  
				verticalAlign: 'middle',  
				offsetX: 0,  
				offsetY: 0,  
				style: {  
				  color: "#000000",  
				  fontSize: '14px',  
				  fontFamily: "Helvetica"  
				}  
			  }
	}
	const ongetChartData = async (tableName) => {
			if(location?.state?.payload?.meter_profile_U===1){
		const data = await getChartDateByTableName({ tableName: tableName });
		if(data.success===true){
			 setIBData(await data.data.data.map((item)=>(item?.IBAVG||0)))
			 setIRData(await data.data.data.map((item)=>(item?.IRAVG||0)))
			 setIYData(await data.data.data.map((item)=>(item?.IYAVG||0)))
			 setdateData(await data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
			  onHandalePFChart(data.data.data)
			  onHandalePOWChart(data.data.data)
			  onHandaleAPOWChart(data.data.data)
		}else{
			console.log('something wait wrong')
		}
	}else{
		const data = await getChartDateByTableName({ tableName: location?.state?.payload?.instantaneous_S });
		if(data.success===true){
			setIBData(await data.data.data.map((item)=>(item?.IB||0)))
			setIRData(await data.data.data.map((item)=>(item?.IR||0)))
			setIYData(await data.data.data.map((item)=>(item?.IY||0)))
			setdateData(await data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
			setPF(await data.data.data.map((item)=>(item?.PF||0)))
	 setdatePFData(await data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
            setPOW(await data.data.data.map((item)=>(item?.POW||0)))
	 setdatePOWData(await data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
	 setVYN(await data.data.data.map((item)=>(item?.VYN||0)))
	 setVRN(await data.data.data.map((item)=>(item?.VRN||0)))
	 setVBN(await data.data.data.map((item)=>(item?.VBN||0)))
	 setAPOW(await data.data.data.map((item)=>(item?.APOW||0)))
	 setdatePOWData(await data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))

		}else{
			console.log('something wait wrong')
		}
	}
	
	};

	const onHandalePFChart=async(data)=>{
     setPFMIN(await data?.map((item)=>(item?.PFMIN||0)))
     setPFMAX(await data?.map((item)=>(item?.PFMAX||0)))
     setPFAVG(await data?.map((item)=>(item?.PFAVG||0)))
	 setdatePFData(await data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
	}

	const onHandalePOWChart=async(data)=>{
		setPOWMIN(await data?.map((item)=>(item?.POWMIN||0)))
		setPOWMAX(await data?.map((item)=>(item?.POWMAX||0)))
		setPOWAVG(await data?.map((item)=>(item?.POWAVG||0)))
		setdatePOWData(await data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
	   }

	   const onHandaleAPOWChart=async(data)=>{
		setAPOWMIN(await data?.map((item)=>(item?.APOWMIN||0)))
		setAPOWMAX(await data?.map((item)=>(item?.APOWMAX||0)))
		setAPOWAVG(await data?.map((item)=>(item?.APOWAVG||0)))
		setdateData(await data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
	   }

	   const onGetChartTotalDate=async()=>{
		if(location?.state?.payload?.meter_profile_U===1){
		const data=await getTotalChartData({tableName:location?.state?.payload?.LOADSURVEY_S})
		if(data.success===true){
			const data2=await getTotalChartData({tableName:location?.state?.payload?.MIDNIGHT_S})
			if(data2.success===true){
				setloadTotalData(data.data.data.map((item)=>(item.count)))
				setloadTotalDate(data.data.data.map((item)=>(moment(item.ENTRYTS,"YYYY-M-DD").format('DD/MM/YYYY'))))
				setMidTotalData(data2.data.data.map((item)=>(item.count)))
				setMidTotalDate(data2.data.data.map((item)=>(moment(item.ENTRYTS,"YYYY-M-DD").format('DD/MM/YYYY'))))

			}else{
				
			}
		}
		}else{
			const data=await getTotalChartData({tableName:location?.state?.payload?.instantaneous_S})
		if(data.success===true){
			const data2=await getTotalChartData({tableName:location?.state?.payload?.MIDNIGHT_S})
			if(data2.success===true){
				setinstantTotalData(data.data.data.map((item)=>(item.count)))
				setinstantTotalDate(data.data.data.map((item)=>(moment(item.ENTRYTS,"YYYY-M-DD").format('DD/MM/YYYY'))))
				setMidTotalData(data2.data.data.map((item)=>(item.count)))
				setMidTotalDate(data2.data.data.map((item)=>(moment(item.ENTRYTS,"YYYY-M-DD").format('DD/MM/YYYY'))))
			}
		}
	}
	   }
	
	   const ongetLetestBufferdata=async()=>{
		console.log(location?.state);
		const data=await getLetestBufferdata(location?.state?.payload?.CONSSLAVEUID_S)
		if(data.success===true){
			setBufferData(data.data.data[0])
		}else{
			console.log('something went wrong');
		}
	   }

	useEffect(() => {
		// console.log(location.state)
		// viewgetuserdata();
		// ongetConsumerSlavesdetailsByConsumerId({})
		setconsumerSlave(location.state);
		ongetChartData(location?.state?.payload?.LOADSURVEY_S);
		onGetChartTotalDate()
		ongetLetestBufferdata()
	}, [location]);
	
const ongetConsumerSlavesdetailsByConsumerId=async()=>{
	const data=await getConsumerSlavesdetailsByConsumerId()
	if(data.success===true){
		setconsumerSlave(data.data.data);
	}else{
		console.log('something want wrong')
	}
}
	useEffect(() => {
		setLoadFlag(true)
		viewgetuserdata();
	}, []);

	const ongetChartDateWite=async ()=>{
		if(dates.start!==''&&dates.end!==''){
			setfilterChartflag(true)
// const data=await getChartDateByTableNameWithDate({...dates,tableName:location?.state?.payload?.instantaneous_S})
// console.log(data)

	var data = {}
	if(location?.state?.payload?.meter_profile_U===1){
		if(ModelType=='Load Survey Chart'||ModelType=='MidNight Chart'){
			data=	await getTotalChartData({...dates,tableName:ModelType=='MidNight Chart'?location?.state?.payload?.MIDNIGHT_S:location?.state?.payload?.LOADSURVEY_S,type:'date'});
		}else{
		data=await getChartDateByTableNameWithDate({...dates,tableName:location?.state?.payload?.LOADSURVEY_S})
		}
	} else{
		if(ModelType=='Load Survey Chart'||ModelType=='MidNight Chart'){
			data=	await getTotalChartData({...dates,tableName:location?.state?.payload?.instantaneous_S,type:'date'});
		}else{
		data=	await getChartDateByTableNameWithDate({...dates,tableName:ModelType=='MidNight Chart'?location?.state?.payload?.MIDNIGHT_S:location?.state?.payload?.instantaneous_S});
		}
	}
	if(data?.success===true){
		var options= {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				
			},
			animation: false,
			plugins: {
				legend: {
					position: 'bottom',
					fullSize: false,
					labels: {
						pointStyleWidth: 10,
						usePointStyle: false,
						pointStyle: 'rectRounded',
						
						font: function (context) {
							var w = context.chart.width;
							return {
								size: w < 512 ? 8 : 11,
	
							};
						},
					}
				},
				zoom: {
					zoom: {
						wheel: {
							enabled: true,
							modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
						},
						mode: "x",
					},
					pan: {
						enabled: true,
						mode: "x",
					}
				},
				datalabels: {
					align: 'end',
					anchor: 'end',
					clamp: true,
					color: "black",
					padding: {
						top: 0,
						bottom: 0
					},
					font: function (context) {
						var w = context.chart.width;
						return {
							size: w < 512 ? 10 : 12,
	
						};
					},
	
				}
			},
			layout: {
				padding: {
					left: 50,
					right: 50,
					top: 20,
				}
			},
		}
		if(ModelType=='Current Data Chart'){
			setExpandData({data:{
				labels: data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))),
				datasets: [
					{
						label: 'IRData',
						data: data.data.data.map((item)=>(item?.IB||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(252, 84, 75)',
						borderRadius: 5,
						borderColor:'rgb(252, 84, 75)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(253 198 198)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label:'IYData',
						data:  data.data.data.map((item)=>(item?.IR||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(255, 164, 38)',
						borderRadius: 5,
						borderColor:'rgb(255, 164, 38)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(254 226 190)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label: 'IBData',
						data: data.data.data.map((item)=>(item?.IY||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(0, 0, 255)',
						borderRadius: 5,
						borderColor:'rgb(0, 0, 255)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(178 165 254)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					}]},options:{...options,interaction: {mode: 'index',intersect: false}}})
		}else if(ModelType=='PF Chart'){
			setExpandData({data:{
				labels: data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))),
				datasets: [
					{
						label: 'PFMIN',
						data: data.data.data?.map((item)=>(item?.PFMIN||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(252, 84, 75)',
						borderRadius: 5,
						borderColor:'rgb(252, 84, 75)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(253 198 198)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label:'PFMAX',
						data:  data.data.data?.map((item)=>(item?.PFMAX||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(255, 164, 38)',
						borderRadius: 5,
						borderColor:'rgb(255, 164, 38)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(254 226 190)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label: 'PFAVG',
						data: data.data.data?.map((item)=>(item?.PFAVG||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(0, 0, 255)',
						borderRadius: 5,
						borderColor:'rgb(0, 0, 255)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(178 165 254)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					}]},options:{...options,interaction: {mode: 'index',intersect: false}}})
		}else if(ModelType=='KW Chart'){
			setExpandData({data:{
				labels: data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))),
				datasets: [
					{
						label: 'KWMIN',
						data: data.data.data.map((item)=>(item?.POWMIN||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(252, 84, 75)',
						borderRadius: 5,
						borderColor:'rgb(252, 84, 75)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(253 198 198)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label:'KWMAX',
						data:  data.data.data.map((item)=>(item?.POWMAX||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(255, 164, 38)',
						borderRadius: 5,
						borderColor:'rgb(255, 164, 38)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(254 226 190)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label: 'KWAVG',
						data: data.data.data.map((item)=>(item?.POWAVG||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(0, 0, 255)',
						borderRadius: 5,
						borderColor:'rgb(0, 0, 255)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(178 165 254)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					}]},options:{...options,interaction: {mode: 'index',intersect: false}}})
		}else if(ModelType=='KVA Chart'){
			setExpandData({data:{
				labels: data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))),
				datasets: [
					{
						label: 'KVAMIN',
						data: data.data.data.map((item)=>(item?.APOWMIN||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(252, 84, 75)',
						borderRadius: 5,
						borderColor:'rgb(252, 84, 75)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(253 198 198)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label:'KVAMAX',
						data:  data.data.data.map((item)=>(item?.APOWMAX||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(255, 164, 38)',
						borderRadius: 5,
						borderColor:'rgb(255, 164, 38)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(254 226 190)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					},{
						label: 'KVAAVG',
						data: data.data.data.map((item)=>(item?.APOWAVG||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(0, 0, 255)',
						borderRadius: 5,
						borderColor:'rgb(0, 0, 255)',
						pointRadius: 5,
						pointHoverRadius: 7,
						fill: {
							target: 'origin',
							above: 'rgb(178 165 254)',   // Area will be red above the origin
							below: '#000000'    // And blue below the origin
						  }
					}]},options:{...options,interaction: {mode: 'index',intersect: false}}})
		}else if(ModelType=='Load Survey Chart'){
			setExpandData({data:{
				labels: data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))),
				datasets: [
					{
						label: 'Load Survay',
						data: data.data.data.map((item)=>(item?.count||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(0, 143, 251)',
						borderRadius: 5,
						borderColor:'rgb(0, 143, 251)',
						pointRadius: 5,
						pointHoverRadius: 7
					}]},options:{
						// parsing: false,
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								// type: 'time',
								ticks: {
				
									maxRotation: 90,
									minRotation: 90,
									font: {
										size: 10
									},
				
								}
							},
				
							y: {
								beginAtZero: true
							},
						},
				
						// parsing: false,
						plugins: {
							decimation: {
								enabled: true,
								algorithm: 'lttb',
								samples: 800,
								threshold: 800
							},
							legend: {
								position: 'bottom',
								labels: {
									pointStyleWidth: 10,
									usePointStyle: true,
									pointStyle: 'rectRounded'
								}
							},
							zoom: {
								zoom: {
									wheel: {
										enabled: true,
										modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
									},
									mode: "x",
								},
								pan: {
									enabled: true,
									mode: "x",
								}
							},
							datalabels: {
								backgroundColor: function (context) {
									return context.dataset.borderColor;
								},
								borderRadius: 4,
								align: 'end',
								anchor: 'end',
								clamp: true,
								color: "white",
								padding: {
									top: 0,
									bottom: 0
								},
								font: function (context) {
									var w = context.chart.width;
									return {
										size: w < 512 ? 10 : 12,
				
									};
								},
				
							}
						},
						elements: {
							line: {
								fill: true,
								tension: 0.3
							}
						},
						layout: {
							padding: {
								top: 20
							}
						},
					}})
		}else if(ModelType=='MidNight Chart'){
			setExpandData({data:{
				labels: data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))),
				datasets: [
					{
						label: 'MidNight',
						data: data.data.data.map((item)=>(item?.count||0)),
						barPercentage: 1.0,
						backgroundColor: 'rgb(0, 143, 251)',
						borderRadius: 5,
						borderColor:'rgb(0, 143, 251)',
						pointRadius: 5,
						pointHoverRadius: 7
					}]},options:{
						// parsing: false,
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							x: {
								// type: 'time',
								ticks: {
				
									maxRotation: 90,
									minRotation: 90,
									font: {
										size: 10
									},
				
								}
							},
				
							y: {
								beginAtZero: true
							},
						},
				
						// parsing: false,
						plugins: {
							decimation: {
								enabled: true,
								algorithm: 'lttb',
								samples: 800,
								threshold: 800
							},
							legend: {
								position: 'bottom',
								labels: {
									pointStyleWidth: 10,
									usePointStyle: true,
									pointStyle: 'rectRounded'
								}
							},
							zoom: {
								zoom: {
									wheel: {
										enabled: true,
										modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
									},
									mode: "x",
								},
								pan: {
									enabled: true,
									mode: "x",
								}
							},
							datalabels: {
								backgroundColor: function (context) {
									return context.dataset.borderColor;
								},
								borderRadius: 4,
								align: 'end',
								anchor: 'end',
								clamp: true,
								color: "white",
								padding: {
									top: 0,
									bottom: 0
								},
								font: function (context) {
									var w = context.chart.width;
									return {
										size: w < 512 ? 10 : 12,
				
									};
								},
				
							}
						},
						elements: {
							line: {
								fill: true,
								tension: 0.3
							}
						},
						layout: {
							padding: {
								top: 20
							}
						},
					}})
		}
		
		//  setdateData(await data.data.data.map((item)=>(moment.utc(item.ENTRYTS).format('DD/MM/YYYY HH:mm:ss'))))
		//   onHandalePFChart(data.data.data)
		//   onHandalePOWChart(data.data.data)
		//   onHandaleAPOWChart(data.data.data)
		//   setIsModalVisible(false)
		  setfilterChartflag(false)
	}else{
		console.log('something wait wrong')
		// setfilterChartflag(false)
		setIsModalVisible(false)
	}

		}
	}

	const onHandalebtn = (text) => {
		setButtontext(text);
	};

	const onChange = (date, dateString) => {
		setDates({ ...dates, start: moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD') });
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end:  moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD') });
	};
	 
	const showModal =async (type) => {
		const data=await getStartAndEndDateByTable({ tableName:`${location?.state?.payload?.meter_profile_U===1? location?.state?.payload?.LOADSURVEY_S:''}${location?.state?.payload?.meter_profile_U===0? location?.state?.payload?.instantaneous_S:''}` })
		if(data.success===true){
			setIsModalVisible(!isModalVisible);
			setExpireDate(data.data)
			setModelType(type)
	  }else{
		  console.log("something wait wrong")
	  }
	};
	const expandChartRef = useRef()
	const myChart = expandChartRef.current;
	let midValue = 0
	if (myChart != undefined || myChart != null) {
		const xMin = myChart?.scales?.x?.min;
		const xMax = myChart?.scales?.x?.max;
		console.log("minMax == ", xMin, xMax)
		midValue = xMax / 2
	}

	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: -400 }, undefined, 'default');
		}
	}
	useEffect(()=>{
		setTimeout(() => {
			if (myChart != undefined || myChart != null) {
				myChart.zoomScale('x', { min: midValue - 3, max: midValue + 3 }, 'default')
				console.log("midaVAlue in setTimeout == ", Math.round(midValue))
				// myChart.zoom(1.2);
				// myChart.pan({
				// 	x: 500
				// }, undefined, 'default');
	
				// myChart.pan({
				// 	x: - (Number.MAX_SAFE_INTEGER)
				// }, undefined, 'default');
	
			}
		}, 20)
	},[myChart])
	return (
		<>
		<Modal title={ModelType} visible={isModalVisible} footer="" onCancel={() => {setIsModalVisible(false)
		setExpandData({data:{label:[],datasets:[]},options:{}})}} width={800}>
			<div className="d-flex">
				<Space direction="horizontal">
					<div className="d-flex flex-column">
						Start In
						<DatePicker onChange={onChange} name="start" format={'DD-MM-YYYY'}  disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }} />
					</div>
					<div className="d-flex flex-column">
						End In
						<DatePicker onChange={onChanges} name="end" format={'DD-MM-YYYY'}  disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }}  />
					</div>
				</Space>
				
				<div className="mt-3 ml-3">
					<a className={`btn btn-info text-white ${filterChartflag?'btn-progress disabled':''}`} onClick={ongetChartDateWite}>Submit</a>
					<a className={`btn btn-danger text-white ml-2 ${filterChartflag?'disabled':''}`} onClick={()=>(ongetChartData(location?.state?.payload?.LOADSURVEY_S))}>Reset</a>
				</div>
				</div>
				<div style={{ position: "relative", height: "500px" }}>
				<div>
							<div className="mb-1 d-flex justify-content-end">
								<small className='text-red'><sup className="text-sm top-0">*</sup> You can also do Zoom and Scroll using your mouse</small>
							</div>
							<div className="d-flex justify-content-end items-center">
								<button className={`btn btn-primary`}  onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`btn btn-primary ml-2`}  onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="btn btn-primary ml-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="btn btn-primary ml-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>
						{ModelType=='Load Survey Chart'?<Bar options={ExpandData?.options||{}} data={ExpandData?.data||{}} ref={expandChartRef}/>:<Line options={ExpandData?.options||{}} data={ExpandData?.data||{}} ref={expandChartRef}/>}
				</div>
			</Modal>
			<div className="main-content" style={{ minHeight: "562px" }}>
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>

						<li className="breadcrumb-item">
							<Link to="/gsm-modem">
								<Briefcase style={{ height: "16px" }} /> Consumer
							</Link>
						</li>

						<li className="breadcrumb-item">
							<Link to="/gsm-modem">
								<i className="fas fa-list"></i> GSM Modem
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/consumer-meter-details/${consumer_id}`}>Slaves Device</Link>
						</li>
						<li className="breadcrumb-item">
							Consumer Details
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
								<div className="card" style={{ height: "88%" }}>
									<div class="card-header">
										<h4>Modem Details</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-5">
												<b>Consumer Unique ID : </b>
												{viewData.UNIQUE_ID_S}
												<br />
												<b>Consumer Modem Name : </b> {viewData.CONSUMER_MODEM_NAME_U}
												<br />
												<b>Last Seen : </b>
												{moment.utc(viewData.last_seen).format("DD/MM/YYYY hh:mm:ss a")}
											</div>
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<b>IMEI : </b>
												{viewData.IMEI_U}
												<br />
												<b>Consumer Modem Serial No : </b>
												{viewData.CONSUMER_MODEM_SRNO_U}
												<br />
												<b>Consumer Status : </b>
												<div className="badge badge-success badge-shadow text-white">Active</div>
												<br />
											</div>
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
												<BootstrapTooltip
													title={`RSSI:${
														viewData?.RSSI >= 2 && viewData?.RSSI <= 9
															? "Marginal"
															: viewData?.RSSI >= 10 && viewData?.RSSI <= 14
															? "Ok"
															: viewData?.RSSI >= 15 && viewData?.RSSI <= 19
															? "Good"
															: viewData?.RSSI >= 20 && viewData?.RSSI <= 31
															? "Excellent"
															: ""
													}`}
													placement="top"
												>
													<span>
														<b>RSSI : </b>
														{viewData?.RSSI >= 2 && viewData?.RSSI <= 9 ? (
															<>
																<img
																	src={signal2}
																	style={{
																		height: "20px",
																		width: "30px",
																		border: "0",
																		boxShadow: "none",
																		borderRadius: "none",
																	}}
																/>
															</>
														) : viewData?.RSSI >= 10 && viewData?.RSSI <= 14 ? (
															<>
																<img
																	src={signal3}
																	style={{
																		height: "20px",
																		width: "30px",
																		border: "0",
																		borderRadius: "0",
																		boxShadow: "none",
																	}}
																/>
															</>
														) : viewData?.RSSI >= 15 && viewData?.RSSI <= 19 ? (
															<>
																<img
																	src={signal4}
																	style={{
																		height: "20px",
																		width: "30px",
																		border: "0",
																		borderRadius: "0",
																		boxShadow: "none",
																	}}
																/>
															</>
														) : viewData?.RSSI >= 20 && viewData?.RSSI <= 31 ? (
															<>
																<img
																	src={signal5}
																	style={{
																		height: "20px",
																		width: "30px",
																		border: "0",
																		borderRadius: "0",
																		boxShadow: "none",
																	}}
																/>
															</>
														) : (
															"-"
														)}
														{viewData?.RSSI}
													</span>
												</BootstrapTooltip>
												<br />
												<b>SRNO : </b>
												{viewData.SRNO_S}
												<br />
												<b>Connectivity Indication: </b>
												<div
													className={`badge badge-${viewData.MASSAGE === "online" ? `success` : ""}${
														viewData.MASSAGE === "offline" ? `danger` : ""
													}${viewData.MASSAGE == null ? `warning` : ""} badge-shadow text-white`}
												>
													{viewData.MASSAGE === "online" && `online`}
													{viewData.MASSAGE === "offline" && `offline`}
													{viewData.MASSAGE == null && `Never Seen`}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
								<div className="card" style={{ height: "88%" }}>
									<div class="card-header">
										<h4>Consumer Slave Details</h4>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
											<b>Slaves ID : </b>
												{consumerSlave?.payload?.SLAVEID_U || "-"}
												<br />
												<b>Consumer Slave Unique ID : </b>
												{consumerSlave?.payload?.CONSSLAVEUID_S || "-"}
												<br />
												<b>Consumer Device Name : </b> {consumerSlave?.payload?.CONSDEVNAME_U || "-"}
											</div>
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<b>Script Decode Id : </b>
												{consumerSlave?.payload?.SCRIPTDECODEID_S || "-"}
												<br />
												{consumerSlave?.payload?.meter_profile_U===1?<>
												<b>Load Suravey : </b>
												{consumerSlave?.payload?.LOADSURVEY_S || "-"}</>:
												<>
												<b>Instantaneous : </b>
												{consumerSlave?.payload?.instantaneous_S || "-"}</>}
												<br />
											
											</div>
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<b>MidNight : </b>
												{consumerSlave?.payload?.MIDNIGHT_S || "-"}
												<br />
												<b>
													Last Seen Duration :{" "}</b>
													{moment.utc(consumerSlave?.last_seen).add(-moment().utcOffset(), "m").fromNow(true)}
												
												<br />

												<b>Last Seen :</b>{moment.utc(consumerSlave?.last_seen).format("DD/MM/YYYY hh:mm:ss a")} 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="col-12" style={{ marginTop: "20px" }}>
										<div className="buttons">
											{buttonArray.map((item, id) => (
												<div
													className={`text-white btn ${item === "Dashboard" && "btn-primary"} ${
														item === "Consumer Info" && "btn-secondary"
													} ${item === "Configuration" && "btn-danger"} ${item === "Slave Data" && "btn-info"} ${
														item === "Meter" && "btn-success"
													} ${item === "Buffer" && "btn-dark"} dashboard  ${
														buttonArray.findIndex((data) => data === btnText) === id ? "btn-lg btn-text-lg" : ""
													} `}
													style={{ marginBottom: "8px" }}
													onClick={() => onHandalebtn(item)}
												>
													{item}
												</div>
											))}
											<div class="float-right text-right">
												{/* {btnText==='Consumer Info'&&<div class="btn btn-info text-white btnedit"><BootstrapTooltip title='Edit' placement='top'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit"><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>} */}
												
													{/* <div class="btn btn-info text-white btndeactive defauldbtn" onClick={showModal}>
														<BootstrapTooltip title="Select Date" placement="top">
															<span
																data-toggle="tooltip"
																data-placement="bottom"
																title=""
																data-original-title="Inactive"
															>
																Select Date
															</span>
														</BootstrapTooltip>
													</div> */}
												<div
													onClick={() => {
														history.push("/gsm-modem");
													}}
													className="btn btn-info text-white float-right"
												>
													Back
												</div>
											</div>
										</div>
									</div>
									{/* <div className="card-body"> */}
									{btnText === "Slave Data" && <Slavedata view={consumerSlave} />}
									{/* </div> */}
									{/* <div className="card-body"> */}
									{/* </div> */}
								</div>
							</div>
						</div>
									{btnText === "Consumer Info" && <Consumerinfo view={consumerSlave} modemData={viewData} />}
						{btnText === "Dashboard" && (
							<>
							{/* <div class="row">
					<div class="col-12">
						<div class="card">
						<div class="card-header d-flex" style={{justifyContent:'space-between'}}>
											<h4></h4>
											<a class="btn btn-info text-white" onClick={()=>(ongetLetestBufferdata())}><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Refresh"><i class="fa fa-refresh mr-1 text-white"></i></span></a>
										</div>
							<div className="table-responsive">
							<table id="table_id" className="table table-striped">
													<thead>
													<tr>
															<th className="text-center">Column Name</th>
															<th>Value</th>
															</tr>
														
													</thead>
													<tbody>
													<tr><td className="text-center">Unique No</td><td>{BufferData?.id}</td></tr>
															<tr><td>CONFIGUNIQUEID</td><td>{BufferData?.CONFIGUNIQUEID}</td></tr>
															<tr><td>CONSUMER UNIQUE ID</td><td>{BufferData?.CONSUMER_UNIQUE_ID}</td></tr>
															<tr><td>FUNCODE1</td><td>{BufferData?.FUNCODE1}</td></tr>
															<tr><td>IMEI</td><td>{BufferData?.IMEI}</td></tr>
															<tr><td>IB</td><td>{BufferData?.IB}</td></tr>
															<tr><td>IR</td><td>{BufferData?.IR}</td></tr>
															<tr><td>IY</td><td>{BufferData?.IY}</td></tr>
															<tr><td>KWH</td><td>{BufferData?.KWH}</td></tr>
															<tr><td>PF</td><td>{BufferData?.PF}</td></tr>
															<tr><td>POW</td><td>{BufferData?.POW}</td></tr>
															<tr><td>RAWDATA</td><td>{BufferData?.RAWDATA}</td></tr>
															<tr><td>APOW</td><td>{BufferData?.APOW}</td></tr>
															<tr><td>SCRIPTCODE</td><td>{BufferData?.SCRIPTCODE}</td></tr>
															<tr><td>SF1REGGROUPCNT</td><td>{BufferData?.SF1REGGROUPCNT}</td></tr>
															<tr><td>STATUS</td><td>{BufferData?.STATUS}</td></tr>
															<tr><td>SUBFRAMECNT</td><td>{BufferData?.SUBFRAMECNT}</td></tr>
															<tr><td>UBPC</td><td>{BufferData?.UBPC}</td></tr>
															<tr><td>VBN</td><td>{BufferData?.VBN}</td></tr>
															<tr><td>VRN</td><td>{BufferData?.VRN}</td></tr>
															<tr><td>VYN</td><td>{BufferData?.VYN}</td></tr>
															<tr><td>Last Seen</td><td>{moment.utc(BufferData?.ENTRYTS,'','Asia/Kolkata').format("DD/MM/YYYY HH:mm:ss a")}</td></tr>
																
													</tbody>
												</table>
							</div>
							<div class="card-footer" style={{ display: "flex", justifyContent: "end", padding: "0px" }}>
								<span className="text-end mr-4">
									{moment.utc(BufferData?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm')}
								</span>
							</div>
						</div>
					</div>
				</div> */}
							<div class="row">
							<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
						<div class="card">
						<div class="card-header d-flex" style={{justifyContent:'space-between'}}>
											<h4>Live Buffer Data</h4>
											<a class="btn btn-info text-white" onClick={()=>(ongetLetestBufferdata())}><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Refresh"><i class="fa fa-refresh mr-1 text-white"></i></span></a>
										</div>
							<div className="table-responsive" style={{height:'375px'}}>
							<table id="table_id" className="table table-striped">
													<thead>
													<tr>
															<th className="text-center">Column Name</th>
															<th style={{minWidth:'100px'}}>Value</th>
															</tr>
														
													</thead>
													<tbody>
													
															<tr><td className="text-center">IB</td><td>{BufferData?.IB===null?'error':BufferData?.IB}</td></tr>
															<tr><td className="text-center">IR</td><td>{BufferData?.IR===null?'error':BufferData?.IR}</td></tr>
															<tr><td className="text-center">IY</td><td>{BufferData?.IY===null?'error':BufferData?.IY}</td></tr>
															<tr><td className="text-center">VBN</td><td>{BufferData?.VBN===null?'error':BufferData?.VBN}</td></tr>
															<tr><td className="text-center">VRN</td><td>{BufferData?.VRN===null?'error':BufferData?.VRN}</td></tr>
															<tr><td className="text-center">VYN</td><td>{BufferData?.VYN===null?'error':BufferData?.VYN}</td></tr>
															<tr><td className="text-center">KW</td><td>{BufferData?.POW===null?'error':BufferData?.POW}</td></tr>
															<tr><td className="text-center">KVA</td><td>{BufferData?.APOW===null?'error':BufferData?.APOW}</td></tr>
															<tr><td className="text-center">PF</td><td>{BufferData?.PF===null?'error':BufferData?.PF}</td></tr>
															<tr><td className="text-center">FRQ</td><td>{BufferData?.APOW===null?'error':BufferData?.FRQ}</td></tr>
															<tr><td className="text-center">KWH</td><td>{BufferData?.KWH===null?'error':BufferData?.KWH}</td></tr>
															
															<tr><td className="text-center">Last Seen</td><td>{moment.utc(BufferData?.ENTRYTS,'','Asia/Kolkata').format("DD/MM/YYYY HH:mm:ss")}</td></tr>
													</tbody>
												</table>
							</div>
							<div class="card-footer" style={{ display: "flex", justifyContent: "end", padding: "0px" }}>
								<span className="text-end mr-4">
									{moment.utc(BufferData?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm')}
								</span>
							</div>
						</div>
					</div>
							<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4><span className="mr-2">Current Data Chart</span></h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('Current Data Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>

										<Chart options={options3} series={series} type="area" height={350}/>
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
											Line chart
										</p>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>PF Chart</h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('PF Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										{consumerSlave?.payload?.meter_profile_U===1?<Chart options={optionsPF} series={seriesPF} type="area" height={350}/>:<Chart options={optionsPF} series={seriesOnlyPF} type="area" height={350}/>}
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
											Line chart
										</p>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>KW Chart</h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('KW Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										{consumerSlave?.payload?.meter_profile_U===1?<Chart options={optionsPOW} series={seriesPOW} type="area" height={350}/>:<Chart options={optionsPOW} series={seriesOnlyPOW} type="area" height={350}/>}
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
											Line chart
										</p>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>KVA Chart</h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('KVA Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										{consumerSlave?.payload?.meter_profile_U===1?<Chart options={optionsAPWO} series={seriesAPOW} type="area" height={350}/>:<Chart options={optionsPOW} series={seriesOnlyAPOW} type="area" height={350}/>}
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
										{consumerSlave?.payload?.meter_profile_U===1?'Line chart':'Line Chart'}
										</p>
									</div>
								</div>
								{consumerSlave?.payload?.meter_profile_U===0?<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>Voltage Chart</h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('Voltage Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										<Chart options={optionsV} series={seriesV} type="line" height={350}/>
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
										{'Line Chart'}
										</p>
									</div>
								</div>:''}
								{consumerSlave?.payload?.meter_profile_U===1?<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>Load Survey Chart</h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('Load Survey Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										<Chart options={optionsLoad} series={seriesOnlyload} type="bar" height={350}/>
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
										{'Bar Chart'}
										</p>
									</div>
								</div>:<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>Instantaneous Chart</h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('Instantaneous Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										<Chart options={optionsItant} series={seriesOnlyinstant} type="bar" height={350}/>
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
										{'Bar Chart'}
										</p>
									</div>
								</div>}
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
									<div class="card">
										<div class="card-header" style={{padding: "10px 15px"}}>
											<h4>MidNight Chart</h4>
											<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => showModal('MidNight Chart')}>Expand</a>
										</div>
										</div>
										<div class="" style={{paddingTop:"5px",paddingBottom:"5px"}}>
										<Chart options={optionsmid} series={seriesOnlymid} type="bar" height={350}/>
										</div>
										<p class="text-right" style={{marginRight:"20px",lineHeight:"10px"}}>
										{'Bar Chart'}
										</p>
									</div>
								</div>
							</div>
							</>
						)}
					</div>
				</section>
			</div>
		</>
	);
}
