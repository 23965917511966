import React, { useEffect, useMemo, useRef, useState } from "react";
import { DatePicker, Space } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { Layers } from "react-feather";
import { BootstrapTooltip } from "../common";
import { bufferDataBYDate, DownloadTablebyDate, findHardBeatbyIMEI, getBufferdata, getStartAndEndDateByTable } from "../Apicommon";
import Pagination from "../CommonComponnet/Pagination";
import moment from "moment";
import { Modal } from "antd";
// import './Buffer.css'
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import signal2 from '../assets/img/secondsignal.png'
import signal3 from '../assets/img/signal3.png'
import signal4 from '../assets/img/signal4.png'
import signal5 from '../assets/img/signal5.png'
$.fn.DataTable = dt;

export default function Gsmheartbeat() {
	// $(document).ready(function () {
	//   $('#table_id').DataTable()
	// });
	const param = useParams();
	const [Bufferdata, setBufferdata] = useState([]);
	const [DownData, setDownData] = useState([]);

	const [loader, setloader] = useState(false);
	const [PageSize, setPageSize] = useState(50);
	const [dates, setDates] = useState({ start: "", end: "" });
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const csvLink = useRef();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [consumerDetails, setconsumerDetails] = useState({});
	const [btnDisable,setBtnDisable]=useState(false)
const [expireDate,setExpireDate]=useState({})
	const location = useLocation();

	const showModal =async () => {
		const data=await getStartAndEndDateByTable({tableName:`gsm_buffer_single`})
	  if(data.success===true){
		  setIsModalVisible(!isModalVisible);
		  setExpireDate(data.data)
	}else{
		console.log("something wait wrong")
	}
	};

	useEffect(() => {
		setconsumerDetails(location.state);
	}, [location]);

	const onHandalgsmheartbeat = async () => {
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		setloader(true);
		$(".loader").fadeOut("slow");
		const data = await findHardBeatbyIMEI({ start: firstPageIndex, end: PageSize }, param.IMEI);
		if (data.success === true) {
			setBufferdata(data.data.data);
			setnumberofPage(data.data.lengths);
			setloader(false);
		} else {
			setloader(false);
		}
	};

	const exportPDF = async () => {
		if (dates.start !== "" && dates.end !== "") {
			setBtnDisable(true)
			setIsModalVisible(false)
			const datas = await DownloadTablebyDate(dates, { tableName: 'gsm_heartbeat_single' });;
			if (datas.success === true) {
				const unit = "pt";
				const size = "A4"; // Use A1, A2, A3 or A4
				const orientation = "portrait"; // portrait or landscape

				const marginLeft = 40;
				const doc = new jsPDF(orientation, unit, size);

				doc.setFontSize(15);

				const title = ` GSMmodem  Data`;
				const headers = [
					[
						"Unique No",
						"IMEI",
						"RAWDATA",
						"RFU",
						"RSSI",
						"UBPC",
						"UPTIME",
					"ENTRYTS"
					],
				];

				const data = datas?.data?.data?.map((item, id) => [item?.SRNO, item.IMEI,item.RAWDATA,item.RFU,item.RSSI,item.UBPC,item.UPTIME,moment
					.utc(item?.ENTRYTS, "", "Asia/Kolkata")
					.format("DD/MM/YYYY HH:mm:ss")]);

				let content = {
					startY: 50,
					head: headers,
					body: data,
				};

				doc.text(title, marginLeft, 40);
				doc.autoTable(content);
				doc.save(`gsm_heartbeat.pdf`);
				setBtnDisable(false)
			} else {
				setBtnDisable(false)
			}
		}
	};

	const excelFileExport = async () => {
		if (dates.start !== "" && dates.end !== "") {
			setBtnDisable(true)
			setIsModalVisible(false)
			const datas = await DownloadTablebyDate(dates, { tableName: 'gsm_heartbeat_single' });
			if (datas.success === true) {
				const array=await datas.data.data.map((item)=>({SRNO:item?.SRNO, IMEI:item.IMEI,RAWDATA:item.RAWDATA,RFU:item.RFU,RSSI:item.RSSI,UBPC:item.UBPC,UPTIME:item.UPTIME,ENTRYTS:moment
					.utc(item?.ENTRYTS, "", "Asia/Kolkata")
					.format("DD/MM/YYYY HH:mm:ss")}))
				const worksheet = XLSX.utils.json_to_sheet(array);
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
				//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
				//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
				XLSX.writeFile(workbook, "gsm_heartbeat.xlsx");
				setBtnDisable(false)
			}else{
				setBtnDisable(false)

			}
		}
	};

	useEffect(() => {
		onHandalgsmheartbeat();
	}, [currentPage, PageSize]);

	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString });

		console.log(date, dateString);
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString });
		console.log(date, dateString);
	};



	const onDownloadcsv = async () => {
		if (dates.start !== "" && dates.end !== "") {
			setBtnDisable(true)
			setIsModalVisible(false)
			 await DownloadTablebyDate(dates, { tableName: 'gsm_heartbeat_single' }).then((datas)=>{
				if (datas.success === true) {
					setDownData(datas.data.data);
					
					function convertToCSV(arr) {
						const array = [Object.keys(arr[0])].concat(arr)
					  
						return array.map(it => {
						  return Object.values(it).toString()
						}).join('\n')
					  }
					const url = window.URL.createObjectURL(new Blob([convertToCSV(datas.data.data)],{ type: 'text/csv;charset=utf-8;' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'gsm_heartbeat.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
					setBtnDisable(false)
				} else {
					setBtnDisable(false)
	
				}
			})
			
			
		}
	};

// 	useEffect(()=>{
// if(DownData.length>0){
// 	console.log(DownData)
// 	csvLink.current.link.click();
// }
// 	},[DownData])

	return (
		<>
			<Modal title="download File" visible={isModalVisible} footer="" onCancel={() => setIsModalVisible(false)}>
				<Space direction="horizontal">
					<div className="d-flex flex-column">
						Start In
						<DatePicker onChange={onChange} name="start" disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }}/>
					</div>
					<div className="d-flex flex-column">
						End In
						<DatePicker onChange={onChanges} name="end" disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }}/>
					</div>
				</Space>
				<div className="mt-3">
					<a className={`btn btn-info text-white ${btnDisable?'disabled':''}`} onClick={exportPDF} >PDF</a>
					<a className={`btn btn-primary text-white ml-2 ${btnDisable?'disabled':''}`} onClick={excelFileExport}>Excel</a>
					<a className={`btn btn-danger text-white ml-2 ${btnDisable?'disabled':''}`} onClick={onDownloadcsv}>
						CSV
					</a>
					<CSVLink data={DownData} filename={"GSMHeartbeat.csv"} ref={csvLink} />
				</div>
			</Modal>
			<div className="main-content">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/gsm-modem">
								<Layers style={{ height: "16px" }} /> Consumer
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/gsm-modem">
								<i className="fas fa-list"></i> GSM Model
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<i className="fas fa-list"></i> GSM Heartbeat
							</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div class="card-header">
										<h4>Modem Details</h4>
									</div>
									<div className="card-body" style={{ paddingTop: "0px" }}>
										<div className="row">
											<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
												<b>Consumer Unique ID : </b>
												{consumerDetails?.payload?.UNIQUE_ID_S}
												<br />
												<b>Consumer Modem Name : </b> {consumerDetails?.payload?.CONSUMER_MODEM_NAME_U}
												<br />
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-5">
												<b>IMEI : </b>
												{consumerDetails?.payload?.IMEI_U}
												<b className="ml-5">Last Seen : </b>
												{moment.utc(consumerDetails?.last_seen).format("DD/MM/YYYY HH:mm:ss")}
												<br />
												<b>Consumer Modem Serial No : </b>
												{consumerDetails?.payload?.CONSUMER_MODEM_SRNO_U}
												<br />
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
												
												<b>Consumer Status : </b>
												<div className="badge badge-success badge-shadow text-white">Active</div>
												<br />
												<b>Connectivity Indication: </b>
												<div
													className={`badge badge-${consumerDetails?.MASSAGE === "online" ? `success` : ""}${
														consumerDetails?.MASSAGE === "offline" ? `danger` : ""
													}${consumerDetails?.MASSAGE == null ? `warning` : ""} badge-shadow text-white`}
												>
													{consumerDetails?.MASSAGE === "online" && `online`}
													{consumerDetails?.MASSAGE === "offline" && `offline`}
													{consumerDetails?.MASSAGE == null && `Never Seen`}
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-1">
												<BootstrapTooltip title={`RSSI:${consumerDetails?.RSSI >= 2 && consumerDetails?.RSSI <= 9?'Marginal':consumerDetails?.RSSI >= 10 && consumerDetails?.RSSI <= 14?'Ok':consumerDetails?.RSSI >= 15 && consumerDetails?.RSSI <= 19?'Good':consumerDetails?.RSSI >= 20 && consumerDetails?.RSSI <= 31?'Excellent':''	}`} placement="top"><span ><b>RSSI : </b>
																		{consumerDetails?.RSSI >= 2 && consumerDetails?.RSSI <= 9 ? (
																			<>
																				<img src={signal2} style={{height:'20px',width:"30px",border:'0',boxShadow:'none',borderRadius:'none'}}/>
																			</>
																		) : consumerDetails?.RSSI >= 10 && consumerDetails?.RSSI <= 14 ? (
																			<>
																			<img src={signal3} style={{height:'20px',width:"30px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			</>
																		) : consumerDetails?.RSSI >= 15 && consumerDetails?.RSSI <= 19 ? (
																			<>
																			<img src={signal4} style={{height:'20px',width:"30px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			</>
																		) : consumerDetails?.RSSI >= 20 && consumerDetails?.RSSI <= 31 ? (
																			<>
																			<img src={signal5} style={{height:'20px',width:"30px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			</> 
																		) : (
																			"-"
																		)}
																		{consumerDetails?.RSSI}</span>
																		</BootstrapTooltip>
																		
												<br />
												<b>SRNO : </b>
												{consumerDetails?.payload?.SRNO_S}
												<br />
												 {/* <b>Total Slave : </b>{JSON.parse(consumerDetails?.payload?.SLAVES_ID_U||{})?.DETAILS_U?.length}<br />  */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4>GSM Heartbeat</h4>
										<div className="card-header-action">
											<Link  className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus  text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2">
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													title="Download"
													onClick={showModal}
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												{/* <div className="dropdown-menu">
													<a className="dropdown-item" href="https://eums.memighty.com/Export/download_gsmconsumer_pdf">
														PDF
													</a>
													<a className="dropdown-item" href="https://eums.memighty.com/Export/exportexcelgsmconsumer">
														Excel
													</a>
													<a className="dropdown-item" href="https://eums.memighty.com/Export/exportcsvgsmconsumer">
														CSV
													</a>
												</div> */}
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<table id="table_id" className="table table-striped">
													<thead>
														<tr>
															<th className="text-center">Unique No</th>
															<th>IMEI</th>
															<th>RAWDATA</th>
															<th>RFU</th>
															<th>RSSI</th>
															<th>SRNO</th>
															<th>UBPC</th>
															<th>UPTIME</th>
															<th style={{ minWidth: "100px" }}>Last Seen</th>
														</tr>
													</thead>
													<tbody>
														{Bufferdata.length > 0 &&
															Bufferdata.map((item, id) => (
																<tr className="trheigth">
																	<td>{item?.SRNO}</td>
																	<td>{item?.IMEI}</td>
																	<td>{item?.RAWDATA}</td>
																	<td>{item?.RFU}</td>
																	<td>{item?.RSSI}</td>
																	<td>{item?.SRNO}</td>
																	<td>{item?.UBPC}</td>
																	<td>{item?.UPTIME}</td>
																	<td>
																		{moment
																			.utc(item?.ENTRYTS, "", "Asia/Kolkata")
																			.format("DD/MM/YYYY HH:mm:ss")}
																	</td>
																</tr>
															))}
													</tbody>
												</table>
												{Bufferdata.length === 0 && <h4 className="text-center">No Data Found</h4>}
											</div>
										</div>
										<Pagination
											className="pagination-bar"
											currentPage={currentPage}
											totalCount={numberofPage || 1}
											pageSize={PageSize}
											onPageChange={(page) => setCurrentPage(page)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
