import moment from "moment";
import React,{useEffect} from "react";

import {Switch,Route,Redirect,withRouter} from "react-router-dom"

const PrivetRoute = ({path,Component,history}) =>{
  // console.log(token);
  let token =window.localStorage.getItem("token");
  let logintime =window.localStorage.getItem("login_time");
  const current_time=moment().utcOffset("+05:30")
  useEffect(()=>{ 
    const diff_hours=current_time.diff(moment(logintime).utcOffset("+05:30"),'hours')
      console.log(current_time.diff(moment(logintime).utcOffset("+05:30"),'hours'))
      if(logintime==null||logintime==NaN||logintime==undefined||diff_hours>=24){
        window.localStorage.setItem('token', '')
        window.localStorage.setItem('user_info', JSON.stringify({}))
        window.localStorage.setItem('login_time','')
        history.push('/')
      }
    },[])
    return(
      <>
      
    {token&&token!=="" ? <Route  
        path={path}
        component={Component}
        exact
        />
        :
        <Redirect push to="/" />
        }
      </>
    )
  }
  
  export default withRouter(PrivetRoute)