import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert2";
import { addUsersLogin, getRolesUserData, updateUser, userActive, userInactive } from "../Apicommon";
import { BootstrapTooltip } from "../common";
window.Swal = swal;
export default function User() {
	const [user, setUser] = useState({
		userID: new Date().valueOf(),
		LastName: "",
		FirstName: "",
		email: "",
		password: "",
		profile_user: "",
		mobileNo: null,
		roles: "",
	});
   const [flag,setFlag]=useState(false)
   const [userId,setuserId]=useState(null)
const [loginErrors, setValidation] = useState({});
const [disable,setDisable]=useState(true)
	const [role, setrole] = useState([]);
	const location = useLocation();
	const [pass, setPass] = useState(false);
const history=useHistory()
	useEffect(() => {
		if (location?.state) {
         setuserId(location?.state?.userID)
		 setDisable(false)
         setUser({
         LastName: location?.state?.LastName,
         FirstName: location?.state?.FirstName,
         email: location?.state?.email,
         password:'',
         profile_user: location?.state?.profile_user,
         mobileNo: location?.state?.mobileNo,
         roles: location?.state?.roles,
		 active:location?.state?.active
      })
		}
	}, [location]);

	useEffect(() => {
		getRoles()
	}, []);

   const getRoles=async()=>{
      const data = await getRolesUserData();
		if (data.success === true) {
			setrole(data.data.data);
		} else {
			console.log("somthing wait wrong");
		}
   }

	function isImage(icon) {
		const ext = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".svg"];
		return ext.some((el) => icon.endsWith(el));
	}

	const onHandaleFile = (e) => {
		var totalSizeMB = e.target.files[0].size / Math.pow(1024, 2);
		if (totalSizeMB >= 2 || !isImage(e.target.files[0].name)) {
			swal.fire("", "File size should be under 2 MB and File Extension Should only be PNG , JPG , JPEG , JIF . Please Try Again", "error");
		} else {
			var reader = new FileReader();
			reader.onloadend = function () {
				var base64data = reader.result;
				setUser({ ...user, profile_user: base64data });
			};
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onHandalchange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

   const validation = (name, value) => {
      switch (name) {
          case 'email':
              if (!value) {
                  return 'Please input email!'
              } else {
                  return '';
                }
                case 'password':
              if (!value) {
                  return 'Please input password!'
              } else {
                  return '';
                }
                case 'LastName':
              if (!value) {
                  return 'Please input Last Name!'
              } else {
                  return '';
                }
                case 'FirstName':
              if (!value) {
                  return 'Please input First Name!'
              } else {
                  return '';
                }
                case 'mobileNo':
              if (!value) {
                  return 'Please input Mobile No!'
              } else {
                  return '';
                }
                case 'password':
              if (!value) {
                  return 'Please input password!'
              } else {
                  return '';
                }
                case 'roles':
              if (!value) {
                  return 'Please input roles!'
              } else {
                  return '';
                }
                default: {
                  return null;
              }
          }
          }

   const onSubmitAddUser=async(e)=>{
      e.preventDefault()
      setFlag(true)
      let allErrors = {};
      Object.keys(user).forEach(key => {
        const error = validation(key, user[key]);
        if (error && error.length) {
            allErrors[key] = error
        }
    });
    if(userId!==null&&allErrors.password){
      allErrors={}
    }

    if (Object.keys(allErrors).length) {
       setFlag(false)
      return setValidation(allErrors)
    }else{
      setValidation({})
      if(userId===null){
const data=await addUsersLogin(user);
if(data.success===true){
   toast.success("Add User Success")
   setUser({userID: null,
		LastName: "",
		FirstName: "",
		email: "",
		password: "",
		profile_user: "",
		mobileNo: null,
		roles: ""})
      history.push('/users')
      setFlag(false)

}else if(data?.err?.response?.status===400){
   toast.error("User Already Exist")
   setFlag(false)

}
      }else{
        const obj={
         LastName: user.LastName,
		FirstName: user.FirstName,
		email: user.email,
		profile_user: user?.profile_user,
		password:user?.password,
		mobileNo: user?.mobileNo,
		roles: user?.roles
        }
         const data=await updateUser(userId,obj);
if(data.success===true){
   toast.success("Update User Success")
   setUser({userID: '',
		LastName: "",
		FirstName: "",
		email: "",
		password: "",
		profile_user: "",
		mobileNo: null,
		roles: ""})
      history.push('/users')
      setFlag(false)

}else if(data?.err?.response?.status===400){
   toast.error("Something Wait Wrong")
   setFlag(false)

}else{
   setFlag(false)
}
      }
    }
   }

   const onInactive=async()=>{
		swal.fire({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
			const data=await userInactive(userId)
			if (data.success === true) {
				history.push('/users')
				toast.success("Inactive User Success");
			}
		} else if (willDelete.isDismissed) {
			swal.fire("User Details is safe");
		}
	});
   }

   const onActive=async()=>{
	swal.fire({
		title: "Are you sure?",
		text: "Once Active, you will be able to recover this User Details!",
		icon: "warning",
		showCancelButton: true,
	}).then(async (willDelete) => {
		if (willDelete.isConfirmed) {
			const data=await userActive(userId)
			if (data.success === true) {
				history.push('/users')
				toast.success("Active User Success");
			}
		} else if (willDelete.isDismissed) {
			swal.fire("User Details is safe");
		}
	});
   }

	return (
		<>
		
			<div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/roles-users">
								<Users style={{ height: "16px" }} /> Roles & Users
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link to="/users">
							 Users
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{userId===null?'Add User':'Update User'}</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4>{userId===null?'Add User':'Update User'}</h4>
										<div class="card-header-action">
										{userId!==null?<div  class="btn btn-info text-white btnedit mr-2"><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" onClick={()=>(setDisable(true))}><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{userId!==null&&user?.active===1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={()=>{onInactive()}}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{userId!==null&&user?.active===0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={()=>{onActive()}}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''}
											<Link to="/users" class="btn btn-info text-white">
												Back
											</Link>
										</div>
									</div>
									<div class="card-body">
										<form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data">
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputEmail4">Role:</label>
													<select
														id="inputState"
														class="form-control"
														name="roles"
														onChange={onHandalchange}
														value={user.roles}
														disabled={!disable}
													>
														<option value="">Select Role</option>
														{role && role.map((item) => <option value={item.ROLES_UNIQUEID}>{item.ROLES_NAME}</option>)}
													</select>
                                       <p className="text-danger">
                      {loginErrors?.roles||''}
                    </p>
												</div>
											</div>
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputEmail4">First Name:</label>
													<input
														type="text"
														class="form-control MapLat"
														name="FirstName"
														placeholder="Enter First Name"
														onChange={onHandalchange}
														value={user.FirstName}
														disabled={!disable}
													/>
                                       <p className="text-danger">
                      {loginErrors?.FirstName||''}
                    </p>
												</div>
												<div class="form-group col-md-3">
													<label for="inputPassword4">Last Name:</label>
													<input
														type="text"
														class="form-control MapLat"
														name="LastName"
														placeholder="Enter Last Name"
														onChange={onHandalchange}
														value={user.LastName}
														disabled={!disable}
													/>
                                       <p className="text-danger">
                      {loginErrors?.LastName||''}
                    </p>
												</div>
												<div class="form-group col-md-3">
													<label for="inputPassword4">Email:</label>
													<input
														type="text"
														class="form-control MapLat"
														name="email"
														placeholder="Enter Email"
														onChange={onHandalchange}
														value={user.email}
														disabled={!disable}
													/>
                                       <p className="text-danger">
                      {loginErrors?.email||''}
                    </p>
												</div>
												<div class="form-group col-md-3">
													<label for="inputState">Personal Mobile Number:</label>
													<input
														type="text"
														class="form-control MapLat"
														name="mobileNo"
														maxlength="10"
														placeholder="Enter Mobile Number"
														onChange={onHandalchange}
														disabled={!disable}
														value={user.mobileNo}
													/>
                                       <p className="text-danger">
                      {loginErrors?.mobileNo||''}
                    </p>
													<input type="hidden" class="form-control MapLat" id="btntypeforsubmit" name="submityper" />
												</div>
											</div>
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputState">Profile Photo</label>
													<input
														class="form-control"
														type="file"
														id="user_photo"
														name="user_profile_photo"
														onChange={onHandaleFile}
														disabled={!disable}
													/>
													<span id="user_phpt_span" style={{ display: "none" }}>
														<img id="us_pftp" height="100" width="100" />
													</span>
                                      
												</div>
												<div class="form-group col-md-3">
													<label for="inputState">Password</label>
													<div class="input-group">
														<input
															class="form-control"
															type={`${pass ? "text" : "password"}`}
															name="password"
															id="user_password"
															placeholder="Enter Password"
															onChange={onHandalchange}
															value={user.password}
														disabled={!disable}
														/>
                                          
														<div class="input-group-append" onClick={() => setPass(!pass)}>
															<div class="input-group-text">
																{pass ? (
																	<i
																		class={"far fa-eye-slash"}
																		id="togglePassword"
																		style={{ cursor: "pointer" }}
																	></i>
																) : (
																	<i class={"far fa-eye"} id="togglePassword" style={{ cursor: "pointer" }}></i>
																)}
															</div>
														</div>
													</div>
                                       <p className="text-danger">
                      {loginErrors?.password||''}
                    </p>
												</div>
											</div>
											<div class="form-row d-flex justify-content-between"></div>
										</form>
									</div>
									<div class="form-group" style={{ marginLeft: "20px" }}>
										<div class="text-left">
											<input type="submit" name="submit" class={`btn btn-danger ${flag?'btn-progress':''}`} value={userId===null?`Submit`:`Update`} id="submitvn" onClick={onSubmitAddUser} disabled={!disable}/>
											<Link class="btn btn-light text-dark ml-3" to="/users">
												Cancel
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
