import React, { useEffect, useState } from 'react'
import logo from '../assets/img/logo.png'
import user from '../assets/img/user.png'
import { Link,withRouter } from 'react-router-dom'
import * as Icon from 'react-feather'
import $ from 'jquery'
import jQuery from 'jquery'

function Navbar() {
  
  const [settings,setSettings]=useState(false)
  const [slidebar,setSlidebar]=useState(false)

  const onHandalSetting=()=>{
    setSettings(!settings)
  }
  
  
    useEffect(()=>{
      // var setting = document.getElementsByClassName("settingPanelToggle");
      // setting[0].addEventListener("click",()=>{
      //   var element = document.getElementsByClassName("settingSidebar");
      //   element[0].classList.toggle("showSettingPanel");
      // })
      // var page = document.querySelectorAll(".page-wrapper");
      // console.log(page)
      // page.addEventListener('click',()=>{
      //   var element = document.getElementsByClassName("settingSidebar");
      //   element[0].classList.remove("showSettingPanel");
      // })
       
            // $(".page-wrapper").on("click", function () {
            //   $(".settingSidebar").removeClass("showSettingPanel");
            // })
        
        // feather.replace();
        // Global
        $(window).on("load", function () {
          $(".loader").fadeOut("slow");
        });
        
       
        jQuery(document).ready(function(){
          let sidebar_nicescroll_opts = {
            cursoropacitymin: 0,
            cursoropacitymax: 0.8,
            zindex: 892
          },
            now_layout_class = null;
        
          var sidebar_sticky = function () {
            if ($("body").hasClass("layout-2")) {
              $("body.layout-2 #sidebar-wrapper").stick_in_parent({
                parent: $("body")
              });
              $("body.layout-2 #sidebar-wrapper").stick_in_parent({ recalc_every: 1 });
            }
          };
          sidebar_sticky();
        
          var sidebar_nicescroll;
          var update_sidebar_nicescroll = function () {
            let a = setInterval(function () {
              if (sidebar_nicescroll != null) sidebar_nicescroll.resize();
            }, 10);
        
            setTimeout(function () {
              clearInterval(a);
            }, 600);
          };
        
          var sidebar_dropdown = function () {
            if ($(".main-sidebar").length) {
                $('.main-sidebar').scroll(function () {
                    sidebar_nicescroll = $(".main-sidebar").getNiceScroll();
              $(".main-sidebar").niceScroll(sidebar_nicescroll_opts);
                })
              
              $(".main-sidebar .sidebar-menu li a.has-dropdown")
                .off("click")
                .on("click", function () {
                  var me = $(this);
        
                  me.parent()
                    .find("> .dropdown-menu")
                    .slideToggle(500, function () {
                      update_sidebar_nicescroll();
                      return false;
                    });
                  return false;
                });
            }
          };
          sidebar_dropdown();
        
          if ($("#top-5-scroll").length) {
            $("#top-5-scroll")
              .css({
                height: 315
              })
              .niceScroll();
          }
          if ($("#scroll-new").length) {
            $("#scroll-new")
              .css({
                height: 200
              })
              .niceScroll();
          }
        
          $(".main-content").css({
            minHeight: $(window).outerHeight() - 95
          });
        
          $(".nav-collapse-toggle").click(function () {
            $(this)
              .parent()
              .find(".navbar-nav")
              .toggleClass("show");
            return false;
          });
        
          $(document).on("click", function (e) {
            $(".nav-collapse .navbar-nav").removeClass("show");
          });
        
          var toggle_sidebar_mini = function (mini) {
            let body = $("body");
        
            if (!mini) {
              body.removeClass("sidebar-mini");
              $(".main-sidebar").css({
                overflow: "hidden"
              });
              setTimeout(function () {
                $('.main-sidebar').scroll(function () {
                $(".main-sidebar").niceScroll(sidebar_nicescroll_opts);
                sidebar_nicescroll = $(".main-sidebar").getNiceScroll();
                })
              }, 500);
              $(".main-sidebar .sidebar-menu > li > ul .dropdown-title").remove();
              $(".main-sidebar .sidebar-menu > li > a").removeAttr("data-toggle");
              $(".main-sidebar .sidebar-menu > li > a").removeAttr(
                "data-original-title"
              );
              $(".main-sidebar .sidebar-menu > li > a").removeAttr("title");
            } else {
              body.addClass("sidebar-mini");
              body.removeClass("sidebar-show");
              if(sidebar_nicescroll){

                  sidebar_nicescroll.remove();
              }
              sidebar_nicescroll = null;
              $(".main-sidebar .sidebar-menu > li").each(function () {
                let me = $(this);
                // console.log(me)
        
                if (me.find("> .dropdown-menu").length) {
                  me.find("> .dropdown-menu").hide();
                  me.find("> .dropdown-menu").prepend(
                    '<li className="dropdown-title pt-3">' + me.find("> a").text() + "</li>"
                  );
                } else {
                    // console.log(me.find("> .nav-link"))
                    // const tooltipatt=document.querySelectorAll(".main-sidebar .sidebar-menu > li")
                        //  me.find("> a").attr("data-toggle", "tooltip");
                        //     me.find("> a").attr("data-original-title", me.find("> a").text());
                        //     console.log(me)
                            // $("[data-toggle='tooltip']").tooltip({
                            //     placement: "right"
                            //   });
                }
              });
            }
          };

          $(".settingPanelToggle").on("click", function () {
            $(".settingSidebar").toggleClass("showSettingPanel");
          })
        
          // sticky header toggle function
          var toggle_sticky_header = function (sticky) {
            if (!sticky) {
              $(".main-navbar")[0].classList.remove("sticky");
            } else {
              $(".main-navbar")[0].classList += " sticky";
            }
          };
        
          $('.menu-toggle').on('click', function (e) {
            var $this = $(this);
            $this.toggleClass('toggled');
        
          });
        
          $.each($('.main-sidebar .sidebar-menu li.active'), function (i, val) {
            var $activeAnchors = $(val).find('a:eq(0)');
        
            $activeAnchors.addClass('toggled');
            $activeAnchors.next().show();
          });
        
          $("[data-toggle='sidebar']").click(function () {
            var body = $("body"),
              w = $(window);
        
            if (w.outerWidth() <= 1024) {
              body.removeClass("search-show search-gone");
              if (body.hasClass("sidebar-gone")) {
                body.removeClass("sidebar-gone");
                body.addClass("sidebar-show");
              } else {
                body.addClass("sidebar-gone");
                body.removeClass("sidebar-show");
              }
        
              update_sidebar_nicescroll();
            } else {
              body.removeClass("search-show search-gone");
              if (body.hasClass("sidebar-mini")) {
                toggle_sidebar_mini(false);
              } else {
                toggle_sidebar_mini(true);
              }
            }
        
            return false;
          });
        
          var toggleLayout = function () {
            var w = $(window),
              layout_class = $("body").attr("class") || "",
              layout_classes =
                layout_class.trim().length > 0 ? layout_class.split(" ") : "";
        
            if (layout_classes.length > 0) {
              layout_classes.forEach(function (item) {
                if (item.indexOf("layout-") != -1) {
                  now_layout_class = item;
                }
              });
            }
        
            if (w.outerWidth() <= 1024) {
              if ($("body").hasClass("sidebar-mini")) {
                toggle_sidebar_mini(false);
                $(".main-sidebar").niceScroll(sidebar_nicescroll_opts);
                sidebar_nicescroll = $(".main-sidebar").getNiceScroll();
              }
        
              $("body").addClass("sidebar-gone");
              $("body").removeClass("layout-2 layout-3 sidebar-mini sidebar-show");
              $("body")
                .off("click")
                .on("click", function (e) {
                  if (
                    $(e.target).hasClass("sidebar-show") ||
                    $(e.target).hasClass("search-show")
                  ) {
                    $("body").removeClass("sidebar-show");
                    $("body").addClass("sidebar-gone");
                    $("body").removeClass("search-show");
        
                    update_sidebar_nicescroll();
                  }
                });
        
              update_sidebar_nicescroll();
        
              if (now_layout_class == "layout-3") {
                let nav_second_classes = $(".navbar-secondary").attr("class"),
                  nav_second = $(".navbar-secondary");
        
                nav_second.attr("data-nav-classes", nav_second_classes);
                nav_second.removeAttr("class");
                nav_second.addClass("main-sidebar");
        
                let main_sidebar = $(".main-sidebar");
                main_sidebar
                  .find(".container")
                  .addClass("sidebar-wrapper")
                  .removeClass("container");
                main_sidebar
                  .find(".navbar-nav")
                  .addClass("sidebar-menu")
                  .removeClass("navbar-nav");
                main_sidebar.find(".sidebar-menu .nav-item.dropdown.show a").click();
                main_sidebar.find(".sidebar-brand").remove();
                main_sidebar.find(".sidebar-menu").before(
                  $("<div>", {
                    class: "sidebar-brand"
                  }).append(
                    $("<a>", {
                      href: $(".navbar-brand").attr("href")
                    }).html($(".navbar-brand").html())
                  )
                );
                setTimeout(function () {
                  sidebar_nicescroll = main_sidebar.niceScroll(sidebar_nicescroll_opts);
                  sidebar_nicescroll = main_sidebar.getNiceScroll();
                }, 700);
        
                sidebar_dropdown();
                $(".main-wrapper").removeClass("container");
              }
            } else {
              $("body").removeClass("sidebar-gone sidebar-show");
              if (now_layout_class) $("body").addClass(now_layout_class);
        
              let nav_second_classes = $(".main-sidebar").attr("data-nav-classes"),
                nav_second = $(".main-sidebar");
        
              if (
                now_layout_class == "layout-3" &&
                nav_second.hasClass("main-sidebar")
              ) {
                nav_second.find(".sidebar-menu li a.has-dropdown").off("click");
                nav_second.find(".sidebar-brand").remove();
                nav_second.removeAttr("class");
                nav_second.addClass(nav_second_classes);
        
                let main_sidebar = $(".navbar-secondary");
                main_sidebar
                  .find(".sidebar-wrapper")
                  .addClass("container")
                  .removeClass("sidebar-wrapper");
                main_sidebar
                  .find(".sidebar-menu")
                  .addClass("navbar-nav")
                  .removeClass("sidebar-menu");
                main_sidebar.find(".dropdown-menu").hide();
                main_sidebar.removeAttr("style");
                main_sidebar.removeAttr("tabIndex");
                main_sidebar.removeAttr("data-nav-classes");
                $(".main-wrapper").addClass("container");
                // if(sidebar_nicescroll != null)
                //   sidebar_nicescroll.remove();
              } else if (now_layout_class == "layout-2") {
                $("body").addClass("layout-2");
              } else {
                update_sidebar_nicescroll();
              }
            }
          };
          toggleLayout();
          $(window).resize(toggleLayout);
        
          $("[data-toggle='search']").click(function () {
            var body = $("body");
        
            if (body.hasClass("search-gone")) {
              body.addClass("search-gone");
              body.removeClass("search-show");
            } else {
              body.removeClass("search-gone");
              body.addClass("search-show");
            }
          });
        
          // tooltip
          if (jQuery().tooltip) {
          $("[data-toggle='tooltip']").tooltip();
          }
        
          // popover
          if (jQuery().popover) {
          $('[data-toggle="popover"]').popover({
            container: "body"
        });
    }
        
          // Select2
          if (jQuery().select2) {
            $(".select2").select2();
          }
        
          // Selectric
          if (jQuery().selectric) {
            $(".selectric").selectric({
              disableOnMobile: false,
              nativeOnMobile: false
            });
          }
        if(jQuery().dropdown){
          $(".notification-toggle").dropdown();
        }
          $(".notification-toggle")
            .parent()
            .on("shown.bs.dropdown", function () {
              $(".dropdown-list-icons").niceScroll({
                cursoropacitymin: 0.3,
                cursoropacitymax: 0.8,
                cursorwidth: 7
              });
            });
            if(jQuery().dropdown){
          $(".message-toggle").dropdown();
            }
          $(".message-toggle")
            .parent()
            .on("shown.bs.dropdown", function () {
              $(".dropdown-list-message").niceScroll({
                cursoropacitymin: 0.3,
                cursoropacitymax: 0.8,
                cursorwidth: 7
              });
            });
        
          if (jQuery().summernote) {
            $(".summernote").summernote({
              dialogsInBody: true,
              minHeight: 250
            });
            $(".summernote-simple").summernote({
              dialogsInBody: true,
              minHeight: 150,
              toolbar: [
                ["style", ["bold", "italic", "underline", "clear"]],
                ["font", ["strikethrough"]],
                ["para", ["paragraph"]]
              ]
            });
          }
        
          // Dismiss function
          $("[data-dismiss]").each(function () {
            var me = $(this),
              target = me.data("dismiss");
        
            me.click(function () {
              $(target).fadeOut(function () {
                $(target).remove();
              });
              return false;
            });
          });
        
          // Collapsable
          $("[data-collapse]").each(function () {
            var me = $(this),
              target = me.data("collapse");
        
            me.click(function () {
              $(target).collapse("toggle");
              $(target).on("shown.bs.collapse", function () {
                me.html('<i className="fas fa-minus"></i>');
              });
              $(target).on("hidden.bs.collapse", function () {
                me.html('<i className="fas fa-plus"></i>');
              });
              return false;
            });
          });
        
          // Background
          $("[data-background]").each(function () {
            var me = $(this);
            me.css({
              backgroundImage: "url(" + me.data("background") + ")"
            });
          });
        
          // Custom Tab
          $("[data-tab]").each(function () {
            var me = $(this);
        
            me.click(function () {
              if (!me.hasClass("active")) {
                var tab_group = $('[data-tab-group="' + me.data("tab") + '"]'),
                  tab_group_active = $(
                    '[data-tab-group="' + me.data("tab") + '"].active'
                  ),
                  target = $(me.attr("href")),
                  links = $('[data-tab="' + me.data("tab") + '"]');
        
                links.removeClass("active");
                me.addClass("active");
                target.addClass("active");
                tab_group_active.removeClass("active");
              }
              return false;
            });
          });
        
          // Bootstrap 4 Validation
          $(".needs-validation").submit(function () {
            var form = $(this);
            if (form[0].checkValidity() === false) {
              // event.preventDefault();
              // event.stopPropagation();
            }
            form.addClass("was-validated");
          });
        
          // alert dismissible
          $(".alert-dismissible").each(function () {
            var me = $(this);
        
            me.find(".close").click(function () {
              me.alert("close");
            });
          });
        
          if ($(".main-navbar").length) {
          }
        
          // Image cropper
          $("[data-crop-image]").each(function (e) {
            $(this).css({
              overflow: "hidden",
              position: "relative",
              height: $(this).data("crop-image")
            });
          });
        
          // Slide Toggle
          $("[data-toggle-slide]").click(function () {
            let target = $(this).data("toggle-slide");
        
            $(target).slideToggle();
            return false;
          });
        
          // Dismiss modal
          $("[data-dismiss=modal]").click(function () {
            $(this)
              .closest(".modal")
              .modal("hide");
        
            return false;
          });
        
          // Width attribute
          $("[data-width]").each(function () {
            $(this).css({
              width: $(this).data("width")
            });
          });
        
          // Height attribute
          $("[data-height]").each(function () {
            $(this).css({
              height: $(this).data("height")
            });
          });
        
          // Chocolat
          if ($(".chocolat-parent").length && jQuery().Chocolat) {
            $(".chocolat-parent").Chocolat();
          }
        
          // Sortable card
          if ($(".sortable-card").length && jQuery().sortable) {
            $(".sortable-card").sortable({
              handle: ".card-header",
              opacity: 0.8,
              tolerance: "pointer"
            });
          }
        
          // Daterangepicker
          if (jQuery().daterangepicker) {
            if ($(".datepicker").length) {
              $(".datepicker").daterangepicker({
                locale: { format: "YYYY-MM-DD" },
                singleDatePicker: true
              });
            }
            if ($(".datetimepicker").length) {
              $(".datetimepicker").daterangepicker({
                locale: { format: "YYYY-MM-DD hh:mm" },
                singleDatePicker: true,
                timePicker: true,
                timePicker24Hour: true
              });
            }
            if ($(".daterange").length) {
              $(".daterange").daterangepicker({
                locale: { format: "YYYY-MM-DD" },
                drops: "down",
                opens: "right"
              });
            }
          }
        
          // Timepicker
          if (jQuery().timepicker && $(".timepicker").length) {
            $(".timepicker").timepicker({
              icons: {
                up: "fas fa-chevron-up",
                down: "fas fa-chevron-down"
              }
            });
          }
        
          $("#mini_sidebar_setting").on("change", function () {
            var _val = $(this).is(":checked") ? "checked" : "unchecked";
            if (_val === "checked") {
              toggle_sidebar_mini(true);
            } else {
              toggle_sidebar_mini(false);
            }
          });
          $("#sticky_header_setting").on("change", function () {
            if ($(".main-navbar")[0].classList.contains("sticky")) {
              toggle_sticky_header(false);
            } else {
              toggle_sticky_header(true);
            }
          });
        
          $(".theme-setting-toggle").on("click", function () {
            if ($(".theme-setting")[0].classList.contains("active")) {
              $(".theme-setting")[0].classList.remove("active");
            } else {
              $(".theme-setting")[0].classList += " active";
            }
          });
        
          // full screen call
        
          $(document).on("click", ".fullscreen-btn", function (e) {
            if (
              !document.fullscreenElement && // alternative standard method
              !document.mozFullScreenElement &&
              !document.webkitFullscreenElement &&
              !document.msFullscreenElement
            ) {
              // current working methods
              if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
              } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
              } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
              } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                  Element.ALLOW_KEYBOARD_INPUT
                );
              }
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
              }
            }
          });
        
          // setting sidebar
        
          
        
          // close right sidebar when click outside
          // var mouse_is_inside = false;
          // $(".settingSidebar").hover(
          //   function () {
          //     mouse_is_inside = true;
          //   },   
          //   function () {
          //     mouse_is_inside = false;
          //   }
          // );
        
          // $("body").mouseup(function () {
          //   if (!mouse_is_inside) $(".settingSidebar").removeClass("showSettingPanel");
          // });
        if(jQuery().niceScroll){
          $(".settingSidebar-body").niceScroll();
        }
          // theme change event
          $(".choose-theme li").on("click", function () {
            var bodytag = $("body"),
              selectedTheme = $(this),
              prevTheme = $(".choose-theme li.active").attr("title");
        
            $(".choose-theme li").removeClass("active")
              selectedTheme.addClass("active");
            $(".choose-theme li.active").data("theme");
        
            bodytag.removeClass("theme-" + prevTheme);
            bodytag.addClass("theme-" + $(this).attr("title"));
          });
        
          // dark light sidebar button setting
          $(".sidebar-color input:radio").change(function () {
            if ($(this).val() == "1") {
              $("body").removeClass("dark-sidebar");
              $("body").addClass("light-sidebar");
            } else {
              $("body").removeClass("light-sidebar");
              $("body").addClass("dark-sidebar");
            }
          });
        
          // dark light layout button setting
          $(".layout-color input:radio").change(function () {
            if ($(this).val() == "1") {
              $("body").removeClass();
              $("body").addClass("light");
              $("body").addClass("light-sidebar");
              $("body").addClass("theme-white");
        
              $(".choose-theme li").removeClass("active");
              $(".choose-theme li[title|='white']").addClass("active");
              $(".selectgroup-input[value|='1']").prop("checked", true);
            } else {
              $("body").removeClass();
              $("body").addClass("dark");
              $("body").addClass("dark-sidebar");
              $("body").addClass("theme-black");
        
              $(".choose-theme li").removeClass("active");
              $(".choose-theme li[title|='black']").addClass("active");
              $(".selectgroup-input[value|='2']").prop("checked", true);
            }
          });
        
          // restore default to dark theme
          $(".btn-restore-theme").on("click", function () {
            //remove all class from body
            $("body").removeClass();
            jQuery("body").addClass("light");
            jQuery("body").addClass("light-sidebar");
            jQuery("body").addClass("theme-white");
        
            // set default theme
            $(".choose-theme li").removeClass("active");
            $(".choose-theme li[title|='white']").addClass("active");
        
            $(".select-layout[value|='1']").prop("checked", true);
            $(".select-sidebar[value|='2']").prop("checked", true);
            toggle_sidebar_mini(false);
            $("#mini_sidebar_setting").prop("checked", false);
            $("#sticky_header_setting").prop("checked", true);
            toggle_sticky_header(true);
          });
        
          //start up class add
        
          //add default class on body tag
          jQuery("body").addClass("light");
          jQuery("body").addClass("light-sidebar");
          jQuery("body").addClass("theme-white");
          // set theme default color
          $(".choose-theme li").removeClass("active");
          $(".choose-theme li[title|='white']").addClass("active");
          //set default dark or light layout(1=light, 2=dark)
          $(".select-layout[value|='1']").prop("checked", true);
          //set default dark or light sidebar(1=light, 2=dark)
          $(".select-sidebar[value|='1']").prop("checked", true);
          // sticky header default set to true
          $("#sticky_header_setting").prop("checked", true);
        });
    })

  return (
    <>
  <div id="app">
    <div className="main-wrapper main-wrapper-1">
      <div className="navbar-bg"></div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg
									collapse-btn"><Icon.AlignJustify className='feather' /> </a></li>
            <li><a href="#" className="nav-link nav-link-lg fullscreen-btn">
                <i data-feather="maximize"></i>
              </a></li>
            <li>
              <form className="form-inline mr-auto">
                <div className="search-element">
                  <input className="form-control" type="search" placeholder="Search" aria-label="Search" data-width="200"/>
                  <button className="btn" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </li>
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown"><a href="#" data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"> <img alt="image" src={user}
                className="user-img-radious-style"/> <span className="d-sm-none d-lg-inline-block"></span></a>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">Hello Sarah Smith</div>
              <Link to="/userprofile" className="dropdown-item has-icon"> <i className="far
										fa-user"></i> Profile
              </Link> 
              <div className="dropdown-divider"></div>
              <a href="auth-login.html" className="dropdown-item has-icon text-danger"> <i className="fas fa-sign-out-alt"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <div className="main-sidebar sidebar-style-2">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <Link to="/group-dashboard"> <img alt="image" src={'https://eums.memighty.com/assets/img/download.png'} className="header-logo" /> <span
                className="logo-name">MEMIGHTY</span>
            </Link>
          </div>
          <ul className="sidebar-menu">
            <li className="menu-header">Main</li>
            <li className="dropdown active">
              <Link to="/group-dashboard" className="nav-link" data-toggle='tooltip' data-original-title="Group Dashbord"><Icon.PieChart className='feather' /><span>Group Dashbord</span></Link>
            </li>
            <li className="dropdown">
              <a to="#" className="menu-toggle nav-link has-dropdown"><Icon.Briefcase className='feather' /><span>Consumer</span></a>
              <ul className="dropdown-menu">
                <li><Link to="/gsm-modem" className="nav-link" >GSM Modem</Link></li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown"><Icon.Lock className='feather' /><span>Admin</span></a>
              <ul className="dropdown-menu">
                <li><Link className="nav-link" to="/modeam-diver">Modem Driver</Link></li>
                <li><Link className="nav-link" to="/meter-diver">Meter Driver</Link></li>
                <li><Link className="nav-link" to="/service-profile">Service Profile</Link></li>
                <li><Link className="nav-link" to="/service-profile">GSM Signal Strength</Link></li>
              </ul>
            </li>
            <li className="dropdown">
              <Link to="#" className="nav-link" data-toggle='tooltip' data-original-title="Rules & Users"><Icon.Users className='feather' /><span>Roles & Users</span></Link>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown"><Icon.Layers className='feather' /><span>Buffer</span></a>
              <ul className="dropdown-menu">
                <li><Link className="nav-link" to="/gsm-buffer">GSM Buffer</Link></li>
              </ul>
            </li>
          </ul>
        </aside>
      </div>
      <div className="main-contents" >
        {/* <div className={`settingSidebar ${settings?`showSettingPanel`:``}`} onClick={onHandalSetting}> */}
        <div className='settingSidebar'>
          <a href="#" className="settingPanelToggle"> <i className="fa fa-spin fa-cog"></i>
          </a>
          <div className="settingSidebar-body ps-container ps-theme-default">
            <div className=" fade show active">
              <div className="setting-panel-header">Setting Panel
              </div>
              <div className="p-15 border-bottom">
                <h6 className="font-medium m-b-10">Select Layout</h6>
                <div className="selectgroup layout-color w-50">
                  <label className="selectgroup-item">
                    <input type="radio" name="value" value="1" className="selectgroup-input-radio select-layout" checked/>
                    <span className="selectgroup-button">Light</span>
                  </label>
                  <label className="selectgroup-item">
                    <input type="radio" name="value" value="2" className="selectgroup-input-radio select-layout"/>
                    <span className="selectgroup-button">Dark</span>
                  </label>
                </div>
              </div>
              <div className="p-15 border-bottom">
                <h6 className="font-medium m-b-10">Sidebar Color</h6>
                <div className="selectgroup selectgroup-pills sidebar-color">
                  <label className="selectgroup-item">
                    <input type="radio" name="icon-input" value="1" className="selectgroup-input select-sidebar"/>
                    <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip"
                      data-original-title="Light Sidebar"><i className="fas fa-sun"></i></span>
                  </label>
                  <label className="selectgroup-item">
                    <input type="radio" name="icon-input" value="2" className="selectgroup-input select-sidebar" checked/>
                    <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip"
                      data-original-title="Dark Sidebar"><i className="fas fa-moon"></i></span>
                  </label>
                </div>
              </div>
              <div className="p-15 border-bottom">
                <h6 className="font-medium m-b-10">Color Theme</h6>
                <div className="theme-setting-options">
                  <ul className="choose-theme list-unstyled mb-0">
                    <li title="white" className="active">
                      <div className="white"></div>
                    </li>
                    <li title="cyan">
                      <div className="cyan"></div>
                    </li>
                    <li title="black">
                      <div className="black"></div>
                    </li>
                    <li title="purple">
                      <div className="purple"></div>
                    </li>
                    <li title="orange">
                      <div className="orange"></div>
                    </li>
                    <li title="green">
                      <div className="green"></div>
                    </li>
                    <li title="red">
                      <div className="red"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="p-15 border-bottom">
                <div className="theme-setting-options">
                  <label className="m-b-0">
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input"
                      id="mini_sidebar_setting"/>
                    <span className="custom-switch-indicator"></span>
                    <span className="control-label p-l-10">Mini Sidebar</span>
                  </label>
                </div>
              </div>
              <div className="p-15 border-bottom">
                <div className="theme-setting-options">
                  <label className="m-b-0">
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input"
                      id="sticky_header_setting"/>
                    <span className="custom-switch-indicator"></span>
                    <span className="control-label p-l-10">Sticky Header</span>
                  </label>
                </div>
              </div>
              <div className="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                <a href="#" className="btn btn-icon icon-left btn-primary btn-restore-theme">
                  <i className="fas fa-undo"></i> Restore Default
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default withRouter(Navbar)
