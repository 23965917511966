import React, { useEffect, useRef, useState } from "react";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { Link, useHistory, useParams } from "react-router-dom";
import { Briefcase } from "react-feather";
import { BootstrapTooltip } from "../common";
import { consumerSlavebyId, GsmViewUserData } from "../Apicommon";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import signal2 from '../assets/img/secondsignal.png'
import signal3 from '../assets/img/signal3.png'
import signal4 from '../assets/img/signal4.png'
import signal5 from '../assets/img/signal5.png'
import "jspdf-autotable";
import CsvDownload from "react-json-to-csv";
import { Modal } from "antd";
// import Viewuser from '../viewuser/Viewuser'
$.fn.DataTable = dt;

export default function ConsumerSlavebyIds() {
	const [slaveData, setslaveData] = useState([]);
	const [filterslaveData, setfilterslaveData] = useState([]);
	const [loader, setloader] = useState(false);
	const { consumer_id } = useParams();
	const history = useHistory();
	const [viewData, setViewData] = useState({});
	const [arraydatass,setarraydatass]=useState([])
	const [isModalVisible, setIsModalVisible] = useState(false);
const [filterSlave,setFilterSlave]=useState({slave_name:'',meter_profile:''})


	const viewgetuserdata = async () => {
		const resultData = await GsmViewUserData(consumer_id);
		if (resultData.success === true) {
			setViewData(resultData.data.data[0]);
		} else {
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	}

const onhandleOk=()=>{
	setIsModalVisible(false)
if(filterSlave.slave_name===''&&filterSlave.meter_profile===''){
return setslaveData(filterslaveData)
}
const slaveDataFilter=filterslaveData&&filterslaveData.filter((item)=>(filterSlave.slave_name!==''?item?.payload?.CONSDEVNAME_U.includes(filterSlave.slave_name):[])&&(filterSlave.meter_profile!==''?filterSlave.meter_profile==item?.payload?.meter_profile_U:[]))
setslaveData(slaveDataFilter)

}

const onHandaleChange=(e)=>{
	setFilterSlave({...filterSlave,[e.target.name]:e.target.value})
}

	const onHandaleslaveData = async () => {
		setloader(true);
		$(".loader").fadeOut("slow");
		const data = await consumerSlavebyId(consumer_id);
		if (data.success === true) {
			let arrayoFdata = [];

		 data.data.data.map((elt) => {arrayoFdata.push({
			SLAVEID_U:elt.payload.SLAVEID_U,CONSSLAVEUID_S:elt.payload.CONSSLAVEUID_S,CONSDEVNAME_U:elt.payload.CONSDEVNAME_U,SCRIPTDECODEID:elt.payload.SCRIPTDECODEID_S,last_seen: elt.last_seen,Meter_Profile:elt.payload.meter_profile_U,last_seen_duretion:moment.utc(elt.last_seen||null).add(-(moment().utcOffset()), 'm').fromNow()})});
			setarraydatass(arrayoFdata)
			setslaveData(data.data.data);
			setfilterslaveData(data.data.data)
			
		} else {
			setloader(false);
		}
	};
	const excelFileExport = async () => {
		var arraydata=[]

		await filterslaveData && filterslaveData.map((elt) => {arraydata.push({
			SLAVEID_U:elt.payload.SLAVEID_U,CONSSLAVEUID_S:elt.payload.CONSSLAVEUID_S,CONSDEVNAME_U:elt.payload.CONSDEVNAME_U,SCRIPTDECODEID:elt.payload.SCRIPTDECODEID_S,last_seen: elt?.last_seen?moment.utc(elt?.last_seen||null).format("DD/MM/YYYY HH:mm:ss "):'Not Last Seen',Meter_Profile:elt.payload.meter_profile_U===0?"Instantaneous":'Load Survey',last_seen_duretion:moment.utc(elt.last_seen||null).add(-(moment().utcOffset()), 'm').fromNow()})});
		const worksheet = XLSX.utils.json_to_sheet(arraydata);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		XLSX.writeFile(workbook, `${consumer_id}_GSMmodem_slaves_Data.xlsx`);
	};

	

	const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(15);

		const title = `${consumer_id} GSMmodem slaves Data`;
		const headers = [["Unique No", "Consumer Slave Id", "Consumer Device Name","SCRIPTDECODEID", "Last seen","Meter Profile","last seen duretion"]];

		const data =
		filterslaveData && filterslaveData.map((elt) => [elt.payload.SLAVEID_U, elt.payload.CONSSLAVEUID_S, elt.payload.CONSDEVNAME_U,elt.payload.SCRIPTDECODEID_S, elt?.last_seen?moment.utc(elt?.last_seen||null).format("DD/MM/YYYY HH:mm:ss "):'Not Last Seen',elt.payload.meter_profile_U===0?"Instantaneous":'Load Survey',moment.utc(elt.last_seen||null).add(-(moment().utcOffset()), 'm').fromNow()]);

		let content = {
			startY: 50,
			head: headers,
			body: data,
		};

		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save(`${consumer_id}_GSMmodem_slaves_Data.pdf`);
	};
	useEffect(() => {
		viewgetuserdata()
		onHandaleslaveData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({destroy: true, pageLength: 50 });
				setloader(false);
			}, 1000)
		);
	}, []);

	const onHandaleviewRediect = (item) => {
		history.push({ pathname: `/view-user/${consumer_id}`, state: item });
	};

	function getSecondsTodayBydate(ts) {
		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
	}
	return (
		<>
		<Modal title="Filter Slaves Data" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputPassword4">Slaves Name:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="slave_name"
							onChange={onHandaleChange}
							value={filterSlave.slave_name}
						>
							<option value="">Select Slave Name</option>
							{filterslaveData&&filterslaveData.map((item)=>(<option value={item.payload.CONSDEVNAME_U}>{item.payload.CONSDEVNAME_U}</option>))}
						</select>
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Meter Profile:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="meter_profile"
							onChange={onHandaleChange}
							value={filterSlave.meter_profile}
						>
							<option value="">Select Meter Profile</option>
							<option value={1}>Loadsurvey</option>
							<option value={0}>Instantaneous</option>

						</select>
					</div>
					</div>
					<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/consumer-meter-details/${consumer_id}`}>
						Reset
					</Link>
				</div>
					</Modal>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>

						<li className="breadcrumb-item">
							<Link to="/gsm-modem">
								<Briefcase style={{ height: "16px" }} /> Consumer
							</Link>
						</li>

						<li className="breadcrumb-item">
							<Link to="/gsm-modem">
								<i className="fas fa-list"></i> GSM Modem
							</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							Slave Details
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
					<div className="row">
							<div className="col-12">
								<div className="card" >
									<div class="card-header">
										<h4>Modem Details</h4>
									</div>
									<div className="card-body" style={{paddingTop:"0px"}}>
										<div className="row">
											<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
												<b>Consumer Unique ID : </b>
												{viewData.UNIQUE_ID_S}
												<br />
												<b>Consumer Modem Name : </b> {viewData.CONSUMER_MODEM_NAME_U}
												<br />
												
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-5">
												<b>IMEI : </b>
												{viewData.IMEI_U}
												<b className="ml-5">Last Seen : </b>
												{moment.utc(viewData.last_seen).format("DD/MM/YYYY HH:mm:ss")}
												<br />
												<b>Consumer Modem Serial No : </b>
												{viewData.CONSUMER_MODEM_SRNO_U}
												<br />
												
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
											
												<b>Consumer Status : </b>
												<div className="badge badge-success badge-shadow text-white">Active</div>
												<br />
												<b>Connectivity Indication: </b>
												<div
													className={`badge badge-${viewData.MASSAGE === "online" ? `success` : ""}${
														viewData.MASSAGE === "offline" ? `danger` : ""
													}${viewData.MASSAGE == null ? `warning` : ""} badge-shadow text-white`}
												>
													{viewData.MASSAGE === "online" && `online`}
													{viewData.MASSAGE === "offline" && `offline`}
													{viewData.MASSAGE == null && `Never Seen`}
												</div>
												
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-1">
											<BootstrapTooltip title={`RSSI:${viewData?.RSSI >= 2 && viewData?.RSSI <= 9?'Marginal':viewData?.RSSI >= 10 && viewData?.RSSI <= 14?'Ok':viewData?.RSSI >= 15 && viewData?.RSSI <= 19?'Good':viewData?.RSSI >= 20 && viewData?.RSSI <= 31?'Excellent':''	}`} placement="top"><span ><b>RSSI : </b>
																		{viewData?.RSSI >= 2 && viewData?.RSSI <= 9 ? (
																			<>
																				<img src={signal2} style={{height:'20px',width:"30px",border:'0',boxShadow:'none',borderRadius:'none'}}/>
																			</>
																		) : viewData?.RSSI >= 10 && viewData?.RSSI <= 14 ? (
																			<>
																			<img src={signal3} style={{height:'20px',width:"30px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			</>
																		) : viewData?.RSSI >= 15 && viewData?.RSSI <= 19 ? (
																			<>
																			<img src={signal4} style={{height:'20px',width:"30px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			</>
																		) : viewData?.RSSI >= 20 && viewData?.RSSI <= 31 ? (
																			<>
																			<img src={signal5} style={{height:'20px',width:"30px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			</> 
																		) : (
																			"-"
																		)}
																		{viewData?.RSSI}</span>
																		</BootstrapTooltip>
												<br />
												<b>SRNO : </b>
												{viewData.SRNO_S}
												<br />
												{/* <b>Total Slave : </b>{JSON.parse(viewData?.SLAVES_ID_U)?.DETAILS_U?.length}<br /> */}
												
											</div>
										</div>
									</div>
								</div>
							</div>
							
						</div>
						<div className="row" >
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4>Slave Device</h4>
										<div className="card-header-action">
											<Link  className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2" onClick={()=>(setIsModalVisible(true))}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												<div className="dropdown-menu">
													<a className="dropdown-item" onClick={exportPDF}>
														PDF
													</a>
													<a className="dropdown-item" onClick={excelFileExport}>
														Excel
													</a>
													<a className="dropdown-item" >
													<CsvDownload data={arraydatass} filename={`${consumer_id}_GSMmodem_slaves_Data.csv`} style={{border:'0px',background:'white'}}>CSV</CsvDownload>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<div className="row" >
											<div className="col-12 ">
												<table className="table table-striped" id="table-1">
													<thead>
														<tr>
															<th className="text-center">Slaves ID</th>
															<th>Consumer Slave Id</th>
															<th style={{ maxWidth: "322px" }}>Consumer Device Name</th>
															<th>SCRIPTDECODEID</th>
															<th className="thwidth" style={{ maxWidth: "10.516px" }}>Last Seen</th>
															<th>Meter Profile</th>
															<th style={{maxWidth:'135px'}}>Last Seen Duration</th>
															<th style={{maxWidth:'135px'}}>Communicat</th>
															<th style={{minWidth:'440px'}}>Details</th>
														</tr>
													</thead>
													<tbody>
														{slaveData.length > 0 &&
															slaveData.map((data, id) => (
																<tr style={{ height: "4rem" }} className="trheigth" key={id}>
																	<td>{data.payload.SLAVEID_U}</td>
																	<td>{data.payload.CONSSLAVEUID_S}</td>
																	<td>{data.payload.CONSDEVNAME_U}</td>
																	<td>{data.payload.SCRIPTDECODEID_S}</td>
																	<td>{data?.last_seen?moment.utc(data?.last_seen||null).format("DD/MM/YYYY HH:mm:ss "):'Not Last Seen'}</td>
																	<td>{data?.payload?.meter_profile_U===0?"Instantaneous":'Load Survey'}</td>
																	<td>{data?.last_seen?moment.utc(data?.last_seen||null).add(-(moment().utcOffset()), 'm').fromNow(true):'Not Last Seen'}</td>
															<td><div
																			className={`badge badge-${(data?.last_seen&&getSecondsTodayBydate(data?.last_seen) < 900)? `success` : "danger"} badge-shadow text-white`}
																		>{data?.last_seen&&getSecondsTodayBydate(data?.last_seen) < 900?'Success':'Not Communicat'}</div></td>
																	
																	<td className="">
																		<a
																			className="btn btn-primary text-white btn-lg"
																			style={{ lineHeight: "1.5" }}
																			onClick={() => onHandaleviewRediect(data)}
																		>
																			View Details
																		</a>
																	</td>
																</tr>
															))}
													</tbody>
												</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
