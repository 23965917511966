import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getRolesDetailesByName, UpdateConsumerSlavesdetailsByConsumerId } from '../Apicommon';
import { BootstrapTooltip } from '../common'

export default function Consumerinfo(props) {
   const [disable,setdisable]=useState(false);
   const [SlaveName,setSlaveName]=useState({slaveName:props.view.payload.CONSDEVNAME_U,ModemName:props.modemData.CONSUMER_MODEM_NAME_U})
   const [ModemRoles,setModemRoles]=useState({})
	const permission = JSON.parse(window.localStorage.getItem("user_info"))?.roles||'';

   const history=useHistory()

   const onUpdateConsumerSlavesdetailsByConsumerId=async()=>{
      const data=await UpdateConsumerSlavesdetailsByConsumerId({...SlaveName,ID:props.modemData.UNIQUE_ID_S,SlavesId:props.view.payload.CONSSLAVEUID_S})
      if(data.success==true){
         console.log(data.data.data)
         history.push(`/consumer-meter-details/${props.modemData.UNIQUE_ID_S}`);
      }else{
         console.log('something want wrong')
      }
   }

   const onRolesDetailesByName=async()=>{
		if(permission!=='ROLE1680234321'){
		const data=await getRolesDetailesByName({ROLES_NAME:permission})
		if(data.success===true){
	setModemRoles(JSON.parse(data?.data?.data?.modem_permission)||{})
}else{
	console.log('something wait wrong')
}
		}
	}
   useEffect(() => {
      
      onRolesDetailesByName()
   }, []);
  return (
   <div class="row">
   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <div class="card">
						<div class="card-header d-flex" style={{justifyContent:'space-between'}}>
											<h4></h4>
                                 <div>
                              
                                { ModemRoles[props.modemData.IMEI_U]?.edit===true||permission === "ROLE1680234321"? <a class="btn btn-info text-white ml-2" onClick={()=>(setdisable(true))}><BootstrapTooltip title="Edit" placement="top"><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Refresh"><i class="fa fa-edit text-white"></i></span></BootstrapTooltip></a>:''}
                                       </div>
										</div>
    <div class="card-body" id="diffrentdata">
    {disable===false? <fieldset class="customLegend">
      <legend>Modem Details</legend>
      <div class="form-row  rounded p-3" style={{marginLeft:'10px', borderColor: '#e3e6fc !important',position:'relative',   marginTop: '5px'}}>
         <div class="col-lg-4">
            <div class="form-group">
               <label for="inputPassword4">Consumer Modem Name<span class="text-danger">*</span></label>
               <input class="form-control" id="cust_fname" name="distributer_cust_id" type="text" disabled={!disable} value={SlaveName.ModemName} onChange={(e)=>setSlaveName({...SlaveName,ModemName:e.target.value})}></input>
            </div>
         </div>
         <div class="col-lg-4">
            <div class="form-group">
            <label for="inputPassword4">IMEI</label>
            <input class="form-control" id="cust_fname" name="distributer_cust_id" type="text"  disabled={!disable} value={props.modemData.IMEI_U} placeholder="Created by"/>
            </div>
         </div>
         <div class="col-lg-4">
            <div class="form-group">
               <label for="inputPassword4">CONSUMER MODEM SRNO</label>
               <input class="form-control" id="cust_fname" name="distributer_cust_id" type="text" disabled={!disable} value={props.modemData.CONSUMER_MODEM_SRNO_U} placeholder="Created by"></input>
            </div>
         </div>
         
      </div>
   </fieldset>:''}
   <fieldset class="customLegend">
      <legend>Consumer Device Details</legend>
      <div class="form-row  rounded p-3" style={{marginLeft:'10px', borderColor: '#e3e6fc !important',position:'relative',   marginTop: '5px'}}>
         <div class="col-lg-4">
            <div class="form-group">
               <label for="inputPassword4">Consumer Device Name<span class="text-danger">*</span></label>
               <input class="form-control" id="cust_fname" name="distributer_cust_id" type="text" disabled={!disable} value={SlaveName.slaveName} onChange={(e)=>setSlaveName({...SlaveName,slaveName:e.target.value.replace(/["`\t\n]/g,'')})}></input>
               <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Special Characters like Quotation mark ("),New line,Horizontal tab not allowed
														</span>
            </div>
            
         </div>
      </div>
   </fieldset>
   <div class="text-left">
  {disable?<input type="submit" name="submit" class={`btn btn-danger ${!disable?'disabled':''} mt-2 mr-4`} value="Submit" id="submitvn" onClick={onUpdateConsumerSlavesdetailsByConsumerId}/>:''}
   </div>
   </div>
    </div>
    </div>
    </div>
  )
}
