import React, { useEffect, useMemo, useRef, useState } from "react";
import { DatePicker, Space } from 'antd';
import { Link } from "react-router-dom";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { Layers } from "react-feather";
import { BootstrapTooltip } from "../common";
import { bufferDataBYDate, getBufferdata, getStartAndEndDateByTable } from "../Apicommon";
import Pagination from '../CommonComponnet/Pagination';
import moment from "moment";
import { Modal } from 'antd';
import './Buffer.css'
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import "jspdf-autotable";
import { CSVLink } from "react-csv";
$.fn.DataTable = dt;


export default function Gsmbuffer() {
	// $(document).ready(function () {
	//   $('#table_id').DataTable()
	// });
	const [Bufferdata, setBufferdata] = useState([]);
	const [DownData, setDownData] = useState([]);

	const [loader, setloader] = useState(false);
  const [PageSize,setPageSize]=useState(50)
  const [dates,setDates]=useState({start:'',end:''})
  const [numberofPage,setnumberofPage]=useState(1) 
  const [currentPage, setCurrentPage] = useState(1);
  const [Btn,setBtn]=useState(false)
  const csvLink = useRef()
  const [isModalVisible, setIsModalVisible] = useState(false);
const [expireDate,setExpireDate]=useState({})
  const showModal =async () => {
	  const data=await getStartAndEndDateByTable({tableName:`gsm_buffer_single`})
	  if(data.success===true){
		  setIsModalVisible(!isModalVisible);
		  setExpireDate(data.data)
	}else{
		console.log("something wait wrong")
	}
  };

	const onHandalBuffer = async () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
		setloader(true);
		$(".loader").fadeOut("slow");
		const data = await getBufferdata({start:firstPageIndex,end:PageSize});
		if (data.success === true) {
			setBufferdata(data.data.data);
      setnumberofPage(data.data.lengths)
			setloader(false);
		} else {
			setloader(false);
		}
	};

	const exportPDF =async () => {

		if(dates.start!==''&&dates.end!==''){
			setBtn(true)
			const datas=await bufferDataBYDate(dates)
			if (datas.success === true) {

				const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape
	
		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);
	
		doc.setFontSize(15);
	
		const title = ` GSMBuffer  Data`;
		const headers = [["Unique No",'CONFIGUNIQUEID',
		"CONSUMER UNIQUE ID",
		"FUNCODE1",
		"IB",
		"IB_ERRC",
		"IB_ERRS",
		"IMEI",
		"IR",
		"IR_ERRC",
		"IR_ERRS",
		"IY",
		"IY_ERRC",
		"IY_ERRS",
		"KWH",
		"KWH_ERRC",
		"KWH_ERRS",
		"METERSRNO",
		"PF",
		"PF_ERRC",
		"PF_ERRS",
		"POW",
		"POW_ERRC",
		"POW_ERRS",
		"RAWDATA",
		"APOW",
		"SCRIPTCODE",
		"SF1REGGROUPCNT",
		"STATUS",
		"SUBFRAMECNT",
		"UBPC",
		"VBN",
		"VBN_ERRC",
		"VBN_ERRS",
		"VRN",
		"VRN_ERRC",
		"VRN_ERRS",
		"VYN",
		"VYN_ERRC",
		"VYN_ERRS",
		"Last Seen"]];
	
		const data =datas?.data?.data?.map(item=> [ item?.id,
		item?.CONFIGUNIQUEID,
		item?.CONSUMER_UNIQUE_ID,
		item?.FUNCODE1,
		item?.IB,
		item?.IB_ERRC,
		item?.IB_ERRS,
		item?.IMEI,
		item?.IR,
		item?.IR_ERRC,
		item?.IR_ERRS,
		item?.IY,
		item?.IY_ERRC,
		item?.IY_ERRS,
		item?.KWH,
		item?.KWH_ERRC,
		item?.KWH_ERRS,
		item?.METERSRNO,
		item?.PF,
		item?.PF_ERRC,
		item?.PF_ERRS,
		item?.POW,
		item?.POW_ERRC,
		item?.POW_ERRS,
		item?.RAWDATA,
		item?.APOW,
		item?.SCRIPTCODE,
		item?.SF1REGGROUPCNT,
		item?.STATUS,
		item?.SUBFRAMECNT,
		item?.UBPC,
		item?.VRN,
		item?.VRN_ERRC,
		item?.VRN_ERRS,
		item?.VYN,
		item?.VYN_ERRC,
		item?.VYN_ERRS,
		item?.VBN,
		item?.VBN_ERRC,
		item?.VBN_ERRS,
		moment.utc(item?.ENTRYTS,'','Asia/Kolkata').format("DD/MM/YYYY hh:mm:ss a")]);
	
		let content = {
		  startY: 50,
		  head: headers,
		  body: data
		};
	
		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save(`GSMbuffer.pdf`)
		setBtn(false)
		setIsModalVisible(false)
			} else {
		setBtn(false)
				
			}
			}
		
	  }

	  const excelFileExport=async()=>{
		if(dates.start!==''&&dates.end!==''){
		setBtn(true)
			const datas=await bufferDataBYDate(dates)
			if (datas.success === true) {
		const worksheet = XLSX.utils.json_to_sheet(datas.data.data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		XLSX.writeFile(workbook, "GSMbuffer.xlsx");
		setBtn(false)
		setIsModalVisible(false)
			}
		}
	  }
 
  
	useEffect(() => {
		onHandalBuffer()
	}, [currentPage,PageSize]);
  
	const onChange = (date, dateString) => {
	setDates({...dates,start:moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD')})
	  };
	  const onChanges = (date, dateString) => {
	setDates({...dates,end:moment(dateString,'DD-MM-YYYY').format('YYYY-MM-DD')})
	  };

	  

	  const onDownloadcsv=async()=>{
		if(dates.start!==''&&dates.end!==''){
		const datas=await bufferDataBYDate(dates)
		
		if (datas.success === true) {
			setDownData(datas.data.data)
		csvLink.current.link.click()
		} else {
			
		}
		}
	  }

	return (
		<>
		<Modal title="download File" visible={isModalVisible} footer="" onCancel={()=>setIsModalVisible(false)}>
		<Space direction="horizontal">
		<div className="d-flex flex-column">Start In
    <DatePicker onChange={onChange} name='start' format='DD-MM-YYYY' disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }}/></div>
	<div className="d-flex flex-column" >End In
    <DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
              return (moment(expireDate.start_date).add(-1,'days')>=current)||(moment(expireDate.end_date).add(0,'days')<=current)  ;
            }}/></div>
	</Space>
	<div className="mt-3">
	<a className={`btn btn-info text-white ${Btn?'disabled':''}`} onClick={exportPDF} >
														PDF
													</a>
													<a className={`btn btn-primary text-white ml-2 ${Btn?'disabled':''}`} onClick={excelFileExport}>
														Excel
													</a>
													<a className={`btn btn-danger text-white ml-2 ${Btn?'disabled':''}`} onClick={onDownloadcsv}>
														CSV
														<CSVLink data={DownData} filename={"GSMBufferDat.csv"} ref={csvLink}/>
													</a>
													</div>
      </Modal>
			<div className="main-content">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/gsm-buffer">
								<Layers style={{ height: "16px" }} /> Buffer
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/gsm-buffer">
								<i className="fas fa-list"></i> GSM Buffer
							</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4>GSM Buffer</h4>
										<div className="card-header-action">
											<Link  className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus  text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2">
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													title="Download"
													onClick={showModal}
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												{/* <div className="dropdown-menu">
													<a className="dropdown-item" href="https://eums.memighty.com/Export/download_gsmconsumer_pdf">
														PDF
													</a>
													<a className="dropdown-item" href="https://eums.memighty.com/Export/exportexcelgsmconsumer">
														Excel
													</a>
													<a className="dropdown-item" href="https://eums.memighty.com/Export/exportcsvgsmconsumer">
														CSV
													</a>
												</div> */}
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e)=>(setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<table id="table_id" className="table table-striped">
													<thead>
														<tr>
															<th className="text-center">Unique No</th>
															<th>CONFIGUNIQUEID</th>
															<th>CONSUMER UNIQUE ID</th>
															<th>FUNCODE1</th>
															<th>IB</th>
															<th>IB_ERRC</th>
															<th>IB_ERRS</th>
															<th>IMEI</th>
															<th>IR</th>
															<th>IR_ERRC</th>
															<th>IR_ERRS</th>
															<th>IY</th>
															<th>IY_ERRC</th>
															<th>IY_ERRS</th>
															<th>KWH</th>
															<th>KWH_ERRC</th>
															<th>KWH_ERRS</th>
															<th>METERSRNO</th>
															<th>PF</th>
															<th>PF_ERRC</th>
															<th>PF_ERRS</th>
															<th>POW</th>
															<th>POW_ERRC</th>
															<th>POW_ERRS</th>
															<th>RAWDATA</th>
															<th>APOW</th>
															<th>SCRIPTCODE</th>
															<th>SF1REGGROUPCNT</th>
															<th>STATUS</th>
															<th>SUBFRAMECNT</th>
															<th>UBPC</th>
															<th>VBN</th>
															<th>VBN_ERRC</th>
															<th>VBN_ERRS</th>
															<th>VRN</th>
															<th>VRN_ERRC</th>
															<th>VRN_ERRS</th>
															<th>VYN</th>
															<th>VYN_ERRC</th>
															<th>VYN_ERRS</th>
															<th style={{minWidth:'100px'}}>Last Seen</th>
														</tr>
													</thead>
													<tbody>
														{Bufferdata.length>0 &&
															Bufferdata.map((item, id) => (
																<tr className="trheigth">
																	<td>{item?.id}</td>
																	<td>{item?.CONFIGUNIQUEID}</td>
																	<td>{item?.CONSUMER_UNIQUE_ID}</td>
																	<td>{item?.FUNCODE1}</td>
																	<td>{item?.IB}</td>
																	<td>{item?.IB_ERRC}</td>
																	<td>{item?.IB_ERRS}</td>
																	<td>{item?.IMEI}</td>
																	<td>{item?.IR}</td>
																	<td>{item?.IR_ERRC}</td>
																	<td>{item?.IR_ERRS}</td>
																	<td>{item?.IY}</td>
																	<td>{item?.IY_ERRC}</td>
																	<td>{item?.IY_ERRS}</td>
																	<td>{item?.KWH}</td>
																	<td>{item?.KWH_ERRC}</td>
																	<td>{item?.KWH_ERRS}</td>
																	<td>{item?.METERSRNO}</td>
																	<td>{item?.PF}</td>
																	<td>{item?.PF_ERRC}</td>
																	<td>{item?.PF_ERRS}</td>
																	<td>{item?.POW}</td>
																	<td>{item?.POW_ERRC}</td>
																	<td>{item?.POW_ERRS}</td>
																	<td>{item?.RAWDATA}</td>
																	<td>{item?.APOW}</td>
																	<td>{item?.SCRIPTCODE}</td>
																	<td>{item?.SF1REGGROUPCNT}</td>
																	<td>{item?.STATUS}</td>
																	<td>{item?.SUBFRAMECNT}</td>
																	<td>{item?.UBPC}</td>
																	<td>{item?.VBN}</td>
																	<td>{item?.VBN_ERRC}</td>
																	<td>{item?.VBN_ERRS}</td>
																	<td>{item?.VRN}</td>
																	<td>{item?.VRN_ERRC}</td>
																	<td>{item?.VRN_ERRS}</td>
																	<td>{item?.VYN}</td>
																	<td>{item?.VYN_ERRC}</td>
																	<td>{item?.VYN_ERRS}</td>
																	<td>{moment.utc(item?.ENTRYTS,'','Asia/Kolkata').format("DD/MM/YYYY hh:mm:ss a")}</td>
																</tr>
															))}
													</tbody>
												</table>
											</div>
										</div>
                    <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={numberofPage||1}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
