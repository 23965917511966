import React, { useEffect, useState } from 'react'
import {LoginUesr} from '../Apicommon'
import {toast} from 'react-toastify'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const Login = (props) => {
const [login,setLogin]=useState({email:'',password:''})
const [loginErrors, setValidation] = useState({});
const [flag,setFlag]=useState(false)
const history=useHistory()
const onHandalChange=(e)=>{
  setLogin({...login,[e.target.name]:e.target.value})
}

let token =window.localStorage.getItem("token");
useEffect(() => {
  if(token&&token!==""){
    history.push('/group-dashboard')
  }
}, []);
const validation = (name, value) => {
  switch (name) {
      case 'email':
          if (!value) {
              return 'Please input email!'
          } else {
              return '';
            }
            case 'password':
          if (!value) {
              return 'Please input password!'
          } else {
              return '';
            }
            default: {
              return null;
          }
      }
      }

  const onFormSubmit=async(e)=>{
    e.preventDefault()
    setFlag(true)
    const userData={
      email:login.email,
      password:login.password
    }
    let allErrors = {};
    Object.keys(login).forEach(key => {
      const error = validation(key, userData[key]);
      if (error && error.length) {
          allErrors[key] = error
      }
  });

  if (Object.keys(allErrors).length) {
    setFlag(false)

    return setValidation(allErrors)
} else {
  setValidation({})
  const resultData=await LoginUesr(login)
  if (resultData.success === true) {
    toast.success("Login Success")
    window.localStorage.setItem('token',resultData?.data?.userData[0]?.token)
    window.localStorage.setItem('user_info',JSON.stringify(resultData?.data?.userData[0]))
    window.localStorage.setItem('login_time',moment().utcOffset("+05:30"))

    props.history.push('/group-dashboard')
    setFlag(false)

  }else if(resultData?.err?.response?.status===401) {
    toast.error("Invalid Password And User Email")
    setFlag(false)

  }else if(resultData?.err?.response?.status===400) {
    toast.error("Invalid Password And User Email")
    setFlag(false)

  }
}
   }

  return (
   <>
   <section className="section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="card card-primary">
              <div className="card-header">
                <h4>Login</h4>
              </div>
              <div className="card-body">
                <form className="needs-validation" onSubmit={onFormSubmit}>
                  <div className="form-group">
                    <label for="email">Email</label>
                    <input id="email" type="email" className="form-control" name="email" tabIndex="1"   onChange={onHandalChange} value={login.email}/>
                    <p className="text-danger">{loginErrors?.email||''}</p>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label for="password" className="control-label">Password</label>
                      <div className="float-right">
                        <a href="auth-forgot-password.html" className="text-small">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <input id="password" type="password" className="form-control" name="password" tabIndex="2"  onChange={onHandalChange} value={login.password}/>
                    <p className="text-danger">
                      {loginErrors?.password||''}
                    </p>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me"/>
                      <label className="custom-control-label" for="remember-me">Remember Me</label>
                    </div>
                  </div>
                  <div className="form-group">
                  {flag?
                    <button type="submit" className="btn btn-primary btn-lg btn-progress disabled " tabIndex="4" style={{width: "100%"}}>
                      progress
                    </button>
                  :  <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
                      Login
                    </button>}
                  </div>
                </form>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
   </>
  )
}

export default Login
