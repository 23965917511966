import { create } from "@mui/material/styles/createTransitions";
import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { consumerRolesData, editRolesPermission, FindTabName, getCardNameAndId, IsertRolesUserData, RolesActiveIn } from "../Apicommon";
import { BootstrapTooltip } from "../common";
import swal from "sweetalert2";
window.Swal = swal;

export default function AddRoles() {
	const [permission, setPermission] = useState({});
	const [CardPermission, setCardPermission] = useState({card_permission_edit:[],card_permission_delete:[],card_permission:[],card_create:false});
	const [CardList, setCardList] = useState({});

	const [Modempermission, setModemPermission] = useState({});
	const [ModempermissionList, setModemPermissionList] = useState({});
	const [TabList, setTabList] = useState([]);
	const [roles,setroles]=useState({ROLES_NAME:'',Descriptions:'',active:''})
	const [roleId,setRolesId]=useState(null)
	const [disabled,setDisable]=useState(true)
	const [TableByTab,setTableByTab]=useState({isopen:false,TabId:''})
	const [TableByMainCard,setTableByMainCard]=useState({isopen:false,MainCardId:''})
	const [TableBySubCard,setTableBySubCard]=useState({isopen:false,SubCardId:''})
	const location = useLocation();
	const history = useHistory();

	const onAddRolesUser=async(e)=>{
		e.preventDefault()
		setDisable(true)
		const obj={
			...roles,
			ROLES_PERMISSION:JSON.stringify(permission),
			modem_permission:JSON.stringify(Modempermission),
			CardPermission:JSON.stringify(CardPermission)
		}
		
		if(roles.name!==''){
			if(roleId===null){
		const data=await IsertRolesUserData(obj)
		if(data.success===true){
		setDisable(false)
			toast.success("Add Roles Success")
		setPermission({})
		setroles({ROLES_NAME:'',Descriptions:''})
		history.push('/roles-users')
	}else{
		setDisable(false)
		toast.error('Exist Role Name')
	}
}else{
	const data=await editRolesPermission(obj,roleId)
		if(data.success===true){
			toast.success("Update Roles Success")
		setDisable(false)
		setPermission({})
		setroles({ROLES_NAME:'',Descriptions:''})
		history.push('/roles-users')
	}else{
		toast.error('Enter Valid Information')
		setDisable(false)

	}
}
	}else{
		toast.error('Enter Valid Information')
		setDisable(false)

	}
}



useEffect(() => {
	if(location?.state){
		setRolesId(location?.state?.ID)
		setroles({ROLES_NAME:location?.state?.ROLES_NAME,Descriptions:location?.state?.Descriptions,active:location?.state?.active})
		setDisable(false)
		setCardPermission({card_permission:JSON.parse(location?.state?.card_permission||'{}')?.card_permission||[],card_create:JSON.parse(location?.state?.card_permission||'{}')?.card_create,card_permission_edit:JSON.parse(location?.state?.card_permission||'{}')?.card_permission_edit||[],card_permission_delete:JSON.parse(location?.state?.card_permission||'{}')?.card_permission_delete||[]})
		// setPermission(JSON.parse(location?.state?.ROLES_PERMISSION))
	}
}, [location]);

const onFindTabName = async () => {
	// if(roleId===null){
	var obj={create:false}
	const data = await FindTabName();
	if (data.success === true) {
		setTabList(data.data.data);
		data.data.data.map((item)=>{
			obj[item.TAB_NAME]={
				view: false,
				delete: false,
				edit: false}
			})
			if(location?.state){
			
				const userData=JSON.parse(location?.state?.ROLES_PERMISSION)
				obj.create=userData.create
				data.data.data.map((item)=>{
					if(userData[item.TAB_NAME]){
					obj[item.TAB_NAME]=userData[item.TAB_NAME]
					}else{
						obj[item.TAB_NAME]={
				view: false,
				delete: false,
				edit: false}
					}
				})
				setPermission(obj)

			}else{
				setPermission(obj)
			}

	} else {
	}
// }
};

const onconsumerRolesData=async()=>{
	var obj={}
	const data = await consumerRolesData();
	if (data.success === true) {
		setModemPermissionList(data.data.data)
		data.data.data.map((item)=>{
			obj[item.IMEI]={
				view: true
			}	
		})
		if(location?.state?.modem_permission){
			const userData=JSON.parse(location?.state?.modem_permission)
			data.data.data.map((item)=>{
				if(userData[item.IMEI]){
				obj[item.IMEI]=userData[item.IMEI]
				}else{
					obj[item.IMEI]={
			view: true}
				}
			})
			setModemPermission(obj)
		}else{
		setModemPermission(obj)
	}
	}else{
		
	}
}

const onSetCardDetails=async()=>{
const data=await getCardNameAndId()
if(data.success===true){
	setCardList(data.data.data)
}else{
	console.log('something wait wrong');
}
}



useEffect(()=>{
	onFindTabName()
	onconsumerRolesData()
	onSetCardDetails()
},[])


	const onHandalChange=(e)=>{
		setroles({...roles,[e.target.name]:e.target.value})
	}

	const onCheckChange=(e,name)=>{
		const obj={...permission}
		if(e.target.checked){
		permission[name][e.target.name]=true
		}else{
			permission[name][e.target.name]=false
		}
		setPermission({...obj})
	}

	const onCheckChangeModem=(e,name)=>{
		const obj={...Modempermission}
		if(e.target.checked){
			Modempermission[name][e.target.name]=true
		}else{
			Modempermission[name][e.target.name]=false
		}
		setModemPermission({...obj})
	}

	const onHandalCard=(e,id,tabid,priority,subId)=>{
		const TabDetail=TabList&&TabList.filter((info)=>(info.TABID==tabid))[0]
		const obj={...permission}
var permission1=CardPermission.card_permission
if(e.target.checked){
	permission1.push(id)
	obj[TabDetail.TAB_NAME][e.target.name]=true
	if(priority==2){
	const subCardTotal=permission1.filter((item)=>(item==subId))
		if(subCardTotal.length==0){
			permission1.push(subId)
		}
	}
}else{
	permission1=permission1.filter((item)=>(item!==id))
	const filterData=CardList.filter((data)=>(permission1.filter((item)=>(item==data?.ID&&data?.card_Id_level_2==id&&(data?.priority==2||data?.priority==3))).length>0)).map((item)=>(item.ID))
	permission1=permission1.filter((item)=>(filterData.filter((data)=>(data==item)).length==0))
	if(priority==2){
		const filterData1=CardList.filter((data)=>(permission1.filter((item)=>(item==data?.ID&&data?.card_Id_level_2==subId&&(data?.priority==2||data?.priority==3))).length>0))
		if(filterData1.length===0){
		permission1=permission1.filter((item)=>(item!==subId))
		}
		}
	if(permission1.length==0){
	obj[TabDetail.TAB_NAME][e.target.name]=false
	}
}
setCardPermission({...CardPermission,card_permission:[...permission1]})
setPermission({...obj})

	}

	const onHandalCardedit=(e,id,tabid,priority,subId)=>{
		const TabDetail=TabList&&TabList.filter((info)=>(info.TABID==tabid))[0]
		const obj={...permission}
		var permission1=CardPermission.card_permission_edit
if(e.target.checked){
	permission1.push(id)
	obj[TabDetail.TAB_NAME][e.target.name]=true
	if(priority==2){
	const subCardTotal=permission1.filter((item)=>(item==subId))
		if(subCardTotal.length==0){
			permission1.push(subId)
		}
	}
}else{
	permission1=permission1.filter((item)=>(item!==id))
	const filterData=CardList.filter((data)=>(permission1.filter((item)=>(item==data?.ID&&data?.card_Id_level_2==id&&(data?.priority==2||data?.priority==3))).length>0)).map((item)=>(item.ID))
	permission1=permission1.filter((item)=>(filterData.filter((data)=>(data==item)).length==0))
	if(priority==2){
		const filterData1=CardList.filter((data)=>(permission1.filter((item)=>(item==data?.ID&&data?.card_Id_level_2==subId&&(data?.priority==2||data?.priority==3))).length>0))
		if(filterData1.length===0){
		permission1=permission1.filter((item)=>(item!==subId))
		}
		}
	if(permission1.length==0){
	obj[TabDetail.TAB_NAME][e.target.name]=false
}
}
setCardPermission({...CardPermission,card_permission_edit:[...permission1]})
setPermission({...obj})

	}
	const onHandalCarddelete=(e,id,tabid,priority,subId)=>{
		const TabDetail=TabList&&TabList.filter((info)=>(info.TABID==tabid))[0]
		const obj={...permission}
		var permission1=CardPermission.card_permission_delete
if(e.target.checked){
	permission1.push(id)
	obj[TabDetail.TAB_NAME][e.target.name]=true
	if(priority==2){
	const subCardTotal=permission1.filter((item)=>(item==subId))
		if(subCardTotal.length==0){
			permission1.push(subId)
		}
	}

}else{
	permission1=permission1.filter((item)=>(item!==id))
	const filterData=CardList.filter((data)=>(permission1.filter((item)=>(item==data?.ID&&data?.card_Id_level_2==id&&(data?.priority==2||data?.priority==3))).length>0)).map((item)=>(item.ID))
	permission1=permission1.filter((item)=>(filterData.filter((data)=>(data==item)).length==0))
	if(priority==2){
		const filterData1=CardList.filter((data)=>(permission1.filter((item)=>(item==data?.ID&&data?.card_Id_level_2==subId&&(data?.priority==2||data?.priority==3))).length>0))
		if(filterData1.length===0){
		permission1=permission1.filter((item)=>(item!==subId))
		}
		}
	if(permission1.length==0){
	obj[TabDetail.TAB_NAME][e.target.name]=false
	}
}
setCardPermission({...CardPermission,card_permission_delete:[...permission1]})
setPermission({...obj})

}

	const onRolesActiveIn=async(status)=>{
		swal.fire({
			title: "Are you sure?",
			text: `${status===1?'Once active, you will be able to recover this User Details!':'Once deleted, you will not be able to recover this User Details!'}`,
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data=await  RolesActiveIn(roleId,{active:status})
				if (data.success === true) {
					history.push('/roles-users')
					toast.success(`${status===1?'Active User Success':'Inactive User Success'}`);
				}
			} else if (willDelete.isDismissed) {
				swal.fire("Roles Details is safe");
			}
		});
	}

	return (
		<>
			<div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-Dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/roles-users">
								<Users style={{ height: "16px" }} /> Roles & Users
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/roles-users">Roles</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >{roleId===null?`Add User Roles`:'Update User Roles'}</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4>{roleId===null?`Add User Roles`:'Update User Roles'}</h4>
										<div className="card-header-action">
										{roleId!==null?<div  class="btn btn-info text-white btnedit mr-2"><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" onClick={()=>(setDisable(true))}><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{roleId!==null&&roles.active===1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={()=>(onRolesActiveIn(0))}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{roleId!==null&&roles.active===0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={()=>(onRolesActiveIn(1))}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''}
											<Link to="/roles-users" className="btn btn-info text-white align-left">
												Back
											</Link>
										</div>
									</div>
									<div className="card-body">
										<form>
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputPassword4">
														Name:<span class="text-danger">*</span>
													</label>
													<input
														type="text"
														class="form-control"
														name="ROLES_NAME"
														placeholder="Enter Role name"
														onChange={onHandalChange}
														required=""
														value={roles.ROLES_NAME}
														disabled={!disabled}
													/>
												</div>
											</div>
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputPassword4">Description:</label>
													<textarea
														type="text"
														class="form-control"
														name="Descriptions"
														placeholder="Enter Role Description"
														onChange={onHandalChange}
														value={roles.Descriptions}
														required=""
														disabled={!disabled}

													/>
												</div>
											</div>
											<h4>Dashboard permission</h4>
											<table class="table border" style={{ marginTop: "10px" }}>
												{/* <thead>
												<tr>
												<th scope="col" class="thalignleft" style={{ width: "15%" }}>Tab Create</th>
												<th scope="col" class="thalign" style={{ width: "12%" }}>
															Create
														</th>
														</tr>
												</thead> */}
												{/* <tbody>
												<tr>
												<th scope="row" class="thalignleft">
															Create Tab
														</th>
														<th scope="row" class="thalignleft">
														<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='add'
																	onChange={(e)=>e.target.checked?setPermission({...permission,create:true}):setPermission({...permission,create:false})}
																	checked={permission.create}
																	disabled={!disabled}
																/>
															</div>
														</th>
												</tr>
												</tbody> */}
<tbody>
<tr class="" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}><th scope="row" class="tw-text-start" style={{width:'250px'}}><h5 class="tw-text-lg tw-font-normal tw-mb-0 ">Tab</h5></th><td class=" tw-text-start tw-font-normal tw-py-3"><div class="pretty p-icon p-curve p-pulse vertical-align d-flex"><input
																	type="checkbox"
																	name='add'
																	onChange={(e)=>e.target.checked?setPermission({...permission,create:true}):setPermission({...permission,create:false})}
																	checked={permission.create}
																	disabled={!disabled}
																/><div class="state p-info-o ml-2"><label class="mb-0"> Create</label></div></div></td>
																
																</tr>
																<tr style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
																<th scope="row" class=" tw-text-start"><h5 class="tw-text-lg tw-font-normal tw-mb-0 ">Card</h5></th><td class=" tw-text-start tw-font-normal tw-py-3"><div class="pretty p-icon p-curve p-pulse vertical-align d-flex"><input
																	type="checkbox"
																	name='add'
																	onChange={(e)=>e.target.checked?setCardPermission({...CardPermission,card_create:true}):setCardPermission({...CardPermission,card_create:false})}
																	checked={CardPermission.card_create}
																	disabled={!disabled}
																/><div class="state p-info-o ml-2"><label class="mb-0"> Create</label></div></div></td>
																</tr>
<tr class="rounded"><td colspan="4">
<table class="table border" style={{ marginTop: "10px" }}>
												<thead>
													<tr>
														<th scope="col" class="thalignleft" style={{ width: "30%" }}>
															Tab permission Name
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															View
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Edit
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Active/Inactive
														</th>
													</tr>
												</thead>
												<tbody>
												{Object.entries(permission).map(([key,value])=>{return(
													key!=='create'?(<><tr className={"odd"}>
														<th scope="row" class="thalignleft">
														{TableByTab?.isopen===true&&TableByTab?.TabId==key?(<a	className="btn btn- text-secondary" onClick={()=>(setTableByTab({isopen:false,TabId:''}))}>
														<svg class="svg-inline--fa fa-angle-down" style={{height: "18px", width: "15px"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path></svg>
																		</a>):<><a className="btn btn- text-secondary" onClick={()=>(setTableByTab({isopen:true,TabId:key}))}>
																			<i className="fas fa-angle-right" style={{height: "18px",width: "15px"}}></i>
																		</a></>}	{key}
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='view'
																	onChange={(e)=>onCheckChange(e,key)}
																	checked={permission[key].view}
																	disabled={!disabled}

																/>
															</div>
														</th>
														<th class="thalign old">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='edit'
																	onChange={(e)=>onCheckChange(e,key)}
																	checked={permission[key].edit}
																	disabled={!disabled}

																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='delete'
																	onChange={(e)=>onCheckChange(e,key)}
																	checked={permission[key].delete}
																	disabled={!disabled}

																/>
															</div>
														</th>
													</tr>
													{TableByTab?.isopen===true&&TableByTab?.TabId==key?<tr class="rounded"><td colspan="4">
													<table class="table border" style={{ marginTop: "10px" }}>
												<thead>
													<tr>
														<th scope="col" class="thalignleft" style={{ width: "30%" }}>
															Main Card permission
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															View
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Edit
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Delete
														</th>
													</tr>
												</thead>
												<tbody>
												{CardList.length>0&&CardList.map((item)=>{return TabList&&TabList.filter((info)=>(info.TAB_NAME==key))[0]?.TABID===item?.card_tab_id&&item?.priority==1?(<>
													<tr className={"odd"}>
													<th scope="row" class="thalignleft">
													{TableByMainCard?.isopen===true&&TableByMainCard?.MainCardId==item?.ID?(<a	className="btn btn- text-secondary" onClick={()=>(setTableByMainCard({isopen:false,MainCardId:''}))}>
														<svg class="svg-inline--fa fa-angle-down" style={{height: "18px", width: "15px"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path></svg>
																		</a>):<><a className="btn btn- text-secondary" onClick={()=>(setTableByMainCard({isopen:true,MainCardId:item?.ID}))}>
																			<i className="fas fa-angle-right" style={{height: "18px",width: "15px"}}></i>
																		</a></>}	{item?.CARD_NAME} {item?.card_description?('('+item?.card_description+')'):''}
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='view'
																	onChange={(e)=>onHandalCard(e,item?.ID,item?.card_tab_id,item?.priority)}
																	checked={CardPermission?.card_permission?.filter((items) => items === item.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='edit'
																	onChange={(e)=>onHandalCardedit(e,item?.ID,item?.card_tab_id,item?.priority)}
																	checked={CardPermission?.card_permission_edit?.filter((items) => items === item.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='delete'
																	onChange={(e)=>onHandalCarddelete(e,item?.ID,item?.card_tab_id,item?.priority)}
																	checked={CardPermission?.card_permission_delete?.filter((items) => items === item.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
													</tr>
													{TableByMainCard?.isopen===true&&TableByMainCard?.MainCardId==item?.ID?<tr class="rounded"><td colspan="4">
													<table class="table border" style={{ marginTop: "10px" }}>
												<thead>
													<tr>
														<th scope="col" class="thalignleft" style={{ width: "30%" }}>
															Sub Card permission
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															View
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Edit
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Delete
														</th>
													</tr>
												</thead>
												<tbody>
												{CardList.length>0&&CardList.map((data1)=>{return TabList&&TabList.filter((info1)=>(info1.TAB_NAME==key))[0]?.TABID===data1?.card_tab_id&&data1?.priority==2&&data1?.card_Id_level_2==item?.ID?(<>
													<tr className={"odd"}>
													<th scope="row" class="thalignleft">
													{TableBySubCard?.isopen===true&&TableBySubCard?.SubCardId==data1?.ID?(<a	className="btn btn- text-secondary" onClick={()=>(setTableBySubCard({isopen:false,SubCardId:''}))}>
														<svg class="svg-inline--fa fa-angle-down" style={{height: "18px", width: "15px"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path></svg>
																		</a>):<><a className="btn btn- text-secondary" onClick={()=>(setTableBySubCard({isopen:true,SubCardId:data1?.ID}))}>
																			<i className="fas fa-angle-right" style={{height: "18px",width: "15px"}}></i>
																		</a></>}	{data1?.CARD_NAME} {data1?.card_description?('('+data1?.card_description+')'):''}
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='view'
																	onChange={(e)=>onHandalCard(e,data1?.ID,data1?.card_tab_id,data1?.priority,data1?.card_Id_level_2)}
																	checked={CardPermission?.card_permission?.filter((items) => items === data1.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='edit'
																	onChange={(e)=>onHandalCardedit(e,data1?.ID,data1?.card_tab_id,data1?.priority,data1?.card_Id_level_2)}
																	checked={CardPermission?.card_permission_edit?.filter((items) => items === data1.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='delete'
																	onChange={(e)=>onHandalCarddelete(e,data1?.ID,data1?.card_tab_id,data1?.priority,data1?.card_Id_level_2)}
																	checked={CardPermission?.card_permission_delete?.filter((items) => items === data1.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
													</tr>
													{TableBySubCard?.isopen===true&&TableBySubCard?.SubCardId==data1?.ID?<tr class="rounded"><td colspan="4">
													<table class="table border" style={{ marginTop: "10px" }}>
												<thead>
													<tr>
														<th scope="col" class="thalignleft" style={{ width: "30%" }}>
															Card permission
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															View
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Edit
														</th>
														<th scope="col" class="thalign" style={{ width: "12%" }}>
															Delete
														</th>
													</tr>
												</thead>
												<tbody>
												{CardList.length>0&&CardList.map((data2)=>{return TabList&&TabList.filter((info1)=>(info1.TAB_NAME==key))[0]?.TABID===data2?.card_tab_id&&data2?.priority==3&&data2?.level_2_card_id_level_3==item?.ID?(<>
													<tr className={"odd"}>
													<th scope="row" class="thalignleft">
															{data2?.CARD_NAME} {data2?.card_description?('('+data2?.card_description+')'):''}
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='view'
																	onChange={(e)=>onHandalCard(e,data2?.ID)}
																	checked={CardPermission?.card_permission?.filter((items) => items === data2.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='view'
																	onChange={(e)=>onHandalCardedit(e,data2?.ID)}
																	checked={CardPermission?.card_permission_edit?.filter((items) => items === data2.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='view'
																	onChange={(e)=>onHandalCarddelete(e,data2?.ID)}
																	checked={CardPermission?.card_permission_delete?.filter((items) => items === data2.ID).length > 0 ? true : false}
																	disabled={!disabled}
																/>
															</div>
														</th>
													</tr>
													
													</>
												):''})}
												</tbody>
												</table>
													</td></tr>:''}
													</>
												):''})}
												</tbody>
												</table>
													</td></tr>:''}
													</>
												):''})}
												</tbody>
												</table>
													</td></tr>:''}
													
													</>):''
												)})}
													
												</tbody>
											</table>
</td></tr>
</tbody>
												</table>
											
											{/* <table class="table border" style={{ marginTop: "10px" }}>
												<thead>
												<tr>
												<th scope="col" class="thalignleft" style={{ width: "15%" }}>Card Create permission</th>
												<th scope="col" class="thalign" style={{ width: "12%" }}>
												Card Create 
														</th>
														</tr>
												</thead>
												<tbody>
												<tr>
												<th scope="row" class="thalignleft">
														Card Create 
														</th>
														<th scope="row" class="thalignleft">
														<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='add'
																	onChange={(e)=>e.target.checked?setCardPermission({...CardPermission,card_create:true}):setCardPermission({...CardPermission,card_create:false})}
																	checked={CardPermission.card_create}
																	disabled={!disabled}
																/>
															</div>
														</th>
												</tr>
												</tbody>

												</table> */}
											
											<table class="table border" style={{ marginTop: "10px" }}>
												<thead>
													<tr>
													<th scope="row" class="thalignleft" style={{ width: "20%" }}>
													CONSUMER MODEM NAME
														</th>
														<th scope="row" class="thalignleft"style={{ width: "20%" }}>
														CONSUMER MODEM SRNO
														</th>
														<th scope="col" class="thalignleft" style={{ width: "20%" }}>
															Modem permission IMEI
														</th>
														<th scope="col" class="thalign" style={{ width: "20%" }}>
															View
														</th>
														<th scope="col" class="thalign" style={{ width: "20%" }}>
															Edit
														</th>
													</tr>
												</thead>
												<tbody>
												{Object.entries(Modempermission).map(([key,value])=>{return(
													<tr className={"odd"}>
													<th scope="row" class="thalignleft">
															{ModempermissionList&&ModempermissionList.map((item)=>(item.IMEI==key?item.CONSUMER_MODEM_NAME_U:''))}
														</th>
														<th scope="row" class="thalignleft">
															{ModempermissionList&&ModempermissionList.map((item)=>(item.IMEI==key?item.CONSUMER_MODEM_SRNO_U:''))}
														</th>
													<th scope="row" class="thalignleft">
															{key}
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='view'
																	onChange={(e)=>onCheckChangeModem(e,key)}
																	checked={Modempermission[key].view}
																	disabled={!disabled}
																/>
															</div>
														</th>
														<th class="thalign even">
															<div class="pretty p-icon p-curve p-pulse vertical-align thalign">
																<input
																	type="checkbox"
																	name='edit'
																	onChange={(e)=>onCheckChangeModem(e,key)}
																	checked={Modempermission[key].edit}
																	disabled={!disabled}
																/>
															</div>
														</th>
													</tr>
												)})}
												</tbody>
												</table>

											<div class="form-group" style={{marginLeft:"20px"}}>
												<div class="text-left">
														
													<input type="submit" name="submit" class="btn btn-danger ml-2" value={roleId===null?`Submit`:`Updated`} id="submitvn" disabled={!disabled}  onClick={onAddRolesUser}/>
													<Link
														class="btn btn-light text-dark ml-2"
														to='/roles-users'
													>
														Cancel
													</Link>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
