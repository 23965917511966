import React, { useEffect, useState} from "react";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { Link,useHistory,useParams,useLocation } from "react-router-dom";
import { Briefcase } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import { BootstrapTooltip } from "../common";
import { getSlavesDetailbySrno, MetterData } from "../Apicommon";
import moment from "moment";
import { CSVLink } from "react-csv";
// import Viewuser from '../viewuser/Viewuser'
$.fn.DataTable = dt;

export default function Connect_slave_details() {
    const [ActiveSlave, setActiveSlave] = useState([]);
    const [flag,setFlag]=useState(false)
    const location=useLocation()
	const [loader, setloader] = useState(false);
    const params=useParams()
    const onHandalemeterData=async()=>{
        setloader(true);
		$(".loader").fadeOut("slow");
        const data=await MetterData()
		if (data.success === true) {
const arrayoFdata=[]
await data.data.data.length>0 &&data.data.data.map((item, id) => {
                                                                var slave=JSON.parse(item.SLAVES_ID_U)
                                                                slave.DETAILS_U.map((data)=>{
                                                                        arrayoFdata.push(data)
                                                                    })
                                                            })
        const data2=await getSlavesDetailbySrno(params.SRNO,location?.state?.active)
		if (data2.success === true) {
            if(location?.state?.active===true){
               const arraySlaves= await arrayoFdata.filter((item)=>(JSON.parse(data2.data.data[0].ACTIVE_SLAVES_DETAILS).some((data)=>(item?.CONSSLAVEUID_S==data.consumer_unique_id))))
			   console.log(arraySlaves);
            setActiveSlave(arraySlaves)
            }else{
				const arraySlaves=await arrayoFdata.filter((item)=>(JSON.parse(data2.data.data[0].INACTIVE_SLAVES_DETAILS).some((data)=>(item?.CONSSLAVEUID_S==data.consumer_unique_id))))
				console.log(arraySlaves);
            setActiveSlave(arraySlaves)
            }
		} else {
			setloader(false);
		}
			
		} else {
			setloader(false);
		}
    }
    // const onHandaleslaveData=async()=>{
    //     setloader(true);
	// 	$(".loader").fadeOut("slow");
        
    // }
    useEffect(()=>{
        setFlag(location?.state?.active)
        onHandalemeterData().then(() =>
        setTimeout(function () {
            $("#table-1").DataTable({destroy: true,"pageLength": 50});
			setloader(false);
        }, 1000)
    );
    
    },[location])
  return (
    <>
        <div className="main-content">
					{loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/group-dashboard">
									<i className="fas fa-tachometer-alt"></i> Home
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link to="/connectivity-deshboard">
									<Briefcase style={{ height: "16px" }} /> Connectivity Deshboard
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link >
									<i className="fas fa-list"></i> Connectivity Slaves Details
								</Link>
							</li>
						</ol>
					</nav>
					<section className="section">
						<div className="section-body">
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<h4>{location?.state?.active===true?'Active Slave Details':'InActive Slave Details'}</h4>
											<div className="card-header-action">
												<Link to="/add-consumer" className="btn btn-info text-white mr-2">
													<BootstrapTooltip title="Add">
														<span>
															<i
																className="fas fa-plus text-white"
																style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
															></i>
														</span>
													</BootstrapTooltip>
												</Link>
												<button type="button" className="btn btn-info mr-2">
													<BootstrapTooltip title="Filter">
														<span>
															<i className="fa fa-filter text-white"></i>
														</span>
													</BootstrapTooltip>
												</button>
												<div className="dropdown d-inline mr-2">
													<button
														className="btn btn-info dropdown-toggle mr-2"
														type="button"
														id="dropdownMenuButton3"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
														data-placement="bottom"
														title="Download"
													>
														<i className="fa fa-download mr-1"></i>
													</button>
													<div className="dropdown-menu">
														<a className="dropdown-item" href="https://eums.memighty.com/Export/download_gsmconsumer_pdf">
															PDF
														</a>
														<a className="dropdown-item" href="https://eums.memighty.com/Export/exportexcelgsmconsumer">
															Excel
														</a>
														<a className="dropdown-item">
														{/* <CSVLink data={meterdata}>CSV</CSVLink> */}
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<table className="table table-striped" id="table-1">
													<thead>
														<tr>
															<th className="text-center">Unique No</th>
															<th >Consumer Slave Id</th>
															<th>Consumer Device Name</th>
															<th>Oprete time</th>
															<th>Script Decode Id</th>
															<th >Load Suravey</th>
															<th>MidNight</th>
															<th>Created At</th>
                                                            <th>Created At Duration</th>
														</tr>
													</thead>
													<tbody>
														{ActiveSlave.length>0 &&
															ActiveSlave.map((data, id) => (
                                                                        <tr style={{ height: "4rem" }} className="trheigth" key={id}>
																		<td>{data.SLAVEID_U}</td>
																		<td>{data.CONSSLAVEUID_S}</td>
																		<td>{data.CONSDEVNAME_U}</td>
																		<td>{data.OPERATETIME_U}</td>
																		<td>{data.SCRIPTDECODEID_S}</td>
																		<td>{data.LOADSURVEY_S}</td>
																		<td>{data.MIDNIGHT_S}</td>
																		<td>{moment(data.CREATED_AT_S).format("DD/MM/YYYY hh:mm:ss a")}</td>
                                    <td>{moment(data.CREATED_AT_S).fromNow(true)}</td>
																	</tr>
                                                        ))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
    </>
  )
}
