import React from 'react'
import { Briefcase } from 'react-feather'
import { Link } from 'react-router-dom'

export default function AddUser() {
  return (
    <>
      <div className="main-content" style={{ minHeight: "562px" }}>
      <nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/group-dashboard">
									<i className="fas fa-tachometer-alt"></i> Home
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link to="/gsm-modem">
									<Briefcase style={{ height: "16px" }} /> Consumer
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link to="/gsm-modem">
									<i className="fas fa-list"></i> GSM Modem
								</Link>
							</li>
              <li className="breadcrumb-item">
								<Link to="/gsm-modem">
									Add Consumer
								</Link>
							</li>
						</ol>
					</nav>
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Add GSM Consumers</h4>
                    <div className="card-header-action">
                      <Link to="/gsm-modem" className="btn btn-info text-white align-left">Back</Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <fieldset className="customLegend">
                          <legend>Distributor Customer Details
                          </legend>
                          <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" style={{ marginLeft: "25px" }}>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="col-lg-12 ">
                                  <div className="form-row">
                                    <div className="form-group col-md-8">
                                      <label for="inputPassword4">Distributor Customer ID:</label>
                                      <input className="form-control" id="fname" name="fname" type="text" placeholder="Distributor Customer ID" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </fieldset>
                      </div>
                      <div className="col-12">
                        <fieldset className="customLegend">
                          <legend>Modem Details</legend>
                          <div className="form-row p-3" style={{ marginLeft: '10px', borderColor: '#e3e6fc !important', position: 'relative', marginTop: '5px' }}>
                            <div className="form-group col-md-3">
                              <label for="inputPassword4">Modem Profile Name <span className="text-danger">*</span></label>
                              <select className="form-select form-control" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                              <span id="modemiddata"></span>
                            </div>
                            <div className="form-group col-md-3">
                              <label for="inputPassword4">IMEI:<span className="text-danger">*</span></label>
                              <input className="form-control" id="deviceIMEI" name="deviceIMEI" type="text" placeholder="IMEI" maxlength="16" onkeyup="return checkimei(this)" />
                              <span className="text-danger" id="imeirepeterror"></span>
                            </div>
                            <div className="form-group col-md-3">
                              <label for="inputPassword4">Modem Serial No:<span className="text-danger">*</span></label>
                              <input className="form-control" id="consmrid" name="consmrid" type="text" placeholder="Device Serial No" required="" />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-12">
                        <fieldset className="customLegend">
                          <legend>Service Provider Details</legend>
                          <div className="form-row p-3" style={{ marginLeft: '10px', borderColor: '#e3e6fc !important', position: 'relative', marginTop: '5px' }}>
                            <div className="form-group col-md-3">
                              <label for="inputPassword4">Service Provider <span className="text-danger">*</span></label>
                              <select id="inputState" className="form-select form-control cer_pro" name="service_provider_id" required="" onchange="return getvalueofdropdown(this)">
                                <option value="">Select Service Provider</option>
                                <option value="SPUID1650352227927">mlora</option>
                              </select>
                              <span id="ser_provider"></span>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="text-left mt-5">
                        <input type="submit" name="submit" className="btn btn-danger mr-2" value="Submit" id="submitvn" />
                        <input type="submit" name="submitandnew" className="btn btn-primary mr-2" value="Submit And New" id="submitandnew" />
                        <Link className="btn btn-light text-dark " to='/gsm-modem'>Cancel</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
