import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getCardDeshboardByCardid, getSubCardDeshboardByCardid } from "../Apicommon";

export default function SlavesTable() {
	const [currentDate, setcurrentDate] = useState(moment(new Date()));
	const [mainCard, setMainCard] = useState({});
	const [consumerSlave, setconsumerSlave] = useState([]);
	const [subCard, setSubCard] = useState({});
	const [subSubCard, setSubSubCard] = useState({});
	const [totalTabledata, setTotalTabledata] = useState({});
	const params = useParams();
	const location = useLocation();
	const ongetSubToSubCardDeshboardByCardid = async () => {
		const data = await getCardDeshboardByCardid(params?.lastcardId);
		if (data.success === true) {
			setSubSubCard(data.data.data[0]);
		} else {
			console.log("something wait wrong");
		}
	};
	const ongetCardDeshboardByCardid = async () => {
		const data = await getCardDeshboardByCardid(params?.SRNO);
		if (data.success === true) {
			setMainCard(data.data.data[0]);
		} else {
			console.log("something wait wrong");
		}
	};
	const ongetSubCardDeshboardByCardid = async () => {
		const data = await getSubCardDeshboardByCardid(params?.subcardId);
		if (data.success === true) {
			setSubCard(data.data.data[0]);
		} else {
			console.log("something wait wrong");
		}
	};
	useEffect(() => {
		ongetCardDeshboardByCardid();
		ongetSubToSubCardDeshboardByCardid();
		ongetSubCardDeshboardByCardid();
	}, []);

	useEffect(() => {
		setconsumerSlave(JSON.parse(location?.state?.payload?.slaves_details || "[]"));
		setTotalTabledata(location.state);
	}, [location]);

	return (
		<>
			<div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<span> configuration Dashboard</span>
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/dashboard-sub-view/${params?.SRNO}/${params?.deshboardId}`}>
								<span>{mainCard?.CARD_NAME}</span>
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to={`/dashboard-sub-to-sub-view/${params?.SRNO}/${params?.deshboardId}/${params?.subcardId}`}>
								<span>{subCard?.CARD_NAME}</span>
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<span>TableView</span>
						</li>
					</ol>
				</nav>
				<div class="row">
					<div class="col-12 col-md-6 col-lg-6 col-xl-6">
						<div class="card">
							<div class="card-header">
								<h4>Main Card Details</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px" }}>
								<div class="row">
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">Card Name: </b>
										{mainCard?.CARD_NAME}
										<br />
										<b class="mr-2">Description:</b> {mainCard?.card_description}
									</div>
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">VRN:</b>
										{mainCard?.payload?.VRN}
										<br />
										<b class="mr-2">VYN:</b>
										{mainCard?.payload?.VYN}
										<br />
										<b class="mr-2">VBN:</b>
										{mainCard?.payload?.VBN}
										<br />
									</div>

									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">IR : </b>
										{mainCard?.payload?.IR}
										<br />
										<b class="mr-2">IY: </b>
										{mainCard?.payload?.IY}
										<br />
										<b>IB: </b>
										{mainCard?.payload?.IB}
										<br />
									</div>
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">KVA : </b>
										{mainCard?.payload?.KVA}
										<br />
										<b class="mr-2">KW : </b>
										{mainCard?.payload?.POW}
										<br />
										<b class="mr-2">PF : </b>
										{mainCard?.payload?.PF}
										<br />
									</div>
								</div>
							</div>
							<div class="card-footer" style={{ display: "flex", justifyContent: "end", padding: "0px" }}>
								<span className="text-end mr-2">
									{currentDate.diff(moment.utc(mainCard?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"), "minutes") === 0
										? moment
												.utc(mainCard?.payload?.ENTRYTS || null)
												.add(-moment().utcOffset(), "m")
												.fromNow(true)
										: currentDate.diff(
												moment.utc(mainCard?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"),
												"minutes"
										  ) + ` minutes `}
									ago
								</span>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-6 col-xl-6">
						<div class="card">
							<div class="card-header">
								<h4>Sub Card Details</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px" }}>
								<div class="row">
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">Card Name: </b>
										{subCard?.CARD_NAME}
										<br />
										<b class="mr-2">Description:</b> {subCard?.card_description}
									</div>
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">VRN:</b>
										{subCard?.payload?.VRN}
										<br />
										<b class="mr-2">VYN:</b>
										{subCard?.payload?.VYN}
										<br />
										<b class="mr-2">VBN:</b>
										{subCard?.payload?.VBN}
										<br />
									</div>

									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">IR : </b>
										{subCard?.payload?.IR}
										<br />
										<b class="mr-2">IY: </b>
										{subCard?.payload?.IY}
										<br />
										<b>IB: </b>
										{subCard?.payload?.IB}
										<br />
									</div>
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">KVA : </b>
										{subCard?.payload?.KVA}
										<br />
										<b class="mr-2">KW : </b>
										{subCard?.payload?.POW}
										<br />
										<b class="mr-2">PF : </b>
										{subCard?.payload?.PF}
										<br />
									</div>
								</div>
							</div>
							<div class="card-footer " style={{ display: "flex", justifyContent: "end", padding: "0px" }}>
								<span className="text-end mr-2">
									{currentDate.diff(moment.utc(subCard?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"), "minutes") === 0
										? moment
												.utc(subCard?.payload?.ENTRYTS || null)
												.add(-moment().utcOffset(), "m")
												.fromNow(true)
										: currentDate.diff(moment.utc(subCard?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"), "minutes") +
										  ` minutes `}
									ago
								</span>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-6 col-xl-6">
						<div class="card">
							<div class="card-header">
								<h4>Card Details</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px" }}>
								<div class="row">
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">Card Name: </b>
										{subSubCard?.CARD_NAME}
										<br />
										<b class="mr-2"> Description:</b> {subSubCard?.card_description}
									</div>
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">VRN:</b>
										{subSubCard?.payload?.VRN}
										<br />
										<b class="mr-2">VYN:</b>
										{subSubCard?.payload?.VYN}
										<br />
										<b class="mr-2">VBN:</b>
										{subSubCard?.payload?.VBN}
										<br />
									</div>

									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">IR : </b>
										{subSubCard?.payload?.IR}
										<br />
										<b class="mr-2">IY: </b>
										{subSubCard?.payload?.IY}
										<br />
										<b>IB: </b>
										{subSubCard?.payload?.IB}
										<br />
									</div>
									<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<b class="mr-2">KVA : </b>
										{subSubCard?.payload?.KVA}
										<br />
										<b class="mr-2">KW : </b>
										{subSubCard?.payload?.POW}
										<br />
										<b class="mr-2">PF : </b>
										{subSubCard?.payload?.PF}
										<br />
									</div>
								</div>
							</div>
							<div class="card-footer" style={{ display: "flex", justifyContent: "end", padding: "0px" }}>
								<span className="text-end mr-2">
									{currentDate.diff(moment.utc(subSubCard?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"), "minutes") ===
									0
										? moment
												.utc(subSubCard?.payload?.ENTRYTS || null)
												.add(-moment().utcOffset(), "m")
												.fromNow(true)
										: currentDate.diff(
												moment.utc(subSubCard?.payload?.ENTRYTS || null).add(-moment().utcOffset(), "m"),
												"minutes"
										  ) + ` minutes `}
									ago
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="card">
							<div className="table-responsive">
								<table className="table table-striped" id="table-1">
									<thead>
										<tr>
											<th className="text-center">ID</th>
											<th>IMEI</th>
											<th>CONSUMER UNIQUE ID</th>
											<th>VRN</th>
											<th>VYN</th>
											<th>VBN</th>
											<th>IR</th>
											<th>IY</th>
											<th>IB</th>
											<th>KW</th>
											<th>PF</th>
											<th>KVA</th>
											<th>ENTRYTS</th>
										</tr>
									</thead>
									<tbody>
										{consumerSlave &&
											consumerSlave.map((item) => (
												<tr style={{ height: "4rem" }} className="trheigth">
													<td className="text-center">{item?.id || "0"}</td>
													<td>{item?.IMEI || "0"}</td>
													<td>{item?.CONSUMER_UNIQUE_ID}</td>
													<td>{item?.VRN || "0"}</td>
													<td>{item?.VYN || "0"}</td>
													<td>{item?.VBN || "0"}</td>
													<td>{item?.IR || "0"}</td>
													<td>{item?.IY || "0"}</td>
													<td>{item?.IB || "0"}</td>
													<td>{item?.POW || "0"}</td>
													<td>{item?.PF || "0"}</td>
													<td>{item?.APOW || "0"}</td>
													<td style={{ width: "100px" }}>
														{moment.utc(item?.ENTRYTS || "").format("DD/MM/YYYY HH:mm:ss")}
													</td>
												</tr>
											))}
										<tr>
											<td colSpan={3} className="text-center">
												<b>Total</b>
											</td>
											<td>{totalTabledata?.payload?.VRN}</td>
											<td>{totalTabledata?.payload?.VYN}</td>
											<td>{totalTabledata?.payload?.VBN}</td>
											<td>{totalTabledata?.payload?.IR}</td>
											<td>{totalTabledata?.payload?.IY}</td>
											<td>{totalTabledata?.payload?.IB}</td>
											<td>{totalTabledata?.payload?.POW}</td>
											<td>{totalTabledata?.payload?.PF}</td>
											<td>{totalTabledata?.payload?.KVA}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
