import React, { useEffect, useState} from "react";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { Link,useHistory } from "react-router-dom";
import { Briefcase } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import { BootstrapTooltip } from "../common";
import { MetterData } from "../Apicommon";
import moment from "moment";
import { CSVLink } from "react-csv";
// import Viewuser from '../viewuser/Viewuser'
$.fn.DataTable = dt;

export default function Slavedevicedata() {
    const [meterdata, setmeterdata] = useState([]);
	const [loader, setloader] = useState(false);
    const onHandalemeterData=async()=>{
        setloader(true);
		$(".loader").fadeOut("slow");
        const data=await MetterData()
		if (data.success === true) {
const arrayoFdata=[]
            data.data.data.length>0 &&data.data.data.map((item, id) => {
                                                                var slave=JSON.parse(item.SLAVES_ID_U)
                                                                slave.DETAILS_U.map((data)=>{
                                                                        arrayoFdata.push(data)
                                                                    })
                                                            })
			setmeterdata(arrayoFdata);
			setloader(false);
		} else {
			setloader(false);
		}
    }
    useEffect(()=>{
        onHandalemeterData().then(() =>
        setTimeout(function () {
            $("#table-1").DataTable({destroy: true,"pageLength": 50});
        }, 1500)
    );
    },[])
  return (
    <>
        <div className="main-content">
					{loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/group-dashboard">
									<i className="fas fa-tachometer-alt"></i> Home
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link to="/gsm-modem">
									<Briefcase style={{ height: "16px" }} /> Consumer
								</Link>
							</li>

							<li className="breadcrumb-item">
								<Link to="/gsm-modem">
									<i className="fas fa-list"></i> GSM Modem
								</Link>
							</li>
						</ol>
					</nav>
					<section className="section">
						<div className="section-body">
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<h4>All Slave Device</h4>
											<div className="card-header-action">
												<Link to="/add-consumer" className="btn btn-info text-white mr-2">
													<BootstrapTooltip title="Add">
														<span>
															<i
																className="fas fa-plus text-white"
																style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
															></i>
														</span>
													</BootstrapTooltip>
												</Link>
												<button type="button" className="btn btn-info mr-2">
													<BootstrapTooltip title="Filter">
														<span>
															<i className="fa fa-filter text-white"></i>
														</span>
													</BootstrapTooltip>
												</button>
												<div className="dropdown d-inline mr-2">
													<button
														className="btn btn-info dropdown-toggle mr-2"
														type="button"
														id="dropdownMenuButton3"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
														data-placement="bottom"
														title="Download"
													>
														<i className="fa fa-download mr-1"></i>
													</button>
													<div className="dropdown-menu">
														<a className="dropdown-item" href="https://eums.memighty.com/Export/download_gsmconsumer_pdf">
															PDF
														</a>
														<a className="dropdown-item" href="https://eums.memighty.com/Export/exportexcelgsmconsumer">
															Excel
														</a>
														<a className="dropdown-item">
														<CSVLink data={meterdata}>CSV</CSVLink>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<table className="table table-striped" id="table-1">
													<thead>
														<tr>
															<th className="text-center">Unique No</th>
															<th >Consumer Slave Id</th>
															<th>Consumer Device Name</th>
															<th>Oprete time</th>
															<th>Script Decode Id</th>
															<th >Load Suravey</th>
															<th>MidNight</th>
															<th>Created At</th>
                                                            <th>Created At Duration</th>
														</tr>
													</thead>
													<tbody>
														{meterdata.length>0 &&
															meterdata.map((data, id) => (
                                                                        <tr style={{ height: "4rem" }} className="trheigth" key={id}>
																		<td>{data.SLAVEID_U}</td>
																		<td>{data.CONSSLAVEUID_S}</td>
																		<td>{data.CONSDEVNAME_U}</td>
																		<td>{data.OPERATETIME_U}</td>
																		<td>{data.SCRIPTDECODEID_S}</td>
																		<td>{data.LOADSURVEY_S}</td>
																		<td>{data.MIDNIGHT_S}</td>
																		<td>{moment(data.CREATED_AT_S).format("DD/MM/YYYY hh:mm:ss a")}</td>
                                    <td>{moment(data.CREATED_AT_S).fromNow()}</td>
																	</tr>
																	
                                                        ))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
    </>
  )
}
