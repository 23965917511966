import './App.css';
import Login from './login/Login';
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import Navbar from './navbar/Navbar';
import Meter from './meter/Meter';
import Modem from './modem/Modem';
import Deshbord from './Deshbord/Deshbord';
import Gsmbuffer from './gsmbuffer/Gsmbuffer';
import Gsmmodem from './Gsmmodem/Gsmmodem';
import Service from './service/Service';
import Rolesuser from './rolesuser/Rolesuser';
import Userprofile from './Userprofile/Userprofile';
import AddUser from './AddUser/AddUser';
import './assets/css/style.css'
import './assets/css/components.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Viewuser from './viewuser/GsmViewuser';
import Header from './Header/Header';
import GSMSignalStrength from './GSMSignalStrength/GSMSignalStrength';
import Slavedevicedata from './Slavedata/Slavedevicedata';
import consumerSlavebyId from './Consumerslavebyid/ConsumerSlavebyIds';
import PrivetRoute from './PrivetRoute';
import 'antd/dist/antd.css';
import AddRoles from './rolesuser/AddRoles';
import Gsmheartbeat from './Gsmmodem/Gsmheartbeat';
import AddLoginUser from './AddLoginUser/AddLoginUser';
import User from './AddLoginUser/User';
import moment from 'moment';
import Connect_deshboard from './Connect_deshboard/Connect_deshboard';
import Connect_slave_details from './Connect_slave_details/Connect_slave_details';
import SubDeshboard from './Deshbord/SubDeshboard';
import SubtoSubDeshboard from './Deshbord/SubtoSubDeshboard';
import SlavesTable from './Deshbord/SlavesTable';
import DashboardGraph from './DashboardGraph/DashboardGraph';
import Notification from './Notification/Notification';
import Databasetable from './Databasetable/Databasetable';

function App(props) {

  moment.updateLocale("en", {
		relativeTime: {
		  future: "in %s",
		  past: "%s ago",
		  s: " %d seconds ago",
		  ss: "%d seconds ago",
		  m: "%d min ago",
		  mm: "%d min ago",
		  h: "an hours ago",
		  hh: "%d hours ago",
		  d: "a day ago",
		  dd: "%d days ago",
		  w: "a week ago",
		  ww: "%d weeks ago",
		  M: "a month ago",
		  MM: "%d months ago",
		  y: "a year ago",
		  yy: "%d years ago"
		}
	  });
    moment.tz.add("Asia/Calcutta|HMT BURT IST IST|-5R.k -6u -5u -6u|01232|-18LFR.k 1unn.k HB0 7zX0");
moment.tz.link("Asia/Calcutta|Asia/Kolkata");
  return (
    <>
      {props.history.location.pathname === '/' ? "" : <Header />}
      <Switch>
        <Route exact path="/" component={withRouter(Login)} />
        <PrivetRoute path="/userprofile" Component={withRouter(Userprofile)} exact />
        <PrivetRoute path="/gsm-modem" Component={withRouter(Gsmmodem)} exact />
        <PrivetRoute path='/slave-device' Component={Slavedevicedata}/>
        <PrivetRoute path="/modem-driver" Component={withRouter(Modem)} exact />
        <PrivetRoute path="/meter-driver" Component={withRouter(Meter)} exact />
        <PrivetRoute path="/consumer-meter-details/:consumer_id" Component={withRouter(consumerSlavebyId)} exact />
        <PrivetRoute path="/view-user/:consumer_id" Component={withRouter(Viewuser)} exact />
        <PrivetRoute path="/service-profile" Component={withRouter(Service)} exact />
        <PrivetRoute path="/gsm-buffer" Component={withRouter(Gsmbuffer)} exact />
        <PrivetRoute path="/roles-users" Component={withRouter(Rolesuser)} exact />
        <PrivetRoute path="/add-consumer" Component={withRouter(AddUser)} exact />
        <PrivetRoute path="/gsm-signal-strength" Component={withRouter(GSMSignalStrength)} exact />
        <PrivetRoute path="/group-dashboard" Component={withRouter(Deshbord)} />
        <PrivetRoute path="/add-roles" Component={withRouter(AddRoles)} />
        <PrivetRoute path="/gsm-heartbeat/:IMEI" Component={withRouter(Gsmheartbeat)} exact />
        <PrivetRoute path='/users' Component={withRouter(AddLoginUser)} exact />
        <PrivetRoute path='/add-users' Component={withRouter(User)} exact />
        <PrivetRoute path='/connectivity-deshboard' Component={withRouter(Connect_deshboard)} exact />
        <PrivetRoute path='/slaves-connecvity-details/:SRNO' Component={withRouter(Connect_slave_details)} exact />
        <PrivetRoute path='/dashboard-sub-view/:SRNO/:deshboardId' Component={withRouter(SubDeshboard)} exact />
        <PrivetRoute path='/dashboard-sub-to-sub-view/:SRNO/:deshboardId/:subcardId' Component={withRouter(SubtoSubDeshboard)} exact />
        <PrivetRoute path='/dashboard-sub-to-sub-TableView/:SRNO/:deshboardId/:subcardId/:lastcardId' Component={withRouter(SlavesTable)} exact />
        <PrivetRoute path='/dashboard-graph-view/:SRNO' Component={withRouter(DashboardGraph)} exact />
        <PrivetRoute path='/notification' Component={withRouter(Notification)} exact />
        <PrivetRoute path='/database-table-list' Component={withRouter(Databasetable)} exact />

      </Switch>
      <ToastContainer position={"top-right"} autoClose={3000} limit={4}/>
    </>
  );
}

export default withRouter(App);
