import React from 'react'
import * as $ from 'jquery'
import dt from 'datatables.net-dt'
import { Link } from 'react-router-dom';
import { BootstrapTooltip } from '../common';
import { Lock } from 'react-feather';
$.fn.DataTable = dt
export default function modem() {
    $(document).ready(function () {
        $('#table-1').DataTable()
    });
    return (
        <>
            <div className="main-content">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/group-dashboard"><i className="fas fa-tachometer-alt"></i> Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/"><Lock style={{ height: '16px' }} /> Admin</Link></li>
                        <li className="breadcrumb-item"><Link to="/modem-driver"><i className="fas fa-list"></i> Modem Driver</Link></li>
                        <li className="breadcrumb-item"><Link to="/modem-driver"> All Modem Driver</Link></li>
                    </ol>
                </nav>
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12"></div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Modem Driver</h4>
                                            <div className="card-header-action">
                                                <Link to="/add-consumer" className="btn btn-info text-white mr-2"><BootstrapTooltip title="Add"><span><i className="fas fa-plus text-white" style={{ fontSize: '16px', paddingLeft: '5px', paddingRight: '5px' }} ></i></span></BootstrapTooltip></Link>
                                                <button type="button" className="btn btn-info mr-2" ><BootstrapTooltip title="Filter"><span><i className="fa fa-filter text-white"></i></span></BootstrapTooltip></button>
                                                <div className="dropdown d-inline mr-2">
                                                    <button className="btn btn-info dropdown-toggle mr-2" type="button" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-placement="bottom" title="Download"><i className="fa fa-download mr-1"></i></button>
                                                    <div className="dropdown-menu" >
                                                        <a className="dropdown-item" href="https://eums.memighty.com/Export/download_gsmconsumer_pdf">PDF</a>
                                                        <a className="dropdown-item" href="https://eums.memighty.com/Export/exportexcelgsmconsumer">Excel</a>
                                                        <a className="dropdown-item" href="https://eums.memighty.com/Export/exportcsvgsmconsumer">CSV</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div id="table-1_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"><div className="row"><div className="col-sm-12"><table className="table table-striped dataTable no-footer" id="table-1" role="grid" aria-describedby="table-1_info">
                                                    <thead>
                                                        <tr role="row"><th className="text-center sorting_asc" tabIndex="0" aria-controls="table-1" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="
                              #
                            : activate to sort column descending" style={{ width: ' 50.4375px' }}>
                                                            Unique No
                                                        </th><th className="sorting" tabIndex="0" aria-controls="table-1" rowSpan="1" colSpan="1" aria-label="Task Name: activate to sort column ascending" style={{ width: '730px' }}> Name</th><th className="sorting" tabIndex="0" aria-controls="table-1" rowSpan="1" colSpan="1" aria-label="Status: activate to sort column ascending" style={{ width: '120px' }}>Status</th></tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className='odd trheigth'>
                                                            <td>1</td>
                                                            <td>AMR EUMS GSM4 DLMS232 PN230V-1A</td>
                                                            <td>
                                                                <div className="badge badge-success badge-shadow">Active</div>
                                                            </td>
                                                        </tr>
                                                        <tr className='even trheigth'>
                                                            <td>2</td>
                                                            <td>AMR EUMS GSM4 DLMS232 PN230V-1A</td>
                                                            <td>
                                                                <div className="badge badge-success badge-shadow">Active</div>
                                                            </td>
                                                        </tr>
                                                        <tr className='odd trheigth'>
                                                            <td>3</td>
                                                            <td>AMR EUMS GSM4 DLMS232 PN230V-1A</td>
                                                            <td>
                                                                <div className="badge badge-success badge-shadow">Active</div>
                                                            </td>
                                                        </tr>
                                                        <tr className='even trheigth'>
                                                            <td>4</td>
                                                            <td>AMR EUMS GSM4 DLMS232 PN230V-1A</td>
                                                            <td>
                                                                <div className="badge badge-success badge-shadow">Active</div>
                                                            </td>
                                                        </tr>
                                                        <tr className='odd trheigth'>
                                                            <td>5</td>
                                                            <td>AMR EUMS GSM4 DLMS232 PN230V-1A</td>
                                                            <td>
                                                                <div className="badge badge-success badge-shadow">Active</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table></div></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
